export default {
    eb1a: {
        //表单第一部分
        firstTitle: 'P1.关于申请受益人/组织的信息',
        firstPart: '第一部分_关于申请受益人/组织的信息',
        firstDetail: '如果个人提交此申请书，请回答编号1.a. - 1.c的问题;如果公司或组织提交此申请书，请回答编号2的问题',
        familyName: '姓(姓氏)',
        firstName: '名(名字)',
        midName: '中间名',
        company: '公司或组织名称',
        emailAddress: '申请受益人邮寄地址',
        p1_3Hint:'州、省 根据国家输入情况，必须二填一',
        findEmail: 'USPS邮政编码查找',
        recipient: '收件人',
        address: '地址',
        apartment: '公寓',
        house: '住宅',
        floor: '楼层',
        cityProper: '市/区',
        state: '州',
        postCode: '邮政编码',
        province: '省',
        country: '国家/地区',
        otherInfo: '其他信息',
        otherEIN: '美国国税局雇主识别号(EIN)',
        otherSSN: '美国社会保险号(SSN)(若有)',
        otherUSCIS: 'USCIS在线账号(若有)',
        //表单第二部分
        secondTitle: 'P2.申请类型',
        p2_2Hint:'如果是第二次申请，请选择2.a,并填写上一次申请的申请号，否则不填',
        secondPart: '第二部分_申请类型',
        petitionFor: '申请类型（单选）：',
        extraordinary: '杰出人才。',
        outstandings: '杰出的教授或研究员。',
        multinational: '跨国公司高管或经理。',
        advancedDegree: '拥有高级学位的专业人士或具有杰出能力的外国人（不寻求国家利益豁免(NIW)）。',
        professional: '专业人士（至少拥有学士学位或美国学士学位同等学历的外国学位）的专业人士。',
        skilledWorker: '熟练工人（需要至少两年的专业培训或经验）。',
        otherWorker: '其他工人（需要不低于两年的培训或经验）。',
        niwAlien: '申请NIW的外国人（是拥有高级学位的专业人士或具有杰出能力的外国人）',
        purpose: '本次提交申请书的目的（单选）：',
        previous: '为修正前次提交的申请。前次提交申请的接受码：',
        schedule: '补充附表A、第一组或第二组的指定。',
        //表单第三部分
        thirdTitle: 'P3.申请受益人的信息',
        thirdPart: '第三部分_申请受益人的信息',
        birthDate: '出生日期（月/日/年）',
        birthCity: '出生的市/区',
        birthState: '出生的州或省',
        birthCountry: '出生的国家',
        citizenCountry: '国籍',
        p3_2Hint:'州、省 根据国家输入情况，必须二填一',
        regisNumber: '外国人登记号码（若有）',
        usSSN: '美国SNN（若有）',
        lastArrival: '申请受益人最近一次入境的记录',
        filingUs: '如申请受益人现居美国，请提供以下信息。',
        lastDate: '最后一次入境日期（月/日/年）',
        recordNumber: '出入境表格I-94记录编号',
        shownDate: '表格I-94上显示的授权居留到期日期（月/日/年）',
        paroled: '表格I-94的状态（例如：入学，假释（若为假释））',
        passportNum: '护照号码',
        travelNum: '旅行证件号码',
        issuCountry: '护照或旅行证件签发国',
        expirationDate: '护照或旅行证件的到期日期（月/日/年）',
        p3_10Hint:'现在暂居美国或在美工作，10-15必填' ,
        //表单第四部分
        forthTitle: 'P4.处理信息',
        forthPart: '第四部分_处理信息',
        selectThree: '请提供第三部分中所提到的申请受益人的信息（单选）',
        P4_1Hint:'现居于美国境外，1-2必填',
        visaAt: '申请受益人将在美国境外申请签证。境外面签的大使馆或美国领事馆地址：',
        contryFour: '国家',
        cityTown: '城市或城镇',
        lawful: '申请受益人现在美国境内并将美国境内申请身份调整，申请受益人变更为合法永久居民身份',
        lastPermanent: '申请受益人当前居住的国家，如果现居美国，请填写在境外最后一个永久居住的国家。',
        foreignAddress: '如果在第三部分提供了美国地址，请填写编号3.a.-3.f.，提供该申请受益人的外国地址：',
        romanLetter: '如果该申请受益人的母语字母不是罗马字母，请用母语拼音在项目编号4.a.-4.c.中键入该申请受益人的姓名和地址：',
        mailAddress: '邮寄地址',
        additionInfo: '如果您对以下项目编号6.a.-10回答“是”，请在第十一部分附加信息中，提供的空格中填写案件号、办理办公室、决定日期和决定。',
        applicatForm: '在提交本I-140表格的同时，您是否还提交了其他申请表？',
        answer: '如果您对项目编号为6.a.的问题回答是，请勾选所有同时提交的表格。',
        formOne: '表格I-485',
        formTwo: '表格I-131',
        formThree: '表格I-765',
        otherAddition: '其他（在第十一部分附加信息中提供解释。）',
        removalProceed: '申请受益人是否有遣返记录？',
        anyVisa: '申请受益人是否提交过其他移民申请（个人申请/代理申请）？',
        supportAnother: '申请受益人是否在没有劳工证原件的情况下提交此请愿书，因为劳工证原件之前作为支持材料随另一份表格I-140已经提交？',
        laborDOL: '如果您在没有劳工证件原件的情况下提交次申请书，您是否要求美国公民及移民局(USCIS)要求劳工部（DOL）提供劳工证副本？',
        yes: '是',
        no: '否',
        //表单第五部分
        fifthTitle: 'P5.关于申请受益人的更多信息',
        fifthPart: '第五部分_关于申请受益人的更多信息',
        petitionType: '申请受益人类型（单选）',
        employer: '雇主',
        self: '自己',
        other: '其他（例如，合法永久居民、美国公民或其他代理人）',
        followInfo: '若申请受益人为某公司/组织，请提供以下信息：',
        businessType: '业务类型',
        establishDate: '成立日期（月/日/年）',
        currentNum: '当前美国员工数量',
        grossAnnual: '年收入总额',
        netAnnual: '年收入净额',
        NAICS: 'NAICS代码',
        DOLCase: 'DOL劳工证号码',
        DOLFrom: '劳工证DOL申报日期（月/日/年）',
        DOLto: '劳工证到期日期（月/日/年）',
        provideInfo: '若申请人为个人填写此申请书，请提供以下信息：',
        occupation: '职业',
        annualIncome: '年收入',
        //表单第六部分
        sixthTitle: 'P6.关于申请受益人的更多信息',
        sixthPart: '第六部分_关于申请受益人预定就业的基本信息',
        jobTitle: '职衔',
        socCode: 'SOC代码',
        nontechnical: '非技术职位描述',
        fullTime: '是否为全职职位？',
        howHours: '若在第4项的回答是“否”，填写该职位每周工作时间',
        permanent: '是否为永久职位？',
        newPosition: '是否为新设立职位？',
        wages: '工资（具体说明是时薪、周薪、月薪还是年薪）：',
        per: '每',
        workLocation: '工作地点',
        newAddress: '如果申请受益人的预定工作地点不是您在第一部分中提供的地址，请回答项目编号9.a.-9.e.的问题，并提供新工作地址',
        //表单第七部分
        seventhTitle: 'P7.申请受益人配偶及所有子女信息',
        seventhPart: '第七部分_申请受益人配偶及所有子女信息',
        sevenDetail: '请在第七部分，提供申请受益人配偶和所有子女的信息。（此外，请确认以上人员是否会作为副申请人申请签证或进行身份调整。）如果您需要额外的空间来提供有关其他家庭成员的信息，请使用第十一部分补充材料页。',
        person: '家庭成员',
        birthCoun: '出生国',
        relationship: '与申请受益人的关系',
        adjustment: '他或她是否正在申请调整身份？',
        apply: '他或她是否正在申请海外签证？',
        //表单第八部分
        eighthTitle: 'P8.申请受益人或授权签字人的陈述、联系信息、确认、声明和签名',
        eighthPart: '第八部分_申请受益人或授权签字人的陈述、联系信息、确认、声明和签名',
        eighthNote: '注: 完成该部分前请阅读 I-140说明书的“处罚”部分。',
        statement: '申请受益人或授权签字人陈述',
        noteTwo: '注: 勾选1.a.或1.b.项，若适用，可勾选第2项。',
        understand: '我能读懂英语，我已阅读并了解回答了此申请书 上的所有问题和说明。',
        partNine: '在第9部分提及的译员已将申请书上的所有问题和 说明告知于我，我用自己擅长的语言做了回答。译员翻译的所有信息我已知悉。',
        request: '应我的要求，第10部分提及的准备方基于我提供的信息为我准备了申请书。',
        contactInfo: '授权签字人的联系信息',
        p8_3Hint:'5,6.需填写美国手机号，如没有，请填写7.',
        authorizeName: '授权签字人的姓氏',
        authorizeGiven: '授权签字人的名字',
        authorizeTitle: '授权签字人的职衔',
        authorizeDay: '授权签字人的日间电话号码',
        authorizeMobile: '授权签字人的手机号（有就填写）',
        authorizeEmail: ' 授权签字人的邮箱地址（有就填写）',
        declaration: '申请或授权签字声明和资格',
        agreement1: '本人所提交的任何文件副本均为未经更改的原始文件复印 件，本人作为申请人，理解USCIS后期可能要求提交 原始文件。',
        agreement2: '本人在此授权，若必要，USCIS或其他实体和个人可查阅我 的个人记录或申请组织的文件，以确定是否符合移民资格或 是否合法。本人了解USCIS有权使用任何公共信息资源对我 提交的申请和证据材料进行审查。本人知悉USCIS可采用任 何适用方式以核实所有申请支持证据和材料，包括但不限于 现场合规审查。 ',
        agreement3: '若为组织代理填写，本人特此证明是由本人授权代理。',
        agreement4: ' 本人特此证明，本人已审阅本申请书，知悉申请书中包含 的所有信息以及随申请书提交的所有信息内容，且已确认 所有信息均完整、真实、正确，如作伪证，愿受惩罚。',
        signature: '申请人或授权签字人的签名',
        p8_8Hint:'这里需要打印该表后，本人亲自签名',
        signatureTwo: '申请人的签名',
        signatureDate: '签署日期（月/日/年）',
        attention: '所有申请收益人和授权签字人注意:',
        signatories: '若您未能完整填写本申请书或未能提交说明书中所要求 的文件，USCIS可能延迟申请通过时间或拒绝您的申请。',
        //表单第九部分
        ninethTitle: 'P9.译员联系信息、资质和签名',
        ninethPart: '第九部分_译员联系信息、资质和签名',
        interpreter: '提供译员以下相关信息',
        interpreterName: '译员全名',
        interpreterLast: '译员的姓氏',
        interpreterFirst: '译员的名字',
        interpreterBusi: '译员从事业务或所在组织（有就请填写）',
        interpreterMail: '译员邮寄地址',
        interpreterContact: '译员的联系信息',
        interpreterDaytime: '译员日间电话号码（美国号码，有就请填写）',
        interpreterMobile: '译员移动手机号码',
        interpreterEmail: '译员的电子邮件地址（有就请填写）',
        certification: '译员声明',
        certify: '本人特此证明，如作伪证愿受处罚',
        fluent: '本人擅长英语和',
        specifyLanguage: '该语言为第 8 部分 1.b. 所特指的语言。我已将此申请书的说明以及申请书上的所有问题与答复完整地告知申请受益人本人或授权签字人。申请受益人或授权签字人向我表明，其已理解申请书的说明以及其上的所有问题与答复，并且对于申请受益人或授权签字人确认和声明部分以及答复也进行了确切说明。',
        interpreterSign: '译员签名',
        signDate: '签署日期（月/日/年）',
        //表单第十部分
        tenthTitle: 'P10.申请准备方签字、联系信息、声明',
        tenththPart:'第十部分_申请准备方签字、联系信息、声明', 
        preparer: '请提供准备方相关信息。',
        preparerName: '准备方全名',
        preparerLast: '准备方的姓氏',
        preparerFirst: '准备方的名字',
        preparerBuis: '准备方从事业务或所在组织（有就填写）',
        preparerMail: '准备方的邮寄地址',
        preparerContact: '准备方联系信息',
        preparerDaytime: '准备方日间电话号码',
        preparerMobile: '准备方移动手机号码（美国号码，有就填写）',
        preparerEmail: '准备方的电子邮件地址（有就填写）',
        preparerStatement: '准备方声明',
        consent: '本人并非代理人或派遣代表，本人在申请受益人同意下，代申请受益人拟备本申请书。',
        attorney: '本人是代理人或派遣代表，在本次代理申请中进行的所有活动均符合申请准备范围。',
        extends: '超出准备范围',
        notExtends: '不会超出本申请的准备范围',
        preparerNote: '注：若准备方为代理人或派遣代表，除此份申请书外，还需提交 一份填写完整的G-28表《作为律师或授权代表出庭通知书》 或 G-28 I表《作为律师在美国地界以外事务出庭通知书》。',
        preparerCertification: '准备方证明',
        preparerSignNote: '本人在此签字证明，若有伪证行为愿接受处罚。本人应申请受益 人或授权签名人的要求，准备了此份申请书。申请受益人 本人已查阅此份申请书，包括申请受益人或授权签名人的 确认和声明部分，经申请受益人本人确认，申请书和证明 文件中的所有信息均完整、真实和正确。',
        preparerSign: '准备方签字',
        preparerData: '签署日期（月/日/年）',
        //表单第十一部分
        elevenTitle: 'P11.补充材料',
        elevenPart: '第十一部分_补充材料',
        elevenExtra: '如还需提供补充材料，可使用以下空白处。如所需提供信 息过多，空白处不足，可复印本页或另附纸张填写，并连 同本申请书一并提交。在每页纸顶部写上姓名和外国人登 记号码（如有）；注明回答内容所在页码、部码和编码；每页纸均需签名并注明日期。',
        IRS: '美国国税局雇员身份证号码',
        pageNum: '页码',
        partNum: '部码',
        itemNum: '编码'
    },
    homePage: {
                //表单第一部分
        LandR:'登录/注册',
        language: '更换语言',
        navigation1: '首页',
        navigation2: 'EB-1A',
        navigation3: 'NIW',
        navigation4: '移民代理',
        navigation5: '免费评估',
        navigation6: '答疑专区',
        // firstTerm
        title1: '在线DIY您的专属移民计划',
        content1: 'DIY工作台将在线引导您的整个移民资料准备阶段',
        list1_1:'无须下载各种材料包',
        list1_2:'自行分类整理各种证据文件',
        list1_3:'只需按照工作台教学引导、框架模板，进行材料收集、填写、上传',
        list1_4:'在线编辑整理并一键生成PDF文件，打印签字后即可递交至移民局',
        advertising1: '仅需',
        advertising2: '$599',
        advertising3: '即可开启高效移民DIY申请',
        advertisingBtn: '查看报价',

        // swiper
        swperTitle:'用户反馈',
        swperTitle_tip:'移民团队收获的客户评价',

        // DIY_flowchart_List
        DIY_flowchart_List_title:'DIY 流程',
        DIY_flowchart_List_text1:'教学引导',
        DIY_flowchart_List_text2:'全程咨询',
        DIY_flowchart_List_text3:'填写上传',
        DIY_flowchart_List_text4:'在线预览',
        DIY_flowchart_List_text5:'材料审核',
        DIY_flowchart_List_text6:'翻译润色',
        DIY_flowchart_List_text7:'中英查看',
        DIY_flowchart_List_text8:'一键生成',
        DIY_flowchart_List_text9:'下载打印',

            //secondChunkTitle
        title2: '为什么选择CasePass',
        title2_en: 'Product advantages',

        secondChunkCardData1_title:'倾囊相授，解决移民信息差',
        secondChunkCardData1_tip:'无任何保留与信息差，让您无后顾之忧。',
        secondChunkCardData1_list1:'答疑专区',
        secondChunkCardData1_list2:'免费评估',
        secondChunkCardData1_list3:'DIY工具',
        secondChunkCardData1_list4:'案例展示',
        secondChunkCardData1_list5:'教学引导',

        secondChunkCardData2_title:'一目了然，一看就会',
        secondChunkCardData2_tip:'一目了然的设计、框架、教学和深具行业的客服团队，让您更轻松的完成移民申请',
        secondChunkCardData2_list1:'框架清晰',
        secondChunkCardData2_list2:'全程辅导',
        secondChunkCardData2_list3:'标准模版',
        secondChunkCardData2_list4:'操作指引',
        secondChunkCardData2_list5:'在线咨询',

        secondChunkCardData3_title:'价格美丽，诚意满满',
        secondChunkCardData3_tip:'每一个 case 我们都会竭尽全力为您服务，同时价格具有行业优势',
        secondChunkCardData3_list1:'价格透明',
        secondChunkCardData3_list2:'DIY服务',
        secondChunkCardData3_list3:'审核咨询',
        secondChunkCardData3_list4:'翻译服务',
        secondChunkCardData3_list5:'移民代理',

        secondChunkCardData4_title:'自己动手，质量更高',
        secondChunkCardData4_tip:'自己的文案角度更加打动移民官，传统移民文案无法用理性和感性的文案描述您经历和能力，自己更懂专业领域',
        secondChunkCardData4_list1:'自主撰写',
        secondChunkCardData4_list2:'批注建议',
        secondChunkCardData4_list3:'表达润色',
        secondChunkCardData4_list4:'同步预览',
        secondChunkCardData4_list5:'一键导出',

            //thirdlyChunkTitle
        title3:'DIY申请移民服务',

            //tabBtn 默认值
        tabBtn1:'在线DIY工具',
        tabBtn2:'审核咨询',
        tabBtn3:'翻译服务',

        tabBtn1_title:'自动化DIY申请工具',
        tabBtn1_tip:'移民项目计划专属DIY工作台 & 高效准备您的申请材料',
        tabBtn1_list1:'教学引导',
        tabBtn1_list2:'填写上传',
        tabBtn1_list3:'在线预览',
        tabBtn1_list4:'一键生成',
        tabBtn1_list5:'下载打印',
        tabBtn1_list6:'流程梳理',

        tabBtn2_title1:'在线咨询',
        tabBtn2_list1_title1:'一键呼叫：',
        tabBtn2_list1_tip1:'点击【在线咨询】按钮，立即发送咨询请求至移民顾问，快捷响应。',
        tabBtn2_list1_title2:'提升效率：',
        tabBtn2_list1_tip2:'解答表格填写、申请方向、亮点挖掘等疑问，系统规划移民方案，提高申请质量。',
        tabBtn2_list1_title3:'减轻负担：',
        tabBtn2_list1_tip3:'官费代付，为申请人提供便捷服务。',
        tabBtn2_list1_title4:'辅助收集：',
        tabBtn2_list1_tip4:'评判、选取、收集和创造材料，帮助更好地完成资料整理和准备。',

        tabBtn2_title2:'一键审核',
        tabBtn2_list2_title1:'专业知识和经验：',
        tabBtn2_list2_tip1:'评估您的材料是否合规，提供有益的建议和指导。 ',
        tabBtn2_list2_title2:'增加获批机会：',
        tabBtn2_list2_tip2:'避免常见错误，确保申请材料尽可能完整和准确。',
        tabBtn2_list2_title3:'提高移民申请质量：',
        tabBtn2_list2_tip3:'提升材料质量，确保可读性、格式规范和语法准确。',

        tabBtn2_title3:'专属客服',
        tabBtn2_list3_title1:'个性化：',
        tabBtn2_list3_tip1:'一对一专人对接，具体分析，解答疑惑，提供专业的建议和方案。 ',
        tabBtn2_list3_title2:'应答及时：',
        tabBtn2_list3_tip2:'移民全阶段随时响应，提高移民申请的效率和准确性。',
        tabBtn2_list3_title3:'全程跟进：',
        tabBtn2_list3_tip3:'提供持续指导，避免遗漏或错误，提高通过机会。',


        tabBtn2_title4:'批注建议',
        tabBtn2_list4_title1:'清晰明了：',
        tabBtn2_list4_tip1:'直观指出可能存在的问题、遗漏或错误，减少误解和遗漏。 ',
        tabBtn2_list4_title2:'传递直观：',
        tabBtn2_list4_tip2:'更有效地传达信息和意图，增加效率。',
        tabBtn2_list4_title3:'修改便捷：',
        tabBtn2_list4_tip3:'批注建议展示至需要改进处，便于修改和更新。',

        tabBtn3_title1:'逻辑纠正',
        tabBtn3_list1_title1:'明确意图：',
        tabBtn3_list1_tip1:'让移民材料逻辑合理、语句连贯，减少沟通障碍，让移民官员更好理解。',
        tabBtn3_list1_title2:'信息准确：',
        tabBtn3_list1_tip2:'确保表格和文件信息的准确无误，表达清晰，避免混淆。',
        tabBtn3_list1_title3:'符合要求：',
        tabBtn3_list1_tip3:'避免逻辑漏洞、文件之间的矛盾、不一致、不连贯等而引发疑问。',

        tabBtn3_title2:'文字润色',
        tabBtn3_list2_title1:'突出特点：',
        tabBtn3_list2_tip1:'根据个人特点进行调整，增强主张论述，提升个人竞争力。',
        tabBtn3_list2_title2:'语言优化：',
        tabBtn3_list2_tip2:'修饰句式结构、词汇选用和语法表达，更加流畅、自然和富有表现力。',
        tabBtn3_list2_title3:'减少差异：',
        tabBtn3_list2_tip3:'适应目标国的文化和语言规范、习惯和惯用表达，进行调整和修改。',

        tabBtn3_title3:'专业认证',
        tabBtn3_list3_title1:'符合官方要求：',
        tabBtn3_list3_tip1:'对翻译材料可加盖具有翻译认证资格的翻译章。',
        tabBtn3_list3_title2:'专业翻译团队：',
        tabBtn3_list3_tip2:'确保翻译材料的准确性和可靠性。',

        tabBtn3_title4:'中&英预览',
        tabBtn3_list4_title1:'预览效果：',
        tabBtn3_list4_tip1:'翻译完毕后，一键生成所有材料的中英对比浏览。',
        tabBtn3_list4_title2:'对比便捷：',
        tabBtn3_list4_tip2:'同步直观地查看原文和翻译文档，了解翻译的内容和质量。',
        tabBtn3_list4_title3:'快速定位：',
        tabBtn3_list4_tip3:'可快速浏览整个文档，找到特定的段落或关键信息，节省时间和精力。',

        bottomName:'了解详情',
        fourthlyChunk_title:'在线DIY申请平台，让移民申请一路畅通',
        fourthlyChunk_btn:'免费评估',
        
        footerLink_lie1_title:'关于我们',
        footerLink_lie1_list1:'品牌介绍',

        footerLink_lie2_title:'相关协议',
        footerLink_lie2_list1:'用户协议',
        footerLink_lie2_list2:'隐私保护说明',
        footerLink_lie2_list3:'购买协议',

        footerLink_lie3_title:'DIY美国',
        footerLink_lie3_list1:'EB-1A移民计划',
        footerLink_lie3_list2:'NIW移民计划',

        footerLink_lie4_title:'联系方式',
        footerLink_lie4_list2:'重庆市渝北区双子座(b)座',


        // immigrant  移民代理
        immigrant_title:'欢迎来到KR.run',
        immigrant_tip:'移民代理为您提供移民相关一系列全套服务，您可全程监督移民流程，我们为您提供过程服务，您只需验收结果。',
        immigrant_btn:'了解移民代理详情',

        immigrant_box2_title:'移民代理服务',
        immigrant_box2_tip:'申请咨询一站式服务',
        immigrant_list_text1:'专业评估',
        immigrant_list_text2:'方案策划',
        immigrant_list_text3:'材料收集',
        immigrant_list_text4:'证据整理',
        immigrant_list_text5:'撰写翻译',
        immigrant_list_text6:'校验标记',
        immigrant_list_text7:'审核跟进',
        immigrant_list_text8:'面试技巧',

        immigrant_fromName:'预约移民方案',
        immigrant_from1_name:'姓名',
        immigrant_from1_name_tip:'请输入姓名',
        immigrant_from1_phone:'电话号码',
        immigrant_from1_phone_tip:'请输入电话',
        immigrant_from1_authCode:'验证码',
        immigrant_from1_authCode_tip:'请输入验证码',
        immigrant_from1_authCode_tip2:'获得验证码',
        immigrant_from1_authCode_tip3:'重新发送',
        immigrant_from1_email:'电子邮箱',
        immigrant_from1_email_tip:'请输入邮箱',
        immigrant_from1_email_tip2:'请输入合法邮箱',
        immigrant_from1_WeiXin:'微信号',
        immigrant_from1_WeiXin_tip:'请输入微信号',
        immigrant_from1_time:'预约时间',
        immigrant_from1_time_tip1:'时区',
        immigrant_from1_time_tip2:'日期',
        immigrant_from1_time_tip4:'请选择日期',
        immigrant_from1_time_tip3:'时间',
        immigrant_from1_Submit:'提交',
        immigrant_from1_Cancel:'取消',
        
        immigrant_from1_IMGCode:'图形验证码',
        immigrant_from1_IMGCode_tip1:'请输入验证码',
        immigrant_from1_IMGCode_sure:'确认',
        immigrant_from1_IMGCode_cancel:'取消',


        immigrant_from1_sure_tip:'您已完成提交，我们将第一时间联系您',
        
        eb1a_head_text1:'美国EB-1A移民计划(DIY)',
        eb1a_head_text2:'项目介绍',
        eb1a_head_text3:'移民条件',
        eb1a_head_text4:'移民流程',

        eb1a_first_title:'美国EB-1A移民计划(DIY)',
        eb1a_first_text1:'EB-1A是专为杰出人才移民设置的绿卡类别,是绿卡申请中的第一优先类别,不需要雇主担保就可申请移民,要求申请人:',
        eb1a_first_text2:'在科学、艺术、商业、教育或体育等领域拥有高超的专业技能并在其所属领域里属于占少数顶尖人才且其才能和成就在其专业领域里获得国际国内的公认和推崇。',
        eb1a_first_btn1:'在线评估',
        eb1a_first_btn2:'现在就去DIY',
        eb1a_first_btn3:'查看报价',

        eb1a_second_title:'移民所需满足条件',
        eb1a_second_tip1:'购买DIY服务前请务必确认已满足基本条件(10项满足3条或以上)',
        eb1a_second_tip2_1:'如无法判断可',
        eb1a_second_tip2_2:'联系我们>',
        eb1a_second_list1_title:'获得重大奖项',
        eb1a_second_list1_tip:'因在专业领域成绩突出，获得国际国内重大奖项',
        eb1a_second_list2_title:'协会会员',
        eb1a_second_list2_tip:'因杰出成就被授予专业领域内重要协会成员身份',
        eb1a_second_list3_title:'媒体报道',
        eb1a_second_list3_tip:'专业媒体、行业媒体或其他主流媒体对申请人及其工作有过报道',
        eb1a_second_list4_title:'参与评审',
        eb1a_second_list4_tip:'单独或作为专家组成员评审同行业其他人的工作',
        eb1a_second_list5_title:'原创贡献',
        eb1a_second_list5_tip:'申请受益人在主张的专业领域做出重大意义的原创贡献',
        eb1a_second_list6_title:'文章/著作',
        eb1a_second_list6_tip:'申请受益人在专业、主要行业或其他主流刊物上发表学术文章或拥有著作权',
        eb1a_second_list7_title:'作品展出',
        eb1a_second_list7_tip:'申请受益人的作品参加过艺术性展览、展示',
        eb1a_second_list8_title:'领导性/关键性',
        eb1a_second_list8_tip:'申请受益人在某些信誉卓著的组织或机构中发挥领导性或关键性作用',
        eb1a_second_list9_title:'高薪酬',
        eb1a_second_list9_tip:'与同行业其他人相比，申请受益人因为专业领域的工作，获得明显更高的工资或高酬劳',
        eb1a_second_list10_title:'艺术商业成功',
        eb1a_second_list10_tip:'申请受益人在艺术表演上取得商业成功',


        eb1a_thirdly_title:'DIY申请流程操作演示',

        eb1a_fourthly_title:'EB-1A移民流程',

        eb1a_fourthly_list1_title:'前期评估',
        eb1a_fourthly_list1_content_p1:'1.“背景评估”有效理清思路，优化背景条件，提高成功率（围绕十大标准展开，契合3条即可）',
        eb1a_fourthly_list1_content_p2:'2.“精准定位”精准定位自己的专业领域，所有证据围绕专业领域来展开，与专业无关的材料直接放弃。（EB-1A成功的基础）',

        eb1a_fourthly_list2_title:'收集证据',
        eb1a_fourthly_list2_content_p1:'1.“搜集证据链”证据链清晰完整，且具有很强的说服力。',
        eb1a_fourthly_list2_content_p2:'2.选用主要内容（对杰出能力进行证明的文件资料）如：提交最高级别的奖项、挑选影响力最广的论文、专业媒体报道、专业协会等。',

        eb1a_fourthly_list3_title:'撰写材料',
        eb1a_fourthly_list3_content_p1:'1.“申请信”是申请材料的集大成部分',
        eb1a_fourthly_list3_content_p2:'无论是前面的评估定位还是证据链，最精华的部分都要体现在申请信里边，需要得到移民官认可我们在专业领域的杰出能力，在申请信中一般会突出客观性的证据；',
        eb1a_fourthly_list3_content_p3:'例如：获得的奖项、媒体的报道、发表的论文等等；要清楚地列出来尽量详实，避免啰嗦，不要遗漏关键的数据（如果证据太多，可额外做一份附录，便于移民官有效的选择性翻阅）',
        eb1a_fourthly_list3_content_p4:'2.“专家推荐信/证言”是申请材料的第二大件',
        eb1a_fourthly_list3_content_p5:'尽管“推荐信”没有被移民局规定是必须的，但申请人仍然要找到业内但权威专家来撰写，因为推荐人的份量可以间接体现申请人的水平，如推荐人本身就是业内名人，那么他说出来的话在移民官这里是会为您加分的',
        eb1a_fourthly_list3_content_p6:'3.“赴美计划”是申请材料的第三大件',
        eb1a_fourthly_list3_content_p7:'“赴美计划”需进行全面且深入的未来规划阐释。应充分证明自身的杰出性，还需有力证实自身才华对美国具有显著的积极意义，并且明确表达在抵达美国后持续施展自身才华的意愿。因此，“赴美计划”之中应当涵盖移民至美国后的“商业计划”。例如，需向移民官明晰地阐述自身专业领域当前于美国的发展态势，确切说明获取绿卡后将在美国从事的工作以及能够带来的利益；同时需阐述抵达美国后的 1 - 3 年内的短期计划以及 5 - 10 年内的长期计划，并且对这些计划的实施方式予以详细说明。',
        eb1a_fourthly_list3_content_p8:'“商业计划书”（包括雇主的雇佣信、行业调研报告以及与美国公司的商业合作合同等）能够更为有效地证明商业计划的可行性。',

        eb1a_fourthly_list4_title:'填写表格',
        eb1a_fourthly_list4_content_p1:'1.“填写I-140表格”(移民请愿)',
        eb1a_fourthly_list4_content_p2:'一份九页纸的申请表格，该表格递交给移民局，就是向美国官方表达移民诉求，如I-140表格被批准了就代表美国政府已经同意了申请人的移民请愿，虽说当前阶段还不能100%拿到绿卡，但也算成功了99%；',
        eb1a_fourthly_list4_content_p3:'当前I-140表格分为11个部分，主要以个人信息为主，美国会不定期更新表格，在递交前表格前需要检查是否为最新版本；',
        eb1a_fourthly_list4_content_p4:'“I-140”为EB-1A移民中决定性的一步表格多检查，请勿错填或漏填，否则很可能被移民局拒签',
        eb1a_fourthly_list4_content_p5:'I-140费用$700，移民局收到钱之后会发送一封收据通知（I-797C表格），这代表申请已正式进入了审理程序。(将于2024年4月1日上调至$715)',
        eb1a_fourthly_list4_content_p6:'2.“绿色通道”（Premium Processing—I-907优先审理程序）',
        eb1a_fourthly_list4_content_p7:'I-907优先审理程序（加急流程）支付$2500，15天内知道结果，通过与否支付金额不予退还；注：最新官方提示I-140表格的加急费用将于2024年2月26日正式上调至2805美金。',
        eb1a_fourthly_list4_content_p8:'15天内未得到结果，不仅会退钱而且还会免费加急服务（概率很低）',

        eb1a_fourthly_list5_title:'递交申请',
        eb1a_fourthly_list5_content_p1:'1.下载打印',
        eb1a_fourthly_list5_content_p2:'2.申请材料分类装订打包',
        eb1a_fourthly_list5_content_p3:'3.邮递选择',

        eb1a_fourthly_list6_title:'查询申请状态',
        eb1a_fourthly_list6_content_p1_1:'1.“注册',
        eb1a_fourthly_list6_content_p1_2:'USCIS官网',
        eb1a_fourthly_list6_content_p1_3:'账号”等待收取移民局收据',
        eb1a_fourthly_list6_content_p2:'2.“递交之后该EB-1A案件就进入审理等待期”',
        eb1a_fourthly_list6_content_p3:'(移民局未能给出明确的参考范围，正常审理周期6-13个月左右，不同审核中心有差异，审理速度与案件积压程度影响)',
        eb1a_fourthly_list6_content_p4_1:'3.“审理阶段“可通过收据上编号，去登录',
        eb1a_fourthly_list6_content_p4_2:'美国移民局USCIS的官网',
        eb1a_fourthly_list6_content_p4_3:'，在线查询审理进度',
        eb1a_fourthly_list6_content_p5:'4.“申请审理”结果:',
        eb1a_fourthly_list6_content_p6:'通过——（恭喜，准备面试）',
        eb1a_fourthly_list6_content_p7:'拒绝——（可以选择上诉或者复议，成功率比补件情况通过率低）',
        eb1a_fourthly_list6_content_p8:'补件——（Request for Evidence——要求证据的通知）',

        
        eb1a_fourthly_list7_title:'补件(若有)',
        eb1a_fourthly_list7_content_p1:'1.移民官员认为递交证据不足，那么就会收到补充额外材料的通知',
        eb1a_fourthly_list7_content_p2:'例如：材料里说被媒体广泛报道，但只提供了一两家媒体，移民官认为太少要求补充（补充材料需要围绕之前十大标准来进行），补件是很常见的情况，可以从中了解移民官的审核重点，进一步完善申请材料。',

        eb1a_fourthly_list8_title:'面试(技巧)',
        eb1a_fourthly_list8_content_p1_1:'1.在线注册填写信息「Register Online」:',
        eb1a_fourthly_list8_content_p1_2:'www.ustraveldocs.com',
        eb1a_fourthly_list8_content_p2:'2.预约时间体检「Get a medical exam in China」',
        eb1a_fourthly_list8_content_p3_1:'3.准备面试材料「Complete your pre-interview checklist 」：',
        eb1a_fourthly_list8_content_p3_2:'具体材料',
        eb1a_fourthly_list8_content_p4:'4.面试「Interview 」（请注意：为确保面试顺利进行，除特殊情况外，仅限申请人本人参加）',
        eb1a_fourthly_list8_content_p5_1:'5.',
        eb1a_fourthly_list8_content_p5_2:'阅读面试指南',
        eb1a_fourthly_list8_content_p5_3:'「Review interview guidelines」',

        eb1a_fourthly_list9_title:'获得绿卡',
        eb1a_fourthly_list9_content_p1:'正常情况下登陆美国1-3个月内，移民局会给予正式绿卡（此时走完EB-1A全流程）',
        eb1a_fourthly_list9_content_p2:'Ps.拿到绿卡两种方式',
        eb1a_fourthly_list9_content_p3:'1.人在美国境外：NVC程序拿到绿卡（对于大多数国人申请人来说，都是走广州领事馆签证流程）',
        eb1a_fourthly_list9_content_p4:'当I-140获批之后，美国移民局会将材料在一到两个月内递交到美国国家签证中心，正式进入NVC流程，NVC主要负责收取签证申请费、进行背景调查、资料审核;',
        eb1a_fourthly_list9_content_p5:'当I-140通过之后，NVC并不会马上联系申请人，期间还可能经历排期（排期现象很常见，每年配发绿卡数量都是有限额）',
        eb1a_fourthly_list9_content_p6:'NVC审理阶段（正常情况下需耗时三个月）',
        eb1a_fourthly_list9_content_p7:'缴纳审理费用$345/每个家庭成员',
        eb1a_fourthly_list9_content_p8:'填写DS-260表格',
        eb1a_fourthly_list9_content_p9:'按照要求提交婚姻公证',
        eb1a_fourthly_list9_content_p10:'出生公证',
        eb1a_fourthly_list9_content_p11:'无犯罪公证等等',
        eb1a_fourthly_list9_content_p12:'2.人在美国境内：通过I-485拿到绿卡',

        eb1a_fourthly_list10_title:'宣誓入籍',
        eb1a_fourthly_list10_content_p1:'根据移民监流程宣誓入籍',

        eb1a_fifth_btn:'购买服务',

        eb1a_popUp_title:'未登录',
        eb1a_popUp_toLogin:'去登录',
        eb1a_popUp_know:'知道了',
        eb1a_popUp_tip:'请先登录后，进行免费评估',

        niw_head_text1:'美国NIW移民计划(DIY)',
        niw_head_text2:'项目介绍',
        niw_head_text3:'移民条件',
        niw_head_text4:'移民流程',

        niw_first_title:'美国NIW移民计划（DIY）',
        niw_first_text1:'NIW（国家利益豁免）是美国职业移民第二类优先简称EB-2的一个子类别，移民申请可以豁免劳工证。要求申请人：',
        niw_first_text2:'符合EB-2A对高等学位的要求或者EB-2B对专精能力的要求',
        niw_first_text3:'证明受益人处于推动本领域发展的优势地位，所从事工作对于美国整个国家具有深远内在意义，符合经济、文化、教育或社会福利等方面的国家利益。',
        niw_first_btn1:'在线评估',
        niw_first_btn2:'现在就去DIY',
        niw_first_btn3:'查看报价',

        niw_second_title:'移民所需满足条件',
        niw_second_tip1:'购买DIY服务前请务必确认已满足NIW申请条件',
        niw_second_tip2_1:'如无法判断可',
        niw_second_tip2_2:'联系我们>',
        niw_second_left_t_title:'高等学历（符合以下任意1条）',
        niw_second_left_t_list1:'1.硕士或以上学历;',
        niw_second_left_t_list2:'2.拥有本科学历，且5年以上相关专业领域的工作经验;',
        niw_second_left_c_title:'二选一',
        niw_second_left_b_title:'特殊技能',
        niw_second_left_b_title_tip:'（6项至少满足3项）',
        niw_second_left_b_list1:'在其专业领域内曾经获得过学院、大学或其他教育培训机构颁发的官方学术证明;',
        niw_second_left_b_list2:'在相关领域内拥有十年或以上全职工作经验;',
        niw_second_left_b_list3:'在相关领域内拥有专业从业执照或专家认证;',
        niw_second_left_b_list4:'在相关工作领域有符合杰出能力的高收入证明;',
        niw_second_left_b_list5:'相关权威领域协会会员;',
        niw_second_left_b_list6:'在相关领域、政府机构、职业组织中有重大贡献的证明;',

        niw_second_right_tip:'必要条件',
        niw_second_right_list1:'申请人的专业研究领域有实质性价值和国家性的重要意义',
        niw_second_right_list2:'申请人处于推动本领域进展的有利位置',
        niw_second_right_list3:'申请人被豁免工作机会和劳工证要求有益于美国的国家利益',
        


        niw_thirdly_title:'DIY申请流程操作演示',

        niw_fourthly_title:'NIW移民流程',

        niw_fourthly_list1_title:'前期评估',
        niw_fourthly_list1_content_p1:'1.“背景评估”有效理清思路，优化背景条件，提高成功率（满足NIW申请基础条件与必要条件）',
        niw_fourthly_list1_content_p2:'2.“精准定位”精准定位自己满足NIW基础条件的哪一项（高等学位/专精能力），再判断申请人是否处于推动本领域发展的优势地位，所从事工作对于美国整个国家具有深远内在意义，符合经济、文化、教育或社会福利等方面的国家利益。（NIW成功的基础）',

        niw_fourthly_list2_title:'收集证据',
        niw_fourthly_list2_content_p1:'1.“搜集证据链”证据链清晰完整，是对申请人专业领域价值贡献的实质说明，需具有很强的说服力。',
        niw_fourthly_list2_content_p2:'2.摘取主要内容：对申请人拥有高等学位/专精能力以及其在专业领域的发展有利美国国家利益进行证明等文件资料。',

        niw_fourthly_list3_title:'撰写材料',
        niw_fourthly_list3_content_p1:'1.“申请信”是申请材料的集大成部分',
        niw_fourthly_list3_content_p2:'无论是前面的评估定位还是证据链，最精华的部分都要体现在申请信里边，需要得到移民官认可我们在专业领域的杰出能力，在申请信中一般会突出客观性的证据；',
        niw_fourthly_list3_content_p3:'例如：获得的奖项、媒体的报道、发表的论文等等；要清楚地列出来尽量详实，避免啰嗦，不要遗漏关键的数据（如果证据太多，可额外做一份附录，便于移民官有效的选择性翻阅）',
        niw_fourthly_list3_content_p4:'2.“专家推荐信/证言”是申请材料的第二大件',
        niw_fourthly_list3_content_p5:'尽管“推荐信”没有被移民局规定是必须的，但申请人仍然要找到业内但权威专家来撰写，因为推荐人的份量可以间接体现申请人的水平，如推荐人本身就是业内名人，那么他说出来的话在移民官这里是会为您加分的',
        niw_fourthly_list3_content_p6:'3.“赴美计划”是申请材料的第三大件',
        niw_fourthly_list3_content_p7:'“赴美计划”需进行全面且深入的未来规划阐释。应充分证明自身的杰出性，还需有力证实自身才华对美国具有显著的积极意义，并且明确表达在抵达美国后持续施展自身才华的意愿。因此，“赴美计划”之中应当涵盖移民至美国后的“商业计划”。例如，需向移民官明晰地阐述自身专业领域当前于美国的发展态势，确切说明获取绿卡后将在美国从事的工作以及能够带来的利益；同时需阐述抵达美国后的 1 - 3 年内的短期计划以及 5 - 10 年内的长期计划，并且对这些计划的实施方式予以详细说明',
        niw_fourthly_list3_content_p8:'“商业计划书”（包括雇主的雇佣信、行业调研报告以及与美国公司的商业合作合同等）能够更为有效地证明商业计划的可行性。',

        niw_fourthly_list4_title:'填写表格',
        niw_fourthly_list4_content_p1:'1.“填写I-140表格”(移民请愿)',
        niw_fourthly_list4_content_p2:'一份九页纸的申请表格，该表格递交给移民局，就是向美国官方表达移民诉求，如I-140表格被批准了就代表美国政府已经同意了申请人的移民请愿，虽说当前阶段还不能100%拿到绿卡，但也算成功了99%；',
        niw_fourthly_list4_content_p3:'当前I-140表格分为11个部分，主要以个人信息为主，美国会不定期更新表格，在递交前表格前需要检查是否为最新版本；',
        niw_fourthly_list4_content_p4:'“I-140”为NIW移民中决定性的一步表格多检查，请勿错填或漏填，否则很可能被移民局拒签；',
        niw_fourthly_list4_content_p5:'I-140费用$700，移民局收到钱之后会发送一封收据通知（I-797C表格），这代表申请已正式进入了审理程序。(将于2024年4月1日上调至$715)',
        niw_fourthly_list4_content_p6:'2.“绿色通道”（Premium Processing—I-907优先审理程序）',
        niw_fourthly_list4_content_p7:'I-907优先审理程序（加急流程）支付$2500，15天内知道结果，通过与否支付金额不予退还；注：最新官方提示I-140表格的加急费用将于2024年2月26日正式上调至2805美金。',
        niw_fourthly_list4_content_p8:'15天内未得到结果，不仅会退钱而且还会免费加急服务（概率很低）',
        niw_fourthly_list4_content_p9:'3.ETA-9089劳工证申请表',
        niw_fourthly_list4_content_p10:'劳工证申请表需随I-140表格一起递交至移民局，个人信息部分必填，其余部分可空白。',

        niw_fourthly_list5_title:'递交申请',
        niw_fourthly_list5_content_p1:'1.下载打印',
        niw_fourthly_list5_content_p2:'2.申请材料分类装订打包',
        niw_fourthly_list5_content_p3:'3.邮递选择',

        niw_fourthly_list6_title:'查询申请状态',
        niw_fourthly_list6_content_p1_1:'1.“注册',
        niw_fourthly_list6_content_p1_2:'USCIS官网',
        niw_fourthly_list6_content_p1_3:'账号”等待收取移民局收据',
        niw_fourthly_list6_content_p2:'2.“递交之后该NIW案件就进入审理等待期”',
        niw_fourthly_list6_content_p3:'(移民局未能给出明确的参考范围，正常审理周期6-13个月左右，不同审核中心有差异，审理速度与案件积压程度影响)',
        niw_fourthly_list6_content_p4_1:'3.“审理阶段“可通过收据上编号，去登录',
        niw_fourthly_list6_content_p4_2:'美国移民局USCIS的官网',
        niw_fourthly_list6_content_p4_3:'，在线查询审理进度',
        niw_fourthly_list6_content_p5:'4.“申请审理”结果:',
        niw_fourthly_list6_content_p6:'通过——（恭喜，准备面试）',
        niw_fourthly_list6_content_p7:'拒绝——（可以选择上诉或者复议，成功率比补件情况通过率低）',
        niw_fourthly_list6_content_p8:'补件——（Request for Evidence——要求证据的通知）',

        
        niw_fourthly_list7_title:'补件(若有)',
        niw_fourthly_list7_content_p1:'1.移民官员认为递交证据不足，那么就会收到补充额外材料的通知',
        niw_fourthly_list7_content_p2:'例如：材料里说被媒体广泛报道，但只提供了一两家媒体，移民官认为太少要求补充（补充材料需要围绕之前十大标准来进行），补件是很常见的情况，可以从中了解移民官的审核重点，进一步完善申请材料。',

        niw_fourthly_list8_title:'面试(技巧)',
        niw_fourthly_list8_content_p1_1:'1.在线注册填写信息「Register Online」:',
        niw_fourthly_list8_content_p1_2:'www.ustraveldocs.com',
        niw_fourthly_list8_content_p2:'2.预约时间体检「Get a medical exam in China」',
        niw_fourthly_list8_content_p3_1:'准备面试材料「Complete your pre-interview checklist 」：',
        niw_fourthly_list8_content_p3_2:'具体材料',
        niw_fourthly_list8_content_p4:'4.面试「Interview 」（请注意：为确保面试顺利进行，除特殊情况外，仅限申请人本人参加）',
        niw_fourthly_list8_content_p5_1:'5.',
        niw_fourthly_list8_content_p5_2:'阅读面试指南',
        niw_fourthly_list8_content_p5_3:'「Review interview guidelines」',


        niw_fourthly_list9_title:'获得绿卡',
        niw_fourthly_list9_content_p1:'正常情况下登陆美国1-3个月内，移民局会给予正式绿卡（此时走完NIW全流程）',
        niw_fourthly_list9_content_p2:'Ps.拿到绿卡两种方式',
        niw_fourthly_list9_content_p3:'1.人在美国境外：NVC程序拿到绿卡（对于大多数国人申请人来说，都是走广州领事馆签证流程）',
        niw_fourthly_list9_content_p4:'当I-140获批之后，美国移民局会将材料在一到两个月内递交到美国国家签证中心，正式进入NVC流程，NVC主要负责收取签证申请费、进行背景调查、资料审核;',
        niw_fourthly_list9_content_p5:'当I-140通过之后，NVC并不会马上联系申请人，期间还可能经历排期（排期现象很常见，每年配发绿卡数量都是有限额）',
        niw_fourthly_list9_content_p6:'NVC审理阶段（正常情况下需耗时三个月）',
        niw_fourthly_list9_content_p7:'缴纳审理费用$345/每个家庭成员',
        niw_fourthly_list9_content_p8:'填写DS-260表格',
        niw_fourthly_list9_content_p9:'按照要求提交婚姻公证',
        niw_fourthly_list9_content_p10:'出生公证',
        niw_fourthly_list9_content_p11:'无犯罪公证等等',
        niw_fourthly_list9_content_p12:'2.人在美国境内：通过I-485拿到绿卡',

        niw_fourthly_list10_title:'宣誓入籍',
        niw_fourthly_list10_content_p1:'根据移民监流程宣誓入籍',

        niw_fifth_btn:'购买服务',


        aboutUs_t_title:'关于我们',
        aboutUs_t_text1:'欢迎来到CasePass！',
        aboutUs_t_text2:'CasePass由重庆快润科技有限公司开发运营，我们是一家专注于为用户提供高效、便捷的移民公司。我们的团队由一群经验丰富、热情洋溢的专业人士组成，致力于为用户提供优质的移民服务。',
        aboutUs_t_text3:'我们的使命是帮助更多的人实现自己的移民梦想。我们深知移民过程中的种种困难和挑战，因此我们致力于为用户提供全方位、个性化、性价比的移民服务，让他们在移民过程中更加轻松、顺畅。',
        aboutUs_t_text4:'无论您是想要自主DIY移民美国、移民代理服务还是想要了解更多移民相关咨讯，我们都能够为您提供专业、全面的服务。我们相信，通过我们的努力和服务，您一定能够顺利地实现自己的移民梦想！',
        aboutUs_t_text5:'如果您有任何关于移民的问题或者需要我们的帮助，请随时联系我们。我们将竭诚为您服务！',

        aboutUs_b_title:'制作人员名单',
        aboutUs_list1_l_p:'负责人',
        aboutUs_list1_l_n:'吴磊',
        aboutUs_list1_r_p:'英文文案',
        aboutUs_list1_r_n:'叶含雨',

        aboutUs_list2_l_p:'产品经理',
        aboutUs_list2_l_n:'戴沛利',
        aboutUs_list2_r_p:'',
        aboutUs_list2_r_n:'王祎涵',

        aboutUs_list3_l_p:'产品助理',
        aboutUs_list3_l_n:'姚嘉欣',
        aboutUs_list3_r_p:'',
        aboutUs_list3_r_n:'吴先波',

        aboutUs_list4_l_p:'UI设计',
        aboutUs_list4_l_n:'Aiden',
        aboutUs_list4_r_p:'',
        aboutUs_list4_r_n:'刘燕',

        aboutUs_list5_l_p:'前端开发',
        aboutUs_list5_l_n:'谢皓宇',
        aboutUs_list5_r_p:'',
        aboutUs_list5_r_n:'连峻晗',

        aboutUs_list6_l_p:'后端开发',
        aboutUs_list6_l_n:'Steven',
        aboutUs_list6_r_p:'文案编辑',
        aboutUs_list6_r_n:'曾俊豪',

        aboutUs_list7_l_p:'测试',
        aboutUs_list7_l_n:'冯飞',
        aboutUs_list7_r_p:'',
        aboutUs_list7_r_n:'余双令',

        aboutUs_list8_l_p:'客服',
        aboutUs_list8_l_n:'陈千柔',
        aboutUs_list8_r_p:'',
        aboutUs_list8_r_n:'韩嘉欣',

    },
    // 公用的
    common: {
        noLogin: '未登录',
        noLogin2:'尚未登录',
        redTip1:'请勿使用qq邮箱',
        redTip2:'州、省 根据国家输入情况，必须二填一',
        redTip3:'州省必须二填一',
        redTip4:'这里需要打印该表后，手写内容',
        inputTip1:'需要下载打印后手写',
        inputTip2:'请选择时间',
        inputTip3:'请手写输入',
        selectTip1:'请选择',
        submitBtn1:'保存',
        lastBtn1:'上一步',
        nextBtn1:'下一步',
        finishBtn:'完成',
        MSG_tip1:'表保存成功',
        MSG_tip9:'保存成功',
        MSG_tip2:'保存失败',
        MSG_tip3:'表格必选项未填完！',
        MSG_tip4:'取消成功',
        MSG_tip5:'提交成功',
        MSG_tip6:'获取下载地址失败',
        MSG_tip7:'删除成功',
        MSG_tip12:'删除失败',
        MSG_tip8:'提交成功，该模块已完成，请进入下一阶段',
        MSG_tip10:'需购买后才能查看！',
        MSG_tip11:'请购买后再使用该功能',
        MSG_tip13:'编辑成功',
        MSG_tip14:'编辑失败',
        MSG_tip15:'添加成功',
        MSG_tip16:'添加失败',
        MSG_tip17:'调序完成',
        MSG_tip18:'调序失败',
        btn_confirm:'确认',
        btn_cancel:'取消',
        extensible:'可展开',
        noBuy:'未购买DIY服务',
        noBuy2:'请先登录后，并购买该服务才能正常使用哦！！',
        toBuy:'去购买',
        toLogin:'去登录',
        Iknow:'知道了',
        urgent_tip:'正在加急处理你的资料，现在修改无效哟！',
        getFileFail:'获取文件失败',

        Upload_btn:'上传',
        Edit_btn:'编辑',
        Delete_btn:'删除',


        // 预览界面
        preview_title:'材料目录',
        preview_left:'左边内容为上传的“源文件”',
        preview_left_up:'上',
        preview_left_down:'下',
        preview_right:'右边内容为“英文翻译文件”',
        preview_right_up:'上',
        preview_right_down:'下',

        preview_tip1:'暂无PDF',

        QandA_tip1:'搜索关键词：',
        QandA_tip2:'暂无回答',
        QandA_tip3:'如果您正在DIY移民，你有何好建议呢？',
        QandA_ques:'选择建议分类',
        QandA_ques_a:'网站操作',
        QandA_ques_b:'页面功能',
        QandA_tip4:'你的问题和想法会帮助更多人...',
        QandA_tip5:'请先登录后，再进行操作',
        QandA_tip6:'提交成功，感谢你的反馈',
        QandA_tip7:'问题建议不能为空',

        QandA_btn1:'我要提问',
        QandA_btn2:'提问题',
        QandA_btn3:'提建议',
        QandA_btn4:'提交',
        QandA_btn5:'去登录',
        QandA_btn6:'知道了',

        homeRight1:'联系我们',
        homeRight2:'回到顶部',

    },
    // 评估页面
    freeAssessment: {
        all_title:'美国移民项目评估',
        table1_title:'1.基础信息*',
        table2_title:'2.职业经验*',
        table3_title:'3.预约时间*',

        table3_text1:'您何时方便接听电话?',
        table3_text2:'(您可以预约时间与我们的专家详细咨询移民情况)',
        table3_question1:'选择时区',
        table3_question1_tip:'请选择时区',
        table3_question2:'选择日期',
        table3_question2_tip:'请选择日期',
        table3_question3:'预约时间',
        table3_question3_tip:'请选择时间',
        table3_submit:'提交',

        table1_list1:'个人信息',
        table1_list1_question1:'您的姓名',
        table1_list1_question1_tip:'请输入姓名',
        table1_list1_question2:'您的性别',
        table1_list1_question2_select1:'先生',
        table1_list1_question2_select2:'女士',
        table1_list1_question3:'出生日期',
        table1_list1_question3_tip:'请选择日期',
        table1_list1_question4:'邮箱',
        table1_list1_question4_tip:'请输入邮箱',
        table1_list1_question5:'微信',
        table1_list1_question5_tip:'请输入微信',

        table1_list2:'婚姻状况',
        table1_list2_question1:'婚姻状况',
        table1_list2_question1_select1:'未婚',
        table1_list2_question1_select2:'已婚',
        table1_list2_question1_select3:'离异',
        table1_list2_question2:'子女',
        table1_list2_question2_1:'姓名',
        table1_list2_question2_2:'年龄',
        table1_list2_question2_btn:'添加子女信息',


        table1_list3:'身份情况',
        table1_list3_question1:'您是否服过兵役',
        table1_list3_question1_select1:'是',
        table1_list3_question1_select2:'否',
        table1_list3_question2:'何种兵役',
        table1_list3_question3:'您是否是共产党员',
        table1_list3_question3_select1:'是',
        table1_list3_question3_select2:'否',

        table1_list4:'健康状况',
        table1_list4_question1:'有无传染性疾病',
        table1_list4_question1_select1:'有',
        table1_list4_question1_select2:'无',
        table1_list4_question2:'有无犯罪记录',
        table1_list4_question2_select1:'有',
        table1_list4_question2_select2:'无',

        table1_list5:'移民情况',
        table1_list5_question1:'护照签发地',
        table1_list5_question1_tip:'请输入',
        table1_list5_question2:'签发年月',
        table1_list5_question2_tip:'请选择',
        table1_list5_question3:'到期年月',
        table1_list5_question3_tip:'请选择',
        table1_list5_question4:'是否申请过美国移民',
        table1_list5_question4_select1:'是',
        table1_list5_question4_select2:'否',
        table1_list5_question4_tip:'如果有,请注明移民类别、结果或者移民局的收据编号',
        table1_list5_question5:'是否申请过留学或旅游签证或其他',
        table1_list5_question5_select1:'是',
        table1_list5_question5_select2:'否',
        table1_list5_question5_tip:'如果有,请注明类别以及签证结果',

        table1_next_btn:'下一项',
        table1_up_btn:'上一项',
        table1_saveAndNext_btn:'保存,并下一步',

        table2_list1:'教育背景',
        table2_list1_question1:'学历',
        table2_list1_question1_tip:'请输入',
        table2_list1_question2:'学校',
        table2_list1_question2_tip:'请输入',
        table2_list1_question3:'专业名称',
        table2_list1_question3_tip:'请输入',
        table2_list1_question4:'入学年月',
        table2_list1_question4_tip:'请选择',
        table2_list1_question5:'毕业年月',
        table2_list1_question5_tip:'请选择',
        table2_list1_add_btn:'添加教育经历',
        table2_list1_del_btn:'删除',

        table2_list2:'工作经历',
        table2_list2_question1:'公司/单位',
        table2_list2_question1_tip:'请输入',
        table2_list2_question2:'职位名称',
        table2_list2_question2_tip:'请输入',
        table2_list2_question3:'年收入',
        table2_list2_question3_tip:'请输入',
        table2_list2_question4:'开始时间',
        table2_list2_question4_tip:'请选择',
        table2_list2_question5:'结束时间',
        table2_list2_question5_tip:'请选择（不填默认为至今）',
        table2_list2_add_btn:'添加工作信息',
        table2_list2_del_btn:'删除',

        table2_list3:'所获奖项',
        table2_list3_question1:'国内外所获的奖项',
        table2_list3_question1_tip:'奖项名称、具体描述(注明时间、发奖单位、奖项级别、集体奖项第几获奖人等.非必填项)',
        table2_list3_question1_select1:'未获得',
        table2_list3_question1_select2:'有获得奖项',

        table2_list4:'协会会员',
        table2_list4_question1:'行业专业的会员资格',
        table2_list4_question1_tip:'行业、具体描述(注明入会时间、会员或理事、以及入会标准) 请注意:具备领域出色成绩才会有入会资格的协会,才符合杰出人才的条件',
        table2_list4_question1_select1:'未加入',
        table2_list4_question1_select2:'有加入协会',

        table2_list5:'媒体报道',
        table2_list5_question1:'各大媒体对您的报道',
        table2_list5_question1_tip:'媒体名称(电视TV、报刊、杂志、电台)、报道有无专门提到您名字、具体描述(注明时间、杂志期数等,电视报道说明具体栏目)',
        table2_list5_question1_select1:'未报道',
        table2_list5_question1_select2:'有报道过',

        table2_list6:'行业贡献',
        table2_list6_question1:'在本领域的重大贡献',
        table2_list6_question1_tip:'可以包括专利,重要科技成果以及其他在本人从业领域中的重大突出贡献(如果是教练员,请列出所培养的学生获得过的国内外奖项;若是专利,请写明该专利是否被应用实现商业价值)需要、有书面资料可以证明',
        table2_list6_question1_select1:'未有过',
        table2_list6_question1_select2:'有过重大贡献',

        table2_list7:'学术文章',
        table2_list7_question1:'发表过的学术文章',
        table2_list7_question1_tip:'文章名称(论文、书籍、报纸、专业杂志等)、具体描述(注明时间、杂志期数等,专著(专题论著)提供发行量、您的文章著作有无被引用过?次数多少?)',
        table2_list7_question1_select1:'未发表',
        table2_list7_question1_select2:'有发表过',

        table2_list8:'重大项目',
        table2_list8_question1:'负责过的国家或省级重大项目',
        table2_list8_question1_tip:'项目名称、具体描述(注明时间、项目发放单位、项目级别、第几负责人)',
        table2_list8_question1_select1:'未参与',
        table2_list8_question1_select2:'有参与过重大项目',

        table2_list9:'国际会议',
        table2_list9_question1:'参加过的国际会议',
        table2_list9_question1_tip:'项目名称、具体描述(注明时间、项目发放单位、项目级别、第几负责人)',
        table2_list9_question1_select1:'未参与',
        table2_list9_question1_select2:'参与过国际会议',

        table2_list10:'重要职位',
        table2_list10_question1:'有关申请人现在工作单位的介绍',
        table2_list10_question1_tip:'工作单位名称、工作单位是否在本领域有名望?若有,请描述。申请人在该工作单位是否担任重要职位或者起关键性作用?若有,请描述',
        table2_list10_question1_select1:'未曾有',
        table2_list10_question1_select2:'有起到过关键性的领导或决策',

        table2_list11:'作品展览',
        table2_list11_question1:'请提供其作品参加过展览的情况',
        table2_list11_question1_tip:'视觉性展览名称、时间,地点,规模,参展标准',
        table2_list11_question1_select1:'未参展',
        table2_list11_question1_select2:'有参展过展览',

        table2_list12:'艺术商业价值',
        table2_list12_question1:'请提供在表演艺术方面所取得的商业成就',
        table2_list12_question1_tip:'作品(如电影,舞蹈演出、演唱会等)、时间,地点和概况、所实现的商业价值',
        table2_list12_question1_select1:'未取得',
        table2_list12_question1_select2:'有在表演艺术取得商业成就',

        table2_list13:'从业执照',
        table2_list13_question1:'请提供在专业领域获得的从业资格证书',
        table2_list13_question1_tip:'专业从业资格证明（执照或证书的复印件；颁发执照或证书的机构简介，可向机构索要官方的宣传册，若没有，官网的介绍也可以；执照或证书的颁发标准细则。）',
        table2_list13_question1_select1:'未取得',
        table2_list13_question1_select2:'有从业资格证书/执照',

        table2_list14:'其他成就',
        table2_list14_question1:'请概述其它专业领域所取得的成就',
        table2_list14_question1_tip:'制定行业标准、创建艺术流派和运动等以上未提及的重要成就。',
        table2_list14_question1_select1:'没其他',
        table2_list14_question1_select2:'有其他成就',

        table2_list15:'收入对比',
        table2_list15_question1:'收入',
        table2_list15_question1_tip:'申请人收入、本行业其他从业人员的平均收入、是否可以拿出书面证明(如工资单,报税单或单位证明书)',
        table2_list15_question1_select1:'无证明',
        table2_list15_question1_select2:'有高于同行从业平均收入证明',

        table2_list16:'推荐信',
        table2_list16_question1:'是否能提供三封以上的推荐信',
        table2_list16_question1_tip:'(提示：移民局更注重由与你本人并无工作或者私人关系的专家所写的推荐信,因为这样的信会显得更加客观.因此我们希望您直接上级领导除外的,能够找到和你没有直接工作关系的推荐人,最好有国外专家)可填写推荐人名、任职单位、职位',
        table2_list16_question1_select1:'无法提供',
        table2_list16_question1_select2:'可以提供',

        table2_next_btn:'下一项',
        table2_up_btn:'上一项',
        table2_saveAndNext_btn:'保存,并下一步',

        FA_input_tip1:'请选择预约日期',
        FA_input_tip2:'请选择预约时间',
        FA_input_tip3:'请选择所在时区',
    },
    // 1145表
    eb1a_1145: {
        t1145_title:'申请/呈请接收电子通知',
        // t1145_text1:'在美国时，使用此表格请求电子通知（电子通知）。公民和移民服务局接受您的移民申请。这项服务适用于在USCIS Lockbox设施提交的申请。',
        t1145_text2:'该表的用处?',
        t1145_text3:'若使用此表，USCIS在接收您的移民申请后，将给您发送电子通知函（电子通知）。此服务适用于提交至USCIS锁箱设施(Lockbox)的申请。',
        t1145_text4:'总则',
        t1145_text5:'填写以下信息，在打包申请材料时，请将此表置于申请打包的第一页。对于您提交的每份表格，我局接收后均会发送电子邮件和/或短信通知。',
        t1145_text6:'在接收申请后，我局将在24小时内发送电子通知。国内申请人将收到电子邮件和/或短信，海外申请人将只能收到电子邮件。若邮件发送失败，将不会再次发送。',
        t1145_text7:'在电子邮件/短信里，将显示您的申请接收号，并告知您如何获得更新状态信息。邮件/短信仅为方便申请人接收信息，不包含任何个人信息，亦不代表申请的获批。',
        t1145_text8:'除电子邮件/短信外，USCIS还将向您邮寄申请接收通知（I-797C），您将在申请显示接受后10天内收到该通知。此通知将作为您的未决申请或申请的证明。',
        t1145_text9:'USCIS免责申明',
        t1145_text10:'法条依据：根据《移民和国籍法》第103（a）条（经第101条等修订）收集表格信息。',
        t1145_text11:'使用目的：此表格上提供的信息将主要用于接收电子通知。USCIS在接收申请后，将根据您提供的信息，向您发送短信和/或电子邮件。',
        t1145_text12:'免责申明：表格所提供的信息属自愿行为。若未能/错误提供相应信息，USCIS将不能向您发送短信和/或电子邮件。',
        t1145_text13:'常规用途：本表所提供的信息将由DHS工作人员和承包商根据使用规范进行使用和披露，如相关已发布的记录通知系统所述[DHS/USCIS-007-福利信息系统和DHS/USCIS-001-外国人档案（A档案）和中央索引系统（CIS），可在www.dhs.gov/privacy中找到。为了执法目的或国家安全利益，也可酌情提供这些信息。',
        t1145_text14:'完善此表，在打包申请材料时，请将此表置于申请打包的第一页',
        t1145_text15:'1.申请人/呈请人姓氏',
        t1145_text15_tip:'请填入姓氏',
        t1145_text16:'3.呈请人/呈请人中间名',
        t1145_text16_tip:'3.请输入名字',
        t1145_text17:'5.移动手机号（接收短信）',
        t1145_text17_tip:'请输入电话',
        t1145_text18:'2.申请人/呈请人名字',
        t1145_text19:'4.电子邮箱地址（请勿使用qq邮箱）',
        t1145_text19_tip1:'请输入邮箱',
        t1145_text19_tip2:'请输入正确的邮箱地址',
        t1145_submit_btn:'保存',
        t1145_submit_btn_tip:'G-1145表保存成功',
        t1145_submit_btn_fail1:'G1145表格必选项未填完！',
        t1145_submit_btn_fail2:'保存失败',
        
    },
    // I907
    eb1a_I907:{
        I907_title:'I-907 (加急申请服务申请)',
        I907_P1_title:'P1.申请人相关信息',
        I907_P1_module1_name:'第一部分_申请人相关信息',
        // I907_P1_module1_tip1:'州省必须二填一',
        I907_P1_module1_question1:'1. 外国人登记号（有就填写）',
        I907_P1_module1_question2:'2. USCIS在线账号（有就填写）',
        I907_P1_module1_question3:'3.姓氏',
        I907_P1_module1_question4:'名字',
        I907_P1_module1_question5:'中间名',
        I907_P1_module1_question6:'4. 相关公司/组织名',
        I907_P1_module1_tip2:'邮寄地址',
        I907_P1_module1_question7:'收件人',
        I907_P1_module1_question8:'街道编号和名称',
        I907_P1_module1_question9_select1:'公寓',
        I907_P1_module1_question9_select2:'街道',
        I907_P1_module1_question9_select3:'楼层',
        I907_P1_module1_question10:'房号',
        I907_P1_module1_question11:'市/镇',
        I907_P1_module1_question12:'州',
        I907_P1_module1_question13:'邮政编码',
        I907_P1_module1_question14:'州/省',
        I907_P1_module1_question15:'邮编',
        I907_P1_module1_question16:'国家/地区',
        I907_P1_module1_question17:'6.邮寄地址是否本人当前所在地址？',
        I907_P1_module1_question18:'若编号6项填“否”，请将本人当前所在地址填在编号7处。',
        I907_P1_module1_question18_select1:'是',
        I907_P1_module1_question18_select2:'否',
        I907_P1_module1_question19:'7.本人当前所在地',
        I907_P1_module1_question20:'街道编号和名称',
        I907_P1_module1_question20_select1:'公寓',
        I907_P1_module1_question20_select2:'街道',
        I907_P1_module1_question20_select3:'楼层',
        I907_P1_module1_question21:'房号',
        I907_P1_module1_question22:'市/镇',
        I907_P1_module1_question23:'州',
        I907_P1_module1_question24:'邮政编码',
        I907_P1_module1_question25:'州/省',
        I907_P1_module1_question26:'邮编',
        I907_P1_module1_question27:'国家/地区',
        I907_P1_module1_question28:'8.加急申请服务申请（以下仅选择一项）',
        I907_P1_module1_question29:'我是呈请人本人，当前正在申请或已申请加急处理服务。',
        I907_P1_module1_question30_1:'我是正在提交或已提交加急处理服务申请人的律师或授权代表。 ',
        I907_P1_module1_question30_2:'（若未与申请一起提交表格G-28或表格G-28I，需填写并提交表格G-28：作为律师或授权代表出庭的通知，或表格G-28I：在美境外事务中作为律师出庭的通知。）',
        I907_P1_module1_question31:'我是正在提交或已提交加急处理服务申请的申请人。',
        I907_P1_module1_question32_1:'我是申请人的律师或授权代表，该申请人正在提交或已提交加急处理服务。',
        I907_P1_module1_question32_2:'（若表格G-28或表格G-28I未与申请一起提交，请填写并提交表格G-28或表格G-28I。）',

        I907_P2_title:'P2.申请相关信息',
        I907_P2_module1_name:'第一部分_申请人相关信息',
        I907_P2_module1_question1:'1.相关呈请表/申请表代码',
        I907_P2_module1_question2:'2.相关呈请/申请接收码',
        I907_P2_module1_question3:'3.申请类别或资格',
        I907_P2_module1_tip1:'4.呈请人/申请人相关信息',
        I907_P2_module1_question4:'姓氏',
        I907_P2_module1_question5:'名字',
        I907_P2_module1_question6:'中间名',
        I907_P2_module1_tip2:'5.申请受益人姓名',
        I907_P2_module1_question7:'姓氏',
        I907_P2_module1_question8:'名字',
        I907_P2_module1_question9:'中间名',
        I907_P2_module1_tip3:'6.公司机构组织联系人姓名',
        I907_P2_module1_question10:'姓氏',
        I907_P2_module1_question11:'名字',
        I907_P2_module1_question12:'中间名',
        I907_P2_module1_question13:'职位',
        I907_P2_module1_question14:'6.公司或机构组织国税局（IRS）雇主识别好（EIN）（有就填写）',
        I907_P2_module1_tip4:'7.相关申请案提及的呈请人，申请人，公司，组织地址',
        I907_P2_module1_question15:'街道编号和名称',
        I907_P2_module1_question15_select1:'公寓',
        I907_P2_module1_question15_select2:'街道',
        I907_P2_module1_question15_select3:'楼层',
        I907_P2_module1_question16:'房号',
        I907_P2_module1_question17:'市/镇',
        I907_P2_module1_question18:'州',
        I907_P2_module1_question19:'邮政编码',
        I907_P2_module1_question20:'州/省',
        I907_P2_module1_question21:'邮编',
        I907_P2_module1_question22:'国家/地区',

        I907_P3_title:'P3.加急服务申请声明，联系信息，声明，资质与签名',
        I907_P3_module1_name:'第三部分_加急服务申请声明，联系信息，声明，资质与签名',
        I907_P3_module1_tip1:'注: 在填写此部分之前，请阅读I-907表格说明中的处罚部分。',
        I907_P3_module1_tip2:'本人了解美国公民与移民服务局（USCIS）相关规定，若美国公民与移民服务局（USCIS）未在适用处理时间内对相关案件采取行动，将退还第1部分所列人员的额外处理服务费。我了解在处理申请案时，会对涉嫌欺诈、虚假陈述进行调查，并发出批准通知、补充证据通知、拒绝意向通知或拒绝通知。',
        I907_P3_module2_name:'加急服务_申请声明',
        I907_P3_module2_tip1:'注: 请选择项目编号1中的A项或B项方框。若适用，可选择项目编号2的方框。',
        I907_P3_module2_question1:'1.英语能力相关声明',
        I907_P3_module2_question1_a_1:'A.',
        I907_P3_module2_question1_a_2:'我能阅读和理解英语，我已经阅读并理解了关于此加急申请书中的每个问题，相关说明以及对应回答',
        I907_P3_module2_question1_b_1:'B.',
        I907_P3_module2_question1_b_2:'第4部分所指译员',
        I907_P3_module2_question1_b_3:'已告知我此申请的每个问题，相关说明以及对应回答。',
        I907_P3_module2_question2:'2.申请人准备方声明',
        I907_P3_module2_question2_1:'应我的请求，第5部分提及的准备方',
        // I907_P3_module2_question2_2:'基于我提供/授权的信息为我准备了此申请。',
        I907_P3_module3_name:'加急服务申请方联系信息',
        I907_P3_module3_question3:'3.申请方日间电话号',
        I907_P3_module3_question4:'4.申请方移动手机号码（有就填写）',
        I907_P3_module3_question5:'5.申请方传真号（有就填写）',
        I907_P3_module3_question6:'6.申请方邮件地址（有就填写）',
        I907_P3_module4_name:'加急服务申请方_声明与资质',
        I907_P3_module4_tip1:'本人在此承诺，本人所提交的任何文件副本均为未经更改的原始文件复印件，本人作为申请人，理解可能后期需向USCIS提交原始文件。',
        I907_P3_module4_tip2:'本人在此授权，允许USCIS或其他实体和个人在必要时从我的记录或从申请组织文件中查阅信息，以确定是否符合移民资格或是否合法。',
        I907_P3_module4_tip3:'本人在此声明，申请书中的所有信息及随之递交的文件均由我提供或授权，我本人已审查并理解所有相关信息，且证实信息为完整、真实和正确的，如做伪证，愿意受罚。',
        I907_P3_module5_name:'加急服务申请方_签字',
        I907_P3_module5_tip1:'本人在此声明，申请书中的所有信息及随之递交的文件均由我提供或授权，我本人已审查并理解所有相关信息，且证实信息为完整、真实和正确的，如做伪证，愿意受罚。',
        I907_P3_module5_question7:'7.申请方签字',
        I907_P3_module5_question8:'签字日期（月/日/年）',
        I907_P3_module5_tip2:'注: 若未能完整填写此申请或未能提交说明中列出的所需文件，美国公民及移民服务局(USCIS)可能会拒绝您的申请。',

        I907_P4_title:'P4.译员联系方式，资质，签名',
        I907_P4_module1_name:'第四部分_译员联系方式，资质，签名',
        I907_P4_module3_tip1:'请提供译员以下相关信息',
        I907_P4_module2_name:'译员全名',
        I907_P4_module2_question1:'1.译员姓氏',
        I907_P4_module2_question2:'译员名字',
        I907_P4_module2_question3:'2.译员所在企业或机构组织名（有就填写）',
        I907_P4_module3_name:'译员邮寄地址',
        I907_P4_module3_question4:'3.街道编号和名称',
        I907_P4_module3_question4_select1:'公寓',
        I907_P4_module3_question4_select2:'街道',
        I907_P4_module3_question4_select3:'楼层',
        I907_P4_module3_question5:'房号',
        I907_P4_module3_question6:'市/镇',
        I907_P4_module3_question7:'州',
        I907_P4_module3_question8:'邮政编码',
        I907_P4_module3_question9:'州/省',
        I907_P4_module3_question10:'邮编',
        I907_P4_module3_question11:'国家/地区',
        I907_P4_module4_name:'译员联系信息',
        I907_P4_module4_question12:'4.译员日间电话号',
        I907_P4_module4_question13:'5.译员移动手机号（有就填写）',
        I907_P4_module4_question14:'6.译员邮件地址（有就填写）',
        I907_P4_module5_name:'译员资质声明',
        I907_P4_module5_tip2:'本人特此证明，如作伪证愿受处罚：',
        I907_P4_module5_question15:'我能熟练使用英语和',
        I907_P4_module5_tip3:'该语言为第3部分1.b.所特指的语言。我已将此申请书的说明和申请书上的所有问题与答复全部告知于申请人本人或授权签字人。',
        I907_P4_module5_tip4:'申请人或授权签字人向我表明其已理解申请书的说明和其上的所有问题及答复，包括申请人或授权签字人的声明和资格证部分的答复也做了确切说明。',
        I907_P4_module6_name:'译员签名',
        I907_P4_module6_question16:'7.译员签字',
        I907_P4_module6_question17:'签字日期（月/日/年）',

        I907_P5_title:'P5.除申请人外的准备方联系信息，声明，签字',
        I907_P5_module1_name:'第五部分_除申请人外的准备方联系信息，声明，签字',
        I907_P5_module1_tip1:'提供准备方以下信息',
        I907_P5_module2_name:'准备方全名',
        I907_P5_module2_question1:'1.准备方姓氏',
        I907_P5_module2_question2:'准备方名字',
        I907_P5_module2_question3:'2.准备方所在企业或机构组织名称（有就填写）',
        I907_P5_module3_name:'准备方邮寄地址',
        I907_P5_module3_question4:'3.街道编号和名称',
        I907_P5_module3_question4_select1:'公寓',
        I907_P5_module3_question4_select2:'街道',
        I907_P5_module3_question4_select3:'楼层',
        I907_P5_module3_question5:'房号',
        I907_P5_module3_question6:'市/镇',
        I907_P5_module3_question7:'州',
        I907_P5_module3_question8:'邮政编码',
        I907_P5_module3_question9:'州/省',
        I907_P5_module3_question10:'邮编',
        I907_P5_module3_question11:'国家/地区',
        I907_P5_module4_name:'准备方联系信息',
        I907_P5_module4_question12:'4.准备方日间电话号',
        I907_P5_module4_question13:'5.准备方移动手机号（有就填写）',
        I907_P5_module4_question14:'6.准备方邮件地址（有就填写）',
        I907_P5_module5_name:'准备方声明',
        I907_P5_module5_tip2:'7.请选择以下选项',
        I907_P5_module5_question15_a_1:'A.',
        I907_P5_module5_question15_a_2:'本人并非代理人或派遣代表，本人在申请人同意下，代申请人拟备本申请书。',
        I907_P5_module5_question15_b_1:'B.',
        I907_P5_module5_question15_b_2:'本人是代理人或派遣代表，本次代理申请中，所有活动均符合申请准备范围。',
        I907_P5_module5_question16:'超过准备范围',
        I907_P5_module5_question17:'未超过申请准备范围',
        I907_P5_module5_question18:' 注: 若准备方为代理人或派遣代表，除此份申请书外，还需提交一份完整填写的G-28表或G-281表',
        I907_P5_module6_name:'准备方资质',
        I907_P5_module6_tip:'本人在此签字证明，若有伪证行为愿接受处罚，本人应申请人的要求准备此申请书。申请方本人已查阅此份申请书，包括申请方声明和证明部分，并已确认申请书中的所有信息和证明文件中的所有信息均完整、真实和正确。',
        I907_P5_module7_name:'准备方签名',
        I907_P5_module7_question18:'8.准备方签名',
        I907_P5_module7_question19:'签字日期（月/日/年）',

        I907_P6_title:'P6.附加信息',
        I907_P6_module1_name:'第六部分_附加信息',
        I907_P6_module1_tip1:'如还需提供补充材料，可使用以下空白处。如所需提供信 息过多，空白处不足，可复印本页或另附纸张填写，并连 同本申请书一并提交。在每页纸顶部写上姓名和外国人登 记号码（如有）；注明回答内容所在页码、部码和编码；每页纸均需签名并注明日期。',
        I907_P6_module1_question1:'姓(姓氏)',
        I907_P6_module1_question2:'名(名字)',
        I907_P6_module1_question3:'中间名',
        I907_P6_module1_question4:'2.外国人登记号（有就填写）',
        I907_P6_common_question1:'页码',
        I907_P6_common_question2:'部码',
        I907_P6_common_question3:'编码',
    },
    //1450表
    I1450:{
        I1450_title:'信用卡付款授权表',
        I1450_tip_name:'G-1450填表说明',
        I1450_tip_list1:'1. 黑色墨水清晰打印填写。',
        I1450_tip_list2:'2. 完整填写“申请人/呈请人/请求人信息”，“信用卡付款信息”以及“信用卡信息”部分。注：付款的信用卡需为美国本土银行信用卡',
        I1450_tip_list3:'3. 填写完善的G-1450表置于所提交申请材料的最上方。',
        I1450_tip_list4:'注: 若提交信息有误，银行将无法进行扣费，USCIS将无法处理您的支付款。',
        I1450_tip_list5:'注: 若需其他信息，请登陆USCIS官网查看表G-1450详细信息。',
        I1450_tip_list6:'我们在此强烈建议您将填写完善的G-1450表进行备份，以备不时之需。',
        I1450_tip_list7:'完成此转款申请表即代表您同意支付政府服务费用，包括申请费、生物识别服务费和所有相关金融交易费，无论后续USCIS对您的申请采取任何行动，以上款项均不退还。您必须提交准确金额的费用。美国公民及移民服务局（USCIS）将从您的信用卡中收取您在表中授权的款项。',
        I1450_tip_list8:'请参阅您提交的表格以了解更多信息，或拨打USCIS客户联系电话1-800-375-5283。',
        I1450_tip_list9:'听障人士（TTY）请致电: 1-800-767-1833。',
        I1450_module1_name:'申请人/呈请人/请求人信息（合法全名）',
        I1450_module1_question1:'姓氏',
        I1450_module1_question2:'中间名（若有）',
        I1450_module1_question3:'名字',
        I1450_module2_name:'信用卡转款信息（信用卡持有人信息）',
        I1450_module2_question1:'姓氏',
        I1450_module2_question2:'中间名（若有）',
        I1450_module2_question3:'名字',
        I1450_module3_name:'信用卡持有人转款地址',
        I1450_module3_question1:'街道编号和名称',
        I1450_module3_question1_select1:'公寓',
        I1450_module3_question1_select2:'街道',
        I1450_module3_question1_select3:'楼层',
        I1450_module3_question2:'房号',
        I1450_module3_question3:'市/镇',
        I1450_module3_question4:'州',
        I1450_module3_question5:'邮政编码',
        I1450_module4_name:'信用卡持有人签字与联系信息',
        I1450_module4_question1:'信用卡持有人签字',
        I1450_module4_question2:'信用卡持有人日间手机号码',
        I1450_module4_question3:'信用卡持有人电子邮箱',
        I1450_module5_name:'信用卡信息',
        I1450_module5_question1:'信用卡卡号',
        I1450_module5_question2:'信用卡有效期月（月/年）',
        I1450_module5_question3:'信用卡类型',
        I1450_module5_question4:'授权金额（美元）',

        I1450_input_tip1:'请填入姓氏',
        I1450_input_tip2:'请输入名字',
        I1450_input_tip3:'请输入姓氏（持卡人）',
        I1450_input_tip4:'请输入名字（持卡人）',
        I1450_input_tip5:'请输入街道',
        I1450_input_tip6:'请输入市/镇',
        I1450_input_tip7:'请选择州',
        I1450_input_tip8:'请输入邮政编码',
        I1450_input_tip9:'请输入日间号码',
        I1450_input_tip10:'请输入电子邮箱',
        I1450_input_tip11:'请输入正确的邮箱地址',
        I1450_input_tip12:'请选择有效年月',
        I1450_input_tip13:'请选择类型',
        I1450_input_tip14:'请输入授权金额',
    } ,
    // 个人中心
    personal:{
        title:'个人中心',
        selectDropDown1:'个人信息',
        selectDropDown2:'消息提示',
        selectDropDown3:'我的计划',
        selectDropDown4:'购买记录',
        selectDropDown5:'退出账号',

        // 个人信息 PM
        PM_box1_title1:'手机号码',
        PM_box1_btn1:'更换手机',
        PM_box1_title2:'电子邮箱',
        PM_box1_btn2:'去绑定',
        PM_box2_title:'上次登录时间',
        PM_box3_btn:'账号注销',

        PM_dia1_title:'您确定要注销账号吗？',
        PM_dia1_tip:'注销后所有信息和数据将被清空，正在进行的服务权益将自动视为放弃，请确认是否注销',
        PM_dia1_btn1:'暂不注销',
        PM_dia1_btn2:'确认注销',

        PM_dia2_title:'注销前，请确认以下内容',
        PM_dia2_tip1:'您仅可注销您本人申请的账号',
        PM_dia2_tip2:'注销后，CasePass网站权益将被清除与失效',
        PM_dia2_tip3:'注销后，所有数据、记录等将无法访问和找回',
        PM_dia2_tip4:'账号一旦注销完成，将无法恢复',
        PM_dia2_tip5:'注销后，您使用账号登录无法登录或使用',
        PM_dia2_tip6:'账号注销处理需要7天，在此期间请勿登录，否则将恢复账号，无法注销成功',
        PM_dia2_btn1:'暂不注销',
        PM_dia2_btn2:'确认注销',

        PM_dia3_tip1:'账户注销成功，即将退出登录',
        PM_dia3_btn1:'确认',

        PM_SMS_title:'更换手机',
        PM_SMS_tip1:'请输入手机号',
        PM_SMS_tip2:'请输入验证码',
        PM_SMS_btn1:'获得验证码',
        PM_SMS_btn2:'取消',
        PM_SMS_btn3:'确认',
        PM_SMS_btn4:'重新发送',

        MR_title:'消息提醒',
        MR_table_list1:'消息内容',
        MR_table_list2:'时间',
        MR_table_list3:'操作',
        MR_table_list3_btn1:'详情',
        MR_table_list3_btn2:'删除',
        MR_table_list3_allDel:'删除',
        MR_tip1:'删除成功',

        MP_box1_title:'已购买服务',
        MP_box1_tip1:'暂无计划',
        MP_box1_btn1:'查看预览',
        MP_box1_btn2:'对比预览',
        MP_box1_btn3:'去DIY',
        MP_box1_btn4:'生成打印文件',
        MP_box1_btn5:'打印文件生成中',
        MP_box1_btn6:'下载打印文件',
        MP_box1_btn7:'重新生成打印',

        MP_dia1_title:'下载文件',
        MP_dia1_tip:'您已完成所有申请材料的上传确认要下载打印的pdf文件',
        MP_dia1_btn1:'取消',
        MP_dia1_btn2:'确认下载',

        MP_box2_title:'我的权益',
        MP_box2_table1:'服务类型',
        MP_box2_table2:'状态',
        MP_box2_table3:'提交时间',
        MP_box2_table4:'操作',
        MP_box2_table4_btn1:'取消',
        MP_box2_table4_btn2:'提交',
        MP_box2_table4_btn3:'再次提交',
        MP_box2_table4_btn4:'审核结果',

        MP_box3_title:'近期修改记录',
        MP_box3_table1:'序号',
        MP_box3_table2:'计划名称',
        MP_box3_table3:'内容类型',
        MP_box3_table4:'时间',
        MP_box3_table5:'操作',
        MP_box3_table5_btn1:'查看',

        MP_dia_title:'提交成功',
        MP_dia_tip1:'1.请稍等生成pdf文件需要一些时间',
        MP_dia_tip2:'（大约需要十分钟）',
        MP_dia_tip3:'2.生成完成后，才能下载pdf文件',
        MP_dia_tip4:'（后续修改，需再次生成文件才能pdf文件）',
        MP_dia_btn1:'知道了',

        PR_title:'购买记录',
        PR_table_list1:'序号',
        PR_table_list2:'订单类型',
        PR_table_list3:'订单号',
        PR_table_list4:'创建时间',
        PR_table_list5:'支付时间',
        PR_table_list6:'金额',
        PR_table_list7:'状态',
        PR_table_list7_state1:'未支付',
        PR_table_list7_state2:'等待确认',
        PR_table_list7_state3:'部分支付',
        PR_table_list7_state4:'支付成功',
        PR_table_list7_state5:'支付失败',
        PR_table_list7_state6:'已取消',
        PR_table_list7_state7:'未定义',
        PR_table_list8:'操作',
        PR_table_list8_btn1:'去支付',
        PR_table_list8_btn2:'取消订单',

        PR_dia_title:'取消订单',
        PR_dia_tip:'确认取消订单？',
        PR_dia_btn1:'取消',

        PR_dia2_title:'完成支付'
    },
    // 支付页面
    payment:{
        // paymentPage
        PP_pay1_title1:'便捷支付',
        PP_pay1_title2:'开通服务',
        PP_pay1_title3:'已选服务',
        PP_pay1_list1:'服务时长',
        PP_pay1_list1_a:'一年半',
        PP_pay1_list2:'到期日期',
        PP_pay1_list3:'支付金额',
        PP_pay1_list4:'优惠折扣',
        PP_pay1_list5:'汇率',
        PP_pay1_list6:'订单总额',
        PP_pay1_list7:'应付金额',
        PP_pay1_btn1:'支付宝支付(￥)',
        PP_pay1_btn2:'Paypal支付($)',
        PP_pay1_tip1:'注意事项：',
        PP_pay1_tip2:'若超过每日支付限额无法付款，请先在您的支付宝余额账户中预存足够金额再进行支付。',

        pp_pay2_title1:'对公转账 支付（￥）',
        pp_pay2_title2:'转账流程',
        pp_pay2_step1:'第一步：对公汇款',
        pp_pay2_list1_a:'账户名称',
        pp_pay2_list1_b:'重庆快润科技有限公司',
        pp_pay2_list2_a:'开户银行',
        pp_pay2_list2_b:'中信银行重庆金渝支行',
        pp_pay2_list3_a:'银行账号',
        pp_pay2_list3_b:'8111 2010 1260 0567 967',
        pp_pay2_list4_a:'支付金额',
        pp_pay2_list4_b:'$',
        pp_pay2_list5_a:'汇率',
        pp_pay2_list6_a:'汇款金额',
        pp_pay2_list7_a:'汇款备注',
        pp_pay2_list7_tip:'请在对公转账时务必备注您当前登录的手机号码',
        pp_pay2_step2:'第二步：激活服务',
        pp_pay2_tip1:'转账完成后请点击页面中的「已完成支付」，我们在收到汇款1个工作日内会为您自动升级账号',
        pp_pay2_tip2_a:'如需加急操作，请在汇款完成后联系',
        pp_pay2_tip2_b:'在线客服',
        pp_pay2_tip2_c:'或者发送相关信息至',
      // pp_pay2_tip2_d:'1@kr.run', 邮箱不能用双语
        PP_pay2_btn3:'返回商品详情',

        PP_pay2_btn1:'我已完成支付',
        PP_pay2_btn2:'查看转账方式',

        PP_all_tip1:'请耐心等待...',
        PP_all_tip2:'获取微信支付订单失败，请稍后重试',
        PP_all_tip3:'获取推荐价格失败',

        PP_discounts_top1:'权益',
        PP_discounts_tip1:'请输入优惠推荐码',
        PP_discounts_btn1:'确认',
        PP_discounts_tip2:'使用推荐码享受专属折扣，仅限首次购买服务时有效',
    },
    // 工作台 DIY
    workbench:{
        right_top_title:'个人中心',
        right_top_list1:'个人信息',
        right_top_list2:'消息提示',
        right_top_list3:'我的计划',
        right_top_list4:'个人中心',

        W_diy_step1_tip1:'填写',
        W_diy_step1_tip2:'表格前，请您仔细阅读官方申请须知',
        W_diy_step1_tip3:'表申请须知',
        W_diy_step1_tip4:'其内容包括并不限于，申请人要求、表格说明、填表要求等,您可下载后仔细浏览',

        W_diy_step1_tip5:'若你未购买',
        W_diy_step1_tip6:'翻译服务',
        W_diy_step1_tip7:'，请用英文填写',

        //证据
        W_diy_step2_tip1:'申请递交的所有材料均需为英文',
        W_diy_step2_tip2:'若您的原始文件为中文，除有中文原始文件，在该PDF中还需要对应加入英文翻译文件，形成一个PDF进行上传',
        W_diy_step2_tip3:'若您的原始文件已为英文文件，则无需再进行翻译',
        W_diy_step2_tip4:'若您已购买',
        W_diy_step2_tip5:'翻译服务',
        W_diy_step2_tip6:'，请直接上传原文件，我们将为您完成英文翻译',

        //证据类型
        W_diy_step2_evi1_tip1:'1.*获奖项、荣誉或名次的证书复印件和奖杯奖牌照片。如证书丢失也可提供颁奖机构或者组委会的证明信；',
        W_diy_step2_evi1_tip2:'2.获奖通知复印件等；',
        W_diy_step2_evi1_tip3:'3.主办方获奖公示名单；',
        W_diy_step2_evi1_tip4:'4.媒体对获奖名单或获奖者本人的报道；',
        W_diy_step2_evi1_tip5:'5.颁奖现场照片；',
        W_diy_step2_evi1_tip6:'6.*主办方、评审评奖机构的介绍；',
        W_diy_step2_evi1_tip7:'7.*奖项的国内、国际影响力和地位的介绍；',
        W_diy_step2_evi1_tip8:'8.*奖项的评选标准、获奖条件、参赛资格、竞选程序的介绍；',
        W_diy_step2_evi1_tip9:'9.评委名单和对评委的介绍；',
        W_diy_step2_evi1_tip10:'10.*奖项设置，包括奖项种类、获奖者数量的介绍；',
        W_diy_step2_evi1_tip11:'11.其他获奖人、候选人名单和介绍；',
        W_diy_step2_evi1_tip12:'12.往届 (包含知名人士获奖者 )的奖项名单和名人介绍；',

        W_diy_step2_evi2_tip1:'1.*会员证或聘书复印件；',
        W_diy_step2_evi2_tip2:'2.邀请函、任命函或协会证明信；',
        W_diy_step2_evi2_tip3:'3.*协会的官方介绍；',
        W_diy_step2_evi2_tip4:'4.*展示协会重要社会影响力、良好声誉的介绍；',
        W_diy_step2_evi2_tip5:'5.对协会声望、影响力、重大活动的媒体报道；',
        W_diy_step2_evi2_tip6:'6.*入会条件、会员资格条件、会员评审程序；',
        W_diy_step2_evi2_tip7:'7.*显示出因杰出成就方能加入的介绍；',
        W_diy_step2_evi2_tip8:'8.如担任协会委员、理事或其他协会职务，对其工作职责的描述；',
        W_diy_step2_evi2_tip9:'9.*成为会员评审人资质介绍；',
        W_diy_step2_evi2_tip10:'10.协会审核信、组织推荐信；',

        W_diy_step2_evi3_tip1:'1.*杂志或报纸媒体报道原件复印件或扫描件，需要包括杂志封面或报纸名称、日期的头版和报道页面，含报道题目、记者姓名信息；',
        W_diy_step2_evi3_tip2:'2.*网络报道原始页面格式的截屏；',
        W_diy_step2_evi3_tip3:'3.*电视画面含有台标、栏目名称和申请受益人名字的截屏；',
        W_diy_step2_evi3_tip4:'4.*广播、音频报道的录音及文字版翻译；',
        W_diy_step2_evi3_tip5:'5.*媒体介绍，含发行区域、发行量、读者群信息；',
        W_diy_step2_evi3_tip6:'6.*发行量第三方统计对比数据；',
        W_diy_step2_evi3_tip7:'7.如属于知名记者的报道，附上记者资料；',

        W_diy_step2_evi4_tip1:'1.*审稿邀请信和完成函、评委或评委聘书、证书；',
        W_diy_step2_evi4_tip2:'2.*邀请方的简介、影响力及评审项目介绍；',
        W_diy_step2_evi4_tip3:'3.包含审稿、评审、评委结果的相关文件、评审公告等；',
        W_diy_step2_evi4_tip4:'4.*担任评委、评审的感谢信、酬劳证明等证明完成审稿、评审等的证据；',
        W_diy_step2_evi4_tip5:'5.评委、评审现场照片；',
        W_diy_step2_evi4_tip6:'6.同期担任评委、评审人的资质介绍材料；',
        W_diy_step2_evi4_tip7:'7.介绍担任评委、评审人选条件的材料；',
        W_diy_step2_evi4_tip8:'8.被评审人、参赛选手的介绍(知名的)；',

        W_diy_step2_evi5_title1:'专利、软件著作权类',
        W_diy_step2_evi5_title1_tip1:'1.*专利、软件著作权证书复印件；',
        W_diy_step2_evi5_title1_tip2:'2.专利说明书、专利产品的图片；',
        W_diy_step2_evi5_title1_tip3:'3.*专利授权书、转让协议、合同；',
        W_diy_step2_evi5_title1_tip4:'4.*专利应用产品销售范围、销售额、产量；',
        W_diy_step2_evi5_title1_tip5:'5.*专利行业地位、原创性与先进性、广泛应用介绍；',
        W_diy_step2_evi5_title1_tip6:'6.政府或组织给予的补贴、拨款或者其他奖励；',
        W_diy_step2_evi5_title1_tip7:'7.相关媒体报道；',

        W_diy_step2_evi5_title2:'制定行业标准类',
        W_diy_step2_evi5_title2_tip1:'1.*介绍国家、国际上技术、行业、产品标准或法规制定等内容的文件；',
        W_diy_step2_evi5_title2_tip2:'2.*标准、法规等适用范围、区域；',
        W_diy_step2_evi5_title2_tip3:'3.标准、法规制定委员会其他成员介绍；',
        W_diy_step2_evi5_title2_tip4:'4.相关媒体报道、组织公告；',

        W_diy_step2_evi5_title3:'创建流派(学派、曲派、画派等)和运动、教育领域的创新',
        W_diy_step2_evi5_title3_tip1:'1.*艺术上的创新流派；',
        W_diy_step2_evi5_title3_tip2:'2.*运动训练或教育方法创新并推动了行业发展的文件；',
        W_diy_step2_evi5_title3_tip3:'3.体现出重大意义的证据；',
        W_diy_step2_evi5_title3_tip4:'4.行业数据、报告，媒体对原创贡献的报道；',

        W_diy_step2_evi5_title4:'科学、学术类',
        W_diy_step2_evi5_title4_tip1:'1.*对发表学术论文的引文检索和论文被引用数量的证明；',
        W_diy_step2_evi5_title4_tip2:'2.影响力因素及领域内反响；',
        W_diy_step2_evi5_title4_tip3:'3.*领域内其他专家对你的肯定；',
        W_diy_step2_evi5_title4_tip4:'4.出版物的转载请求；',
        W_diy_step2_evi5_title4_tip5:'5.科技成果的官方认定、媒体报道；',
        W_diy_step2_evi5_title4_tip6:'6.国家、国际研究基金的资助和评估总结报告；',
        W_diy_step2_evi5_title4_tip7:'7.*科技成果应用、转化证据；',
        W_diy_step2_evi5_title4_tip8:'8.发明、发现、专利类奖励；',

        W_diy_step2_evi5_title5:'商业类',
        W_diy_step2_evi5_title5_tip1:'1.*原创商业理论和实施；',
        W_diy_step2_evi5_title5_tip2:'2.*创新管理方法和实施；',
        W_diy_step2_evi5_title5_tip3:'3.*创新商业项目工程；',
        W_diy_step2_evi5_title5_tip4:'4.*生产流程创新、新产品、新技术、新应用、新服务等；',
        W_diy_step2_evi5_title5_tip5:'5.创新公关传播、市场活动、品牌管理等；',

        W_diy_step2_evi5_title6:'推荐信类',
        W_diy_step2_evi5_title6_tip1:'1.*业内有分量的人物肯定申请受益人原创重大贡献的具体、详细描述；',
        W_diy_step2_evi5_title6_tip2:'2.专家证言，证明贡献的原创性和重大意义；',
        W_diy_step2_evi5_title6_tip3:'3.政府、组织、机构证明信；',

        W_diy_step2_evi6_title1:'论文/学术文章',
        W_diy_step2_evi6_title1_tip1:'1.*发表文章的媒体封面及论文部分首页复印/扫描件，能看到文章名称、内容、作者、日期等；',
        W_diy_step2_evi6_title1_tip2:'2.*刊载媒体发行区域、发行量、读者群、行业地位介绍；',
        W_diy_step2_evi6_title1_tip3:'3.*第三方媒体发行量统计对比；',
        W_diy_step2_evi6_title1_tip4:'4.媒体、专家等对该文章的报道或评价；',
        W_diy_step2_evi6_title1_tip5:'5.在行业内顶级或重要的学术会议、论坛发表学术报告的邀请函、收录的论文集、发言的照片、视频或媒体报道等；',

        W_diy_step2_evi6_title2:'专业书籍著作类',
        W_diy_step2_evi6_title2_tip1:'1.*著作封面(含作者姓名页)以及带有刊号、条形码页面的复印或扫描件；',
        W_diy_step2_evi6_title2_tip2:'2.*如果是合著、就要除了以上内容提供申请受益人负责撰写部分的证据；',
        W_diy_step2_evi6_title2_tip3:'3.*出版机构的简介及其声誉介绍，或其他证明影响力的文件；',
        W_diy_step2_evi6_title2_tip4:'4.出版的其他知名出版物介绍；',
        W_diy_step2_evi6_title2_tip5:'5.*书籍的发行量、销售量、印刷批次、加印次数、发行范围；',
        W_diy_step2_evi6_title2_tip6:'6.媒体、专家等对该书籍的报道或评价；',
        W_diy_step2_evi6_title2_tip7:'7.如被引用和转载，提供显示引用、转载数据的文件、允许转载文件；',
        W_diy_step2_evi6_title2_tip8:'8.*引起业内广泛评论、讨论等影响力方面的证据；',

        W_diy_step2_evi6_title3:'网络、移动互联网络、微信公号、微博、博客作品类',
        W_diy_step2_evi6_title3_tip1:'1.*原始页面截图；',
        W_diy_step2_evi6_title3_tip2:'2.*浏览量、下载量数据，可公证；',
        W_diy_step2_evi6_title3_tip3:'3.*提供发表平台的简介及其声誉、影响力介绍；',

        W_diy_step2_evi7_tip1:'1.*主办方及其声誉的介绍；',
        W_diy_step2_evi7_tip2:'2.*展览、展示本身的影响力介绍；',
        W_diy_step2_evi7_tip3:'3.对展览、展示的媒体报道介绍；',
        W_diy_step2_evi7_tip4:'4.*为本人作品的证据，如展览文字介绍、展览作品署名的照片证据；',
        W_diy_step2_evi7_tip5:'5.*参展证、邀请函、参展协议；',
        W_diy_step2_evi7_tip6:'6.*体现展示的内容、场馆等参展的图片、海报、视频截图；',
        W_diy_step2_evi7_tip7:'7.作品被知名机构收录、收藏、出版相关证据；',
        W_diy_step2_evi7_tip8:'8.专家意见、评论、官方的认定；',

        W_diy_step2_evi8_title1:'证明所在组织“信誉卓著”',
        W_diy_step2_evi8_title1_tip1:'1.*组织介绍、宣传册、网站；',
        W_diy_step2_evi8_title1_tip2:'2.*组织获得的荣誉、奖励、称号等；',
        W_diy_step2_evi8_title1_tip3:'3.*组织有影响力的社会活动；',
        W_diy_step2_evi8_title1_tip4:'4.行业排名数据；',
        W_diy_step2_evi8_title1_tip5:'5.公司的业绩(报表、年报、销售额、协议合同)；',
        W_diy_step2_evi8_title1_tip6:'6.技术研发的成果证明文件，行业认证，官方/协会认证文件等；',
        W_diy_step2_evi8_title1_tip7:'7.关于组织的重大贡献、杰出成绩的相关媒体报道；',
        W_diy_step2_evi8_title1_tip8:'8.政府资助或认定、专利或软件著作权；',

        W_diy_step2_evi8_title2:'证明扮演领导性角色',
        W_diy_step2_evi8_title2_tip1:'1.*显示出职务的聘书、任命书、雇佣合同或证明信；',
        W_diy_step2_evi8_title2_tip2:'2.*彰显发挥领导性作用的工作职责描述；',
        W_diy_step2_evi8_title2_tip3:'3.内部获得领导力方面奖励；',
        W_diy_step2_evi8_title2_tip4:'4.*组织高层人员 (董事长、副董事长、总裁、副总裁、总经理) 撰写的证明信，信中需要包含细节和有证明力的信息，详细解释申请受益人为什么对组织整体层面起到领导性作用；',
        W_diy_step2_evi8_title2_tip5:'5.媒体报道提到本人在组织中发挥领导性作用；',

        W_diy_step2_evi8_title3:'证明扮演关键性角色',
        W_diy_step2_evi8_title3_tip1:'1.*同其他在组织内被相关工作岗位的人对比起来，为什么对组织的理、业务或建设起到关键作用的材料；',
        W_diy_step2_evi8_title3_tip2:'2.职位职责范围描述，可以显示出该职位实际发挥出的作用是关键的；',
        W_diy_step2_evi8_title3_tip3:'3.*能显发挥出关键性作用的具体工作业绩、事例，重在实际表现和挥的作用。显示出对所在组织的活动已经做出至关重要的贡献；',
        W_diy_step2_evi8_title3_tip4:'4.*组织高层人员(董事长、副董事长、总裁、副总裁、总经理)撰?的证明信、信中需要包含细节和有证明力的信息，详细解释申请受益人为什么对组织起到关键性作用；',
        W_diy_step2_evi8_title3_tip5:'5.媒体报道提到本人在组织中发挥关键性作用；',
        W_diy_step2_evi8_title3_tip6:'6.独立顾问为组织发挥关键性作用的证明材料；',

        W_diy_step2_evi9_tip1:'1.*过去三年的纳税申报表、工资单、银行工资流水对账单；',
        W_diy_step2_evi9_tip2:'2.*含工资数字的聘用合同，公司出具雇佣薪资证明信；',
        W_diy_step2_evi9_tip3:'3.对展览、展示的媒体报道介绍；',
        W_diy_step2_evi9_tip4:'4.*同一专业领域内获得的其他收入或服务酬劳；',
        W_diy_step2_evi9_tip5:'5.高版税、高稿费、高价画作、高收费的授课，高价格的咨询服务等等；',
        W_diy_step2_evi9_tip6:'6.媒体关于此专业领域内其他优秀人才获得显著高薪的报道，可做对比；',
        W_diy_step2_evi9_tip7:'7.一份来自可靠的专业机构出具此专业领域人士高薪范围的报告、列表、名单；',
        W_diy_step2_evi9_tip8:'8.*提供区域性或职位薪酬调查报告；',
        W_diy_step2_evi9_tip9:'9.*本人薪酬与权威报告、数据库里同业人士薪酬对比；',

        W_diy_step2_evi10_tip1:'1.*该艺术表演(影片、音乐、戏曲等)的介绍、宣传材料；',
        W_diy_step2_evi10_tip2:'2.*该艺术作品的票房总收入销售记录，院线方、发行方的证明；',
        W_diy_step2_evi10_tip3:'3.*DVD、CD总销售金额、总销售量、总发行量；',
        W_diy_step2_evi10_tip4:'4.播放权、版权销售金额；',
        W_diy_step2_evi10_tip5:'5.关于艺术表演取得商业上成功含销售额的媒体报道；',
        W_diy_step2_evi10_tip6:'6.在线付费下载金额；',

        W_diy_step2_evi11_tip1:'十一、其他证据的证据收集内容建议',
        W_diy_step2_evi11_tip2:'10项主张中未涉及的其他类似证据材料。申请信相关佐证材料，如行业报告、官方分析数据等',

        W_niw_step2_evi_high1_tip1:'1. 正式官方学业证明；',
        W_niw_step2_evi_high1_tip2:'2. 毕业证、学位证扫描件；',
        W_niw_step2_evi_high1_tip3:'3. 学信网学历、学位认证证书中文和英文翻译件；',
        W_niw_step2_evi_high1_tip4:'4. 学历学位WES认证报告；',
        W_niw_step2_evi_high1_tip5:'5. 成绩单、院校介绍、专业领域介绍等；',

        W_niw_step2_evi_high2_tip1:'1. 若您为学士学位，则必须证明在专业领域拥有至少5年的全职工作经验，以证明拥有同等硕士学位能力。',
        W_niw_step2_evi_high2_tip2:'2. 所有前/现单位的证明信件或离职证明。',
        W_niw_step2_evi_high2_tip3:'证明需包括：申请人所属单位的名称、介绍、地址和联系电话，详细阐述申请人在所属单位担任的职务，主导的项目，申请人入职时间以及累计在职时间。',
        W_niw_step2_evi_high2_tip4:'3. 或所属机构/单位的职位公告。（如：学校教师介绍页；医院医师公告页）',

        W_niw_step2_evi_high3_tip1:'证明申请人在专业领域内有着足够的资质及成就，包括但不限于以下角度，您可从中任选合适的角度来进行收集。',
        W_niw_step2_evi_high3_tip2:'',
        W_niw_step2_evi_high3_tip3:'1. 专业领域内的著作',//3
        W_niw_step2_evi_high3_tip4:'论文/学术文章、报告',//4
        W_niw_step2_evi_high3_tip5:'1.*发表文章的媒体封面及论文部分首页复印/扫描件，能看到文章名称、内容、作者、日期等；',
        W_niw_step2_evi_high3_tip6:'2.*刊载媒体发行区域、发行量、读者群、行业地位介绍；',
        W_niw_step2_evi_high3_tip7:'3.*第三方媒体发行量统计对比；',
        W_niw_step2_evi_high3_tip8:'4.论文/学术文章引用量，与同等文章的引用量对比；',
        W_niw_step2_evi_high3_tip9:'5.刊载期刊学术因子介绍；',
        W_niw_step2_evi_high3_tip10:'6.媒体、专家等对该文章的报道或评价；',
        W_niw_step2_evi_high3_tip11:'7.在行业内顶级或重要的学术会议、论坛发表学术报告的邀请函、收录的论文集、发言的照片、视频或媒体报道等；',
        W_niw_step2_evi_high3_tip12:'专业书籍著作类',//12
        W_niw_step2_evi_high3_tip13:'1.*著作封面(含作者姓名页)以及带有刊号、条形码页面的复印或扫描件；',
        W_niw_step2_evi_high3_tip14:'2.*如果是合著、就要除了以上内容提供申请受益人负责撰写部分的证据；',
        W_niw_step2_evi_high3_tip15:'3.合著作者官方介绍',
        W_niw_step2_evi_high3_tip16:'4.*出版机构的简介及其声誉介绍，或其他证明影响力的文件；',
        W_niw_step2_evi_high3_tip17:'5.出版的其他知名出版物介绍；',
        W_niw_step2_evi_high3_tip18:'6.*书籍的发行量、销售量、印刷批次、加印次数、发行范围；',
        W_niw_step2_evi_high3_tip19:'7.媒体、专家等对该书籍的报道或评价；',
        W_niw_step2_evi_high3_tip20:'8.如被引用和转载，提供显示引用、转载数据的文件、允许转载文件；',
        W_niw_step2_evi_high3_tip21:'9.*引起业内广泛评论、讨论等影响力方面的证据；',
        W_niw_step2_evi_high3_tip22:'专利类',//22
        W_niw_step2_evi_high3_tip23:'1.*专利、软件著作权证书复印件；',
        W_niw_step2_evi_high3_tip24:'2.专利价值评估报告；',
        W_niw_step2_evi_high3_tip25:'3.专利登记网站截图；',
        W_niw_step2_evi_high3_tip26:'4.专利说明书、专利产品的图片；',
        W_niw_step2_evi_high3_tip27:'5.*专利授权书、转让协议、合同；',
        W_niw_step2_evi_high3_tip28:'6.*专利应用产品销售范围、销售额、产量；',
        W_niw_step2_evi_high3_tip29:'7.*专利行业地位、原创性与先进性、广泛应用介绍；',
        W_niw_step2_evi_high3_tip30:'8.政府或组织给予的补贴、拨款或者其他奖励；',
        W_niw_step2_evi_high3_tip31:'9.相关媒体报道；',
        W_niw_step2_evi_high3_tip32:'',
        W_niw_step2_evi_high3_tip33:'2. 获得的奖项、表彰证明',//33
        W_niw_step2_evi_high3_tip34:'1.*获奖项、荣誉或名次的证书复印件和奖杯奖牌照片。如证书丢失也可提供颁奖机构或者组委会的证明信；',
        W_niw_step2_evi_high3_tip35:'2.获奖通知复印件等；',
        W_niw_step2_evi_high3_tip36:'3.主办方获奖公示名单；',
        W_niw_step2_evi_high3_tip37:'4.媒体对获奖名单或获奖者本人的报道；',
        W_niw_step2_evi_high3_tip38:'5.颁奖现场照片；',
        W_niw_step2_evi_high3_tip39:'6.*主办方、评审评奖机构的介绍；',
        W_niw_step2_evi_high3_tip40:'7.*奖项的国内、国际影响力和地位的介绍；',
        W_niw_step2_evi_high3_tip41:'8.*奖项的评选标准、获奖条件、参赛资格、竞选程序的介绍；',
        W_niw_step2_evi_high3_tip42:'9.评委名单和对评委的介绍；',
        W_niw_step2_evi_high3_tip43:'10.*奖项设置，包括奖项种类、获奖者数量的介绍；',
        W_niw_step2_evi_high3_tip44:'11.其他获奖人、候选人名单和介绍；',
        W_niw_step2_evi_high3_tip45:'12.往届 (包含知名人士获奖者 )的奖项名单和名人介绍或相关报道；',
        W_niw_step2_evi_high3_tip46:'',
        W_niw_step2_evi_high3_tip47:'3. 专业从业资格证明：',//47
        W_niw_step2_evi_high3_tip48:'1.执照或证书的复印件；',
        W_niw_step2_evi_high3_tip49:'2.颁发执照或证书的机构简介。可向机构索要官方的宣传册，若没有，官网的介绍也可以；',
        W_niw_step2_evi_high3_tip50:'3.执照或证书的颁发标准细则。',
        W_niw_step2_evi_high3_tip51:'',
        W_niw_step2_evi_high3_tip52:'4. 薪资/报酬：',//52
        W_niw_step2_evi_high3_tip53:'1.*过去三年的纳税申报表、工资单、银行工资流水对账单；',
        W_niw_step2_evi_high3_tip54:'2.*含工资数字的聘用合同，公司出具雇佣薪资证明信；',
        W_niw_step2_evi_high3_tip55:'3.*奖金、佣金、津贴、分红等报酬收人证明；',
        W_niw_step2_evi_high3_tip56:'4.高版税、高稿费、高价画作、高收费的授课，高价格的咨询服务等等；',
        W_niw_step2_evi_high3_tip57:'5.媒体关于此专业领域内其他优秀人才获得显著高薪的报道，可做对比；',
        W_niw_step2_evi_high3_tip58:'6.一份来自可靠的专业机构出具此专业领域人士高薪范围的报告、列表、名单；',
        W_niw_step2_evi_high3_tip59:'7.*提供区域性或职位薪酬调查报告；',
        W_niw_step2_evi_high3_tip60:'8.*本人薪酬与权威报告、数据库里同业人士薪酬对比；',
        W_niw_step2_evi_high3_tip61:'',
        W_niw_step2_evi_high3_tip62:'5. 专业领域内重要协会成员：',//62
        W_niw_step2_evi_high3_tip63:'证明申请人是专业领域内重要协会的成员，包括但不限于：',
        W_niw_step2_evi_high3_tip64:'1.  会员资格证明：会员证、聘书、邀请函、任命函、协会开的证明；',
        W_niw_step2_evi_high3_tip65:'2.  协会介绍：协会官方介绍，协会章程；',
        W_niw_step2_evi_high3_tip66:'3.  成为会员的评选程序，评审人员，入会标准等；',
        W_niw_step2_evi_high3_tip67:'4.  关于协会的媒体报道，协会相关负责人、同等级其他会员介绍。',
        W_niw_step2_evi_high3_tip68:'5.  若担任协会领导，一份职责自述；',
        W_niw_step2_evi_high3_tip69:'6. 申请人在专业领域内做出贡献并被认可的证据。',//69
        W_niw_step2_evi_high3_tip70:'1. 工作中主导的项目成功',
        W_niw_step2_evi_high3_tip71:'（签署相关合同、公司开具的证明、项目介绍、照片、内部文件、市场采用实例及影响,相关主导项目媒体报道等，可按照项目一、项目二...进行分点阐述）',
        W_niw_step2_evi_high3_tip72:'2. 专业领域的成果',
        W_niw_step2_evi_high3_tip73:'（造成的行业影响、被引用示例、相应的媒体报道、获得的奖项表彰等）',
        W_niw_step2_evi_high3_tip74:'3. 参与制定或影响行业标准的相关证据',
        W_niw_step2_evi_high3_tip75:'（行业标准制定邀请函，标准最终成品，出席人员名单、介绍、照片、邀请函、报道等）',
        W_niw_step2_evi_high3_tip76:'4. 因专精能力获得的表彰、奖项、专家证言、推荐信等',
        W_niw_step2_evi_high3_tip77:'（证书、报道、照片、表彰机构介绍等）',
        W_niw_step2_evi_high3_tip78:'5. 对该行业作出贡献其他证据。',

        W_niw_step2_evi_high4_tip1:'佐证申请信中专业领域及其重要性的相关内容描述的证据材料。',
        W_niw_step2_evi_high4_tip2:'包括但不限于：',
        W_niw_step2_evi_high4_tip3:'1.行业市场研究报告、发展趋势、重要性等由第三方权威机构出具的相关材料。',//3
        W_niw_step2_evi_high4_tip4:'引用主要部分（注明证据出处）',//4
        W_niw_step2_evi_high4_tip5:'(1)专业领域工作现状报告；',
        W_niw_step2_evi_high4_tip6:'(2)行业发展发展趋势调研；',
        W_niw_step2_evi_high4_tip7:'(3)美国国家政策法案；',
        W_niw_step2_evi_high4_tip8:'(4)专业领域对美国的重要性的权威媒体报道、专家证词、论文著作、研究说明等；',
        W_niw_step2_evi_high4_tip9:'2.证据来源方的介绍',//9
        W_niw_step2_evi_high4_tip10:'(1)官方介绍（若截图需附带链接），以证明材料的权威性；',
        W_niw_step2_evi_high4_tip11:'(2)第三方对于来源方的评价、报道；',
        W_niw_step2_evi_high4_tip12:'(3)引用行业报告等材料本身所造成的广泛讨论、媒体报道的影响；',
    
        W_niw_step2_evi_ex1_tip1:'1. 正式官方学业证明；',
        W_niw_step2_evi_ex1_tip2:'3. 毕业证、学位证扫描件；',
        W_niw_step2_evi_ex1_tip3:'3. 学信网学历、学位认证证书中文和英文翻译件；',
        W_niw_step2_evi_ex1_tip4:'4. 学历学位WES认证报告；',
        W_niw_step2_evi_ex1_tip5:'5. 成绩单、院校介绍、专业领域介绍等；',

        W_niw_step2_evi_ex2_tip1:'1.证明在专业领域拥有至少10年的全职工作经验。',
        W_niw_step2_evi_ex2_tip2:'2.所有前/现单位的证明信件或离职证明。',
        W_niw_step2_evi_ex2_tip3:'包括：申请人所属单位的名称、介绍、地址和联系电话，详细阐述申请人在所属单位担任的职务，主导的项目，申请人入职时间以及累计在职时间。',
        W_niw_step2_evi_ex2_tip4:'3.或所属机构/单位的职位公告。（如：学校教师介绍页；医院医师公告页）',
        
        W_niw_step2_evi_ex3_tip1:'1. 执照或证书的复印件；',
        W_niw_step2_evi_ex3_tip2:'2. 颁发执照或证书的机构简介，可向机构索要官方的宣传册，若没有，官网的介绍也可以；',
        W_niw_step2_evi_ex3_tip3:'3. 执照或证书的颁发标准细则。',

        W_niw_step2_evi_ex4_tip1:'1、由所属单位出具薪酬证明/工资证明，表明申请人凭借专业领域的杰出能力获得高工资或酬劳.',
        W_niw_step2_evi_ex4_tip2:'2、提供申请人三年的税单:',
        W_niw_step2_evi_ex4_tip3:'(注: 可在个人所得税 app 下载税单;如单位收入证明和税单不一致，则提供银行流水，流水屏蔽支出和小额收入，流水显示账号、姓名)',
        W_niw_step2_evi_ex4_tip4:'3、提供同行岗位的平均收入证明和数据统计。',
        
        W_niw_step2_evi_ex5_tip1:'重要协会的成员，包括但不限于：', 
        W_niw_step2_evi_ex5_tip2:'1.会员资格证明：会员证、聘书、邀请函、任命函、协会开的证明；',
        W_niw_step2_evi_ex5_tip3:'2.协会介绍：协会官方介绍，协会章程；',
        W_niw_step2_evi_ex5_tip4:'3.关于协会的媒体报道，协会相关负责人、同等级其他会员介绍。',
        W_niw_step2_evi_ex5_tip5:'4.若担任协会领导，一份职责自述；',
        W_niw_step2_evi_ex5_tip6:'5.注：入会需有一定门槛，只需缴费、工作经验或申请就可以进的协会不算。',
        
        W_niw_step2_evi_ex6_tip1:'证明申请人在其专业领域做出贡献并获得认可。包含但不限于以下角度：',
        W_niw_step2_evi_ex6_tip2:'1. 工作中主导的项目',//2
        W_niw_step2_evi_ex6_tip3:'（签署相关合同、公司开具的证明、项目介绍、照片、内部文件、市场采用实例及影响,相关主导项目媒体报道等，可按照项目一、项目二...进行分点阐述）',
        W_niw_step2_evi_ex6_tip4:'可参考以下：',
        W_niw_step2_evi_ex6_tip5:'（1）原创商业理论和应用的证据；',
        W_niw_step2_evi_ex6_tip6:'（2）创新管理方法和实施的证据：',
        W_niw_step2_evi_ex6_tip7:'（3）重要商业项目、工程实施的证据；',
        W_niw_step2_evi_ex6_tip8:'（4）生产流程创新、新产品、新服务等的大范围应用；',
        W_niw_step2_evi_ex6_tip9:'（5）创新公关传播、市场活动、品牌管理的大范围成功',
        W_niw_step2_evi_ex6_tip10:'2. 专业领域的成果',//10
        W_niw_step2_evi_ex6_tip11:'（成果说明、造成的行业影响、被引用示例、相应的媒体报道、获得的奖项表彰等）',
        W_niw_step2_evi_ex6_tip12:'注1：行业影响、被引用示例类（收集角度）',
        W_niw_step2_evi_ex6_tip13:'(1)相关媒体报道；',
        W_niw_step2_evi_ex6_tip14:'(2)引用/应用方使用数据、说明、感谢信等；',
        W_niw_step2_evi_ex6_tip15:'(3)成果授权证明合同等；',
        W_niw_step2_evi_ex6_tip16:'(4)成果价值评估报告；',
        W_niw_step2_evi_ex6_tip17:'(5)专家证词、政府及权威组织机构的认可证明',
        W_niw_step2_evi_ex6_tip18:'',
        W_niw_step2_evi_ex6_tip19:'注2：媒体报道类（收集角度）',
        W_niw_step2_evi_ex6_tip20:'(6)*杂志或报纸媒体报道原件复印件或扫描件，需要包括杂志封面或报纸名称、日期的头版和报道页面，含报道题目、记者姓名信息；',
        W_niw_step2_evi_ex6_tip21:'(7)*网络报道原始页面格式的截屏；',
        W_niw_step2_evi_ex6_tip22:'(8)*电视画面含有台标、栏目名称和申请受益人名字的截屏；',
        W_niw_step2_evi_ex6_tip23:'(9)*广播、音频报道的录音及文字版翻译；',
        W_niw_step2_evi_ex6_tip24:'(10)*媒体介绍，含发行区域、发行量、读者群信息；',
        W_niw_step2_evi_ex6_tip25:'(11)*发行量第三方统计对比数据；',
        W_niw_step2_evi_ex6_tip26:'(12)如属于知名记者的报道，附上记者资料；',
        W_niw_step2_evi_ex6_tip27:'注3：奖项表彰类（收集角度）',
        W_niw_step2_evi_ex6_tip28:'(1)*获奖项、荣誉或名次的证书复印件和奖杯奖牌照片。如证书丢失也可提供颁奖机构或者组委会的证明信；',
        W_niw_step2_evi_ex6_tip29:'(2)获奖通知复印件等；',
        W_niw_step2_evi_ex6_tip30:'(3)主办方获奖公示名单；',
        W_niw_step2_evi_ex6_tip31:'(4)媒体对获奖名单或获奖者本人的报道；',
        W_niw_step2_evi_ex6_tip32:'(5)颁奖现场照片；',
        W_niw_step2_evi_ex6_tip33:'(6)*主办方、评审评奖机构的介绍；',
        W_niw_step2_evi_ex6_tip34:'(7)*奖项的国内、国际影响力和地位的介绍；',
        W_niw_step2_evi_ex6_tip35:'(8)*奖项的评选标准、获奖条件、参赛资格、竞选程序的介绍；',
        W_niw_step2_evi_ex6_tip36:'(9)评委名单和对评委的介绍；',
        W_niw_step2_evi_ex6_tip37:'(10)*奖项设置，包括奖项种类、获奖者数量的介绍；',
        W_niw_step2_evi_ex6_tip38:'(11)其他获奖人、候选人名单和介绍；',
        W_niw_step2_evi_ex6_tip39:'(12)往届 (包含知名人士获奖者 )的奖项名单和名人介绍；',
        W_niw_step2_evi_ex6_tip40:'',
        W_niw_step2_evi_ex6_tip41:'3. 参与制定或影响行业标准的相关证据',//41
        W_niw_step2_evi_ex6_tip42:'（行业标准制定邀请函，标准最终成品，出席人员名单、介绍、照片、邀请函、报道等）',
        W_niw_step2_evi_ex6_tip43:'1.*介绍国家、国际上技术、行业、产品标准或法规制定等内容的文件；',
        W_niw_step2_evi_ex6_tip44:'2.*标准、法规等适用范围、区域；',
        W_niw_step2_evi_ex6_tip45:'3.标准、法规制定委员会其他成员介绍；',
        W_niw_step2_evi_ex6_tip46:'4.相关媒体报道、组织公告；',
        W_niw_step2_evi_ex6_tip47:'',
        W_niw_step2_evi_ex6_tip48:'4. 因专精能力获得的表彰、奖项、专家证言、推荐信等',//48
        W_niw_step2_evi_ex6_tip49:'（证书、报道、照片、表彰机构介绍等）',
        W_niw_step2_evi_ex6_tip50:'i) 如果申请人曾获奖或获得荣誉，请提供：',
        W_niw_step2_evi_ex6_tip51:'(1) 获得的奖牌、奖杯、奖状、奖金等能证明获奖事实的证据；',
        W_niw_step2_evi_ex6_tip52:'(2) 颁奖的标准细则；',
        W_niw_step2_evi_ex6_tip53:'(3) 参赛或参选资格的具体规定；',
        W_niw_step2_evi_ex6_tip54:'(4) 能证明奖项重要性和认知度的证据，例如主流媒体的报道；',
        W_niw_step2_evi_ex6_tip55:'(5) 颁奖机构和颁奖嘉宾的介绍；',
        W_niw_step2_evi_ex6_tip56:'(6) 对其他前获奖人的介绍；',
        W_niw_step2_evi_ex6_tip57:'ii) 如果申请人曾被媒体报道过，请提供：',
        W_niw_step2_evi_ex6_tip58:'完整的媒体报道本身，并且应当包括以下信息：',
        W_niw_step2_evi_ex6_tip59:'(a) 标题、日期、作者是谁；',
        W_niw_step2_evi_ex6_tip60:'(b) 媒体的发行量、阅读量、发行范围或其他能证明媒体报道被大规模流通的类似证据；',
        W_niw_step2_evi_ex6_tip61:'(c) 媒体报道的目标读者或观众是哪些人。',
        W_niw_step2_evi_ex6_tip62:'iii) 讨论申请人的重大贡献的专家证词或推荐信：',
        W_niw_step2_evi_ex6_tip63:'信件和证词，必须尽可能详尽地描述申请人的贡献，并且必须具体解释为何贡献是重大的，请避免一般性地陈述“申请人的贡献是重要的”。',
        W_niw_step2_evi_ex6_tip64:'',
        W_niw_step2_evi_ex6_tip65:'5. 对该行业作出贡献其他证据。',//65

        W_niw_step2_evi_ex7_tip1:'佐证申请信中专业领域及其重要性的相关内容描述的证据材料。',
        W_niw_step2_evi_ex7_tip2:'包括但不限于：',
        W_niw_step2_evi_ex7_tip3:'1.行业市场研究报告、发展趋势、重要性等由第三方权威机构出具的相关材料。',//3
        W_niw_step2_evi_ex7_tip4:'引用主要部分（注明证据出处）',//4
        W_niw_step2_evi_ex7_tip5:'(1)专业领域工作现状报告；',
        W_niw_step2_evi_ex7_tip6:'(2)行业发展发展趋势调研；',
        W_niw_step2_evi_ex7_tip7:'(3)美国国家政策法案；',
        W_niw_step2_evi_ex7_tip8:'(4)专业领域对美国的重要性的权威媒体报道、专家证词、论文著作、研究说明等；',
        W_niw_step2_evi_ex7_tip9:'2.证据来源方的介绍',//9
        W_niw_step2_evi_ex7_tip10:'(1)官方介绍（若截图需附带链接），以证明材料的权威性；',
        W_niw_step2_evi_ex7_tip11:'(2)第三方对于来源方的评价、报道；',
        W_niw_step2_evi_ex7_tip12:'(3)引用行业报告等材料本身所造成的广泛讨论、媒体报道的影响；',
        
        W_niw_step2_evi_ex8_tip1:'如果申请人还有其他成就与上述6个类别主张的描述不完全匹配但是有可比性，请提供相应的具体证据。例如：',
        W_niw_step2_evi_ex8_tip2:'1.如果申请人以个人或评审团成员身份，做过其领域内他人工作的评委，请提供：',//2
        W_niw_step2_evi_ex8_tip3:'(1)申请人评审工作的文字或影音记录或其他能证明他（她）的确以评委或裁判身份完成了评判工作的证据；',
        W_niw_step2_evi_ex8_tip4:'(2)来自主办方的邀请信和感谢信，具体描述选择申请人作为评审的理由；',
        W_niw_step2_evi_ex8_tip5:'(3)主流媒体对评委工作的报道；',
        W_niw_step2_evi_ex8_tip6:'(4)其他评委的介绍材料和其他能说明被邀请的评委是业内精英的证据；',
        W_niw_step2_evi_ex8_tip7:'(5)主办方和比赛的介绍材料，突出说明比赛的国内或国际影响力，以及评委是否必须是业内杰出人才。',
        W_niw_step2_evi_ex8_tip8:'2.如果申请人在专业性的或主流的行业出版物或其他主流媒体发表过学术文章，请提供：',//8
        W_niw_step2_evi_ex8_tip9:'(1)申请人所发表的全部学术文章的节选，包括下列内容：',
        W_niw_step2_evi_ex8_tip10:'a.标题页，',
        W_niw_step2_evi_ex8_tip11:'b.指出申请人是作者，及是第几作者的页面，',
        W_niw_step2_evi_ex8_tip12:'c.指出发表文章的期刊名称的页面；',
        W_niw_step2_evi_ex8_tip13:'(2)申请人从学术文章引用量查询网站（例如SciFinder、GoogleScholar、ISI Web of Knowledge、Scopus、PubMed、中国科学引文数据库、和中国知网CNKI）得到的引用量查询结果（排除申请人对自己文章的引用后），显示下列信息：',
        W_niw_step2_evi_ex8_tip14:'a.申请人是哪些文章的作者、这些文章的标题、和发表这些文章的期刊，',
        W_niw_step2_evi_ex8_tip15:'b.申请人的h指数和i-10指数（如果有）；',
        W_niw_step2_evi_ex8_tip16:'(3)能证明申请人的学术文章的目标读者是特定领域内的专家学者的文件，例如第三方对该学术期刊的评论；',
        W_niw_step2_evi_ex8_tip17:'(4)能证明发表申请人文章的学术期刊或媒体是国家性或国际性的专业、行业性、或其他主流媒体的文件，例如：',
        W_niw_step2_evi_ex8_tip18:'(5)发行量、阅读量、或其他能证明大规模流通的证据，',
        W_niw_step2_evi_ex8_tip19:'(6)期刊的排名（可以在scimagojr.com等网站查询）及影响因数（通常可以在该期刊的网站上和Tomson Reuters Journal Citation Reports上查到）；',
        W_niw_step2_evi_ex8_tip20:'3. 如果申请人的作品曾在艺术展览或展示中被展出过，请提供：',//20
        W_niw_step2_evi_ex8_tip21:'(1)能证明申请人是艺术品的作者或者主创的证据；',
        W_niw_step2_evi_ex8_tip22:'(2)申请人创作的艺术品的销售记录；',
        W_niw_step2_evi_ex8_tip23:'(3)能证明展出作品的场所或媒介（包括虚拟的）是艺术性的展览或展示的证据，例如博物馆的介绍材料；',
        W_niw_step2_evi_ex8_tip24:'(4)申请人创作的艺术品的宣传材料；',
        W_niw_step2_evi_ex8_tip25:'(5)艺术展览或展示的宣传材料。',
        W_niw_step2_evi_ex8_tip26:'4. 如果申请人曾在声誉卓著的组织或机构中扮演过领导或关键角色，请提供：',//26
        W_niw_step2_evi_ex8_tip27:'(1)能证明申请人的职位和具体职责的文件；',
        W_niw_step2_evi_ex8_tip28:'(2)组织或机构的人员结构图或类似文件，显示申请人在其中所处的位置；',
        W_niw_step2_evi_ex8_tip29:'(3)能证明申请人的贡献对所在的组织或机构整体的活动和成果极为重要的文件；',
        W_niw_step2_evi_ex8_tip30:'(4)来自对申请人所扮演的角色的重要性很了解的现雇主或前雇主的信件，包含以下重要信息：',
        W_niw_step2_evi_ex8_tip31:'a.具体而有效地说明为什么申请人扮演的是领导或关键角色，',
        W_niw_step2_evi_ex8_tip32:'b.将申请人达成的特定任务或成就与同一领域内的同行进行比较；',
        W_niw_step2_evi_ex8_tip33:'(5)能证明申请人所在组织或机构拥有卓著的声誉的证据，例如：',
        W_niw_step2_evi_ex8_tip34:'a.媒体报道，',
        W_niw_step2_evi_ex8_tip35:'b.组织或机构的排名。',
        W_niw_step2_evi_ex8_tip36:'5.如果有票房收据或记录、卡带、光碟、或视频销售记录等证据证明，申请人在表演艺术方面获得了商业成功，请提供：',//36
        W_niw_step2_evi_ex8_tip37:'(1)电影、舞蹈、演唱会等演出的票房收据或其他票房记录；',
        W_niw_step2_evi_ex8_tip38:'(2)唱片、DVD或其他音频或视频制品的销售票据或其他能证明发行量的记录。',
        W_niw_step2_evi_ex8_tip39:'6.其他证据',//39


        W_diy_step3_title:'推荐信注意事项：',
        W_diy_step3_tip1:'1.推荐信的开头，必须写下日期、致美国司法部移民规划局',
        W_diy_step3_tip2:'2.推荐人必须明确表示他清楚写信的目的——“我写这封推荐信是为了支持XX的EB1A',
        W_diy_step3_tip3:'3.信的末尾必须留下推荐人的亲笔签名、联系方式（电话+邮箱）。签名可以是电子签名，也可以是签章，通常移民官不会打电话核实推荐信中的内容，但是必须要有联系方式。',
        W_diy_step3_tip4:'4.如果推荐人任职于著名组织，例如北大校长、上司公司高管，可以请求推荐人使用所在企业的抬头纸来打印推荐信，这样会显得更加正式、真实',
        W_diy_step3_tip5:'5.推荐信的数量通常为5封左右，不得低于3封，其中至少有一封要来自于与申请受益人没有直接师生、上下级关系的专家（独立推荐人）',
        W_diy_step3_tip6:'6.信件中不应出现过多对申请人性格、品质、精神等主观方面的评价或赞扬，“兢兢业业”“乐于助人”“勤劳奉献”等描述对于EB1A和NIW的移民申请都是没有帮助的；移民官不关心申请人的人品，只注重客观事实，关注申请人的贡献、成就、酬劳、职位、头衔。',
        W_diy_step3_tip7:'7.上传推荐信时需一并上传推荐人简历，推荐人简历需要包含其姓名、电话号码、电子邮箱、出生日期、工作经历、教育背景等信息。（或以推荐人附链接的官网介绍截图来作为其个人简历。）',

        W_diy_step4_title:'赴美计划撰写建议',
        W_diy_step4_tip1:'1.语言客观：不可过多赘述情绪，不可直白袒露对两国过于偏颇的言语',
        W_diy_step4_tip2:'2.按点陈列：入美计划属于计划的一部分，尤其对于NIW而言，赴美计划利好美国部分，需要特别下功夫，做到切实，客观，强力，需要简洁有逻辑性，便于移民移民官阅读。',
        W_diy_step4_tip3:'3.除专业分析部分，其他语言最好简单明了，不可过于论文化。',

        W_diy_step5_title:'申请信撰写内容建议：',
        W_diy_step5_tip1:'1.申请信开头请表明符合您已符合美国EB1A移民的哪些标准，根据符合标准进行具体阐述。',
        W_diy_step5_tip2:'2.请在个人成就的陈述中，适当引用证据，展示其重要性、真实性及权威性。',
        W_diy_step5_tip3:'3.申请信中您会将主张证据串联，进行具体说明。其语言需简单明了，陈述需客观切实。',
        // niw
        W_niw_step5_tip1:'1.申请信开头请表明符合您已符合美国NIW移民的哪项基本条件（高等学历或专精能力），根据符合标准进行具体阐述。',
        W_niw_step5_tip2:'2.论述符合国家利益豁免要求：证明专业领域工作具有重大价值和国家重要性；证明您处于继续推进事业的有利位置；证明利好美国，足够豁免工作聘书。',
        W_niw_step5_tip3:'3.请在个人成就的陈述中，适当引用证据，展示其重要性、真实性及权威性。',
        W_niw_step5_tip4:'4.申请信中您会将主张证据串联，进行具体说明。其语言需简单明了，陈述需客观切实。',

        W_diy_step6_title:'提交必看',
        W_diy_step6_tip1:'1.请检查您的申请材料完整度，再点击确认已经全部完成后才能进行下载打印、审核服务（辅助）与翻译服务（辅助）。',
        W_diy_step6_tip2:'2.您可点击工作台右上角我的权益来享受或购买您所需要的服务。',
        W_diy_step6_tip3:'3.DIY工作台只支持申请材料可在线编辑内容，部分内容需手写签字内容请下载打印后完成。',
    
        // 左侧菜单
        W_menu_l_list1:'阶段进度',
        W_menu_l_list2:'填写表格',
        W_menu_l_list3:'证据收集',
        W_menu_l_list4:'推荐信',
        W_menu_l_list5:'赴美计划',
        W_menu_l_list6:'申请信',
        W_menu_l_list7:'提交必看',

        // 右侧菜单
        W_menu_r_box1_title:'我的权益',
        W_menu_r_box1_list1:'EB-1A',
        W_menu_r_box1_niw_list1:'NIW',
        W_menu_r_box1_list2:'咨询问题',
        W_menu_r_box1_list3:'翻译服务',
        W_menu_r_box1_list4:'一键审核',

        W_menu_r_box2_title:'其他功能',
        W_menu_r_box2_list1:'申请须知',
        W_menu_r_box2_list2:'查看预览',
        W_menu_r_box2_list3:'功能引导',

        W_menu_r_box3_title:'答疑专区',
        W_menu_r_box3_btn:'查看',
        

        // 首次引导
        W_guidance_list1_title:'阶段1',
        W_guidance_list1_tip:'一目了然的认识准备材料逻辑结构',
        W_guidance_list2_title:'阶段2',
        W_guidance_list2_tip:'记录了一些注意事项以及技巧',
        W_guidance_list3_title:'阶段3',
        W_guidance_list3_tip:'这里是准备材料的主要工作区',
        W_guidance_list4_title:'阶段4',
        W_guidance_list4_tip:'这里显示您享有的特权;如有需要点击可以购买或使用权益',
        W_guidance_list5_title:'阶段5',
        W_guidance_list5_tip:'申请须知，点击查看指南；查看预览，更轻松帮助您理清思路与申请材料结构；功能引导，点击可查看工作台个阶段操作指引',
        W_guidance_list6_title:'阶段6',
        W_guidance_list6_tip:'专为您设计的快捷搜索;如有不懂或困惑，检索信息也许是您不错的选择;也许检索不到您需要的信息，可直接发出提问会有我们的移民顾问为您解答或帮助',
        W_guidance_list7_title:'引导功能',
        W_guidance_list7_tip:'引导被放置在这里，需要时点击选则引导内容;可选择引导内容：工作台引导、填写引导',

        // 表格须知
        W_FN_title:'表格须知',

        W_FN_title_EB1A:'一份EB-1A申请可能涉及以下表格，请按照您的个人需求进行选择填写。',
        W_FN_title_NIW:'一份NIW申请可能涉及以下表格，请按照您的个人需求进行选择填写。',
        W_FN_tip1:'注:在您填写前请仔细阅读所填表格的申请须知。',
        W_FN_tip2:'若您未购买翻译服务，请用英文填写。',
        W_FN_I140_title:'I-140（职业移民申请书）',
        W_FN_I140_tip_EB1A:'EB-1A移民申请必填。移民局官方申请费用：$700; 注:I-140 由700美金上调至715美金，于2024年4月1日生效;',
        W_FN_I140_tip_EB1A_tip1:'2024年4月1日起，新费用将即刻生效，没有宽限期，申请人必须使用新版本的I-140表格进行申请并支付更新后的费用，若未按规定进行申请，申请将不被移民局接收。注:个人申请人(self-petitioner)将额外支付$300 的庇护计划费用。',
        W_FN_I140_tip_EB1A_tip2:'140表更新：更新版本日期：2024 年 6 月 7 日。从 2024 年 10 月 21 日起，移民局将仅接受 2024 年 6 月 7 日版本，即此更新版本。在2024年10月21日前，申请人仍可以使用 2024 年 4 月 1 日版本，即前次版本。',
        W_FN_I140_tip_NIW:'NIW移民申请必填。移民局官方申请费用：$700; 注: I-140 由700美金上调至715美金，于2024年4月1日生效;',
        W_FN_I140_tip_NIW_tip1:'2024年4月1日起，新费用将即刻生效，没有宽限期，申请人必须使用新版本的I-140表格进行申请并支付更新后的费用，若未按规定进行申请，申请将不被移民局接收。注:个人申请人(self-petitioner)将额外支付$300 的庇护计划费用。',
        W_FN_I140_tip_NIW_tip2:'140表更新：更新版本日期：2024 年 6 月 7 日。从 2024 年 10 月 21 日起，移民局将仅接受 2024 年 6 月 7 日版本，即此更新版本。在2024年10月21日前，申请人仍可以使用 2024 年 4 月 1 日版本，即前次版本。',

        W_FN_8089_title:'ETA-9089（劳工证申请表）',
        W_FN_8089_tip:'ETA-9089是用于申请美国劳工证的表格，需打印后填写表格个人信息等部分内容，并与I-140表格一同递交至移民局。',

        W_FN_I907_title:'I-907（加急审理服务申请表）',
        W_FN_I907_tip:'若您希望移民局在15天内给出审核结果，可以向移民局申请优先处理服务。您需将I-907表格与I-140表格一起提交，并额外向移民局支付加急费用: $2500，共计申请费用$3200 (分两次支付)。注:I-140加急费由原来的2500美金上调至2805美金,于2024年2月26日正式实施。',
        W_FN_I907_tip1:'在2024年4月26日前，申请人可使用旧版和新版表格申请加急服务，支付加急费2805美金，4月26日之后，仅接受新版表格申请加急服务，加急费为2805美金。（Starting April 26, 2024, we will only accept the 02/26/24 edition.）',
        
        W_FN_1145_title:'G-1145（申请/呈请接收电子通知）',
        W_FN_1145_tip:'您可以填写G-1145表格申请美国移民局电子通知服务，以便通过电子通知了解案件进展。该项服务可免费申请。',

        W_FN_1450_title:'G-1450（信用卡交易授权表格）',
        W_FN_1450_tip1:'若您需要通过信用卡支付您的申请费用，请填写G-1450表格。移民局申请可接受的信用卡类型：',
        W_FN_1450_tip2:'Visa、MasterCard、American Express和Discover。',

        W_FN_btn1:'不再提醒',
        W_FN_btn2:'知道了',
        W_FRN_tip1:'此后将不会再提醒',
    },
    // 工作台 证据
    evidenceInfo: {
        // NIW 2主张方向
        EI_NIW_type_btn1:'高等学历',
        EI_NIW_type_btn2:'专精能力',
        EI_NIW_type_title:'当前主张方向：',

        EI_NIW_title:'符合类型',
        EI_NIW_tip_name:'满足的基础条件类型',
        EI_NIW_tip1:'高等学历（两者中满足一个）: ',
        EI_NIW_tip2:'拥有美国（或外国同等）大学硕士及以上学位；',
        EI_NIW_tip3:'拥有美国（或外国同等）大学的学士学位，并同时拥有5年以上相关专业领域的工作经验（这两者综合起来，视为等同于硕士学位）。',
        EI_NIW_tip4:'杰出能力（6项至少满足3项）:',
        EI_NIW_tip5:'在其专业领域内曾经获得过学院、大学或其它教育培训机构颁发的官方学术证明。',
        EI_NIW_tip6:'在所申请领域至少有十年全职工作的职业经验。',
        EI_NIW_tip7:'职业从业执照或特殊专业、职业资格证明。',
        EI_NIW_tip8:'由于自己的杰出能力所获得的薪水或特殊报酬证明。',
        EI_NIW_tip9:'基于杰出能力成为专业协会组织会员资格的证据。',
        EI_NIW_tip10:'对行业或领域，政府机构或商业组织做出重大贡献和成就而获得认可的证据以及其他类似证据',

        EI_NIW_type1:'高等学历',
        EI_NIW_type2:'专精能力',

        EI_NIW_dia_title:'切换满足条件',
        EI_NIW_dia_tip:' 切换会导致主张项发生变动，除工作、学业、辅助证据外其他主张的材料文件需重新上传，请谨慎切换。',
        EI_NIW_dia_btn1:'取消',
        EI_NIW_dia_btn2:'确认切换',

        EI_NIW_MSG_tip1:'主张方向已确认',
        EI_NIW_MSG_tip2:'主张方向确认失败',




        EI_box1_title:'已保存证据',
        // EB1A
        EI_box1_EB1A_list1:'奖项',
        EI_box1_EB1A_list2:'协会',
        EI_box1_EB1A_list3:'报道',
        EI_box1_EB1A_list4:'评审',
        EI_box1_EB1A_list5:'原创贡献',
        EI_box1_EB1A_list6:'著作',
        EI_box1_EB1A_list7:'艺术展览',
        EI_box1_EB1A_list8:'关键人物',
        EI_box1_EB1A_list9:'高薪',
        EI_box1_EB1A_list10:'商业',
        EI_box1_EB1A_list11:'其他证据',
        // NIW
        EI_box1_NIW_list1:'学业',
        EI_box1_NIW_list2:'工作',
        EI_box1_NIW_list3:'个人成就',
        EI_box1_NIW_list4:'辅助证据',
        EI_box1_NIW_list5:'从业资格',
        EI_box1_NIW_list6:'薪资/报酬',
        EI_box1_NIW_list7:'专业协会',
        EI_box1_NIW_list8:'行业贡献',
        EI_box1_NIW_list9:'其他证据',
        
        EI_box1_btn1:'调整顺序',
        EI_box1_tip1_a:'提供至少3项主要主张证据（还需要提供',
        EI_box1_tip1_b:'项主张',
        EI_box1_tip1_c:'）',
        EI_box1_btn2:'新增证据',
        EI_box1_btn3:'如何使用？',

        EI_table1_list1:'证据名称',
        EI_table1_list2:'顺序',
        EI_table1_list3:'已保存文件',
        EI_table1_list4:'操作',
        EI_table1_list4_btn1:'调序',
        EI_table1_list4_btn2:'上传',
        EI_table1_list4_btn3:'编辑',
        EI_table1_list4_btn4:'删除',

        EI_box2_title_a:'文件展示（',
        EI_box2_title_b:'）',
        EI_box2_title_c:'暂无数据',

        EI_box3_title_a:'模板下载',
        EI_box3_title_b:'（仅供参考，添加主张类型后才能下载模板）',
        EI_box3_tip1:'参考模板',
        EI_box3_btn1:'一键下载',

        EI_dia1_title1:'主张顺序调整',
        EI_dia1_title2:'证据顺序调整',
        EI_dia1_order_tip1:'调整顺序后证据顺序标号将发生变化您的证据已被引用，请在索引处进行修改重新索引。',
        EI_dia1_order_table1_list1:'顺序',

        EI_dia1_title:'调整主张展示顺序',
        EI_dia1_name:'主张类别',
        EI_dia1_btn1:'确认',
        EI_dia1_btn2:'取消',

        // 添加证据
        EI_dia_add1_title:'新增证据文件',
        EI_dia_add1_tip1:'点击添加原始文件',
        EI_dia_add1_tip2:'若有网站链接，请附上',
        EI_dia_add1_question1:'1.原始文件是否需要翻译？',
        EI_dia_add1_tip3:'“是否需要翻译”指上传的文件内容是否需要翻译成英文，例如文件已有英文版本、或是无文字内容的文件等就选择不需要翻译，反之则需要翻译。',
        EI_dia_add1_question1_Y:'需要',
        EI_dia_add1_question1_N:'不需要',
        EI_dia_add1_question2:'证据名称',

        EI_dia_add2_tip1:'点击添加原始文件',
        // EI_dia_add2_tip2:'若有网站链接，请附上',
        EI_dia_add2_question1:'2.选择翻译方式',
        EI_dia_add2_question1_Y:'翻译服务（推荐）',
        EI_dia_add2_question1_N:'个人翻译',
        EI_dia_add2_question2:'证据名称（翻译文件）',

        EI_dia_add_tip1:'您已开通翻译服务，可使用专业的翻译服务',
        EI_dia_add_tip2_a:'您暂未开通翻译功能，开通可使用专业的',
        EI_dia_add_tip2_b:'翻译服务',

        EI_dia_add_btn1:'取消',
        EI_dia_add_btn2:'确认',
        EI_dia_add_btn2_a:'编辑',
        EI_dia_add_btn2_b:'新增',

        EI_dia_add_options1:'1.获得过省/国家级/国际性的奖项（奖项）',
        EI_dia_add_options2:'2.仅限杰出人才专业协会会员资格（协会）',
        EI_dia_add_options3:'3.各大媒体对您专业领域成就报道（报道）',
        EI_dia_add_options4:'4.您担任评委对作品进行评审/鉴定（评审）',
        EI_dia_add_options5:'5.您专业领域有原创性的重大贡献（贡献）',
        EI_dia_add_options6:'6.您在主流媒体发表过专业的作品（著作）',
        EI_dia_add_options7:'7.举办艺术展览或其他展示活动（艺术展览）',
        EI_dia_add_options8:'8.在职能中担任领导或重要职务（关键人物）',
        EI_dia_add_options9:'9.专业领域薪资/报酬/待遇远超同行（高薪）',
        EI_dia_add_options10:'10.您在表演/艺术上获过的商业成功（商业）',
        EI_dia_add_options11:'11.其他申请信相关证据材料（其他）',

        EI_dia_add_NIW_options1_list1:'1.官方学历学业证明（学业）',
        EI_dia_add_NIW_options1_list2:'2.专业领域内全职工作经验证明（工作）',
        EI_dia_add_NIW_options1_list3:'3.个人专业成就相关材料(个人成就)',
        EI_dia_add_NIW_options1_list4:'4.证明专业领域重要性的辅助证据(辅助证据)',

        EI_dia_add_NIW_options2_list1:'1.官方学历学业证明（学业）',
        EI_dia_add_NIW_options2_list2:'2.专业领域内至少10年全职工作经验证明（工作）',
        EI_dia_add_NIW_options2_list3:'3.职业从业执照或特殊专业、职业资格证明（从业资格）',
        EI_dia_add_NIW_options2_list4:'4.因特殊技能所获得的薪水或特殊报酬证明（薪资/报酬）',
        EI_dia_add_NIW_options2_list5:'5.因特殊能力成为专业协会组织会员资格的证明（专业协会）',
        EI_dia_add_NIW_options2_list6:'6.专业领域内获得认可，并做出贡献的证明（行业贡献）',
        EI_dia_add_NIW_options2_list7:'7.证明专业领域重要性的辅助证据(辅助证据)',
        EI_dia_add_NIW_options2_list8:'8.其他证据材料（其他证据）',


        EI_dia_add_input_tip1:'请选择是否翻译',
        EI_dia_add_input_tip2:'请选择是否翻译类型',
        EI_dia_add_input_tip3:'请上传原文件',
        EI_dia_add_input_tip4:'请上传翻译文件',
        EI_dia_add_input_tip5:'请填写证据名称',
        EI_dia_add_input_tip6:'请填写证据名称',

        // 添加证据主张
        EI_dia_add_EviType_title:'新增证据',
        EI_dia_add_EviType_question1:'证据主张',
        EI_dia_add_EviType_question1_tip:'请选择证据主张',
        EI_dia_add_EviType_question2:'证据名称',
        EI_dia_add_EviType_question2_tip:'请输入证据名称',
        EI_dia_add_EviType_tip_a:'提示：您添加证据文件夹后，还需要点击文件夹，按照提示上传对应的证据材料。',
        EI_dia_add_EviType_tip_b:'详情',
        EI_dia_add_EviType_btn1:'取消',
        EI_dia_add_EviType_btn2:'确认新增',
        EI_dia_add_EviType_input_tip1:'请选择证据类型',
        EI_dia_add_EviType_input_tip2:'请输入证据名称',

        // 修改证据
        EI_dia_set_title:'编辑证据',
        EI_dia_set_question1:'证据名称',
        EI_dia_set_table_name:'已上传材料',
        EI_dia_set_table_list1:'材料名称',
        EI_dia_set_table_list2:'对应翻译文件',
        EI_dia_set_table_list2_a:'无需翻译',
        EI_dia_set_table_list2_c:'等待官方翻译',
        EI_dia_set_table_list3:'证据名称',
        EI_dia_set_table_list4:'网址链接',
        EI_dia_set_table_list5:'操作',
        EI_dia_set_table_list5_btn1:'编辑',
        EI_dia_set_table_list5_btn2:'删除',
        EI_dia_set_table_list5_btn2_tip:'如证据被索引，请在网页被索引处 在线进行删除调整，你确定要删除吗?',
        EI_dia_set_btn1:'取消',
        EI_dia_set_btn2:'确认编辑',
        EI_dia_set_input_tip:'请选择证据类型',


        // 证据文件列表
        EI_select_text1:'添加主张:',
        EI_select_tip1:'请选择证据主张',
        EI_select_tip2:'无可选主张',
        EI_EvidenceFile_tip1:'获取证据文件夹失败',
        EI_EvidenceFile_tip4:'获取证据文件失败',
        EI_EvidenceFile_tip2_a:'以下为',
        EI_EvidenceFile_tip2_b:'证据文件收集建议，内容仅供参考。若您想添加的证据文件未涵盖在以下类型中，可上传至下列其他分类中。',
        EI_EvidenceFile_tip3:'“是否需要翻译”指上传的文件内容是否需要翻译成英文，例如文件已有英文版本、或是无文字内容的文件等就选择不需要翻译，反之则需要翻译。',

        // 证据文件组件
        EI_EvidenceFile_text1:'官方翻译',
        EI_EvidenceFile_text2:'无需翻译',
        EI_EvidenceFile_text3:'详情说明',
        EI_EvidenceFile_text4:'证据文件',
        EI_EvidenceFile_textBtn1:'收起',
        EI_EvidenceFile_textBtn2:'展开',
        EI_EvidenceFile_textBtn3:'查看详细说明',
        

        
        // 前端提醒
        EI_tip1:'请选择主张类型和证明名称再上传！',
        EI_tip2:'正在上传',
        EI_tip3:'上传成功',
        EI_tip4:'修改成功',
        EI_tip5:'添加失败',
        EI_tip6:'保存成功',
        EI_tip7:'主张顺序调整完成',
        EI_tip8:'调整失败',
        EI_tip9:'调序失败',
        EI_tip10:'请选择主张',
        EI_tip11:'下载模板类型:',
        EI_tip12:'修改失败',
        EI_tip13:'添加成功',
        EI_tip14:'删除成功',
        EI_tip15:'删除失败',
    },
    // 工作台 推荐信
    petition: {
        PT_title:'已保存推荐信',
        PT_table_list1:'推荐信',
        PT_table_list2:'推荐信（翻译）',
        PT_table_list3:'推荐人简历',
        PT_table_list4:'推荐人简历（翻译）',
        PT_table_list5:'证据顺序',
        PT_table_list6:'操作',
        PT_table_list6_btn1:'调序',
        PT_table_list6_btn2:'查看',
        PT_table_list6_btn3:'编辑',
        PT_table_list6_btn4:'删除',
        PT_table_list6_btn4_tip:'如推荐信被索引，请在网页被索引处 在线进行删除调整，你确定要删除吗?',
        PT_table_list6_btn5:'上传推荐信',

        PT_temp_title_a:'推荐信模板',
        PT_temp_title_b:'（仅供参考）',

        PT_temp_tab1_title:'案例一',
        PT_temp_tab1_p1:'名字',
        PT_temp_tab1_p2:'日期',
        PT_temp_tab1_p3:'美国司法部',
        PT_temp_tab1_p4:'移民规划局',
        PT_temp_tab1_p5:'尊敬的先生女士',
        PT_temp_tab1_p6:'我是XXX（推荐人介绍），作为XX领域的专家，我写下这封推荐信是为了支持XX先生在EB1A/NIW条款下的美国移民申请 ',
        PT_temp_tab1_p7:'我与XX先生相识于.......（申请人贡献介绍）',
        PT_temp_tab1_p8:'我认为XX先生的贡献是XX领域顶尖的专家之一，如果他能够获批前往美国，将会对美国XX行业的发展产生重大利好。如果您需要更多关于XX先生的信息，请与我联络',
        PT_temp_tab1_p9:'真诚的：',
        PT_temp_tab1_p10:'XXX（推荐人姓名）',
        PT_temp_tab1_p11:'签名：',
        PT_temp_tab1_p12:'邮箱：',
        PT_temp_tab1_p13:'电话：',
        
        PT_temp_tab2_title:'案例二',
        PT_temp_tab2_p1:'两段式撰写',
        PT_temp_tab2_p2:'第一段推荐人的介绍（简短介绍），姓名，职位，成就：',
        PT_temp_tab2_p3:'陈列推荐人信息：毕业院校，当前的职位，体现出推荐人个人的杰出能力，如某协会会长，大公司高管，书籍作者，论文发表，国家级项目负责人，创新研发某种物质或产品，获得某些领域内国际国内认可奖项。例如：',
        PT_temp_tab2_p4:'以何种契机与申请人结识（简短介绍），在哪方面申请人表现出的才能使推荐人认为申请人专业与杰出。',
        PT_temp_tab2_p5:'第二段着重介绍：',
        PT_temp_tab2_p6:'1.强调创新性：',
        PT_temp_tab2_p7:'........在全省/全国/世界范围内是全新的',
        PT_temp_tab2_p8:'........是XX领域第一部专著 ',
        PT_temp_tab2_p9:'2.强调重要性 ',
        PT_temp_tab2_p10:'.......可以被称为XX行业国家级水平的研究员/艺术家 ',
        PT_temp_tab2_p11:'获得了业界广泛的尊重和赞誉，被誉为.......',
        PT_temp_tab2_p12:'......影响了大批同行的创作理念 ',
        PT_temp_tab2_p13:'3.强调特殊性',
        PT_temp_tab2_p14:'唯一一位取得此荣誉的......',
        PT_temp_tab2_p15:'......从XX位同行/竞争者中脱颖而出',
        PT_temp_tab2_p16:'4.强调持续性',
        PT_temp_tab2_p17:'在长达X年的时间里，XX持续地赢得了业内广泛的尊重和赞誉。',

        PT_temp_tab3_title:'案例三',
        PT_temp_tab3_p1:'王XX',
        PT_temp_tab3_p2:'2022年11月18日',
        PT_temp_tab3_p3:'美国司法部',
        PT_temp_tab3_p4:'移民归化局',
        PT_temp_tab3_p5:'尊敬的先生/女士：',
        PT_temp_tab3_p6:'我叫XXX，目前是辽宁沈阳供电公司电能集团总工程师。教授级高级工程师，辽宁沈阳供电公司电能集团总工程师，全国裸电线标准化技术委员会（SAC/TC422)专家委员，中国电力企业联合会输变电材料标准化技术委员会、导地线技术委员会委员，中国电器工业协会裸电线专委会委员（CEEIA/T23），国网辽宁电力电工新材料攻关团队带头人，特种导线及电缆应用技术实验室负责人。',
        PT_temp_tab3_p7:'我长期从事输电领域特种导线及节能新材料技术的研发，牵头承担20余项国家电网公司科技项目。从导体材料成分配方到合金导线制备精准调控、产品的试制及示范应用，他都身体力行，带领团队分别研发出导电率为61%、61.5%、61.8%的耐热铝合金导线及节能导线，成果被相关院士团队鉴定为“项目整体技术达到国际领先水平”。',
        PT_temp_tab3_p8:'我研究的多项成果填补了相关领域技术空白，获国家科学技术进步奖二等奖1项、省部级奖12项，编写国家及行业标准10项，获得发明专利27项、实用新型43项，发表核心期刊论文21篇、论著2篇。他的研究成果满足了大容量、低损耗、高可靠性的输电及多种应用场景的需求，减少了输电走廊的占地面积，为国家重大输电工程建设提供了支撑。',
        PT_temp_tab3_p9:'作为电力工程方面的专家，我写这封推荐信是为了支持XX女士在EB1A条款下的美国移民申请。',
        PT_temp_tab3_p10:'我和XX女士相识于2014年，当时我们一起在甘肃省酒泉市参与酒泉风电基地的建设。酒泉风电基地是中国第四大的风力发电基地，截止2015年前，酒泉基地已投产发电的风电装机容量达到12710MW，是中国第四大的风力发电基地。其中河西电网输电线路全长约为1000KM，是目前国内输电距离最长、串联变电最多的330KV线路。由于线路经过地形复杂、气候多变的河西走廊区域，输电线路的防雷绝缘成为了工程建设的难点。',
        PT_temp_tab3_p11:' 针对这一问题，作为工程的承建方之一，XX女士领导的陕西圣嘉电力设备工程有限公司，分析了超高压输电线路耐雷水平与雷电流波形的关系.利用EMTP仿真软件中的基础元件建立了雷电流模型、输电线路杆塔模型、绝缘子闪络模型、进线端模型.利用EMTP仿真软件得出超高压输电线路在不同雷电流波形下的耐雷水平。通过所得到的数据分析得出在不同的雷电流波形作用下超高压输电线路耐雷水平及其过电压的变化规律,为超高压输电线路如何采用既经济又安全的绝缘水平提供了依据，极大地促进了酒泉风电基地的建设。',
        PT_temp_tab3_p12:'我高度认可XX女士在电力工程方面的专业成就，并且坚信如果她能够到美国继续从事相关工作，会极大促进美国企业在电力、环保领域的发展。如果您需要更多相关信息，可以随时联系我。',
        PT_temp_tab3_p13:'真诚的：',
        PT_temp_tab3_p14:'邮箱：',
        PT_temp_tab3_p15:'电话：',

        PT_temp_tab4_title:'案例四',
        PT_temp_tab4_p1:'美国司法部',
        PT_temp_tab4_p2:'移民归化局',
        PT_temp_tab4_p3:'尊敬的先生/女士：',
        PT_temp_tab4_p4:'我提出这个独立的咨询意见，是为了说服你批准XXX的永久居留权申请是明智的。',
        PT_temp_tab4_p5:'我的资历',
        PT_temp_tab4_p6:'我叫XXX，是一名中医学家，现任某某医院院长，中医主任医师。我曾担任某某协会委员，某某研究会理事，某某专业委员会理事，某某分会常务理事、某某等职位。2014年8月，我被授予“某某某某某”称号， 2019年9月29日，我被授予“某某某某某”称号。',
        PT_temp_tab4_p7:'我出版了多部专著和论文，如《XXXXXX》《XXXXXX》《XXXXXX》《XXXXXX》《XXXXXX》等。我还培养了一批优秀的中医后继人才，传承了中医药的精髓和技艺。',
        PT_temp_tab4_p8:'因此，我有足够的资格对XX先生的专业水平和贡献做出评价。',
        PT_temp_tab4_p9:'XX的专业成就',
        PT_temp_tab4_p10:'我与XX第一次见面是在2021年深圳中医药学会举办的针灸推拿手法评选活动上，XX先生作为学会的特聘评审专家，其发表的专业意见中对中医各种疗法见解十分深刻。XX先生在古代雷火神灸实按灸的基础上，以现代医学为基础，在艾绒中配上芳香走窜及活血通络的药物，使其具有祛风、散寒、除湿、通络的药力，创新出了雷火灸治疗女性痛经的治疗办法。该疗法可以根据病情不同选择不同的穴位施灸，具有渗透入穴，产生温经散寒、活血通络的作用。',
        PT_temp_tab4_p11:'XX开创的此种雷火灸疗法是一种结合了中医经络理论和现代药理学的创新方法，不仅在配方上组成上具有优越性，能够有效地温通经络，活血化瘀，调节气血，而且在穴位的选取及手法操作上也具有独创性，能够精准地针对不同的病因和症状，达到祛除病邪，恢复平衡的目的。在其诊所的数百例临床实践中，取得了确切且显著的疗效，几乎无不良反应的产生，是医学实践中非常难得的绿色疗法，非常值得在中医领域推广实践。他也因此被中国中医药信息学会的理事会评选成为学会的智库专家之一，该理事会成员均是中国医学界的国家级专家，这是对XX专业成就的巨大认可。XX开创的雷火灸疗法不仅为广大患者提供了一种安全、有效、便捷、经济的治疗选择，也为中医药的发展和传承做出了重要贡献。',
        PT_temp_tab4_p12:'XX的研究符合美国的杰出人才移民',
        PT_temp_tab4_p13:'根据一篇2020年发表在《BMC妇女健康》上的研究，美国女性痛经的发生率高达50%到90%，是导致女性缺勤和抑郁的主要原因。痛经不仅会给女性带来许多生理上的难受和不适，还会严重影响她们的学习、工作和社交活动，从影响许多女性的心理健康，甚至导致严重抑郁。目前，美国女性痛经的治疗方法主要有药物治疗和非药物治疗两类。药物治疗主要包括非甾体抗炎药（NSAIDs）和激素避孕药。 药物治疗的成效一般较好，能有效减轻大多数女性的痛经症状。但是，药物治疗也有一些不足之处和副作用，比如，不能根本解决问题，需要长期服用；可能引起消化道不适、出血异常、血栓形成等并发症；可能与其他药物或疾病相互作用或影响等。',
        PT_temp_tab4_p14:'非药物治疗主要包括生活方式调整和替代医学方法。其中，替代医学方法包括针灸、艾灸、中草药、芳香疗法、生物反馈等，能通过刺激穴位或神经末梢，调节内分泌和免疫系统，平衡气血和阴阳，缓解疼痛。针灸治疗相对于药物治疗的优势在于不会产生药物副作用，也不会造成药物依赖或上瘾，并且也能有效地减轻疼痛强度和持续时间。因此，针灸治疗在美国的推广将能够在一定程度上缓解许多因过度服药导致的不良反应和药物依赖。',
        PT_temp_tab4_p15:'雷火灸作为一种亚洲传统医学的方法，在美国还不太为人所知，缺乏足够的认可和推广。但是，随着人们对自然疗法和替代医学的兴趣和需求的增加，雷火灸未来将在美国获得更多的关注和应用。因此，XX去到美国之后，将利用其专业的中医技术和知识促进雷火灸疗法在美国的发展和应用。',
        PT_temp_tab4_p16:'结论',
        PT_temp_tab4_p17:'基于我对XX先生能力及成就的深入了解，我对他的永久居留申请给予最强烈的支持。他是中医针灸推拿领域的杰出和领先研究者。结合XX先生的成就和能力，他的持续努力将极大地促进美国的国家利益。我希望上述意见将有助于您就XX先生是否有资格获得美国永久居留权做出决定。如果您想了解更多信息，请随时与我联系。',
        PT_temp_tab4_p18:'真诚的',
        PT_temp_tab4_p19:'XXX',
        PT_temp_tab4_p20:'签字：',
        PT_temp_tab4_p21:'电话：',

        PT_temp_tab5_title:'案例五',
        PT_temp_tab5_p1:'年月日',
        PT_temp_tab5_p2:'美国司法部',
        PT_temp_tab5_p3:'移民归化局',
        PT_temp_tab5_p4:'尊敬的先生/女士：',
        PT_temp_tab5_p5:'我提出这个独立的咨询意见，是为了说服你批准XX女士的永久居留权申请是明智的。',
        PT_temp_tab5_p6:'我的资历',
        PT_temp_tab5_p7:'我是设计师品牌时尚公司——某某的创始人XXX，现任杭州某某有限公司法人和执行董事。我主要负责公司的整个业务运营规划和管理工作。在服装设计领域我拥有近三十年的行业运营及管理经验，是业务策略及成就的主要推动者。某某逐渐成长为中国最有特征差异及综合影响力的品牌之一，并已得到其他国家及地区消费者的认同与接受，在全球的独立实体零售店共计1956家，除中国之外，在澳洲、俄罗斯和日本等地均有分布。目前，某某的年收益十分可观，已成为设计师品牌这个行业的佼佼者。我始终秉承“艺术探索、美好生活”的理念，让某某致力于构筑充满艺术感的生活氛围，使消费者从多元化的艺术设计产品中获得更多乐趣，体味更美好的艺术生活。作为服装设计行业的开源领军人物，我能站在客观的角度对XX女士做出评价。',
        PT_temp_tab5_p8:'XX女士的专业成就',
        PT_temp_tab5_p9:'我与XX女士是多年好友，也是未来的合作伙伴。我们对于服装设计的看法非常相似，她坚持着将艺术与生活相融的设计理念，以明亮的用色去呈现女性独立精彩又保有童心的形象。这与我认为的从艺术中探索美好生活的观点不谋而合。',
        PT_temp_tab5_p10:'XX女士是一位非常优秀的设计师，所设计的作品也曾多次登上国际四大时装周，早在她创建个人品牌某某某之前，其作品就已参加了3次伦敦时装周，1次纽约时装周。在短短几年间的荣誉也同样拿到手软，包括2017连卡佛创意集结奖、2019国际羊毛局大赛亚洲区优胜者在内的若干奖项。在做设计时，她一直追求将技术与设计美学相平衡，在作品中展现她浪漫文艺的同时也看见了理性的一面。对于色彩的运用与搭配，她有着自己用色的一套方法，用自己的方式去发现规律，最后用针织技术将对色彩的平衡应用诠释到极致。',
        PT_temp_tab5_p11:'XX女士是设计师中的技术迷，对于针织技术的痴迷让她一直寻求与行业顶级工厂合作，建立针织的技术壁垒，目前已经在无缝针织领域取得了突破并在之后的设计系列中得到应用。就服装设计行业而言，她已站在该领域的最前沿，未来她将继续带领团队研究设计技术，促进美国时尚设计的高质量发展。',
        PT_temp_tab5_p12:'XX女士符合美国的杰出人才移民',
        PT_temp_tab5_p13:'自1970年以来，由于生产和消费的全球化，美国的制造设计业迁移到第三世界国家，企业外迁使得大量设计人员外流，设计业出现衰退现象。直到九十年代，其产业结构才发生巨大变化，制造设计业被重视起来。现如今，设计行业属于高速发展的行业，美国希望吸纳世界各地的杰出设计师来缓解人才缺口问题。正如XX女士过去的成就所证明的那样，她的贡献对美国的国家利益十分重要。',
        PT_temp_tab5_p14:'结论',
        PT_temp_tab5_p15:'基于XX女士在时尚设计这一领域的卓越能力及突出贡献，我对她的永久居留申请给予最强烈的支持。结合XX女士的成就和能力，她的持续努力将极大地促进美国的国家利益。我希望上述意见将有助于您就XX女士是否有资格获得美国永久居留权做出决定。如果您想了解更多信息，请随时与我联系。',
        PT_temp_tab5_p16:'真诚的',
        PT_temp_tab5_p17:'XXX',
        PT_temp_tab5_p18:'签字：',
        PT_temp_tab5_p19:'电话：',
        PT_temp_tab5_p20:'单    位：',
        PT_temp_tab5_p21:'职    位：',
        PT_temp_tab5_p22:'日    期：',

        PT_temp_down_a:'模板下载',
        PT_temp_down_b:'（仅供参考）',
        PT_temp_down_name:'参考模板',
        PT_temp_down_name2:'一键下载',

        PT_tip1:'查看',
        PT_tip2:'编辑推荐信',
        PT_tip3:'推荐信顺序调整',
        PT_tip4:'调序完成',
        PT_tip5:'调序失败',
        PT_tip6:'推荐信名称还未填写！',
        PT_tip7:'添加推荐信',
        PT_tip8:'添加推荐信',

        DP_title:'请选择查看文件类型',
        DP_btnsBox1_btn1:'原始文件(简历)',
        DP_btnsBox1_btn2:'翻译文件(简历)',
        DP_btnsBox2_btn1:'翻译文件(推荐信)',
        DP_btnsBox2_btn2:'翻译文件(推荐信)',


        UL_title1:'上传推荐人简历',
        UL_upfile1:'点击添加原始文件',
        UL_upfile1_ques1:'1.原始文件是否需要翻译？',
        UL_upfile1_ques1_tip:'“是否需要翻译”指上传的文件内容是否需要翻译成英文，例如文件已有英文版本、或是无文字内容的文件等就选择不需要翻译，反之则需要翻译。',
        UL_upfile1_ques1_a:'需要',
        UL_upfile1_ques1_b:'不需要',
        UL_upfile1_ques2:'请输入推荐人名称',
        UL_upfile1_ques3:'请输入推荐人职位介绍',

        UL_upfile2:'点击添加原始文件',
        UL_upfile2_ques1:'2.选择翻译方式',
        UL_upfile2_ques1_a:'翻译服务（推荐）',
        UL_upfile2_ques1_b:'个人翻译',
        UL_upfile2_ques2:'请输入推荐人名称(英文)',
        UL_upfile2_ques3:'请输入推荐人职位介绍(英文)',

        UL_title2:'上传推荐信',
        UL_upfile3:'点击添加原始文件',
        UL_upfile3_ques1:'1.原始文件是否需要翻译？',
        UL_upfile3_ques1_tip:'“是否需要翻译”指上传的文件内容是否需要翻译成英文，例如文件已有英文版本、或是无文字内容的文件等就选择不需要翻译，反之则需要翻译。',
        UL_upfile3_ques1_a:'需要',
        UL_upfile3_ques1_b:'不需要',

        UL_upfile4:'点击添加原始文件',
        UL_upfile4_ques1:'2.选择翻译方式',
        UL_upfile4_ques1_a:'翻译服务（推荐）',
        UL_upfile4_ques1_b:'个人翻译',

        UL_tip1:'您已开通翻译服务，可使用专业的翻译服务',
        UL_tip2_a:'您暂未开通翻译功能，开通可使用专业的',
        UL_tip2_b:'翻译服务',

        UL_btn1:'取消',
        UL_btn2:'确认',
        UL_btn2_a:'编辑',
        UL_btn2_b:'新增',

        UL_input_tip1:'请选择是否翻译',
        UL_input_tip2:'请选择是否翻译类型',
        UL_input_tip3:'请上传原文件',
        UL_input_tip4:'请上传翻译文件',
        UL_input_tip5:'请输入推荐人名称',
        UL_input_tip6:'请输入推荐人职位介绍',
        UL_input_tip7:'请输入推荐人名称（英文）',
        UL_input_tip8:'请输入推荐人职位介绍（英文）',
        UL_input_tip9:'请选择是否翻译',
        UL_input_tip10:'请选择是否翻译类型',
        UL_input_tip11:'请上传原文件',
        UL_input_tip12:'请上传翻译文件',
    },
    // 工作台 赴美计划
    USA_Plan: {
       UP_title1:'已保存附件', 
       UP_box1_btn1:'上传附件', 
       UP_box1_tip1:'提示内容：附件为赴美计划中所提到的商业计划书、行业报告/数据、美国工作/合作邀请等',
       UP_table1_list1:'附件名称', 
       UP_table1_list2:'附件名称（翻译文件）', 
       UP_table1_list3:'顺序', 
       UP_table1_list4:'操作', 
       UP_table1_list4_btn1:'调序', 
       UP_table1_list4_btn2:'查看', 
       UP_table1_list4_btn3:'删除', 
       UP_table1_list4_btn3_tip:'你确定要删除吗?', 

       UP_longText_title:'赴美计划',
       UP_longText_tip_a:'·在撰写赴美计划时，建议您先阅读页面顶部及在线编辑框内的内容建议及事项，对您的撰写将提供帮助。',
       UP_longText_tip_b_1:'·若未购买',
       UP_longText_tip_b_2:'翻译服务',
       UP_longText_tip_b_3:'，请您用英文撰写。',
       UP_longText_tip_c:'·在线编辑框内有我们为您提供的内容模板，您可以填充模板参考内容。',
       
       UP_longText_EB1A_content1:'内容建议：',
       UP_longText_EB1A_content2:'专业且详细的市场分析，体现个人对于行业的整体发展十分了解，最好带有明确的市场效益数据对比-国内，国际，对标美国。',
       UP_longText_EB1A_content3:'详细的职业发展安排',
       UP_longText_EB1A_content4:'赴美意向强烈：当前采取了何种定居美国的行动，如考取了语言证书，在美认可的职业证书，获得了美企offer，美科研工作室聘书，在美开办了分公司，加入了美国某个行业工会，签订了商业合作关系。。。',
       UP_longText_EB1A_content5:'利好美国目的明确：可具体到定居何处，从事何种工作，参加某行业协会，对该工作当前的市场竞争认知（当前国际，尤其美国本土该行业出现的何种短板，行业或科研困境，针对该短板或者困境，自己能提供何种切实的解决方案）',
       UP_longText_EB1A_content6:'表达赴美意愿，强调自己对于利好美国的不可或缺性，恳请移民移民官同意自己的移民申请。',
       UP_longText_EB1A_content7:'（如：当前在美从事前沿科研工作，但因签证问题无法继续居留美国，自己的离开将会对这一工作造成延迟或失败，因此同意我的永居身份申请是必然的；自己当前研究的事物或者开展的商业活动将会给美国带来多大的价值，如申请未能通过，将会对美国经济民生带去高达多少亿的损失，因此同意我的永居身份申请才是合理的）',

       UP_longText_NIW_content1:'内容建议：',
       UP_longText_NIW_content2:'强调您对美国国家利益的贡献意愿和承诺',
       UP_longText_NIW_content3:'研究/工作领域和背景',
       UP_longText_NIW_content4:'概述您的研究/工作领域和其对美国国家利益的重要性 ',
       UP_longText_NIW_content5:'强调您的专业知识、经验和成就',
       UP_longText_NIW_content6:'强调您目前的资历，证明有能力推进计划的实现，并要求豁免劳工证和工作聘书',
       UP_longText_NIW_content7:'赴美工作目标',
       UP_longText_NIW_content8:'说明您计划在美国从事的具体研究/工作领域',
       UP_longText_NIW_content9:'阐述您的长期目标和职业发展计划',
       UP_longText_NIW_content10:'强调与美国相关机构、研究团队或产业界的合作意向',
       UP_longText_NIW_content11:'具体研究计划或项目',
       UP_longText_NIW_content12:'提供详细的研究计划或项目描述',
       UP_longText_NIW_content13:'阐述研究问题/目标和预期成果 ',
       UP_longText_NIW_content14:'解释该计划/项目对领域和美国的重要性 ',
       UP_longText_NIW_content15:'说明采用的研究方法和实施计划',
       UP_longText_NIW_content16:'资源和合作伙伴',
       UP_longText_NIW_content17:'描述您计划利用的资源和设备',
       UP_longText_NIW_content18:'强调与相关研究人员、机构或合作伙伴的合作关系',
       UP_longText_NIW_content19:'说明这些资源和合作伙伴对计划/项目的支持和促进作用 ',
       UP_longText_NIW_content20:'时间框架和阶段性目标',
       UP_longText_NIW_content21:'提供研究计划/项目的时间框架',
       UP_longText_NIW_content22:'列出每个阶段的具体目标和计划',
       UP_longText_NIW_content23:'阐述每个阶段的预期成果和里程碑',
       UP_longText_NIW_content24:'社会影响和贡献',
       UP_longText_NIW_content25:'说明您的研究/工作如何对社会产生影响和贡献',
       UP_longText_NIW_content26:'强调您的贡献对解决问题、改善现有技术或推动经济增长的重要性',
       UP_longText_NIW_content27:'提供具体实例和数据支持您的论述',
       UP_longText_NIW_content28:'结束语',
       UP_longText_NIW_content29:'总结赴美计划和您的承诺',
       UP_longText_NIW_content30:'表达感谢和期望获得NIW批准的愿望 这个提炼后的大纲可以帮助您组织和撰写赴美计划的详细内容。请在每个部分中提供清晰、具体的信息，并使用实例和数据来支持您的陈述。确保您的赴美计划与其他申请材料一致，并突出您的专业知识、研究能力和贡献。',


       UP_box3_title_a:'导入文档',
       UP_box3_title_b:'(若已写文档)',
       UP_box3_tip1:'点击或拖拽添加原始文件',
       UP_box3_btn1:'保存',
       UP_box3_btn2:'预览',

       UP_box4_title_a:'模板下载',
       UP_box4_title_b:'（仅供参考）',
       UP_box4_tip1:'参考模板',
       UP_box4_btn1:'一键下载',

        UP_to_title:'赴美计划——预览',

       UP_tip1:'内容较多，正在保存，请等待！！',
       UP_tip2:'生成成功',
       UP_tip3:'预览失败',
       UP_tip4:'正在生成预览文件，请等待',


       UP_dia_title1:'添加附件',
       UP_dia_title2:'上传附件顺序调整',
       UP_dia_title3:'查看',

       UP_dia1_tip1:'点击添加原始文件',
       UP_dia1_ques1:'1.原始文件是否需要翻译？',
       UP_dia1_ques1_tip:'“是否需要翻译”指上传的文件内容是否需要翻译成英文，例如文件已有英文版本、或是无文字内容的文件等就选择不需要翻译，反之则需要翻译。',
       UP_dia1_ques1_a:'需要',
       UP_dia1_ques1_b:'不需要',
       UP_dia1_ques2:'请输入材料名称',

       UP_dia2_tip1:'点击添加原始文件',
       UP_dia2_ques1:'2.选择翻译方式',
       UP_dia2_ques1_a:'翻译服务（推荐）',
       UP_dia2_ques1_b:'个人翻译',
       UP_dia2_ques2:'请输入材料名称',

       UP_dia_tran_tip1:'您已开通翻译服务，可使用专业的翻译服务',
       UP_dia_tran_tip2_a:'您暂未开通翻译功能，开通可使用专业的',
       UP_dia_tran_tip2_b:'翻译服务',
       UP_dia_btn1:'取消',
       UP_dia_btn2_a:'确认',
       UP_dia_btn2_b:'编辑',
       UP_dia_btn2_c:'新增',

       UP_dia_input_tip1:'请选择是否翻译',
       UP_dia_input_tip2:'请选择是否翻译类型',
       UP_dia_input_tip3:'请上传原文件',
       UP_dia_input_tip4:'请上传翻译文件',
       UP_dia_input_tip5:'请输入材料名称',
       UP_dia_input_tip6:'请输入材料名称（英文）',

       UP_dia3_View_tip:'请选择查看文件类型',
       UP_dia3_View_btn1:'原始文件',
       UP_dia3_View_btn2:'翻译文件',
    },
    // wangEider
    wangEider:{
        wangEditer_inputBtn1:'证据索引',
        wangEditer_inputBtn2:'推荐信索引',

        wangEditer_tip_a:'请在索引前确保您的',
        wangEditer_tip_b:'不会发生变动(删除、新增、调序、修改材料名称)若索引后，',
        wangEditer_tip_c:'发生变动，应将文档原索引处内容进行删除并重新索引',

        wangEditer_key1:'证据',
        wangEditer_key2:'推荐信',

        wangEditer_title1:'已保存',
        wangEditer_list1_a:'证据名称',
        wangEditer_list1_b:'推荐信名称',
        wangEditer_list2:'操作',
        wangEditer_list_btn:'选择',
    },
    // 工作台 申请信
    ApplyLetter:{
        AL_title:'申请信',
        AL_box1_tip1:'a.在撰写申请信时，建议您先阅读页面顶部的内容建议，对您的撰写将提供帮助。',
        AL_box1_tip2_a:'b.若未购买',
        AL_box1_tip2_b:'翻译服务',
        AL_box1_tip2_c:',请您用英文撰写。',
        AL_box1_tip3:'c.在线编辑框内有我们为您提供的内容模板，您可以填充模板参考内容。',

        AL_box2_title:'导入文档',
        AL_box2_title_p:'(若已写文档)',
        AL_box2_tip1:'点击或拖拽添加原始文件',
        AL_box2_btn1:'保存',
        AL_box2_btn2:'预览',

        AL_box3_title_a:'模板下载',
        AL_box3_title_b:'（仅供参考）',
        AL_box3_tip1:'参考模板',
        AL_box3_btn1:'一键下载',

        // 可不翻译（待定）
        AL_dia1_title:'请选择本次使用的服务',
        AL_dia1_tip1:'若您同时购买了两个服务，将先为您进行审核咨询服务，完成再进行翻译服务。',
        AL_dia1_btn1:'确认',
        AL_dia1_btn2:'取消',

        AL_tip1:'申请信内容为空，不能保存！！！',
        AL_tip2:'内容较多，正在保存，请等待！！',
        AL_tip3:'正在生成预览文件，请等待',
        AL_tip4:'生成成功',

        AL_to_title:'申请信——预览',

        // 默认文本
        AL_EB1A_article1:'EB1A申请文书思路框架',
        AL_EB1A_article2:'撰写文书前：',
        AL_EB1A_article3:'搜集相关行业官方报告，分析自己现有的材料，确定申请的细分领域',
        AL_EB1A_article4:'查阅细分领域的当前发展现状，包括前沿研究，同行的表现，政府部门，行业协会对于该细分领域的关注度',
        AL_EB1A_article5:'确定领域和职业后，根据主张的标准去搜集相关佐证材料',
        AL_EB1A_article6:'联系该细分领域/相关领域的专业人士，寻求推荐信',
        AL_EB1A_article7:'了解文书撰写规范，文件材料规范',
        AL_EB1A_article9:'首页：',
        AL_EB1A_article10:'申请文书正文',
        AL_EB1A_article11:'1.介绍个人在专业领域取得的成就和资质',
        AL_EB1A_article12:'2.按照10项标准（至少满足3项）主张个人专业领域成就',
        AL_EB1A_article13:'因在专业领域成绩突出，获得国际国内重大奖项（重大奖项）',
        AL_EB1A_article14:'因杰出成就被授予专业领域内重要协会成员身份（协会会员）',
        AL_EB1A_article15:'专业媒体、行业媒体或其他主流媒体对申请人及其工作有过报道（媒体报道）',
        AL_EB1A_article16:'单独或作为专家组成员评审同行业其他人的工作（参与评审）',
        AL_EB1A_article17:'申请受益人在主张的专业领域做出重大意义的原创贡献（原创贡献）',
        AL_EB1A_article18:'申请受益人在专业、主要行业或其他主流刊物上发表学术文章或拥有著作权（文章/著作）',
        AL_EB1A_article19:'申请受益人的作品参加过艺术性展览、展示（作品展出）',
        AL_EB1A_article20:'申请受益人在某些信誉卓著的组织或机构中发挥领导性或关键性作用（领导性/关键性）',
        AL_EB1A_article21:'与同行业其他人相比，申请受益人因为专业领域的工作，获得明显更高的工资或高酬劳（高薪酬）',
        AL_EB1A_article22:'申请受益人在艺术表演上取得商业成功（艺术商业成功）',
        AL_EB1A_article23:'3.申请人非凡能力的证明（按照收集主张的证据分点论述，串联出一条完整的证据链）',
        AL_EB1A_article24:'4.说明申请人杰出能力最终体现其本人是专业领域内那一小部分已经上升到该领域最顶端的人之一，并且在国内和国际上都持续性享有盛誉。',
        AL_EB1A_article25:'5.说明申请人寻求进入美国继续在具有非凡能力的领域工作，过往与现在的工作介绍，赴美后的工作计划、目标及其重要性等。',
        AL_EB1A_article26:'6.强调申请人的专业领域研究对美国的重要性，以及其本人对推动领域发展能持续性作出巨大贡献。',
        AL_EB1A_article27:'7.总结：根据以上各项证据及专家证词/推荐信等材料可以得出，申请人的非凡的能力得到了持续的国家和国际赞誉，其成就通过其他人的实施和对其研究工作的广泛引用而在该领域得到认可。申请人完全满足EB1A申请资格，请求移民官员仔细考虑后批准申请。',


        AL_NIW_article1:'NIW申请文书思路框架',
        AL_NIW_article2:'撰写文书前：',
        AL_NIW_article3:'1.搜集相关行业官方报告，分析自己现有的材料，确定申请的细分领域',
        AL_NIW_article4:'2.查阅细分领域的当前发展现状，包括前沿研究，同行的表现，政府部门，行业协会对于该细分领域的关注度',
        AL_NIW_article5:'3.确定领域和职业后，根据主张的标准去搜集相关佐证材料',
        AL_NIW_article6:'4.梳理撰写思路，确定主张重点',
        AL_NIW_article7:'5.联系该细分领域/相关领域的专业人士，寻求推荐信',
        AL_NIW_article8:'6.了解文书撰写规范，文件材料规范',
        AL_NIW_article9:'首页：',
        AL_NIW_article10:'申请文书正文',
        AL_NIW_article11:'1.介绍个人专业领域，给移民官简略的行业理解',
        AL_NIW_article12:'2.（按照6项标准主张个人专业领域成就）',
        AL_NIW_article13:'高学历',
        AL_NIW_article14:'工作经验',
        AL_NIW_article15:'职业资格证明',
        AL_NIW_article16:'会员身份',
        AL_NIW_article17:'获得高薪筹',
        AL_NIW_article18:'个人对行业做出的贡献',
        AL_NIW_article19:'其他成就',
        AL_NIW_article20:'3.与同行做比较，总结以上成就的重大性，主张个人的杰出性，在说明以上7项时，可引用相关行业数据，对比同行现状，重点突出申请人自己的不同与贡献。',
        AL_NIW_article21:'4.搜集美国相关官方文件/报道，主张您所在领域/行业对于美国民生，经济，环境等的重要性，对于个人细分领域的重要性，可重点挖掘细节，以直观数据进行显示。',
        AL_NIW_article22:'5.搜集美国相关官方文件/报道，主张您此类杰出/专精人才的稀缺性和急需性，以及论述达到如您此类专业性的人，能为美国的相关行业做出的贡献',
        AL_NIW_article23:'6.论述当前您主张领域的瓶颈/现状，主张您目前拥有的资质能够推动并助力该领域的发展',
        AL_NIW_article24:'7.总结：对个人的成就和行业的现状以及美国针对该行业的政策进行总结，提出自己有足够能力的推动提议的方向，要求移民官豁免职业移民第二类别要求的劳工证和工作聘书。',

    },
    // 工作台 提交必看
    ReadMe:{
        RM_title:'提交必看',
        RM_step1_title:'一、 检查申请材料完整度',
        RM_step1_tip1:'我已确认撰写完成',
        RM_step1_tip2:'请仔细检查，勾选确认后才能进行下一步',
        RM_step1_btn1:'我确认',

        RM_step1_list1_title:'申请表格',
        RM_step1_list1_type:'检查',
        RM_step1_list1_search:'申请表格',
        RM_step1_list1_tip1_a:'请确保您所需要填写的表格已经全部填写完成，若您并未购买',
        RM_step1_list1_tip1_b:'翻译服务',
        RM_step1_list1_tip1_c:'，请保证您使用英文完成表格填写。',
        RM_step1_list1_tip2:'I-140表（必填）',
        RM_step1_list1_tip3:'I-907表格（若申请加急）',
        RM_step1_list1_tip4:'G-1145表格（若申请电子通知）',
        RM_step1_list1_tip5:'G-1450（若申请信用卡支付）',
        RM_step1_list1_tip6:'ETA-9089表（必须下载打印填写）',

        RM_step1_list2_title:'证据文件',
        RM_step1_list2_type:'检查',
        RM_step1_list2_search:'证据文件',
        RM_step1_list2_tip1_a:'请确保您的证据材料文件已按要求全部上传完成。若您并未购买',
        RM_step1_list2_tip1_b:'翻译服务',
        RM_step1_list2_tip1_c:'，请保证所有上传的原始文件均为英文文件或附加原始文件的英文翻译件（需翻译认证）。',
        RM_step1_list2_tip2:'10项主张至少提供3项',

        RM_step1_list3_title:'推荐信',
        RM_step1_list3_type:'检查',
        RM_step1_list3_search:'推荐信',
        RM_step1_list3_tip1_a:'请确保您已将推荐信全部上传完成。若您并未购买',
        RM_step1_list3_tip1_b:'翻译服务',
        RM_step1_list3_tip1_c:'，请保证您上传的推荐信原始文件为英文。（若推荐信为中文请附上带有翻译认证的英文翻译件）',
        RM_step1_list3_tip2:'1. 信的末尾必须留下推荐人的亲笔签名（可以电子签名/盖章）、联系方式（电话+邮箱）。',
        RM_step1_list3_tip3:'2. 推荐信的数量通常为5封左右，不得低于3封。',

        RM_step1_list4_title:'赴美计划',
        RM_step1_list4_type:'检查',
        RM_step1_list4_search:'赴美计划',
        RM_step1_list4_tip1_a:'请确保您的赴美计划已撰写完成，并预览检查文件已准确无误。若您并未购买',
        RM_step1_list4_tip1_b:'翻译服务',
        RM_step1_list4_tip1_c:'，请保证用英文完成撰写',
        RM_step1_list4_tip2:'1.撰写需语言客观、按点陈述、简洁有逻辑。',
        RM_step1_list4_tip3:'2.证据/推荐信索引无误（索引的证据/推荐信与“证据收集”/“推荐信”中的名称、顺序、内容对应）',

        RM_step1_list5_title:'申请信',
        RM_step1_list5_type:'检查',
        RM_step1_list5_search:'申请信',
        RM_step1_list5_tip1_a:'请确保您的申请信已撰写完成，并预览检查文件已准确无误。若您并未购买',
        RM_step1_list5_tip1_b:'翻译服务',
        RM_step1_list5_tip1_c:'，请保证用英文完成撰写',
        RM_step1_list5_tip2:'证据/推荐信索引无误（索引的证据/推荐信与“证据收集”/“推荐信”中的名称、顺序、内容对应）',


        RM_step2_title:'二、 申请材料相关服务提示',
        RM_step2_tip1:'1. 材料审核（辅助服务）',
        RM_step2_tip2_a:'若您已购买审核咨询服务，请点击网页右上角“一键审核”进行材料审核提交。您可在',
        RM_step2_tip2_b:'个人中心',
        RM_step2_tip2_c:'我的计划',
        RM_step2_tip2_d:'我的权益',
        RM_step2_tip2_e:'中查看审核状态或撤销审核申请。',
        RM_step2_tip3:'2.翻译服务（辅助服务）',
        RM_step2_tip4_a:'若您已购买翻译服务，请点击网页右上角“翻译服务”进行材料翻译申请。您可在',
        RM_step2_tip4_b:'个人中心',
        RM_step2_tip4_c:'我的计划',
        RM_step2_tip4_d:'我的权益',
        RM_step2_tip4_e:'中查看翻译状态或撤销翻译申请。',
        RM_step2_tip5:'3. 下载与打印',
        RM_step2_tip6:'请在个人中心>我的计划里进行材料预览与下载打印文件。',
        
        RM_step3_title:'三、后续流程',

        RM_step3_list1_title:'下载打印材料并完成手写签字部分',
        RM_step3_list1_type:'',
        RM_step3_list1_search:'下载打印材料并手写签字',

        RM_step3_list2_title:'申请材料',
        RM_step3_list2_type:'打包整理',
        RM_step3_list2_search:'打包整理申请材料',

        RM_step3_list3_title:'递交移民局',
        RM_step3_list3_type:'申请材料',
        RM_step3_list3_search:'申请材料递交移民局',

        RM_step3_list4_title:'审理进展',
        RM_step3_list4_type:'查询案件',
        RM_step3_list4_search:'查询案件审理进展',

        RM_step3_list5_title:'（若有）',
        RM_step3_list5_type:'RFE补件',
        RM_step3_list5_search:'RFE补件（若有）',

        RM_step3_list6_title:'面试（技巧）',
        RM_step3_list6_type:'',
        RM_step3_list6_search:'移民签证程序/调整身份（I485）',

        RM_step3_list7_title:'获得绿卡',
        RM_step3_list7_type:'',
        RM_step3_list7_search:'移民签证程序/调整身份（I485）',

        RM_tip1:'已确认',
        RM_tip2:'其他部分未填完，不能确认',
        RM_tip3:'需登录后才能前往个人中心',


    },
    // 商品页面
    PayService:{
        PS_title:'移民服务套餐介绍',
        PS_box1_tip1:'DIY服务标准版为基础服务',
        PS_box1_tip2:'DIY审核咨询与翻译服务为辅助服务不能单独购买',
        PS_box1_tip3:'购买带有基础服务的套餐后，可根据需求再额外加购审核咨询或翻译服务',
        PS_box1_tip4_a:'所有DIY服务都与移民项目（EB1A/NIW）绑定，如购买错误需更换服务请',
        PS_box1_tip4_b:'联系我们',

        PS_box2_title:'购买移民计划服务',
        PS_box2_tip1:'付费套餐自由选择，无隐藏费用',
        PS_box2_tip2_a:'购买前请确认您是否已完成',
        PS_box2_tip2_b:'移民评估',
        PS_box2_tip2_c:'，以免无法使用购买服务',
        PS_box2_tip3:'国家选择',
        PS_box2_tip4:'移民项目选择',

        PS_box3_title:'移民计划服务套餐对比',
        PS_box3_tip1:'审核咨询与翻译服务为可选辅助服务；',
        PS_box3_tip2:'以下为服务套餐所包含的相关服务权益对比。',
        PS_box3_btn1:'立即购买',
        PS_box3_btn2:'已购买',
        PS_box3_btn3:'无法购买',

        PS_dia3_title:'完成支付',

        PS_dia4_title:'未登录',
        PS_dia4_tip:'请先登录后，进行购买服务',
        PS_dia4_btn1:'去登录',
        PS_dia4_btn2:'知道了',


        PS_type_NIW:'美国NIW',
        PS_type_EB1A:'美国EB1A',

        PS_tip1:'，请去个人中心购买记录中查看',
        PS_tip2:'您已确认完成支付，请等待工作人员确认',
        PS_tip3:'确认失败，请重试',
        PS_tip4:'尚未登录，请先登录才能进入移民评估',
        PS_tip5:'支付成功，请稍后',
        PS_tip6:'更新失败',

        // payCard 组件
        PC_type:'辅助',
        PC_title1:'服务',
        PC_right_top:'推荐购买',
        PC_tip1:'费用',
        PC_btn1:'已购买',
        PC_btn2:'去购买',


        // 底部购买
        CMT_title1:'基础服务',
        CMT_title2:'辅助服务',

        // 购买须知
        BK_title:'购买DIY服务须知',

        BK_tip1:'重要：这是一份购买须知！购买DIY服务您已知道移民项目需满足移民条件；',
        BK_tip2:'服务仅限单人使用，如有违反，将承担法律责任。',
        BK_NIW_title:'美国NIW移民计划（DIY）需满足以下两个条件其中之一',
        BK_NIW_tip1:'1.高等学位',
        BK_NIW_tip2:'1）美国大学（或国外其他大学）硕士及以上学位，并且需要在同领域工作',
        BK_NIW_tip3:'2）学士学位+5年专业领域的渐进工作经验',
        BK_NIW_tip4:'2.特殊技能（以下六项至少满足三项）',
        BK_NIW_tip5:'1）在其专业领域内曾经获得过学院、大学或其他教育培训机构颁发的官方学术证明',
        BK_NIW_tip6:'2）在其专业领域有10年以上的全职工作经验，一般需由前任及现任雇主提供证明',
        BK_NIW_tip7:'3）有在相关领域的专业从业执照',
        BK_NIW_tip8:'4）申请人由于自己的特殊技能获得薪水或其他报酬资助（包括项目研究经费）',
        BK_NIW_tip9:'5）申请人是专业协会的会员，会员资格的取得需基于个人能力',
        BK_NIW_tip10:'6）在所在的领域内获得认可，并作出重要的贡献',

        BK_EB1A_title:'美国EB-1A移民计划（DIY）需满足十项里至少三项条件：',
        BK_EB1A_tip1:'1.因在专业领域成绩突出，获得国际国内重大奖项',
        BK_EB1A_tip2:'2.因杰出成就被授予专业领域内重要协会成员身份',
        BK_EB1A_tip3:'3.专业媒体、行业媒体或其他主流媒体对申请人及其工作有过报道',
        BK_EB1A_tip4:'4.单独或作为专家组成员评审同行业其他人的工作',
        BK_EB1A_tip5:'5.申请受益人在主张的专业领域做出重大意义的原创贡献',
        BK_EB1A_tip6:'6.申请受益人在专业、主要行业或其他主流刊物上发表学术文章或拥有著作权',
        BK_EB1A_tip7:'7.申请受益人的作品参加过艺术性展览、展示',
        BK_EB1A_tip8:'8.申请受益人在某些信誉卓著的组织或机构中发挥领导性或关键性作用',
        BK_EB1A_tip9:'9.与同行业其他人相比，申请受益人因为专业领域的工作，获得明显更高的工资或高酬劳',
        BK_EB1A_tip10:'10.申请受益人在艺术表演上取得商业成功',

        BK_tip3_a:'我已阅读并同意',
        BK_tip3_to:'购买协议',
        BK_tip3_b:'《CasePass移民DIY服务购买协议》',
        BK_tip4:'取消，不购买了',
        BK_tip5:'我知道，继续购买DIY服务',


    },
    // 登录
    Login:{
        L_title:'手机验证码登录',
        L_input_tip1:'请输入手机号',
        L_input_tip2:'请输入验证码',
        L_tip1:'获得验证码',
        L_tip2:'重新发送',
        L_tip3:'我已阅读并同意',
        L_tip4:'《服务条款》',
        L_tip5:'和',
        L_tip6:'《隐私协议》',
        L_dia_title:'图形验证码',

        L_btn1:'登 录',
        L_btn2:'取消',
        L_btn3:'确认',
    },
    // 弹窗翻译
    Dia:{
        D_title1:'预约咨询',

        D_tip1:'您已完成提交，我们将第一时间联系您。',

        D_dia1_title:'确认提交',
        D_dia1_tip:'每人仅一次免费评估机会',
        D_dia1_btn1:'我再看看',
        D_dia1_btn2:'确认',

        D_dia2_title:'美国移民项目评估',
        D_dia2_tip1:'已提交评估内容，请耐心等待分析结果',
        D_dia2_tip2:'移民顾问会在预约时间联系您，请及时接听电话/邮件/信息',
        D_dia2_tip3:'如需尽快得到评估结果，可主动联系客服对接移民顾问',
        D_dia2_btn:'知道了',

        // 工作台右侧审核1
        D_dia3_tip1:'您未购审核咨询，无法使用该功能',
        D_dia3_tip2:'如需服务请点击详情',
        // 工作台右侧审核2
        D_dia3_tip3:'提交申请材料申请审核服务',

        D_dia3_btn1:'详情',
        D_dia3_btn2:'知道了',
        D_dia3_btn3:'开通服务',
        D_dia3_btn4:'取消',
        D_dia3_btn5:'确认提交',

        // 审核提交成功
        D_dia4_title:'提交成功',
        D_dia4_tip1:'1.未进行审核服务时，可随时取消进行修改',
        D_dia4_tip2:'（审核服务进行时，无法进行内容修改）',
        D_dia4_tip3:' 2.可在 个人中心 > 我的计划 > 我的权益',
        D_dia4_tip4:'进行查看进度与结果',

        D_dia4_btn1:'知道了',
        D_dia4_btn2:'前往个人中心',

        
        // 工作台右侧咨询1
        D_dia5_tip1:'您未购买审核咨询，无法使用该功能，如需服务请点击',
        // 工作台右侧咨询2
        D_dia5_tip2:'申请咨询服务，专属客服即刻为您服务',

        D_dia5_btn1:'详情',
        D_dia5_btn2:'知道了',
        D_dia5_btn3:'开通服务',
        D_dia5_btn4:'取消',
        D_dia5_btn5:'我要咨询',

        // 咨询提交成功
        D_dia6_title:'提交成功',
        D_dia6_tip1:'1.稍后请注意接听专属客服来电',
        D_dia6_tip2:'（第一时间未接听来电，会每隔五分钟来电本次咨询问题服务一共来电三次，如都为未接通则视为放弃本次咨询服务）',
        D_dia6_tip3:' 2.如点错，请点击下方取消本次咨询服务',
        D_dia6_tip4:'（为广大用户更好的体验，感谢理解）',

        D_dia6_btn1:'取消咨询服务',
        D_dia6_btn2:'知道了',

        // 工作台右侧翻译1
        D_dia7_tip1:'您未购买翻译服务，无法使用该功能如需服务请点击',
        // 工作台右侧翻译2
        D_dia7_tip2:'提交申请材料申请翻译服务',

        D_dia7_btn1:'详情',
        D_dia7_btn2:'知道了',
        D_dia7_btn3:'开通服务',
        D_dia7_btn4:'取消',
        D_dia7_btn5:'确认提交',

        // 翻译提交成功
        D_dia8_title:'提交成功',
        D_dia8_tip1:'1.未进行翻译服务时，可随时取消进行修改',
        D_dia8_tip2:'（翻译服务进行时，无法进行内容修改）',
        D_dia8_tip3:' 2.可在 个人中心 > 我的计划 > 我的权益',
        D_dia8_tip4:'进行查看进度与结果',

        D_dia8_btn1:'知道了',
        D_dia8_btn2:'前往个人中心',
        
    },
    // 9089表
    ETA_9089:{
        ETA_title:'ETA-9089申请表格',
        ETA_tip1:'1，下载并全英填写第J-L部分个人信息部分',
        ETA_tip2:'2，按照指示填写完成并在L页签字落款日期',
        ETA_tip3:'3，对于不确定的地方，填写N/A',
        ETA_tip4:'NIW申请需要填写ETA-9089表格，不提交会导致申请被拒绝。',
        ETA_tip5:'ETA-9089是美国劳工部(DOL)要求的一份表格，适用于申请EB-2和EB-3类别的劳工证申请。虽然在NIW申请中，劳工证被免除，但是申请人仍需要将ETA-9089表格与I-140表格一起递交至移民局，以证明其符合美国劳工市场的要求。',
        ETA_tip6_a:'您可点击下载ETA-9089表格及官方说明，打印后按照“',
        ETA_tip6_b:'ETA-9089表格须知',
        ETA_tip6_c:'”进行手动填写表格个人信息等内容。',
        ETA_tip7:'表格原件',
        ETA_tip8:'表格说明书',
        ETA_tip9:'填表指南（须知）',

        ETA_file1:'ETA-9089申请表格.PDF',
        ETA_file2:'ETA-9089官方表格说明.PDF',
        ETA_file3:'ETA-9089填表指南.PDF',
    },
    Web_title:{
        title1:'登录',
        title2:'CasePass-首页',
        title3:'美国EB-1A移民计划(DIY)',
        title4:'美国NIW移民计划(DIY)',
        title5:'审核咨询',
        title6:'翻译服务',
        title7:'关于我们',
        title8:'移民代理',
        title9:'答疑专区',
        title10:'免费评估',
        title11:'香港优才评估',
        title12:'商品界面',
        title13:'个人中心',
        title14:'个人信息',
        title15:'消息提醒',
        title16:'我的计划',
        title17:'购买记录',
        title18:'EB-1A',
        title19:'NIW',
        title20:'功能引导',
        title21:'PDF展示',
        title22:'对比预览',
        title23:'预览',


    }
}
