<template>
    <el-dialog
        :visible.sync="dialogVisible"
        :modal-append-to-body="false"
        :title="title"
        :width="width"
        :close-on-click-modal="false"
        class="customClass"
        :center="center"
    >
      <slot></slot>
      <div v-if="unFooter" slot="footer" class="dialog-footer">
        <el-button color="#efefef" @click="dialogVisible = false">{{exitBtnText}}</el-button>
        <el-button color="#4258a4" type="primary" class="sureBtn" @click="confirm">{{sureBtnText}}</el-button>
      </div>
    </el-dialog>
  </template>
  <script>
  export default {
    name: 'modulDialog',

    props: {
      value: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ''
      },
      width: {
        type: String,
        default: '50%'
      },
      customClass: {
        type: String,
        default: ''
      },
      center:{
        type: Boolean,
        default:false
      },
      sureBtnText:{
        type:String,
        default:'确 认'
      },
      exitBtnText:{
        type:String,
        default:'取 消'
      },
      unFooter:{
        type: Boolean,
        default:true
      }
    },
    computed: {
      dialogVisible: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('exit', val);
        }
      }
    },
    methods: {
      confirm() {
        // 触发确认事件
        this.$emit('confirm');
        // 隐藏 Dialog
        // this.dialogVisible = false;
      }
    },
    beforeDestroy() {
      // 重置 visible 变量
      this.dialogVisible = false;
    },
    created(){
    }
  };
  </script>
  <style lang="less" scoped>
  .dialog-footer {
    text-align: center;
    margin-top: 50px;
  }

  </style>

  <style lang="less">
  .customClass{
    border-radius: 10px;
  }

  </style>

  