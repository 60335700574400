import axios from 'axios'
import router from '../router'
import { ElMessage } from 'element-plus'
import jwt_decode from "jwt-decode";
import { refreshToken } from '../api/login.api'

import store from '@/store/index'



// 获取语言类型
import i18n from '@/locals/index'

// console.log(import.meta.env.VITE_API_URL);
// // 1. 配置服务器前缀
// // axios.defaults.baseURL = BASE_URL
// axios.defaults.baseURL = import.meta.env.VITE_API_URL
// axios.defaults.timeout = 6000


const service = axios.create({
    // baseURL: process.env.VUE_APP_API_BASEURL,
    baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
    // baseURL: 'http://192.168.11.116:8000',
    timeout: process.env.VUE_APP_timeout // request timeout
})
// 2. 有一个统一的地方设置token（每次发起请求的时候，都经过这个地方，就给请求头加上token）

// 请求拦截器（以后axios每发起一次请求，都要通过这里）
 service.interceptors.request.use(async config => { 
  config.headers.Language = i18n.global.locale
  // config就是请求头的相关信息， url， 参数
  // 从本地取出token，设置给config， config带给后端服务器


  const newTime = parseInt (new Date ().getTime ()/1000)
  let access_token = localStorage.getItem('access_token')
  let refresh_token = localStorage.getItem('refresh_token')
  //无感刷新   
  if(access_token){
    const access_decode = jwt_decode(access_token)
    const refresh_decode = jwt_decode(refresh_token)
    //access_token时间戳判断
    if(newTime - access_decode.exp < 0){
      console.log('access通过');
      config.headers.Authorization = 'Bearer '+ access_token
      return config
    }else if(newTime - refresh_decode.exp < 0){
      //refresh_decode时间戳判断
      console.log('refresh通过');
      localStorage.removeItem('access_token')
      //刷新access_token
      await refreshToken({token:refresh_token}).then(res=>{
        console.log('刷新token');
        localStorage.setItem('access_token',res.data.access_token)
        localStorage.setItem('refresh_token',res.data.refresh_token)
        config.headers.Authorization = 'Bearer '+ res.data.access_token

        return config
      })
    }else{
      // console.log('清除storage');
      localStorage.clear()
      // 清理vuex的数据
      store.commit('RESET_STATE')
      // location.reload()
      if(router.currentRoute.value.fullPath != '/login'){
        ElMessage({
          message: '登录已过期，请重新登录',
          type: 'warning',
        })
      }
      router.push('/login')
    }
  }

//   console.log(token);
  // 设置token
  // config.headers.authorization = access_token
  // 放行
  return config
})

service.interceptors.response.use(res => {
  if (res.Status >= 300 && res.Status  < 200) {
    ElMessage(res.data.msg)
  }
  return res
},err =>{
  // console.log(err)
  return Promise.reject(err)


  // if ([400,401,403].includes(err.response.status)) {
  //   return ElMessage.error(err.response.data.msg)
  // }

    // Vue.prototype.$message({
    //   type:'error',
    //   message:'登录已过期，请重新登录'
    // })
    // return Promise.reject('登录已过期，请重新登录')
})

// 导出
export default service