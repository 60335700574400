import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
//引入默认公共样式
import './assets/css/normalize.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import i18n from './locals'

import '@/utils/rem'


//引入element ui
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


//引入vuex
import store from "./store";

//导入vue -Buffer
import Buffer from "vue-buffer";

import intro from 'intro.js' // introjs库
import 'intro.js/introjs.css' // introjs默认css样式

window.Buffer = Buffer


const app = createApp(App);
app.use(i18n);





for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
  app.config.globalProperties.$intro = intro

app.use(ElementPlus, {
  locale: zhCn,
});
// 引用路由实例
app.use(router);
// 引用vuex
app.use(store)

//新窗口标题修改
app.directive('title',{
  mounted(el){
    document.title = el.dataset.title
  }
})


app.mount('#app')

router.beforeEach((to)=>{
  /* 路由发生变化修改页面title */
  if(to.meta.title){
    document.title = to.meta.title
  }
});