// 基准大小
const baseSize = 16
// 设置 rem 函数
export function setRem () {

  let windowWidth = 1920
  if(window.location.href.includes('EB-1A')) windowWidth = 1600
  if(window.location.href.includes('NIW')) windowWidth = 1600
  if(window.location.href.includes('login')) windowWidth = 1200
  if(window.location.href.includes('estimate')) windowWidth = 1200


  // 当前页面宽度相对于 1920 宽的缩放比例，可根据自己需要修改。
  const scale = document.documentElement.clientWidth / windowWidth
  // 设置页面根节点字体大小, 字体大小最小为12
  let fontSize = (baseSize * Math.min(scale, 2))>12 ? (baseSize * Math.min(scale, 2)): 12

  if(fontSize>=16) fontSize=16
  document.documentElement.style.fontSize = fontSize + 'px'

  
  

}
//初始化
setRem()
//改变窗口大小时重新设置 rem,这里最好加上节流
window.onresize = function () {
  setRem()
}


