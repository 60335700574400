<template>  
<el-config-provider :locale="el_locale">
  <el-Scrollbar ref="scroll">
    <div id="app">
        <router-view/>
    </div>
    <!-- <el-button type="" @click="cs">cs</el-button> -->
  </el-Scrollbar>

  <modulDialog
  v-if="componentDialog.openFalg"
  :title="componentDialog.title"
  :width="componentDialog.width"
  v-model="componentDialog.openFalg"
  :unFooter="componentDialog.unFooter" 
  @confirm="confirmSummit"
  @exit="componentDialog.openFalg = false">
      <immigrantFrom
      @submit="submit"
      @exit="exit"
      ></immigrantFrom>
  </modulDialog>

  <messageDialog 
  v-model="mesDialog.openFalg"
  :width="mesDialog.width"
  @exit="mesDialog.openFalg = false"
  :exitBtnText="$t('common.Iknow')">
  >
      <div style="text-align:center;margin: auto;width: 288px;">{{$t('Dia.D_tip1')}}</div>
  </messageDialog>
</el-config-provider>

</template>

<script>
  import { ElMessage } from 'element-plus'
  import modulDialog from '@/components/moudleDialog/modulDialog.vue'
  import messageDialog  from "@/components/messageDialog/messageDialog.vue";
  import immigrantFrom from "@/views/HomePage/immigrant/component/immigrantFrom.vue";
  import { addCustomer } from "@/api/customer.api";
  import EventBus from '@/utils/EventBus';
  import { getBuyServeAll,getStaticFile } from '@/api/customer.api';
  import zhCn from 'element-plus/es/locale/lang/zh-cn'
  import en from 'element-plus/es/locale/lang/en'
  export default {
    components:{
        modulDialog,immigrantFrom,messageDialog
    },
    created(){
      if(this.$store.state.customer){
        getBuyServeAll().then(res=>{
          this.$store.commit('setBuyingLeads',res.data)
        }).catch(rej=>{
          console.log(rej);
        })
      }
      
      if(this.$i18n.locale === 'zh'){
        this.el_locale = zhCn
        // localStorage.setItem('language','zh')
      }else{
        this.el_locale = en
        // localStorage.setItem('language','en')
      }
      getStaticFile().then(res=>{
        this.$store.commit('setStaticFiles',res.data)
      });

      
    },
    data(){
      return{
        //新用户弹框组件对象
        componentDialog:{
            // modulName:'', //组件名称
            title:this.$t('Dia.D_title1'),
            width:'550px',
            openFalg:false,
            unFooter:false
        },
        //消息提示弹框组件
        mesDialog:{
            width:'400px',
            openFalg:false
        },
        // 新用户类型
        newUserType:null,
        el_locale:en
      }
    },
    methods:{
      toImmigrant(){
          this.openModulDialog(true)
      },
      //弹窗确认回电
      confirmSummit(){
          this.componentDialog.openFalg = false
      },
      //打开新用户弹窗
      openModulDialog(openFalg){
          this.componentDialog.openFalg=openFalg
      },
      //弹窗取消
      exit(){
          this.openModulDialog(false)
      },
      //弹窗确认提交
      submit(val){
          val.entrance = this.newUserType
          addCustomer(val).then(res=>{
              if(res.status === 201){
                  ElMessage({
                      showClose: true,
                      message: this.$t('Dia.D_tip1'),
                      type: 'success'
                  })
                  this.openModulDialog(false)
                  this.openMesDialog()
              }
          }).catch(rej=>{
              ElMessage.error(rej.response.data.msg)
              // console.log(rej);
          })
      },
      //打开消息提示弹窗
      openMesDialog(){
          this.mesDialog.openFalg = true
      }
  },
    mounted(){
      // 活到顶部事件
      EventBus.on('scrollToTop',val => {
        this.$refs.scroll.setScrollTop(val)
      })
      // 触发
      EventBus.on('toContactUs',val => {
        this.newUserType = val
        this.toImmigrant()
      })
    }
  }
</script>

<style lang="less">
    html,body{
      width: 100% !important;
      height: 100%;
      overflow: hidden;
      margin: 0 !important;
    }
    #app{
      width: 100%;
      height: 100%;
      overflow-y: auto;
      max-width: none !important;
    }

    // body{
    //   margin: 0 !important; 
    // }

    // ::-webkit-scrollbar {
    //   display: none;
    // }
    
    // body{
      // overflow-x: hidden;
      // overflow-y: hidden;
    //   margin: 0 !important;
    // }
</style>
