<template>
    <el-dialog
        :visible.sync="dialogVisible"
        :modal-append-to-body="false"
        :title="title"
        :width="width"
        :close-on-click-modal="false"
        class="customClass"
        :center="center"
    >
        <div class="mesSvgBox">
            <img v-if="type==='success'" src="@/views/image/successMes.svg" alt="">
        </div>
      <slot></slot>
      <div v-if="unFooter" slot="footer" class="dialog-footer">
        <el-button color="#efefef" @click="dialogVisible = false">{{exitBtnText}}</el-button>
      </div>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            value: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: ''
            },
            width: {
                type: String,
                default: '50%'
            },
            customClass: {
                type: String,
                default: ''
            },
            center:{
                type: Boolean,
                default:false
            },
            //按钮信息
            exitBtnText:{
                type:String,
                default:'Got it'
            },
            unFooter:{
                type: Boolean,
                default:true
            },
            type:{
                type:String,
                default:'success'
            }
        },
        computed: {
            dialogVisible: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('exit', val);
                }
            }
        },
        methods: {
        confirm() {
            // 触发确认事件
            this.$emit('confirm');
            // 隐藏 Dialog
            // this.dialogVisible = false;
        },
        beforeDestroy() {
            // 重置 visible 变量
            this.dialogVisible = false;
        }
        },

    }
</script>

<style lang="less" scoped>

.dialog-footer {
  text-align: center;
  margin-top: 50px;
  padding-bottom: 20px;
}

.mesSvgBox{
    display: flex;
    justify-content: center;
    img{
        margin-bottom:20px;
        width: 48px;
        height: 48px;
    }
}

</style>

<style lang="less">
.customClass{
  border-radius: 10px;
}

</style>