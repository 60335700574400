<template>
    <div class="immigrantFromBox">
        <el-form 
        ref="immigrantFromRef"
        :model="ruleFrom"
        :rules="rules"
        label-width="80px"
        class="fromBox"
        status-icon>
          <el-form-item :label="$t('homePage.immigrant_from1_name')"  prop="name">
            <el-input v-model="ruleFrom.name" :placeholder="$t('homePage.immigrant_from1_name_tip')"></el-input>
          </el-form-item>
          <el-form-item  prop="phone" :label="$t('homePage.immigrant_from1_phone')" >
            <el-input
                  v-model="ruleFrom.phone"
                  size="large"
                  :placeholder="$t('homePage.immigrant_from1_phone_tip')"
                  :oninput="ruleFrom.phone=ruleFrom.phone.replace(/[^\d.]/g,'')"
                  class="input-with-phone">
                  <template #prepend>
                    <!-- <el-input v-model="select" style="width: 100px" class="login-click">
                      <template #prepend>+</template>
                    </el-input> -->
                    <el-select 
                    size="large" 
                    v-model="ruleFrom.area_code"
                    placeholder="+ 86 - CN" 
                    style="width: 120px" 
                    class="login-click" 
                    allow-create
                    filterable
                    clearable>
                    <el-option
                      v-for="(item, key) in areaCode"
                      :key="key"
                      :label="`+${item.area_code} - ${item.short_name}`"
                      :value="item.area_code"
                    />
                    </el-select>
                  </template>
                </el-input>
          </el-form-item>

            <el-form-item :label="$t('homePage.immigrant_from1_authCode')" prop="sms_code" v-if="!isLogin">
                <div class="verificationCode">
                    <el-input v-model="ruleFrom.sms_code" size="large" :placeholder="$t('homePage.immigrant_from1_authCode_tip')" class="login-click">
                        <template #append>
                            <el-button v-if="noteCodeControner" size="large" type="primary" @click="getAuth">{{$t('homePage.immigrant_from1_authCode_tip2')}}</el-button>
                            <el-button v-else type="primary" size="large" disabled>{{$t('homePage.immigrant_from1_authCode_tip3')}}({{proofTime}})</el-button>
                        </template>
                    </el-input>
                </div>
            </el-form-item>


          <el-form-item :label="$t('homePage.immigrant_from1_email')" prop="email">
            <el-input v-model="ruleFrom.email" :placeholder="$t('homePage.immigrant_from1_email_tip')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('homePage.immigrant_from1_WeiXin')">
            <el-input v-model="ruleFrom.wechat" :placeholder="$t('homePage.immigrant_from1_WeiXin_tip')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('homePage.immigrant_from1_time')" required>
            <el-col :span="8">
                <el-form-item prop="timezone">
                    <el-select  v-model="ruleFrom.timezone" :placeholder="$t('homePage.immigrant_from1_time_tip1')">
                    <el-option v-for="(item,index) in timezonesList" :key="index" :label="item.name" :value="item.code"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item prop="time_day">
                <el-date-picker
                    v-model="ruleFrom.time_day"
                    type="date"
                    :label="$t('homePage.immigrant_from1_time_tip4')"
                    :placeholder="$t('homePage.immigrant_from1_time_tip2')"
                    style="width: 100%"
                />
                </el-form-item>
            </el-col>
            <!-- <el-col class="text-center" :span="2" style="text-align: center;">-</el-col> -->
            <el-col :span="8">
                <el-form-item prop="time_hour">
                    <el-select  v-model="ruleFrom.time_hour" :placeholder="$t('homePage.immigrant_from1_time_tip3')" :disabled="!ruleFrom.time_day">
                    <el-option v-for="(item,index) in time_hourList" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            </el-form-item>
        </el-form>
        <div class="qr-code">
            <img src="@/views/image/OfficialAccounts.jpg"  />
        </div>
        <div class="btnBox">
            <el-button type="primary" color="#4258a4" @click="submitForm('immigrantFromRef')">
                        {{$t('homePage.immigrant_from1_Submit')}}
            </el-button>
            <el-button @click="exit">{{$t('homePage.immigrant_from1_Cancel')}}</el-button>
        </div>
    </div>

    <!-- 数字验证弹窗 -->
    <el-dialog
    v-model="authDialog"
    :title="$t('homePage.immigrant_from1_IMGCode')"
    width="30%"
    >
      <div class="auth_box">
        <el-input v-model="captcha" :placeholder="$t('homePage.immigrant_from1_IMGCode_tip1')" ></el-input>
        <img :src="auth_icon" @click="getAuth()"/>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="authDialog = false">{{$t('homePage.immigrant_from1_IMGCode_cancel')}}</el-button>
          <el-button type="primary" @click="getNote()">
            {{$t('homePage.immigrant_from1_IMGCode_sure')}}
          </el-button>
        </span>
      </template>
    </el-dialog>
</template>

<script>

import { ElMessage } from 'element-plus'
import { getAreaCode,getAuthCode,getNoteCode,getTimeZones } from '@/api/login.api'
import dayjs from 'dayjs'
    export default {
        emits: ['submit','exit'],
        data(){
            return{
                ruleFrom:{
                    area_code:86,
                    phone:''
                },
                //地区区号
                areaCode:[],
                //time_hourList
                time_hourList:[
                    {value:'am (9:30-11:30)',label:'am (9:30-11:30)'},
                    {value:'pm (2:30-4:30)',label:'pm (2:30-4:30)'}
                ],
                rules:{
                    name:[
                        { required: true, message: this.$t('homePage.immigrant_from1_name_tip'), trigger: 'blur' },
                    ],
                    phone:[
                        { required: true, message: this.$t('homePage.immigrant_from1_phone_tip'), trigger: 'blur' },
                    ],
                    time_day:[
                        { type: 'date',required: true, message: '', trigger: 'change' },
                    ],
                    time_hour:[
                        { required: true, message: '', trigger: 'blur' },
                    ],
                    timezone:[
                        { required: true, message: '', trigger: 'blur' },
                    ],
                    email:[
                        {validator: this.validateEmail, trigger: 'blur',required: false}
                    ],
                    sms_code:[
                        { required: true, message: this.$t('homePage.immigrant_from1_authCode_tip'), trigger: 'blur' },
                    ]
                },
                //验证码 弹窗控制
                authDialog:false,
                auth_icon:'',
                //图形验证码
                captcha:'',
                isLogin:false,
                // 时区
                timezonesList:[],

                // 验证时间 60
                proofTime:60,
                // 按钮控制
                noteCodeControner:true,
            }
        },
        methods:{
            getArea(){
                getAreaCode().then(res=>{
                    this.areaCode = res.data.results
                })
            },
            async submitForm(formName){
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    // let {name,phone,area_code,email,sms_code} = {...this.ruleFrom}
                    let appointment = {}
                    appointment.date = dayjs(this.ruleFrom.time_day).format('YYYY-MM-DD')
                    appointment.time_range = this.ruleFrom.time_hour
                    appointment.timezone = this.ruleFrom.timezone
                    this.$emit('submit',{...this.ruleFrom,appointment})
                    // this.$refs[formName].resetFields()
                    // this.$emit('exit')
                    // console.log(this.ruleFrom);
                } else {
                    console.log('error submit!!');
                    return false;
                    // console.log(this.ruleFrom);
                }
                });
            },
            exit(){
                this.$emit('exit')
            },
            validateEmail(rule, value, cb){
                const regEmail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
                if(!value) return cb();
                if (regEmail.test(value)) {
                    //合法的邮箱
                    return cb();
                }
                cb(new Error(this.$t('homePage.immigrant_from1_email_tip2')));
            },
            //图像验证
            getAuth(){
                getAuthCode({area_code:this.ruleFrom.area_code,phone:this.ruleFrom.phone}).then(res=>{
                this.auth_icon = 'data:image/png;base64,' + res.data.captcha;
                if (!this.authDialog) {
                    this.authDialog = true
                }
                }).catch(err=>{
                    ElMessage.error(err.response.data.msg)
                })
            },
            //图形验证确认
            getNote(){
                getNoteCode({area_code:this.ruleFrom.area_code,phone:this.ruleFrom.phone,captcha:this.captcha}).then(res=>{

                    if(res.status === 200){
                        this.ruleFrom.sms_code = res.data.sms_code;
                        this.noteCodeControner = false
                        this.delayedVerify()
                        this.isCaptcha = true
                    }

                }).catch(err=>{
                    ElMessage.error(err.response.data.msg)
                })
                this.authDialog = false
            },
            toTimeZone(){
                getTimeZones().then(res=>{
                    this.timezonesList = res.data
                })
            },

            // 60s验证
            delayedVerify(){
                const Inter = setInterval(()=>{
                    if (this.proofTime == 1) {
                        clearInterval(Inter);
                        this.noteCodeControner = true
                        this.proofTime = 60;
                    }else{
                        this.proofTime --
                    }
                },1000)
            },
        },
        created(){
            this.getArea()
            this.toTimeZone()
            if (this.$store.state.customer) {
                this.isLogin = true
                this.ruleFrom.phone = this.$store.state.customer.phone
            }
        }

    }
</script>

<style lang="less" scoped>
    .immigrantFromBox{
        width: 400px;
        margin: auto;
        .qr-code{
            display: flex;
            justify-content: center;

            img{
                width: 100px;
                height: 100px;
            }
        }
        .btnBox{
            display: flex;
            justify-content: center;
        }
    }
    .auth_box{
      display: flex;
    }
</style>