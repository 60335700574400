export default {
    eb1a: {
        //表单第一部分
        firstTitle: 'P1. Information About the Person or Organization Filing This Petition',
        firstPart: 'Part 1. Information About the Person or Organization Filing This Petition',
        firstDetail: 'If an individual is filing this petition, answer Item Numbers 1.a. - 1.c. If a company or organization is filing this petition, answer Item Number 2.',
        familyName: 'Family Name(Last Name)',
        firstName: 'Given Name(First Name)',
        midName: 'Middle Name',
        company: 'Company or Organization Name',
        emailAddress: ' Mailing Address',
        p1_3Hint:'State/Province must be filled in according to different country',
        findEmail: '(USPS ZIP Code Lookup)',
        recipient: 'In Care Of Name',
        address: 'Street Number and Name',
        apartment: 'Apt.',
        house: 'Ste.',
        floor: 'Flr.',
        cityProper: 'City or Town',
        state: 'State',
        postCode: 'Postal Code',
        province: 'Province',
        country: 'Country',
        otherInfo: 'Other Information',
        otherEIN: 'IRS Employer Identification Number (EIN)',
        otherSSN: 'U.S. Social Security Number (SSN) (if any)',
        otherUSCIS: 'USCIS Online Account Number (if any)',
        //表单第二部分
        secondTitle: 'P2. Petition Type',
        secondPart: 'Part 2. Petition Type',
        p2_2Hint:'If it is your second application, please choose 2.a and fill in the receipt number of your last application, otherwise leave it blank.',
        petitionFor: 'This petition is being filed for (select only one box):',
        extraordinary: 'An alien of extraordinary ability.',
        outstandings: 'An outstanding professor or researcher.',
        multinational: 'A multinational executive or manager',
        advancedDegree: 'A member of the professions holding an advanced degree or an alien of exceptional ability (who is NOT seeking a National Interest Waiver (NIW)).',
        professional: 'A professional (at a minimum, possessing a bachelor\'s degree or a foreign degree equivalent to a U.S. bachelor\'s degree).',
        skilledWorker: 'A skilled worker (requiring at least two years of specialized training or experience).',
        otherWorker: 'Any other worker (requiring less than two years of training or experience).',
        niwAlien: 'An alien applying for an NIW (who IS a member of the professions holding an advanced degree or an alien of exceptional ability).',
        purpose: 'This petition is being filed (select only one box):',
        previous: 'To amend a previously filed petition.Previous Petition Receipt Number',
        schedule: 'For the Schedule A, Group I or II designation.',
        //表单第三部分
        thirdTitle: 'P3. Information About the Person for Whom You Are Filing',
        thirdPart: 'Part 3. Information About the Person for Whom You Are Filing',
        birthDate: 'Date of Birth (mm/dd/yyyy)',
        birthCity: 'City/Town/Village of Birth',
        birthState: 'State or Province of Birth',
        birthCountry: 'Country of Birth',
        citizenCountry: 'Country of Citizenship or Nationality',
        p3_2Hint:'State/Province must be filled in according to different country',
        regisNumber: 'Alien Registration Number (A-Number) (if any)',
        usSSN: '. U.S. SSN (if any)',
        lastArrival: 'Information About His or Her Last Arrival in the United States',
        filingUs: 'If the person for whom you are filing is in the United States, provide the following information.',
        lastDate: 'Date of Last Arrival (mm/dd/yyyy)',
        recordNumber: 'Form I-94 Arrival-Departure Record Number',
        shownDate: 'Expiration Date of Authorized Stay Shown on Form I-94 (mm/dd/yyyy)',
        paroled: 'Status on Form I-94 (for example, class of admission, orparoled, if paroled)',
        passportNum: 'Passport Number',
        travelNum: 'Travel Document Number',
        issuCountry: 'Country of Issuance for Passport or Travel Document',
        expirationDate: 'Expiration Date for Passport or Travel Document (mm/dd/yyyy)',
        p3_10Hint:'If you are now living or working in the United States temporarily, please filling in 10-15.' ,
        //表单第四部分
        forthTitle: 'P4.Processing Information',
        forthPart: 'Part 4. Processing Information',
        selectThree: 'Provide the following information for the person named in Part 3. (select only one box):',
        visaAt: 'Alien will apply for a visa abroad at a U.S. Embassy or U.S.Consulate at:',
        contryFour: 'Country',
        cityTown: 'City or Town',
        P4_1Hint:'If you are currently living outside the United States, please filling in 1-2.',
        lawful: 'Alien is in the United States and will apply for adjustment of status to that of lawful permanent resident.',
        lastPermanent: 'Alien\'s current country of residence or, if now in theUnited States, last country of permanent residence abroad.',
        foreignAddress: 'If you provided a United States address in Part 3., provide the person\'s foreign address in Item Numbers 3.a. - 3.f.:',
        romanLetter: 'If the person\'s native alphabet is other than Roman letters, typeor print the person\'s foreign name and address in the nativealphabet in Item Numbers 4.a. - 4.c.:',
        mailAddress: 'Mailing Address',
        additionInfo: 'If you answer "Yes" to Item Numbers 6.a. - 10., provide the 6. Net Annual Income $case number, office location, date of decision, and disposition of the decision in the space provided in Part 11. Additional Information.',
        applicatForm: 'Are you filing any other petitions or applications with thisForm I-140?',
        answer: 'If you answered “Yes” to Item Number 6.a., select all applicable boxes:',
        formOne: 'Form I-485',
        formTwo: 'Form I-131',
        formThree: 'Form I-765',
        otherAddition: 'Other (Provide an explanation in Part 11. Additional Information.)',
        removalProceed: 'Is the person for whom you are filing in removal proceedings?',
        anyVisa: 'Has any immigrant visa petition ever been filed by or on behalf of this person?',
        supportAnother: 'Are you filing this petition without an original labor certification because the original labor certification was previously submitted in support of another Form I-140?',
        laborDOL: 'If you are filing this petition without an original labor certification, are you requesting that U.S. Citizenship and Immigration Services (USCIS) request a duplicate labor certification from the Department of Labor (DOL)?',
        yes: 'Yes',
        no: 'No',
        //表单第五部分
        fifthTitle: 'P5.Additional Information About the Petitioner',
        fifthPart: 'Part 5. Additional Information About the Petitioner',
        petitionType: 'Type of petitioner (select only one box):',
        employer: 'Employer',
        self: 'Self',
        other: 'Other (For example, Lawful Permanent Resident,U.S. citizen or any other person filing on behalf of the alien)',
        followInfo: 'If a company or an organization is filing this petition, provide the following information:',
        businessType: 'Type of Business',
        establishDate: 'Date Established (mm/dd/yyyy)',
        currentNum: 'Current Number of U.S. Employees',
        grossAnnual: 'Gross Annual Income',
        netAnnual: 'Net Annual Income',
        NAICS: 'NAICS Code',
        DOLCase: 'Labor Certification DOL Case Number',
        DOLFrom: 'Labor Certification DOL Filing Date (mm/dd/yyyy)',
        DOLto: 'Labor Certification Expiration Date (mm/dd/yyyy)',
        provideInfo: 'If an individual is filing this petition,provide the following information.',
        occupation: 'Occupation',
        annualIncome: 'Annual Income',
        //表单第六部分
        sixthTitle: 'P6.Basic Information About the Proposed Employment',
        sixthPart: 'Part 6. Basic Information About the Proposed Employment',
        jobTitle: 'Job Title',
        socCode: 'SOC Code',
        nontechnical: 'Nontechnical Job Description',
        fullTime: 'Is this a full-time position?',
        howHours: 'If the answer to Item Number 4. is "No," how many hours per week for the position?',
        permanent: 'Is this a permanent position?',
        newPosition: 'Is this a new position?',
        wages: 'Wages (Specify hour, week, month, or year):',
        per: 'per',
        workLocation: 'Worksite Location',
        newAddress: 'For Item Numbers 9.a. - 9.e., provide the address where theperson will work if different from the address provided in Part 1.',
        //表单第七部分
        seventhTitle: 'P7.Information About the Spouse and All Children of the Person for Whom You Are Filing',
        seventhPart: 'Part 7. Information About the Spouse and All Children of the Person for Whom You Are Filing',
        sevenDetail: 'For Part 7., provide information on the spouse and all children related to the individual for whom you are filing this petition.Also, note if the individual will apply for a visa abroad or adjustment of status as the dependent of the individual for whom the petition is filed. If you need extra space to provide information about additional family members, use the space provided in Part 11. Additional Information.',
        person: 'Person',
        birthCoun: 'Country of Birth',
        relationship: 'Relationship',
        adjustment: 'Is he or she applying for adjustment of status?',
        apply: 'Is he or she applying for a visa abroad?',
        //表单第八部分
        eighthTitle: 'P8.Statement, Contact Information,Declaration, Certification, and Signature of the Petitioner or Authorized Signatory and Signature ',
        eighthPart: 'Part 8. Statement, Contact Information,Declaration, Certification, and Signature of the Petitioner or Authorized Signatory and Signature ',
        eighthNote: 'NOTE: Read the Penalties section of the Form I-140 Instructions before completing this part. ',
        statement: 'Petitioner\'s or Authorized Signatory\'s Statement',
        noteTwo: 'NOTE: Select the box for either Item Number 1.a. or 1.b. If applicable,select the box for Item Number 2.',
        understand: 'I can read and understand English, and I have read and understand every question and instruction on this petition and my answer to every question.',
        partNine: 'The interpreter named in Part 9. has read to me every question and instruction on this petition and my answer to every question in',
        request: 'At my request, the preparer named in Part 10.,prepared this petition for me based only upon information I provided or authorized.',
        contactInfo: 'Authorized Signatory\'s Contact Information',
        authorizeName: 'Authorized Signatory\'s Family Name (Last Name)',
        authorizeGiven: 'Authorized Signatory\'s Given Name (First Name)',
        authorizeTitle: 'Authorized Signatory\'s Title',
        p8_3Hint:'A US mobile phone number is required for 5-6. If not, please fill in 7.',
        authorizeDay: 'Authorized Signatory\'s Daytime Telephone Number',
        authorizeMobile: 'Authorized Signatory\'s Mobile Telephone Number (if any)',
        authorizeEmail: ' Authorized Signatory\'s Email Address (if any)',
        declaration: 'Petitioner\'s or Authorized Signatory\'s Declaration and Certification',
        agreement1: 'Copies of any documents submitted are exact photocopies of unaltered, original documents, and I understand that, as the petitioner, I may be required to submit original documents to USCIS at a later date.',
        agreement2: 'I authorize the release of any information from my records, or from the petitioning organization\'s records, to USCIS or other entities and persons where necessary to determine eligibility for the immigration benefit sought or where authorized by law. I recognize the authority of USCIS to conduct audits of this petition using publicly available open source information. I also recognize that any supporting evidence submitted in support of this petition may be verified by USCIS through any means determined appropriate by USCIS, including but not limited to,on-site compliance reviews.',
        agreement3: 'If filing this petition on behalf of an organization, I certify that I am authorized to do so by the organization. ',
        agreement4: 'I certify, under penalty of perjury, that I have reviewed this petition, I understand all of the information contained in, and submitted with, my petition, and all of this information is complete, true, and correct.',
        signature: 'Petitioner\'s or Authorized Signatory\'s Signature',
        signatureTwo: 'Petitioner\'s Signature',
        signatureDate: 'Date of Signature (mm/dd/yyyy)',
        attention: 'NOTE TO ALL PETITIONERS AND AUTHORIZED SIGNATORIES:',
        p8_8Hint:'You need to print the form and sign it in person for this blanket.',
        signatories: 'If you do not completely fill out this petition or fail to submit required documents listed in the Instructions,USCIS may delay a decision on or deny your petition. ',
        //表单第九部分
        ninethTitle: 'P9. Interpreter\'s Contact Information,Certification, and Signature',
        ninethPart: 'Part 9. Interpreter\'s Contact Information,Certification, and Signature ',
        interpreter: 'Provide the following information about the interpreter.',
        interpreterName: 'Interpreter\'s Full Name',
        interpreterLast: 'Interpreter\'s Family Name (Last Name)',
        interpreterFirst: 'Interpreter\'s Given Name (First Name)',
        interpreterBusi: 'Interpreter\'s Business or Organization Name (if any)',
        interpreterMail: 'Interpreter\'s Mailing Address',
        interpreterContact: 'Interpreter\'s Contact Information',
        interpreterDaytime: 'Interpreter\'s Daytime Telephone Number',
        interpreterMobile: 'Interpreter\'s Mobile Telephone Number',
        interpreterEmail: 'Interpreter\'s Email Address (if any)',
        certification: 'Interpreter\'s Certification',
        certify: 'I certify, under penalty of perjury, that:',
        fluent: 'I am fluent in English and',
        specifyLanguage: 'which is the same language specified in Part 8., Item Number 1.b., and I have read to this petitioner or the authorized signatory in the identified language  every question and instruction on this petition and his or her answer to every question. The petitioner or authorized signatory informed me that he or she understands every instruction, question, and answer on the petition, including the Petitioner\'s or Authorized Signatory\'s Declaration and Certification, and has verified the accuracy of every answer.',
        interpreterSign: 'Interpreter\'s Signature',
        signDate: 'Date of Signature (mm/dd/yyyy)',
        //表单第十部分
        tenthTitle: 'P10.Contact Information, Declaration, and Signature of the Person Preparing this Petition,if Other Than the Authorized Individual',
        tenththPart:'Part 10. Contact Information, Declaration, and Signature of the Person Preparing this Petition,if Other Than the Authorized Individual', 
        preparer: 'Provide the following information about the preparer.',
        preparerName: 'Preparer\'s Full Name',
        preparerLast: 'Preparer\'s Family Name (Last Name)',
        preparerFirst: 'Preparer\'s Given Name (First Name)',
        preparerBuis: 'Preparer\'s Business or Organization (if any)',
        preparerMail: 'Preparer\'s Mailing Address',
        preparerContact: 'Preparer\'s Mailing Address',
        preparerDaytime: 'Preparer\'s Daytime Telephone Number',
        preparerMobile: 'Preparer\'s Mobile Telephone Number (if any)',
        preparerEmail: 'Preparer\'s Email Address (if any)',
        preparerStatement: 'Preparer\'s Statement',
        consent: 'I am not an attorney or accredited representative but have prepared this petition on behalf of the petitioner and with the petitioner\'s consent.',
        attorney: 'I am an attorney or accredited representative and my representation of the petitioner in this case ',
        extends: 'extends',
        notExtends: 'does not extend beyond the preparation of this application. ',
        preparerNote: 'NOTE: If you are an attorney or accredited representative, you may need to submit a completed Form G-28, Notice of Entry of Appearance as Attorney or Accredited Representative, or Form G-28I, Notice of Entry of Appearance as Attorney In Matters Outside the Geographical Confines of the United States, with this petition.',
        preparerCertification: 'Preparer\'s Certification',
        preparerSignNote: 'By my signature, I certify, under penalty of perjury, that I prepared this petition at the request of the petitioner or authorized signatory. The petitioner has reviewed this completed petition, including the Petitioner\'s or Authorized Signatory\'s Declaration and Certification, and informed me that all of this information in the form and in the supporting documents is complete, true, and correct.',
        preparerSign: 'Preparer\'s Signature',
        preparerData: 'Date of Signature (mm/dd/yyyy)',
        //表单第十一部分
        elevenTitle: 'P11. Additional Information',
        elevenPart: 'Part 11. Additional Information ',
        elevenExtra: 'If you need extra space to provide any additional information within this petition, use the space below. If you need more space than what is provided, you may make copies of this page to complete and file with this petition or attach a separate sheet of paper. Type or print your name and A-Number (if any) at the top of each sheet; indicate the Page Number, Part Number,and Item Number to which your answer refers; and sign and date each sheet.',
        IRS: 'IRS EIN',
        pageNum: 'Page Number',
        partNum: 'Part Number ',
        itemNum: 'Item Number'
    },
    homePage: {
        //表单第一部分
        LandR:'Login/Register',
        language: 'language',
        navigation1: 'Homepage',
        navigation2: 'EB-1A',
        navigation3: 'NIW',
        navigation4: 'Immigration Agency Service',
        navigation5: 'Free Assessment',
        navigation6: 'Q&A Zone',
        // firstTerm
        title1: 'Your Personal Immigration Plan Online DIY Assistant',
        content1: 'Our DIY Platform will guide you through the entire process of your immigration application.',
        list1_1:'Resource-Saving: No need to download various document packages.',
        list1_2:'Intelligent: Organize evidence documents automatically.',
        list1_3:'Simple: Follow the teaching guidance to collect, fill, and upload materials.',
        list1_4:'Efficient: Edit and organize files online and turn the files to PDF forms with a single click. Finally, print, sign, and submit to the USCIS.',
        advertising1: 'Efficient DIY Immigration Application only cost',
        advertising2: '$599',
        // advertising3: ,
        advertisingBtn: 'View Pricing',

        // swiper
        swperTitle:'User Feedback',
        swperTitle_tip:'Customer feedback received by our team',

        // DIY_flowchart_List
        DIY_flowchart_List_title:'DIY Process',
        DIY_flowchart_List_text1:'Teaching and Guidance',
        DIY_flowchart_List_text2:'Whole-Process Consultation',
        DIY_flowchart_List_text3:'Fill and Upload',
        DIY_flowchart_List_text4:'Online Preview',
        DIY_flowchart_List_text5:'Document Review',
        DIY_flowchart_List_text6:'Translation and Polishing',
        DIY_flowchart_List_text7:'Viewing in Chinese and English',
        DIY_flowchart_List_text8:'One-Click Generation',
        DIY_flowchart_List_text9:'Download and Print',

            //secondChunkTitle
        title2: 'Why choose CasePass?',
        title2_en: 'Product advantages',

        secondChunkCardData1_title:'Sharing Unreservedly to Bridging Immigration Information Gap',
        secondChunkCardData1_tip:'Clear showcases with no reservations or information gaps.',
        secondChunkCardData1_list1:'Q&A Zone',
        secondChunkCardData1_list2:'Free Assessment',
        secondChunkCardData1_list3:'DIY Tools',
        secondChunkCardData1_list4:'Case Studies',
        secondChunkCardData1_list5:'Teaching and Guidance',

        secondChunkCardData2_title:'Clear at a Glance, Easy to Understand',
        secondChunkCardData2_tip:'Clear design, framework, instructions, and a highly experienced professional team, assisting you to complete the immigration application process more easily',
        secondChunkCardData2_list1:'Clear Framework',
        secondChunkCardData2_list2:'Whole-process Guidance',
        secondChunkCardData2_list3:'Standard Templates',
        secondChunkCardData2_list4:'Instructions and Guidance',
        secondChunkCardData2_list5:'Online Consultation',

        secondChunkCardData3_title:'Competitive Pricing, Full Sincerity',
        secondChunkCardData3_tip:'We spare no effort to serve you for each case, while offering you a competitive pricing.',
        secondChunkCardData3_list1:'Transparent pricing',
        secondChunkCardData3_list2:'DIY Services',
        secondChunkCardData3_list3:'Review and Consultation',
        secondChunkCardData3_list4:'Translation Services',
        secondChunkCardData3_list5:'Agency Services',

        secondChunkCardData4_title:'DIY by Yourself for Higher Quality',
        secondChunkCardData4_tip:'Only you have the most authority over your field of expertise and your own case.',
        secondChunkCardData4_list1:'Self-authoring',
        secondChunkCardData4_list2:'Annotation and Suggestions',
        secondChunkCardData4_list3:'Refinement and Polishing',
        secondChunkCardData4_list4:'Synchronized Preview',
        secondChunkCardData4_list5:'One-click Export',

            //thirdlyChunkTitle
        title3:'DIY Immigration Services',

            //tabBtn 默认值
        tabBtn1:'Online Immigration Application DIY Tools',
        tabBtn2:'Review and Consultation',
        tabBtn3:'Translation and Polishing',

        tabBtn1_title:'Online Immigration Application DIY Tools',
        tabBtn1_tip:'Professional Immigration Application DIY Workbench & Efficient Guidance of  Materials Preparation',
        tabBtn1_list1:'Teaching and Guidance',
        tabBtn1_list2:'Filing and Upload',
        tabBtn1_list3:'Online Preview',
        tabBtn1_list4:'One-Click Generation',
        tabBtn1_list5:'Download and Print',
        tabBtn1_list6:'Process Outlining',

        tabBtn2_title1:'Online Consultation',
        tabBtn2_list1_title1:'1.',
        tabBtn2_list1_tip1: 'Online Consultation:Click the “Online Consultation” button to instantly reach an immigration consultant for quick responses.',
        tabBtn2_list1_title2:'2.',
        tabBtn2_list1_tip2:'Enhanced Efficiency: Address queries regarding form filing, application guidance, strengths highlighting, and system planning to improve the quality of the application.',
        tabBtn2_list1_title3:'3.',
        tabBtn2_list1_tip3:'Reduced Burden: Facilitate convenient services by handling official fees on behalf of applicants.',
        tabBtn2_list1_title4:'4.',
        tabBtn2_list1_tip4:'Assisted Document Collection: Assist in assessing, selecting, gathering, and creating documents to better facilitate organizing and preparing materials.',

        tabBtn2_title2:'One-Click Review',
        tabBtn2_list2_title1:'1.',
        tabBtn2_list2_tip1:'Expertise and Experience:Assessing your documents uploaded, and offering you valuable advice and guidance. ',
        tabBtn2_list2_title2:'2.',
        tabBtn2_list2_tip2:'Enhancing Approval Chances:Avoiding common errors, ensuring application materials are as complete and accurate as possible.',
        tabBtn2_list2_title3:'3.',
        tabBtn2_list2_tip3:'Improving Quality: Elevating document quality, ensuring readability, format adherence, and grammatical accuracy.',

        tabBtn2_title3:'Specialized Customer Service',
        tabBtn2_list3_title1:'1.',
        tabBtn2_list3_tip1:'Personalization: One-on-one specialized support, detailed analysis, clarifying doubts, and offering professional advice and solutions. ',
        tabBtn2_list3_title2:'2.',
        tabBtn2_list3_tip2:'Prompt Responsiveness: Timely responses throughout the entire immigration process to enhance application efficiency and accuracy.',
        tabBtn2_list3_title3:'3.',
        tabBtn2_list3_tip3:'Continuous Monitoring: Offering ongoing guidance to prevent omissions or errors, thereby increasing the chances of approval.',

        tabBtn2_title4:'Annotation and Suggestions',
        tabBtn2_list4_title1:'1.',
        tabBtn2_list4_tip1:'Clarity and Precision: Clearly indicating issues, omissions, or errors to minimize misunderstandings and oversights. ',
        tabBtn2_list4_title2:'2.',
        tabBtn2_list4_tip2:'Conveying Clarity:Effectively communicating information and intent for increased efficiency.',
        tabBtn2_list4_title3:'3.',
        tabBtn2_list4_tip3:'Convenient Editing:Displaying annotation and suggestions alongside the original text for ease of modification and updates.',

        tabBtn3_title1:'Logical Corrections',
        tabBtn3_list1_title1:'1.',
        tabBtn3_list1_tip1:'Clarity of Intent: Ensuring logical coherence and sentences disconnection to reduce communication barriers and enhance comprehension by immigration officers.',
        tabBtn3_list1_title2:'2.',
        tabBtn3_list1_tip2:'Accuracy of Information:Ensuring information accuracy and clarity in application forms and documents to prevent confusion.',
        tabBtn3_list1_title3:'3. ',
        tabBtn3_list1_tip3:'Adherence to Requirements:Avoiding logical gaps, contradictions, inconsistencies between documents to eliminate potential doubts.',

        tabBtn3_title2:'Text Polishing',
        tabBtn3_list2_title1:'1.',
        tabBtn3_list2_tip1:'Highlighting Attributes: Tailoring content to enhance personal strengths and assertions, thereby boosting case competitiveness.',
        tabBtn3_list2_title2:'2.',
        tabBtn3_list2_tip2:'Language Enhancement:Refining sentence structures, word choices, and grammatical expressions to ensure fluidity and richness in communication.',
        tabBtn3_list2_title3:'3.',
        tabBtn3_list2_tip3:'Reducing Discrepancies:Adapting to the cultural and linguistic norms of the target country through adjustments and modifications.',

        tabBtn3_title3:'Professional Certification',
        tabBtn3_list3_title1:'1. ',
        tabBtn3_list3_tip1:'Compliance with Official Requirements:Translation materials stamped with an officially recognized translation seal, meeting the stipulated translation certification qualifications.',
        tabBtn3_list3_title2:'2. ',
        tabBtn3_list3_tip2:'Professional Translation Team:Ensuring the accuracy and reliability of translated materials.',

        tabBtn3_title4:'Preview in Chinese & English',
        tabBtn3_list4_title1:'1.',
        tabBtn3_list4_tip1:'Preview Display:After translation completion, easily generate a side-by-side preview of all materials in Chinese and English.',
        tabBtn3_list4_title2:'2.',
        tabBtn3_list4_tip2:'Convenient Comparison: Visually compare the original text with the translated documents, facilitating an understanding of the content and quality of the translation.',
        tabBtn3_list4_title3:'3.',
        tabBtn3_list4_tip3:'Quick Navigation:Efficiently navigate through the entire document to locate specific paragraphs or key information, saving time and effort.',

        bottomName:'Learn More',
        fourthlyChunk_title:'Online DIY platform，Immigration Made Easy',
        fourthlyChunk_btn:'Free Assessment',

        footerLink_lie1_title:'Company Profile',
        footerLink_lie1_list1:'Product Introduction',

        footerLink_lie2_title:'Related Agreements',
        footerLink_lie2_list1:'User Agreement',
        footerLink_lie2_list2:'Privacy Policy Statement',
        footerLink_lie2_list3:'Purchase Agreement',

        footerLink_lie3_title:'DIY USA',
        footerLink_lie3_list1:'EB-1A Immigration Program',
        footerLink_lie3_list2:'NIW Immigration Program ',

        footerLink_lie4_title:'Contact Information',
        footerLink_lie4_list2:'Building (b), Gemini, Yubei District, Chongqing City',

                // immigrant  移民代理
        immigrant_title:'Welcome to KR.run',
        immigrant_tip:'Our immigration agency service provides you with a full range of immigration-related services. You can supervise the whole immigration process during our service. The only thing you need to do is to accept the final results.',
        immigrant_btn:'Learn More',

        immigrant_box2_title:'Immigration Agency Service',
        immigrant_box2_tip:'One-stop Application and Consultation Service',
        immigrant_list_text1:'Professional Assessment',
        immigrant_list_text2:'Case Planning',
        immigrant_list_text3:'Material Collection',
        immigrant_list_text4:'Evidence Organization',
        immigrant_list_text5:'Drafting and Translation',
        immigrant_list_text6:'Verification and Marking',
        immigrant_list_text7:'Review and Follow-up',
        immigrant_list_text8:'Interview Techniques',


        immigrant_fromName:'Appoint Immigration Case Consultation',
        immigrant_from1_name:'name',
        immigrant_from1_name_tip:'type in ',
        immigrant_from1_phone:'phone',
        immigrant_from1_phone_tip:'type in ',
        immigrant_from1_authCode:'authCode',
        immigrant_from1_authCode_tip:'type in ',
        immigrant_from1_authCode_tip2:'get auth code',
        immigrant_from1_authCode_tip3:'send again',
        immigrant_from1_email:'E-mail',
        immigrant_from1_email_tip:'type in',
        immigrant_from1_email_tip2:'Please enter a valid email address',
        immigrant_from1_WeiXin:'We-chat',
        immigrant_from1_WeiXin_tip:'type in ',
        immigrant_from1_time:'Appointment',
        immigrant_from1_time_tip1:'Timezone',
        immigrant_from1_time_tip2:'Date',
        immigrant_from1_time_tip4:'type in',
        immigrant_from1_time_tip3:'Time',
        immigrant_from1_Submit:'Submit',
        immigrant_from1_Cancel:'Cancel',

        immigrant_from1_IMGCode:'graph validate code',
        immigrant_from1_IMGCode_tip1:'type in',
        immigrant_from1_IMGCode_sure:'sure',
        immigrant_from1_IMGCode_cancel:'cancel',

        immigrant_from1_sure_tip:'You have completed the submission, we will contact you as soon as possible',

        eb1a_head_text1:'EB-1A immigration (DIY)',
        eb1a_head_text2:'Introduction',
        eb1a_head_text3:'Criteria',
        eb1a_head_text4:'Procedure',

        eb1a_first_title:'Employment-Based Immigration: First Preference EB-1(DIY)',
        eb1a_first_text1:'EB-1A is a green card category that is especially for those with extraordinary abilities. It has the priority among green card applications in the U.S. No offer of employment or labor certification is required.',
        eb1a_first_text2:'Petitioners must be able to demonstrate extraordinary ability in the sciences, arts, education, business, or athletics through sustained national or international acclaim.',
        eb1a_first_btn1:'Online Evaluation',
        eb1a_first_btn2:'DIY Now',
        eb1a_first_btn3:'Quotation',

        eb1a_second_title:'Criteria for EB-1A',
        eb1a_second_tip1:'Please ensure you meet at least 3 of the 10 criteria below before you pay for our DIY service.',
        eb1a_second_tip2_1:'if you need help.',
        eb1a_second_tip2_2:'Contact us >',
        eb1a_second_list1_title:'Prize/Award',
        eb1a_second_list1_tip:'Evidence of receipt of lesser nationally or internationally recognized prizes or awards for excellence.',
        eb1a_second_list2_title:'Membership',
        eb1a_second_list2_tip:'Evidence of your membership in associations in the field which demand outstanding achievement of their members.',
        eb1a_second_list3_title:'Media Materials',
        eb1a_second_list3_tip:'Evidence of published material about you in professional or major trade publications or other major media.',
        eb1a_second_list4_title:'Review',
        eb1a_second_list4_tip:'Evidence that you have been asked to judge the work of others, either individually or on a panel.',
        eb1a_second_list5_title:'Original Contribution',
        eb1a_second_list5_tip:'Evidence of your original scientific, scholarly, artistic, athletic, or business-related contributions of major significance to the field.',
        eb1a_second_list6_title:'Authorship',
        eb1a_second_list6_tip:'Evidence of your authorship of scholarly articles in professional or major trade publications or other major media.',
        eb1a_second_list7_title:'Exhibition',
        eb1a_second_list7_tip:'Evidence that your work has been displayed at artistic exhibitions or showcases.',
        eb1a_second_list8_title:'Leadership/Key Role',
        eb1a_second_list8_tip:'Evidence of published material about you in professional or major trade publications or other major media.',
        eb1a_second_list9_title:'High Salary',
        eb1a_second_list9_tip:'Evidence that you command a high salary or other significantly high remuneration in relation to others in the field.',
        eb1a_second_list10_title:'Successes in the performing arts',
        eb1a_second_list10_tip:'Evidence of your commercial successes in the performing arts.',

        eb1a_thirdly_title:'DIY Application Process Demonstration',

        eb1a_fourthly_title:'EB-1A Application Process',
        eb1a_fourthly_list1_title:'Preliminary evaluation',
        eb1a_fourthly_list1_content_p1:'1.Background - Effectively find the pathway and optimize the petitioner’s background preparation, thus improve the success rate. (Providing evidence for 3 of the 10 criteria is needed.)',
        eb1a_fourthly_list1_content_p2:"2.Positioning - Precisely positioning petitioner's professional field.  All evidence will focus on a certain professional field and irrelevant materials will not be used. (The key to EB-1A success.)",
        
        eb1a_fourthly_list2_title:'Evidence preparation',
        eb1a_fourthly_list2_content_p1:'1.Provide a clear, comprehensive, convincing evidence chain.',
        eb1a_fourthly_list2_content_p2:"2.Highlights, namely documents of the petitioner’s outstanding competence. E.g. presenting the petitioner's highest level of awards, the most influential papers, professional media coverage, professional associations membership, etc.",
       
        eb1a_fourthly_list3_title:'Materials composing',
        eb1a_fourthly_list3_content_p1:'1.Petition letter',
        eb1a_fourthly_list3_content_p2:"Including evaluation, positioning, and evidence chain mentioned above, the petitioner's all highlight extraordinary ability in the professional field should be objectively presented in the petition letter to gain recognition from immigration officers. ",
        eb1a_fourthly_list3_content_p3:"Materials such as petitioners' awards won, relevant media reports and published papers should be listed plainly and detailedly. An additional appendix is optional if there is excessive evidence,  which would make it easier for the immigration officer to go through it selectively and efficiently.",
        eb1a_fourthly_list3_content_p4:'2.Recommend letter',
        eb1a_fourthly_list3_content_p5:"Although recommend letters are not required by the USCIS, petitioners should still strive to provide letters written by authority figures, for those letters reflect the competitiveness of petitioners. If the referrer has expertise and a reputation in the field, then the letter offered will be effective in adding points.",
        eb1a_fourthly_list3_content_p6:'3.Future stay',
        eb1a_fourthly_list3_content_p7:"In the future stay part, petitioners should comprehensively illustrate the plan in the U.S. It is significant to demonstrate petitioners' extraordinary ability, what their performance means to the U.S., and their continuity in the stated field after arriving in the U.S. Therefore, petitioners' business plan is also required in the future stay part. For example, the current development of petitioners' professional field, the subsequential work petitioners will do when getting green cards, the national interests petitioners will bring, yearly plan on short term (1-3 years) and long term (5-10 years) and how it will be conducted.",
        eb1a_fourthly_list3_content_p8:"Providing employment offers, industry research reports, cooperation and contracts with American companies will further prove the feasibility of petitioners' business plan.",
        
        eb1a_fourthly_list4_title:'Form filling ',
        eb1a_fourthly_list4_content_p1:"1.Form I-140, Immigrant Petition for Alien Workers",
        eb1a_fourthly_list4_content_p2:"A nine-page immigration petition form that is submitted to the USCIS. Approval of the I-140 form means one's immigration petition has passed by the USCIS. It would be very close for the petitioner to get a green card.",
        eb1a_fourthly_list4_content_p3:"The current I-140 form is divided into 11 sections, mainly about personal information. The USCIS updates the form periodically, so petitioners need to check if it is the latest version before delivery.",
        eb1a_fourthly_list4_content_p4:"The I-140 form is the decisive step in the EB-1A application. To avoid making any mistakes or omissions, please carefully check it after filling. Otherwise, it is likely to be rejected by the USCIS.",
        eb1a_fourthly_list4_content_p5:"The form-filling fee of I-140 is $700. Once the USCIS receives the payment, they will send back a receipt (namely form I-797C). It indicates the petition is now in the process.Note: The application fee for the I-140 Form (Immigrant Petition for Alien Workers) will be raised from $700 to $715, effective April 1, 2024.",
        eb1a_fourthly_list4_content_p6:'2.Form I-907, Premium Processing',
        eb1a_fourthly_list4_content_p7:"Form I-907 premium processing requires payment of $2805. Petitioners will be notified of the result within 15 business days. The amount petitioners paid is non-refundable, regardless of whether they pass or not.",
        eb1a_fourthly_list4_content_p8:"If the results are not available within 15 business days, the payment will be refunded, and the expedited service will be provided free of charge. However, the probability of this happening is very low.",

        eb1a_fourthly_list5_title:'Delivery',
        eb1a_fourthly_list5_content_p1:'1.Download and print all the evidence.',
        eb1a_fourthly_list5_content_p2:'2.Categorize and package materials as listed in the petition letter.',
        eb1a_fourthly_list5_content_p3:'3.Mail them.',

        eb1a_fourthly_list6_title:'Status Checking',
        eb1a_fourthly_list6_content_p1_1:'1."Register for an account on ',
        eb1a_fourthly_list6_content_p1_2:'the USCIS website',
        eb1a_fourthly_list6_content_p1_3:'" and wait to receive a receipt from the USCIS.',
        eb1a_fourthly_list6_content_p2:'2.After filing, the EB-1A case is pending.',
        eb1a_fourthly_list6_content_p3:'USCIS has not been given a clear reference to the pending. The normal period is about 6-13 months. It varies from different review centers. The processing efficiency is affected by the backlog of cases.',
        eb1a_fourthly_list6_content_p4_1:'3.Petitioners can check the case progress online at',
        eb1a_fourthly_list6_content_p4_2:'the USCIS website',
        eb1a_fourthly_list6_content_p4_3:'by using the receipt number.',
        eb1a_fourthly_list6_content_p5:'4.Results',
        eb1a_fourthly_list6_content_p6:'Approval - Congratulations, one can prepare for the interview.',
        eb1a_fourthly_list6_content_p7:'Rejection - Both appeal and motion are optional, yet the success rate is lower than that of RFE.',
        eb1a_fourthly_list6_content_p8:'RFE - Request for Evidence.',

        
        eb1a_fourthly_list7_title:'RFE (if)',
        eb1a_fourthly_list7_content_p1:'1.It requests petitioners provide insufficient, invalid, or unclear evidence as listed.',
        eb1a_fourthly_list7_content_p2:"For example, the letter states that petitioners' achievements have been widely reported by the media, but only one or two media materials are provided. The immigration officer may ask for supplemental evidence, which still needs to be centered on the 10 criteria mentioned above. RFE is common, petitioners can catch the focus of the case review to further improve the petition materials.",

        eb1a_fourthly_list8_title:'Interview',
        eb1a_fourthly_list8_content_p1_1:'1.Register Online at ',
        eb1a_fourthly_list8_content_p1_2:'www.ustraveldocs.com',
        eb1a_fourthly_list8_content_p2:'2.Make an appointment a medical examination.',
        eb1a_fourthly_list8_content_p3_1:'3.Complete the pre-interview checklist：',
        eb1a_fourthly_list8_content_p3_2:'Specific material',
        eb1a_fourthly_list8_content_p4:'4.Interview（Except in the case of illness or incapacitation, only the petitioner will participate）',
        eb1a_fourthly_list8_content_p5_1:'5.',
        eb1a_fourthly_list8_content_p5_2:'Review interview guidelines',
        eb1a_fourthly_list8_content_p5_3:' ',

        eb1a_fourthly_list9_title:'Green card',
        eb1a_fourthly_list9_content_p1:'Normally, the USCIS will issue a formal green card within 1-3 months after one landing in the U.S. Now the EB-1A process is completed.',
        eb1a_fourthly_list9_content_p2:'Ps.The following are two ways to get a green card.',
        eb1a_fourthly_list9_content_p3:'1.Persons outside the United States',
        eb1a_fourthly_list9_content_p4:'After the I-140 is approved, the USCIS will submit the documents to the National Visa Center (NVC) within one to two months, which is responsible for collecting the visa application fee, checking the background, and reviewing the documents.',
        eb1a_fourthly_list9_content_p5:'However, the NVC will not contact the petitioner immediately. Petitioners should wait for their application date (EB1-Sheet B in Visa Bulletin) to determine when they can file form I-485 or receive NVC payment notice and related actions. Each year green cards are issued in a limited number, so scheduling is common.',
        eb1a_fourthly_list9_content_p6:'NVC pending (normally around 3 months)',
        eb1a_fourthly_list9_content_p7:'Pay processing fee of $345 per family member',
        eb1a_fourthly_list9_content_p8:'Complete form DS-260',
        eb1a_fourthly_list9_content_p9:'Submit a notarized marriage certificate',
        eb1a_fourthly_list9_content_p10:'birth certificate',
        eb1a_fourthly_list9_content_p11:'notary certificate of no criminal convictions',
        eb1a_fourthly_list9_content_p12:'2.Persons in the United StatesGet the green card by filling form I-485',

        eb1a_fourthly_list10_title:'Naturalization',
        eb1a_fourthly_list10_content_p1:'Taking the Oath of naturalization under the immigration supervision process.',

        eb1a_fifth_btn:'Purchase',

        eb1a_popUp_title:'not login',
        eb1a_popUp_toLogin:'to login',
        eb1a_popUp_know:'I know',
        eb1a_popUp_tip:'Please log in for a free evaluation',

        niw_head_text1:'NIW immigration petition(DIY)',
        niw_head_text2:'Introduction',
        niw_head_text3:'Criteria',
        niw_head_text4:'Procedure',

        niw_first_title:'NIW Immigration Petition(DIY)',
        niw_first_text1:'NIW stands for National Interest Waiver, a category within the U.S. green card application process, specifically under the employment-based immigration: second preference (EB-2) visa category. Petitioners who apply for NIW could request a waiver of labor certification. Its requirements are as follows: ',
        niw_first_text2:'Petitioner is a member of the professions holding an advanced degree or its equivalent, or a person who has exceptional ability.',
        niw_first_text3:'Demonstrate that the beneficiaries are in an advantageous position to advance their field and that the work they performed is of far-reaching intrinsic significance to the U.S.  and is in the national interest regarding economic, cultural, educational, or social welfare.',
        niw_first_btn1:'Online Evaluation',
        niw_first_btn2:'DIY Now',
        niw_first_btn3:'Quotation',

        niw_second_title:'Criteria for NIW',
        niw_second_tip1:'Please ensure you meet criteria below before you pay for our DIY service.',
        niw_second_tip2_1:'if you need help.',
        niw_second_tip2_2:'Contact us >',

        niw_second_left_t_title:'Advanced Degree',
        niw_second_left_t_list1:'',
        niw_second_left_t_list2:'Have a U.S. advanced degree or or its foreign equivalent (a baccalaureate or foreign equivalent degree plus 5 years of post-baccalaureate, progressive work experience in the field).;',
        niw_second_left_c_title:'Subcategories',
        niw_second_left_b_title:'Exceptional Ability',
        niw_second_left_b_title_tip:'(Please ensure you meet at least 3 of the 6 criteria)',
        niw_second_left_b_list1:'Official academic record showing that you have a degree, diploma, certificate, or similar award from a college, university, school, or other institution of learning relating to your area of exceptional ability.',
        niw_second_left_b_list2:'Letters from current or former employers documenting at least 10 years of full-time experience in your occupation.',
        niw_second_left_b_list3:'A license to practice your profession or certification for your profession or occupation.',
        niw_second_left_b_list4:'Evidence that you have commanded a salary or other remuneration for services that demonstrates your exceptional ability.',
        niw_second_left_b_list5:'Membership in a professional association(s).',
        niw_second_left_b_list6:'Recognition for your achievements and significant contributions to your industry or field by your peers, government entities, professional or business organizations.',

        niw_second_right_tip:'Requirements',
        niw_second_right_list1:'The proposed endeavor has both substantial merit and national importance.',
        niw_second_right_list2:'Petitioners are well positioned to advance the proposed endeavor.',
        niw_second_right_list3:'It would be beneficial to the United States to waive the requirements of a job offer, and thus the labor certification.',

        niw_thirdly_title:'NIW Application Process',

        niw_fourthly_title:'NIW Application Process',
        niw_fourthly_list1_title:'Preliminary evaluation',
        niw_fourthly_list1_content_p1:'1.Background - Effectively find the pathway and optimize the petitioner’s background preparation, thus improve the success rate. (To satisfy the basic and essential requirements for the NIW petition.)',
        niw_fourthly_list1_content_p2:"Positioning - Precisely position petitioners to meet the NIW's basic (advanced degree or exceptional ability), then move to whether they are advanced in their profession to promote development. And, illustrate whether petitioners' work matters to the U.S. that matches the national interest in terms of economic, cultural, educational, or social well-being. (The key to NIW success.)",
        
        niw_fourthly_list2_title:'Evidence preparation',
        niw_fourthly_list2_content_p1:"1.Providing a clear, comprehensive, convincing evidence chain will be a substantial illustration of petitioners' valued contribution to their professional field.",
        niw_fourthly_list2_content_p2:"2.Providing documentation of the petitioners‘ possession of an advanced degree or exceptional ability and how the development of their professional field will bring national interest to the U.S.",
       
        niw_fourthly_list3_title:'Materials composing',
        niw_fourthly_list3_content_p1:'1.Petition letter',
        niw_fourthly_list3_content_p2:"Including evaluation, positioning, and evidence chain mentioned above, the petitioner's all highlight extraordinary ability in the professional field should be objectively presented in the petition letter to gain recognition from immigration officers. ",
        niw_fourthly_list3_content_p3:"Materials such as petitioners' awards won, relevant media reports and published papers should be listed plainly and detailedly. An additional appendix is optional if there is excessive evidence,  which would make it easier for the immigration officer to go through it selectively and efficiently.",
        niw_fourthly_list3_content_p4:'2.Recommend letter',
        niw_fourthly_list3_content_p5:"Although recommend letters are not required by the USCIS, petitioners should still strive to provide letters written by authority figures, for those letters reflect the competitiveness of petitioners. If the referrer has expertise and a reputation in the field, then the letter offered will be effective in adding points.",
        niw_fourthly_list3_content_p6:'3.Future stay',
        niw_fourthly_list3_content_p7:"In the future stay part, petitioners should comprehensively illustrate the plan in the U.S. It is significant to demonstrate petitioners' extraordinary ability, what their performance means to the U.S., and their continuity in the stated field after arriving in the U.S. Therefore, petitioners' business plan is also required in the future stay part. For example, the current development of petitioners' professional field, the subsequential work petitioners will do when getting green cards, the national interests petitioners will bring, yearly plan on short term (1-3 years) and long term (5-10 years) and how it will be conducted.",
        niw_fourthly_list3_content_p8:"Providing employment offers, industry research reports, cooperation and contracts with American companies will further prove the feasibility of petitioners' business plan.",
        
        niw_fourthly_list4_title:'Form filling ',
        niw_fourthly_list4_content_p1:"1.Form I-140, Immigrant Petition for Alien Workers",
        niw_fourthly_list4_content_p2:"A nine-page immigration petition form that is submitted to the USCIS. Approval of the I-140 form means one's immigration petition has passed by the USCIS. It would be very close for the petitioner to get a green card.",
        niw_fourthly_list4_content_p3:"The current I-140 form is divided into 11 sections, mainly about personal information. The USCIS updates the form periodically, so petitioners need to check if it is the latest version before delivery.",
        niw_fourthly_list4_content_p4:"The I-140 form is the decisive step in the EB-1A application. To avoid making any mistakes or omissions, please carefully check it after filling. Otherwise, it is likely to be rejected by the USCIS.",
        niw_fourthly_list4_content_p5:"The form-filling fee of I-140 is $700. Once the USCIS receives the payment, they will send back a receipt (namely form I-797C). It indicates the petition is now in the process.Note: The application fee for the I-140 Form (Immigrant Petition for Alien Workers) will be raised from $700 to $715, effective April 1, 2024.",
        niw_fourthly_list4_content_p6:'2.Form I-907, Premium Processing',
        niw_fourthly_list4_content_p7:"Form I-907 premium processing requires payment of $2805. Petitioners will be notified of the result within 15 business days. The amount petitioners paid is non-refundable, regardless of whether they pass or not.",
        niw_fourthly_list4_content_p8:"If the results are not available within 15 business days, the payment will be refunded, and the expedited service will be provided free of charge. However, the probability of this happening is very low.",
        niw_fourthly_list4_content_p9:"3.Form ETA-9089, Labor Certification Application",
        niw_fourthly_list4_content_p10:"Should be submitted to the USCIS along with Form I-140. The personal information section is required and the rest of the section can be left blank.",

        niw_fourthly_list5_title:'Delivery',
        niw_fourthly_list5_content_p1:'1.Download and print all the evidence.',
        niw_fourthly_list5_content_p2:'2.Categorize and package materials as listed in the petition letter.',
        niw_fourthly_list5_content_p3:'3.Mail them.',

        niw_fourthly_list6_title:'Status Checking',
        niw_fourthly_list6_content_p1_1:'1."Register for an account on ',
        niw_fourthly_list6_content_p1_2:'the USCIS website',
        niw_fourthly_list6_content_p1_3:'" and wait to receive a receipt from the USCIS.',
        niw_fourthly_list6_content_p2:'2.After filing, the EB-1A case is pending.',
        niw_fourthly_list6_content_p3:'USCIS has not been given a clear reference to the pending. The normal period is about 6-13 months. It varies from different review centers. The processing efficiency is affected by the backlog of cases.',
        niw_fourthly_list6_content_p4_1:'3.Petitioners can check the case progress online at',
        niw_fourthly_list6_content_p4_2:'the USCIS website',
        niw_fourthly_list6_content_p4_3:'by using the receipt number.',
        niw_fourthly_list6_content_p5:'4.Results',
        niw_fourthly_list6_content_p6:'Approval - Congratulations, one can prepare for the interview.',
        niw_fourthly_list6_content_p7:'Rejection - Both appeal and motion are optional, yet the success rate is lower than that of RFE.',
        niw_fourthly_list6_content_p8:'RFE - Request for Evidence.',

        
        niw_fourthly_list7_title:'RFE (if)',
        niw_fourthly_list7_content_p1:'1.It requests petitioners provide insufficient, invalid, or unclear evidence as listed.',
        niw_fourthly_list7_content_p2:"For example, the letter states that petitioners' achievements have been widely reported by the media, but only one or two media materials are provided. The immigration officer may ask for supplemental evidence, which still needs to be centered on the 10 criteria mentioned above. RFE is common, petitioners can catch the focus of the case review to further improve the petition materials.",

        niw_fourthly_list8_title:'Interview',
        niw_fourthly_list8_content_p1_1:'1.Register Online at ',
        niw_fourthly_list8_content_p1_2:'www.ustraveldocs.com',
        niw_fourthly_list8_content_p2:'2.Make an appointment a medical examination.',
        niw_fourthly_list8_content_p3_1:'3.Complete the pre-interview checklist：',
        niw_fourthly_list8_content_p3_2:'Specific material',
        niw_fourthly_list8_content_p4:'4.Interview（Except in the case of illness or incapacitation, only the petitioner will participate）',
        niw_fourthly_list8_content_p5_1:'5.',
        niw_fourthly_list8_content_p5_2:'Review interview guidelines',
        niw_fourthly_list8_content_p5_3:' ',

        niw_fourthly_list9_title:'Green card',
        niw_fourthly_list9_content_p1:'Normally, the USCIS will issue a formal green card within 1-3 months after one landing in the U.S. Now the NIW process is completed. The following are two ways to get a green card.',
        niw_fourthly_list9_content_p2:'Ps.The following are two ways to get a green card.',
        niw_fourthly_list9_content_p3:'1.Persons outside the United States',
        niw_fourthly_list9_content_p4:'After the I-140 is approved, the USCIS will submit the documents to the National Visa Center (NVC) within one to two months, which is responsible for collecting the visa application fee, checking the background, and reviewing the documents.',
        niw_fourthly_list9_content_p5:'However, the NVC will not contact the petitioner immediately. Petitioners should wait for their application date (EB2-Sheet B in Visa Bulletin) to determine when they can file form I-485 or receive NVC payment notice and related actions. Each year green cards are issued in a limited number, so scheduling is common.',
        niw_fourthly_list9_content_p6:'NVC pending (normally around 3 months)',
        niw_fourthly_list9_content_p7:'Pay processing fee of $345 per family member',
        niw_fourthly_list9_content_p8:'Complete form DS-260',
        niw_fourthly_list9_content_p9:'Submit a notarized marriage certificate',
        niw_fourthly_list9_content_p10:'birth certificate',
        niw_fourthly_list9_content_p11:'notary certificate of no criminal convictions',
        niw_fourthly_list9_content_p12:'2.Persons in the United StatesGet the green card by filling form I-485',

        niw_fourthly_list10_title:'Naturalization',
        niw_fourthly_list10_content_p1:'Taking the Oath of naturalization under the immigration supervision process.',

        niw_fifth_btn:'Purchase',

        niw_popUp_title:'not login',
        niw_popUp_toLogin:'to login',
        niw_popUp_know:'I know',
        niw_popUp_tip:'Please log in for a free evaluation',

        aboutUs_t_title:'ABOUT US',
        aboutUs_t_text1:'Welcome to CasePass!',
        aboutUs_t_text2:'CasePass is developed and operated by Chongqing K.R. Technology Co., Ltd., a company dedicated to providing efficient and convenient immigration services for customers and users. Our team consists of experienced and passionate professionals committed to delivering the highest quality immigration services to our customers and users.',
        aboutUs_t_text3:'Our mission is to help more individuals achieve their immigration dreams. We deeply understand the difficulties and challenges involved in the immigration process. Therefore, we are devoted to offering comprehensive, personalized, and cost-effective immigration services, making the immigration journey smoother and more effortless for our customers and users.',
        aboutUs_t_text4:'Whether you are looking for DIY approaches, immigration agent services, or immigration information, we are capable of providing you with the most professional and comprehensive services. We believe that through our efforts and services, you will successfully realize your immigration dreams!',
        aboutUs_t_text5:'If you have any questions about immigration or need our assistance, please feel free to contact us. We are here to serve you wholeheartedly!',


        aboutUs_b_title:'OUR TEAM',
        aboutUs_list1_l_p:'Leader',
        aboutUs_list1_l_n:'Wu Lei',
        aboutUs_list1_r_p:'EN Translator',
        aboutUs_list1_r_n:'Ye Hanyu',

        aboutUs_list2_l_p:'PM',
        aboutUs_list2_l_n:'Danae',
        aboutUs_list2_r_p:'',
        aboutUs_list2_r_n:'Wang Weihan',

        aboutUs_list3_l_p:'Assistant',
        aboutUs_list3_l_n:'Yao Jiaoxin',
        aboutUs_list3_r_p:'',
        aboutUs_list3_r_n:'Wu Xianbo',

        aboutUs_list4_l_p:'UI',
        aboutUs_list4_l_n:'Aiden',
        aboutUs_list4_r_p:'',
        aboutUs_list4_r_n:'Liu Yan',

        aboutUs_list5_l_p:'Front-end',
        aboutUs_list5_l_n:'Xie Haoyu',
        aboutUs_list5_r_p:'',
        aboutUs_list5_r_n:'Lian Junhan',

        aboutUs_list6_l_p:'Back-end',
        aboutUs_list6_l_n:'Steven',
        aboutUs_list6_r_p:'CN Editor',
        aboutUs_list6_r_n:'Zeng Junhao',

        aboutUs_list7_l_p:'Test ',
        aboutUs_list7_l_n:'Feng Fei',
        aboutUs_list7_r_p:'',
        aboutUs_list7_r_n:'Yu Shuangling',

        aboutUs_list8_l_p:'Service',
        aboutUs_list8_l_n:'Chen Qianrou',
        aboutUs_list8_r_p:'',
        aboutUs_list8_r_n:'Han Jiaxin',
    },
    common: {
        noLogin: 'please log in',
        redTip1:'Please do not use QQ email',
        redTip2:'State/Province must be filled in according to different country',
        redTip3:'please choose either State or Province',
        redTip4:'It needs to be downloaded, printed and handwritten',
        inputTip1:'It needs to be downloaded, printed and handwritten',
        inputTip2:'Please select time',
        inputTip3:'Please write',
        selectTip1:'please select',
        submitBtn1:'Submit',
        lastBtn1:'Last Step',
        nextBtn1:'Next Step',
        finishBtn:'Finish',
        MSG_tip1:'The table is saved successfully',
        MSG_tip2:'Fail in keeping',
        MSG_tip3:'The required fields of the form have not been completed!',
        MSG_tip4:'Cancel successfully',
        MSG_tip5:'Submit successfully',
        MSG_tip6:'Failed to obtain the download address. Procedure',
        MSG_tip7:'successfully delete',
        MSG_tip8:'Already completed and submitted, please go for the next step',
        MSG_tip9:'save successfully',
        MSG_tip10:'You need to buy to view it ！',
        MSG_tip11:'Please purchase before using this function',
        MSG_tip12:'fail to delete',
        MSG_tip13:'Edit successfully',
        MSG_tip14:'Edit failure',
        MSG_tip15:'Successfully added',
        MSG_tip16:'Fail to add',
        MSG_tip17:'Order adjustment completed',
        MSG_tip18:'Order adjustment failed',
        btn_confirm:'confirm',
        btn_cancel:'cancel',
        extensible:'↓',
        wangEditer_inputBtn1:'Evidence Index',
        wangEditer_inputBtn2:'Reference Letter Index',
        noBuy:'Unpurchased service',
        noBuy2:'You haven’t purchase the DIY service,The service is available after purchasing!',
        toBuy:'Buy',
        toLogin:'Login',
        Iknow:'Got it',
        urgent_tip:'We are handling your uploaded materials, please wait patiently!',
        getFileFail:'Failed to get file',

        Upload_btn:'Upload',
        Edit_btn:'Edit',
        Delete_btn:'Delete',

        // 预览界面
        preview_title:'Catalogue',
        preview_left:'Source File',
        preview_left_up:'UP',
        preview_left_down:'DOWN',
        preview_right:'Translate File',
        preview_right_up:'UP',
        preview_right_down:'DOWN',

        preview_tip1:'No Data',

        QandA_tip1:'Search keywords:',
        QandA_tip2:'No Response',
        QandA_tip3:'Tell us your suggestion if you are doing DIY immigration.',
        QandA_ques:'Selection suggestion type',
        QandA_ques_a:'Website operation',
        QandA_ques_b:'Page function',
        QandA_tip4:'Your questions and ideas will help more people.',
        QandA_tip5:'Please log in first',
        QandA_tip6:'Submit successfully. Thanks for your feedback.',
        QandA_tip7:'It cannot left blank!',

        QandA_btn1:'Ask questions',
        QandA_btn2:'Ask questions',
        QandA_btn3:'Make a suggestion',
        QandA_btn4:'Submit',
        QandA_btn5:'To Log in',
        QandA_btn6:'Got',

        homeRight1:'Contact us',
        homeRight2:'Back to top',
    },
    // 评估页面
    freeAssessment: {
        all_title:'America-Immigration Program Assessment',
        table1_title:'1.Basic*',
        table2_title:'2.Information-Working*',
        table3_title:'3.Consult*',

        table3_text1:'When are you available to take a call?',
        table3_text2:'(You can schedule a phone consultation here)',
        table3_question1:'Time Zone',
        table3_question1_tip:'please input',
        table3_question2:'Date',
        table3_question2_tip:'please input',
        table3_question3:'Preferred Time',
        table3_question3_tip:'please input',
        table3_submit:'Submit',


        table1_list1:'Personal Information',
        table1_list1_question1:'Name',
        table1_list1_question1_tip:'please enter',
        table1_list1_question2:'Gender',
        table1_list1_question2_select1:'Male',
        table1_list1_question2_select2:'Female',
        table1_list1_question3:'Date of Birth',
        table1_list1_question3_tip:'please enter',
        table1_list1_question4:'E-mail Address',
        table1_list1_question4_tip:'please enter',
        table1_list1_question5:'We-Chat Account',
        table1_list1_question5_tip:'please enter',

        table1_list2:'Marital Status',
        table1_list2_question1:'Marital Status',
        table1_list2_question1_select1:'Unmarried',
        table1_list2_question1_select2:'Married',
        table1_list2_question1_select3:'Divorced',
        table1_list2_question2:'children',
        table1_list2_question2_1:'Name',
        table1_list2_question2_2:'age',
        table1_list2_question2_btn:'Add information of all your children',

        table1_list3:'Military and Political Status',
        table1_list3_question1:'Have you ever received any type of military, paramilitary, or weapon training',
        table1_list3_question1_select1:'Yes',
        table1_list3_question1_select2:'No',
        table1_list3_question2:'Military Type',
        table1_list3_question3:'Have you ever participated, or been a member of the Communist Party',
        table1_list3_question3_select1:'Yes',
        table1_list3_question3_select2:'No',

        table1_list4:'Health Status',
        table1_list4_question1:'Infectious Diseases',
        table1_list4_question1_select1:'Yes',
        table1_list4_question1_select2:'No',
        table1_list4_question2:'Criminal Records',
        table1_list4_question2_select1:'Yes',
        table1_list4_question2_select2:'No',

        table1_list5:'Immigration History',
        table1_list5_question1:'Passport Issuing Place',
        table1_list5_question1_tip:'please enter',
        table1_list5_question2:'Issuing Date',
        table1_list5_question2_tip:'please select',
        table1_list5_question3:'Expiration Date',
        table1_list5_question3_tip:'please select',
        table1_list5_question4:'Have you ever applied for American Immigration Program',
        table1_list5_question4_select1:'yes',
        table1_list5_question4_select2:'no',
        table1_list5_question4_tip:'If you have ever applied for immigration, please indicate the immigration category, application result, and receipt number.',
        table1_list5_question5:'Have you ever study abroad or travel abroad',
        table1_list5_question5_select1:'yes',
        table1_list5_question5_select2:'no',
        table1_list5_question5_tip:'If you have ever applied for any non-immigration/immigration visa, please indicate the specific details of this visa (type, application result, etc.)',

        table1_next_btn:'Next',
        table1_up_btn:'Previous',
        table1_saveAndNext_btn:'Save and Next',

        table2_list1:'Educational Background',
        table2_list1_question1:'Degree',
        table2_list1_question1_tip:'please enter',
        table2_list1_question2:'University/College',
        table2_list1_question2_tip:'please enter',
        table2_list1_question3:'Major',
        table2_list1_question3_tip:'please enter',
        table2_list1_question4:'Enrollment Date',
        table2_list1_question4_tip:'please select',
        table2_list1_question5:'Graduation Date',
        table2_list1_question5_tip:'please select',
        table2_list1_add_btn:'add',
        table2_list1_del_btn:'delete',


        table2_list2:'Working Experience',
        table2_list2_question1:'Company/Institution',
        table2_list2_question1_tip:'please enter',
        table2_list2_question2:'Occupation/Position',
        table2_list2_question2_tip:'please enter',
        table2_list2_question3:'Annual Income',
        table2_list2_question3_tip:'please enter',
        table2_list2_question4:'Starting Date',
        table2_list2_question4_tip:'please select',
        table2_list2_question5:'Ending Date',
        table2_list2_question5_tip:'If left blank, it shall be “current” automatically',
        table2_list2_add_btn:'add',
        table2_list2_del_btn:'delete',

        table2_list3:'Prizes/Awards',
        table2_list3_question1:'Have you ever received nationally or internationally recognized prizes/awards',
        table2_list3_question1_tip:'Please type in the Award name, awarding date, awarding institution, award level, and whether it was received as a team or individual (optional)...',
        table2_list3_question1_select1:'No',
        table2_list3_question1_select2:'Yes',

        table2_list4:'Membership',
        table2_list4_question1:'Are you a member of any relevant professional associations',
        table2_list4_question1_tip:'Please provide information about the association: industry affiliation, date of joining, membership level, membership criteria, and enrollment process...',
        table2_list4_question1_select1:'No',
        table2_list4_question1_select2:'Yes',

        table2_list5:'Media and Reports',
        table2_list5_question1:'Have you or your work been covered by mainstream media',
        table2_list5_question1_tip:'Please provide the names of media platforms, article headlines, publication dates, and specific details regarding the coverage.',
        table2_list5_question1_select1:'No',
        table2_list5_question1_select2:'Yes',

        table2_list6:'Industry Contribution',
        table2_list6_question1:'Have you made significant original contributions to your field of expertise',
        table2_list6_question1_tip:'Please provide detailed information here, contributions can be patents, technological achievements, published papers, research project approvals...',
        table2_list6_question1_select1:'No',
        table2_list6_question1_select2:'Yes',

        table2_list7:'Academic Articles',
        table2_list7_question1:'Have you ever published academic articles in your field of expertise',
        table2_list7_question1_tip:'Please provide relevant information including article titles, journal names, volume numbers, publication dates, citation counts, download counts, etc.',
        table2_list7_question1_select1:'No',
        table2_list7_question1_select2:'Yes',

        table2_list8:'Major Project',
        table2_list8_question1:'Have you ever been in charge of or participate in any national or provincial major projects',
        table2_list8_question1_tip:'Please provide details about the project, including its name, level, team members, funding, etc.',
        table2_list8_question1_select1:'No',
        table2_list8_question1_select2:'Yes',

        table2_list9:'International Conference',
        table2_list9_question1:'Have you ever participated in large-scale domestic or international professional conferences',
        table2_list9_question1_tip:'Please provide detailed information about the conference, including its name, date, location, attendees, level, any media coverage, etc.',
        table2_list9_question1_select1:'No',
        table2_list9_question1_select2:'Yes',

        table2_list10:'Critical and Leading Role',
        table2_list10_question1:'Have you held critical and leading role in your working place',
        table2_list10_question1_tip:'Please provide relevant information including the name and description of your workplace, your position, and your responsibilities...',
        table2_list10_question1_select1:'No',
        table2_list10_question1_select2:'Yes',

        table2_list11:'Exhibition',
        table2_list11_question1:'Have your works been exhibited or showcased in art exhibitions',
        table2_list11_question1_tip:'Please provide details about the exhibitions, including the exhibition name, dates, location, scale, and participation process...',
        table2_list11_question1_select1:'No',
        table2_list11_question1_select2:'Yes',

        table2_list12:'Art and Performance',
        table2_list12_question1:'Have your artistic performances achieved commercial success',
        table2_list12_question1_tip:'Please provide information about the performance, including the name of the art work, date, location, commercial achievements, audience size, etc.',
        table2_list12_question1_select1:'No',
        table2_list12_question1_select2:'Yes',

        table2_list13:'License and Qualification',
        table2_list13_question1:'Have you obtained professional certifications in your field of expertise',
        table2_list13_question1_tip:'Please provide information about the license/certifications, including certificate details, issuing institution, certification level, certification standards, etc.',
        table2_list13_question1_select1:'No',
        table2_list13_question1_select2:'Yes',

        table2_list14:'Other Comparable Evidence',
        table2_list14_question1:'Do you have any other comparable achievements',
        table2_list14_question1_tip:'These could include establishing industry standards, creating an artistic genre, initiating a industry movement, etc.',
        table2_list14_question1_select1:'No',
        table2_list14_question1_select2:'Yes',

        table2_list15:'High Income ',
        table2_list15_question1:'Do you have proof of income that higher than peers',
        table2_list15_question1_tip:'Please provide income-related information, such as income statements, pay stubs, 3-years tax returns, industry salary reports, etc.',
        table2_list15_question1_select1:'No',
        table2_list15_question1_select2:'Yes',

        table2_list16:'Reference Letter',
        table2_list16_question1:'Can you provide three or more recommendation letters',
        table2_list16_question1_tip:'( USCIS place more emphasis on recommendation letters from independent referees or individuals with no direct work or personal relationship with the applicant. Therefore, recommendation letters from independent referees or referees from foreign countries will carry more weight.)Please provide information about the referees, including their names, workplaces, positions, relevant achievements, etc.',
        table2_list16_question1_select1:'No',
        table2_list16_question1_select2:'Yes',

        table2_next_btn:'Next',
        table2_up_btn:'Previous',
        table2_saveAndNext_btn:'Save and Next',

        FA_input_tip1:'You haven’t choose.',
        FA_input_tip2:'You haven’t choose.',
        FA_input_tip3:'You haven’t choose.',
    },
    // 1145表
    eb1a_1145: {
        t1145_title:'e-Notification of Application/Petition Acceptance',
        // t1145_text1:'Use this form to request an electronic notification (e-Notification) when U.S. Citizenship and Immigration Services accepts your immigration application. This service is available for applications filed at a USCIS Lockbox facility.',
        t1145_text2:'What Is the Purpose of This Form? ',
        t1145_text3:'Use this form to request an electronic notification (e-Notification) when U.S. Citizenship and Immigration Services accepts your immigration application. This service is available for applications filed at a USCIS Lockbox facility.',
        t1145_text4:'General Information',
        t1145_text5:'Complete the information below and clip this form to the first page of your application package. You will receive one e-mail and/or text message for each form you are filing.',
        t1145_text6:'We will send the e-Notification within 24 hours after we accept your application. Domestic customers will receive an e-mail and/or text message; overseas customers will only receive an e-mail. Undeliverable e-Notifications cannot be resent.',
        t1145_text7:'The e-mail or text message will display your receipt number and tell you how to get updated case status information. It will not include any personal information. The e-Notification does not grant any type of status or benefit; rather it is provided as a convenience to customers.',
        t1145_text8:'USCIS will also mail you a receipt notice (I-797C), which you will receive within 10 days after your application has been accepted; use this notice as proof of your pending application or petition.',
        t1145_text9:'USCIS Privacy Act Statement',
        t1145_text10:'AUTHORITIES: The information requested on this form is collected pursuant to section 103(a) of the Immigration and Nationality Act, as amended INA section 101, et seq.',
        t1145_text11:'PURPOSE: The primary purpose for providing the information on this form is to request an electronic notification when USCIS accepts immigration form. The information you provide will be used to send you a text and/or email message.',
        t1145_text12:'DISCLOSURE: The information you provide is voluntary. However, failure to provide the requested information may prevent USCIS from providing you a text and/or email message receipting your immigration form.',
        t1145_text13:'ROUTINE USES: The information provided on this form will be used by and disclosed to DHS personnel and contractors in accordance with approved routine uses, as described in the associated published system of records notices [DHS/USCIS-007 - Benefits Information System and DHS/USCIS-001 - Alien File (A-File) and Central Index System (CIS), which can be found at www.dhs.gov/privacy]. The information may also be made available, as appropriate for law enforcement purposes or in the interest of national security.',
        t1145_text14:'Complete this form and clip it on top of the first page of your immigration form(s).',
        t1145_text15:'1.Applicant/Petitioner Full Last Name',
        t1145_text15_tip:'Please enter last name',
        t1145_text16:'3.Applicant/Petitioner Full Middle Name',
        t1145_text16_tip:'Please enter your name',
        t1145_text17:'5.Mobile Phone Number (Text Message)',
        t1145_text17_tip:'Please enter phone number',
        t1145_text18:'2.Applicant/Petitioner Full First Name',
        t1145_text19:'4.Email Address',
        t1145_text19_tip1:'Please enter email',
        t1145_text19_tip2:'Please enter the correct email address',
        t1145_submit_btn:'submit',
        t1145_submit_btn_tip:'Table G-1145 save successfully',
        t1145_submit_btn_fail1:'The table G-1145 required options are not complete!',
        t1145_submit_btn_fail2:'Fail in keeping!',
    },
    // 
    eb1a_I907:{
        I907_title:'I-907 (Request for Premium Processing Service)',
        I907_P1_title:'P1.Applicant information',
        I907_P1_module1_name:'Information About the Person Filing This Request',
        I907_P1_module1_tip1:'please choose either State or Province',
        I907_P1_module1_question1:'1. Alien Registration Number (A-Number) (if any)',
        I907_P1_module1_question2:'2. USCIS Online Account Number (if any)',
        I907_P1_module1_question3:'3.Family Name (Last Name)',
        I907_P1_module1_question4:'Given Name (First Name)',
        I907_P1_module1_question5:'Middle Name',
        I907_P1_module1_question6:'4. Company or Organization Named in the Related Case',
        I907_P1_module1_tip2:'Mailing Address',
        I907_P1_module1_question7:'In Care Of Name',
        I907_P1_module1_question8:'Street Number and Name',
        I907_P1_module1_question9_select1:'Apt.',
        I907_P1_module1_question9_select2:'Ste.',
        I907_P1_module1_question9_select3:'Flr.',
        I907_P1_module1_question10:'Number',
        I907_P1_module1_question11:'City or Town',
        I907_P1_module1_question12:'State',
        I907_P1_module1_question13:'ZIP Code',
        I907_P1_module1_question14:'Province',
        I907_P1_module1_question15:'Postal Code',
        I907_P1_module1_question16:'Country',
        I907_P1_module1_question17:'6.Is your current mailing address the same as your physical address?',
        I907_P1_module1_question18:'If you answered "No" to Item Number 6., provide your physical address in Item Number 7.',
        I907_P1_module1_question18_select1:'Yes',
        I907_P1_module1_question18_select2:'No',
        I907_P1_module1_question19:'7.Physical Address',
        I907_P1_module1_question20:'Street Number and Name',
        I907_P1_module1_question20_select1:'Apt.',
        I907_P1_module1_question20_select2:'Ste.',
        I907_P1_module1_question20_select3:'Flr.',
        I907_P1_module1_question21:'Number',
        I907_P1_module1_question22:'City or Town',
        I907_P1_module1_question23:'State',
        I907_P1_module1_question24:'ZIP Code',
        I907_P1_module1_question25:'Province',
        I907_P1_module1_question26:'Postal Code',
        I907_P1_module1_question27:'Country',
        I907_P1_module1_question28:'8.Request for Premium Processing Service (select only one box):',
        I907_P1_module1_question29:'I am the petitioner who is filing or has filed a petition eligible for Premium Processing Service.',
        I907_P1_module1_question30_1:'I am the attorney or accredited representative for the petitioner who is filing or has filed a petition eligible for Premium Processing Service.',
        I907_P1_module1_question30_2:'(Complete and submit Form G-28, Notice of Entry of Appearance as Attorney or Accredited Representative, or Form G-28I, Notice of Entry of Appearance as Attorney In Matters Outside the Geographical Confines of the United States, if Form G-28 or Form G-28I has not been submitted with the petition.)',
        I907_P1_module1_question31:'I am the applicant who is filing or has filed an application eligible for Premium Processing Service.',
        I907_P1_module1_question32_1:'I am the attorney or accredited representative for the applicant who is filing or has filed an application eligible for Premium Processing Service. ',
        I907_P1_module1_question32_2:' (Complete and submit Form G-28 or Form G-28I, if Form G-28 or Form G-28I has not been submitted with the application.)',

        I907_P2_title:'P2.Information About the Request',
        I907_P2_module1_name:'Information About the Request',
        I907_P2_module1_question1:'1.Form Number of Related Petition or Application',
        I907_P2_module1_question2:'2.Receipt Number of Related Petition or Application',
        I907_P2_module1_question3:'3.Classification or Eligibility Requested',
        I907_P2_module1_tip1:'4.Petitioner or Applicant in the Related Case',
        I907_P2_module1_question4:'Family Name (Last Name)',
        I907_P2_module1_question5:'Given Name (First Name)',
        I907_P2_module1_question6:'Middle Name',
        I907_P2_module1_tip2:'5.Beneficiary in the Related Case',
        I907_P2_module1_question7:'Family Name (Last Name)',
        I907_P2_module1_question8:'Given Name (First Name)',
        I907_P2_module1_question9:'Middle Name',
        I907_P2_module1_tip3:'6.Name of Point of Contact for the Company or Organization',
        I907_P2_module1_question10:'Family Name (Last Name)',
        I907_P2_module1_question11:'Given Name (First Name)',
        I907_P2_module1_question12:'Middle Name',
        I907_P2_module1_question13:'Position Title',
        I907_P2_module1_question14:'7.Company or Organization IRS Employer Identification Number (EIN) (if any)',
        I907_P2_module1_tip4:'8.Address of Petitioner, Applicant, Company, or Organization Named in Related Case',
        I907_P2_module1_question15:'Street Number and Name',
        I907_P2_module1_question15_select1:'Apt.',
        I907_P2_module1_question15_select2:'Ste.',
        I907_P2_module1_question15_select3:'Flr.',
        I907_P2_module1_question16:'Number',
        I907_P2_module1_question17:'City or Town',
        I907_P2_module1_question18:'State',
        I907_P2_module1_question19:'ZIP Code',
        I907_P2_module1_question20:'Province',
        I907_P2_module1_question21:'Postal Code',
        I907_P2_module1_question22:'Country',

        I907_P3_title:"P3.Requestor's Statement, Contact Information, Declaration, Certification, and Signature",
        I907_P3_module1_name:"Requestor's Statement, Contact Information, Declaration, Certification, and Signature",
        I907_P3_module1_tip1:'NOTE: Read the Penalties section of the Form I-907 Instructions before completing this section.。',
        I907_P3_module1_tip2:'I understand that U.S. Citizenship and Immigration Services (USCIS) will refund the Premium Processing Service fee to the person listed in Part 1. of this request if USCIS does not take an action on the related case within the applicable processing timeframe. I understand that case actions include a referral for investigation of suspected fraud, misrepresentation, or the issuance of an approval notice, a request for evidence, a notice of intent to deny, or a denial notice.',
        I907_P3_module2_name:"Requestor's Statement",
        I907_P3_module2_tip1:'NOTE: Select the box for either Item A. or B. in Item Number 1. If applicable, select the box for Item Number 2.',
        I907_P3_module2_question1:"1.Requestor's Statement Regarding the Interpreter",
        I907_P3_module2_question1_a_1:'A.',
        I907_P3_module2_question1_a_2:'I can read and understand English, and I have read and understand every question and instruction on this request and my answer to every question.',
        I907_P3_module2_question1_b_1:'B.',
        I907_P3_module2_question1_b_2:'The interpreter named in Part 4. read to me every question and instruction on this request and my answer to every question in',
        I907_P3_module2_question1_b_3:', a language in which I am fluent, and I understood everything.',
        I907_P3_module2_question2:"2.Requestor's Statement Regarding the Preparer",
        I907_P3_module2_question2_1:'At my request, the preparer named in Part 5.,',
        I907_P3_module2_question2_2:',prepared this request for me based only upon information I provided or authorized.',
        I907_P3_module3_name:"Requestor's Contact Information",
        I907_P3_module3_question3:"3.Requestor's Daytime Telephone Number",
        I907_P3_module3_question4:"4.Requestor's Mobile Telephone Number (if any)",
        I907_P3_module3_question5:"5.Requestor's Fax Number (if any)",
        I907_P3_module3_question6:"6.Requestor's Email Address (if any)",
        I907_P3_module4_name:"Requestor's Declaration and Certification",
        I907_P3_module4_tip1:'Copies of any documents I have submitted are exact photocopies of unaltered, original documents, and I understand that USCIS may require that I submit original documents to USCIS at a later date. Furthermore, I authorize the release of any information from any and all of my records that USCIS may need to determine my eligibility for the immigration benefit that I seek.',
        I907_P3_module4_tip2:'I furthermore authorize release of information contained in this request, in supporting documents, and in my USCIS records, to other entities and persons where necessary for the administration and enforcement of U.S. immigration law.',
        I907_P3_module4_tip3:'I certify, under penalty of perjury, that all of the information in my request and any document submitted with it were provided or authorized by me, that I reviewed and understand all of the information contained in, and submitted with, my request and that all of this information is complete, true, and correct.',
        I907_P3_module5_name:"Requestor's Signature",
        I907_P3_module5_question7:"7.Requestor's Signature",
        I907_P3_module5_question8:'Date of Signature (mm/dd/yyyy)',
        I907_P3_module5_tip2:'NOTE TO ALL REQUESTORS: If you do not completely fill out this request or fail to submit required documents listed in the Instructions, USCIS may deny your request.',

        I907_P4_title:"P4.Interpreter's Contact Information, Certification, and Signature",
        I907_P4_module1_name:"Interpreter's Full Name",
        I907_P4_module3_tip1:'Provide the following information about the interpreter.',
        I907_P4_module2_name:"Interpreter's Full Name",
        I907_P4_module2_question1:"1.Interpreter's Family Name (Last Name)",
        I907_P4_module2_question2:"Interpreter's Given Name (First Name)",
        I907_P4_module2_question3:"2.Interpreter's Business or Organization Name (if any)",
        I907_P4_module3_name:"Interpreter's Mailing Address",
        I907_P4_module3_question4:'3.Street Number and Name',
        I907_P4_module3_question4_select1:'Apt.',
        I907_P4_module3_question4_select2:'Ste.',
        I907_P4_module3_question4_select3:'Flr.',
        I907_P4_module3_question5:'Number',
        I907_P4_module3_question6:'City or Town',
        I907_P4_module3_question7:'State',
        I907_P4_module3_question8:'ZIP Code',
        I907_P4_module3_question9:'Province',
        I907_P4_module3_question10:'Postal Code',
        I907_P4_module3_question11:'Country',
        I907_P4_module4_name:"Interpreter's Contact Information",
        I907_P4_module4_question12:"4.Interpreter's Daytime Telephone Number",
        I907_P4_module4_question13:"5.Interpreter's Mobile Telephone Number (if any)",
        I907_P4_module4_question14:"6.Interpreter's Email Address (if any)",
        I907_P4_module5_name:"Interpreter's Certification",
        I907_P4_module5_tip2:'I certify, under penalty of perjury, that:',
        I907_P4_module5_question15:'I am fluent in English and',
        I907_P4_module5_tip3:', which is the same language specified in Part 3.,Item B. in Item Number 1., and I have read to this requestor in the identified language every question and instruction on this request and his or her answer to every question.',
        I907_P4_module5_tip4:"The requestor informed me that he or she understands every instruction, question, and answer on the request, including the Requestor's Declaration and Certification, and has verified the accuracy of every answer.",
        I907_P4_module6_name:"Interpreter's Signature",
        I907_P4_module6_question16:"7.Interpreter's Signature",
        I907_P4_module6_question17:'Date of Signature (mm/dd/yyyy)',

        I907_P5_title:'P5.Contact Information, Declaration, and Signature of the Person Preparing this Request, if Other Than the Requestor',
        I907_P5_module1_name:'Contact Information, Declaration, and Signature of the Person Preparing this Request, if Other Than the Requestor',
        I907_P5_module1_tip1:'Provide the following information about the preparer.',
        I907_P5_module2_name:"Preparer's Full Name",
        I907_P5_module2_question1:"1.Preparer's Family Name (Last Name)",
        I907_P5_module2_question2:"Preparer's Given Name (First Name)",
        I907_P5_module2_question3:"Preparer's Business or Organization Name (if any)",
        I907_P5_module3_name:"Preparer's Mailing Address",
        I907_P5_module3_question4:'3.Street Number and Name',
        I907_P5_module3_question4_select1:'Apt.',
        I907_P5_module3_question4_select2:'Ste.',
        I907_P5_module3_question4_select3:'Flr.',
        I907_P5_module3_question5:'Number',
        I907_P5_module3_question6:'City or Town',
        I907_P5_module3_question7:'State',
        I907_P5_module3_question8:'ZIP Code',
        I907_P5_module3_question9:'Province',
        I907_P5_module3_question10:'Postal Code',
        I907_P5_module3_question11:'Country',
        I907_P5_module4_name:"Preparer's Contact Information",
        I907_P5_module4_question12:"4.Preparer's Daytime Telephone Number",
        I907_P5_module4_question13:"5.Preparer's Mobile Telephone Number (if any)",
        I907_P5_module4_question14:"6.Preparer's Email Address (if any)",
        I907_P5_module5_name:"Preparer's Statement",
        I907_P5_module5_tip2:'7.Please select an option',
        I907_P5_module5_question15_a_1:'A.',
        I907_P5_module5_question15_a_2:"I am not an attorney or accredited representative but have prepared this request on behalf of the requestor with the requestor's consent.",
        I907_P5_module5_question15_b_1:'B.',
        I907_P5_module5_question15_b_2:'I am an attorney or accredited representative and my representation of the requestor in this case',
        I907_P5_module5_question16:'extends',
        I907_P5_module5_question17:'does not extend beyond the preparation of this request.',
        I907_P5_module5_question18:'NOTE: If you are an attorney or accredited representative, you may need to submit a completed Form G-28 or Form G-28I with this request.',
        I907_P5_module6_name:"Preparer's Certification",
        I907_P5_module6_tip:"By my signature, I certify, under penalty of perjury, that I prepared this request at the request of the requestor. The requestor then reviewed this completed request and informed me that he or she understands all of the information contained in, and submitted with, his or her request, including the Requestor's Declaration and Certification, and that all of this information is complete, true, and correct. I completed this request based only on information that the requestor provided to me or authorized me to obtain or use.",
        I907_P5_module7_name:"Preparer's Signature",
        I907_P5_module7_question18:"8.Preparer's Signature",
        I907_P5_module7_question19:'Date of Signature (mm/dd/yyyy)',


        I907_P6_title:'P6.Additional Information',
        I907_P6_module1_name:'Additional Information',
        I907_P6_module1_tip1:"If you need extra space to provide any additional information within this petition, use the space below. If you need more space than what is provided, you may make copies of this page to complete and file with this petition or attach a separate sheet of paper. Type or print your name and A-Number (if any) at the top of each sheet; indicate the Page Number, Part Number, and Item Number to which your answer refers; and sign and date each sheet.",
        I907_P6_module1_question1:'Family Name (Last Name)',
        I907_P6_module1_question2:'Given Name (First Name)',
        I907_P6_module1_question3:'Middle Name',
        I907_P6_module1_question4:'2.A-Number (if any)`',
        I907_P6_common_question1:'Page Number',
        I907_P6_common_question2:'Part Number',
        I907_P6_common_question3:'Item Number',
    },
    //1450表
    I1450:{
        I1450_title:'Authorization for Credit Card Transactions',
        I1450_tip_name:'How To Fill Out Form G-1450',
        I1450_tip_list1:'1. Type or print legibly in black ink.',
        I1450_tip_list2:`2. Complete the "Applicant's/Petitioner's/Requester's Information," "Credit Card Billing Information," and "Credit Card Information" sections and sign the authorization. NOTE: The credit card must be issued by a U.S. bank.`,
        I1450_tip_list3:'3. Place your Form G-1450 ON TOP of your application, petition, or request package.',
        I1450_tip_list4:'NOTE: Failure to provide the requested information may result in USCIS and your financial institution not accepting the payment. USCIS cannot process credit card payments without an authorized signature.',
        I1450_tip_list5:'NOTE: Please see the USCIS Form G-1450 website for additional information.',
        I1450_tip_list6:'We recommend that you print or save a copy of your completed Form G-1450 to review in the future and for your records.',
        I1450_tip_list7:'By completing this transaction, you agree that you have paid for a government service and that the filing fee, biometric services fee and all related financial transactions are final and not refundable, regardless of any action USCIS takes on an application, petition, or request. You must submit all fees in the exact amounts. USCIS will charge your credit card up to the amount you authorize below.',
        I1450_tip_list8:'Please refer to the form(s) you are filing for additional information, or you may call the USCIS Customer Contact number at 1-800-375-5283.',
        I1450_tip_list9:'For TTY (deaf or hard of hearing) call: 1-800-767-1833.',
        I1450_module1_name:`Applicant's/Petitioner's/Requester's Information (Full Legal Name)`,
        I1450_module1_question1:'Given Name (First Name)',
        I1450_module1_question2:'Middle Name (if any)',
        I1450_module1_question3:'Family Name (Last Name)',
        I1450_module2_name:`Credit Card Billing Information (Credit Card Holder's Name as it Appears on the Card)`,
        I1450_module2_question1:'Given Name (First Name)',
        I1450_module2_question2:'Middle Name (if any)',
        I1450_module2_question3:'Family Name (Last Name)',
        I1450_module3_name:`Credit Card Holder's Billing Address:`,
        I1450_module3_question1:'Street Number and Name',
        I1450_module3_question1_select1:'Apt.',
        I1450_module3_question1_select2:'Ste.',
        I1450_module3_question1_select3:'Flr.',
        I1450_module3_question2:'Number',
        I1450_module3_question3:'City or Town',
        I1450_module3_question4:'State',
        I1450_module3_question5:'ZIP Code',
        I1450_module4_name:`Credit Card Holder's Signature and Contact Information:`,
        I1450_module4_question1:`Credit Card Holder's Signature`,
        I1450_module4_question2:`Credit Card Holder's Daytime Telephone Number`,
        I1450_module4_question3:`Credit Card Holder's Email Address`,
        I1450_module5_name:'Credit Card Information',
        I1450_module5_question1:'Credit Card Number',
        I1450_module5_question2:'Credit Card Expiration Date',
        I1450_module5_question3:'Credit Card Type',
        I1450_module5_question4:'Authorized Payment Amount',

        I1450_input_tip1:'please enter',
        I1450_input_tip2:'please enter',
        I1450_input_tip3:'please enter',
        I1450_input_tip4:'please enter',
        I1450_input_tip5:'please enter',
        I1450_input_tip6:'please enter',
        I1450_input_tip7:'please enter',
        I1450_input_tip8:'please enter',
        I1450_input_tip9:'please enter',
        I1450_input_tip10:'please enter',
        I1450_input_tip11:'please enter',
        I1450_input_tip12:'please enter',
        I1450_input_tip13:'please enter',
        I1450_input_tip14:'please enter',

    } ,
        // 个人中心
    personal:{
        title:'Personal Center',
        selectDropDown1:'Personal Information',
        selectDropDown2:'Massage Notice',
        selectDropDown3:'My Plan',
        selectDropDown4:'Purchasing History',
        selectDropDown5:'Log Out',

        // 个人信息 PM
        PM_box1_title1:'mobile number',
        PM_box1_btn1:'change mobile number',
        PM_box1_title2:'email address',
        PM_box1_btn2:'bind email address',
        PM_box2_title:'log in history',
        PM_box3_btn:'Account Deactivation',

        PM_dia1_title:'Are you sure you want to deactivate your account?',
        PM_dia1_tip:'After deactivation, all information and data will be permanently erased. Ongoing service entitlements will be automatically forfeited. Please confirm again if you wish to proceed with deactivation.',
        PM_dia1_btn1:'Not now',
        PM_dia1_btn2:'Deactivation',

        PM_dia2_title:'Before deactivating, please confirm the following:',
        PM_dia2_tip1:'You can only deactivate the account that belongs to you.',
        PM_dia2_tip2:'After deactivation, all entitlements will be erased and become invalid.',
        PM_dia2_tip3:'Following deactivation, all data and records will be inaccessible and irretrievable.',
        PM_dia2_tip4:'Once the account deactivation is completed, it cannot be restored.',
        PM_dia2_tip5:'Post-deactivation, you won’t be able to log in or use your account.',
        PM_dia2_tip6:'The account deactivation process takes 7 days. During this period, refrain from logging in; otherwise, the account will be reinstated, and the deactivation will not be successful.',
        PM_dia2_btn1:'Not now',
        PM_dia2_btn2:'Confirm Deactivation',

        PM_dia3_tip1:'The account has been successfully logged out. You are about to log out',
        PM_dia3_btn1:'confirm',

        PM_SMS_title:'change mobile number',
        PM_SMS_tip1:'type in',
        PM_SMS_tip2:'Please Enter Verification Code',
        PM_SMS_btn1:'Get Code',
        PM_SMS_btn2:'cancel',
        PM_SMS_btn3:'confirm',
        PM_SMS_btn4:'resent',

        MR_title:'Notification ',
        MR_table_list1:'Message ',
        MR_table_list2:'Time ',
        MR_table_list3:'Action ',
        MR_table_list3_btn1:'Details ',
        MR_table_list3_btn2:'Delete ',
        MR_table_list3_allDel:'Delete ',
        MR_tip1:'successfully deleted ',

        MP_box1_title:'Services Purchased',
        MP_box1_tip1:'No plan at the moment',
        MP_box1_btn1:'Preview',
        MP_box1_btn2:'Contrast preview',
        MP_box1_btn3:'Go DIY',
        MP_box1_btn4:'Generate and Print Document',
        MP_box1_btn5:'Printing file is being produced',
        MP_box1_btn6:'Download and Print Document',
        MP_box1_btn7:'Generate and Print Again',

        MP_dia1_title:'Download Files',
        MP_dia1_tip:'All the application materials have been successfully uploaded. Kindly confirm if you wish to proceed with downloading and printing these PDF files.',
        MP_dia1_btn1:'Cancel',
        MP_dia1_btn2:'Download Now',

        MP_box2_title:'My Benefits',
        MP_box2_table1:'service',
        MP_box2_table2:'status',
        MP_box2_table3:'submit time',
        MP_box2_table4:'action',
        MP_box2_table4_btn1:'Cancel',
        MP_box2_table4_btn2:'Submit',
        MP_box2_table4_btn3:'Resubmit',
        MP_box2_table4_btn4:'Audit Result',

        MP_box3_title:'Recent Modification Records',
        MP_box3_table1:'IDX',
        MP_box3_table2:'Plan Name',
        MP_box3_table3:'Content',
        MP_box3_table4:'Time',
        MP_box3_table5:'Action',
        MP_box3_table5_btn1:'View',

        MP_dia_title:'Successfully submitted',
        MP_dia_tip1:'1.Please wait for generating the PDF . ',
        MP_dia_tip2:'(usually 10 mins)',
        MP_dia_tip3:'2.You can download a PDF after the generation is complete. ',
        MP_dia_tip4:'You can download a PDF after the generation is complete. ',
        MP_dia_btn1:'Got it',

        PR_title:'Purchase Records',
        PR_table_list1:'IDX',
        PR_table_list2:'Order Type',
        PR_table_list3:'Order no.',
        PR_table_list4:'Creation Time',
        PR_table_list5:'Payment Time',
        PR_table_list6:'Amount',
        PR_table_list7:'Status',
        PR_table_list7_state1:'Unpaid',
        PR_table_list7_state2:'Processing',
        PR_table_list7_state3:'Partially paid',
        PR_table_list7_state4:'Complete',
        PR_table_list7_state5:'Payment failed',
        PR_table_list7_state6:'Canceled',
        PR_table_list7_state7:'undefined',
        PR_table_list8:'Operation',
        PR_table_list8_btn1:'Proceed to Pay',
        PR_table_list8_btn2:'Cancel Order',

        PR_dia_title:'Cancel Order',
        PR_dia_tip:'confirm order canceling？',
        PR_dia_btn1:'cancel',

        PR_dia2_title:'Complete Payment'
    },
    // 支付页面
    payment:{
        // paymentPage
        PP_pay1_title1:'Convenient Payment',
        PP_pay1_title2:'Service Payment',
        PP_pay1_title3:'Selected Service',
        PP_pay1_list1:'Service Duration',
        PP_pay1_list1_a:'1.5 years',
        PP_pay1_list2:'Expiration Date',
        PP_pay1_list3:'Payment Amount',
        PP_pay1_list4:'Discounts',
        PP_pay1_list5:'Exchange Rate',
        PP_pay1_list6:'Order Total',
        PP_pay1_list7:'Amount Due',
        PP_pay1_btn1:'Alipay Payment(￥)',
        PP_pay1_btn2:'PayPal Payment($)',
        PP_pay1_tip1:'Note:',
        PP_pay1_tip2:'If exceeding the daily payment limit, please ensure there’s enough balance in your Alipay account before proceeding with the payment.',

        pp_pay2_title1:'Corporate Remittance（￥）',
        pp_pay2_title2:'Payment Transfer Process:',
        pp_pay2_step1:'Step 1: Corporate remittance',
        pp_pay2_list1_a:'Account Name',
        pp_pay2_list1_b:': Chongqing K.R.  Technology Co., Ltd.',
        pp_pay2_list2_a:'Bank',
        pp_pay2_list2_b:': CITIC Bank Chongqing Jinyu Branch',
        pp_pay2_list3_a:'Account Number',
        pp_pay2_list3_b:': 8111 2010 1260 0567 967',
        pp_pay2_list4_a:'Payment Amount',
        pp_pay2_list4_b:'：$',
        pp_pay2_list5_a:'Exchange Rate',
        pp_pay2_list6_a:'Remittance Amount',
        pp_pay2_list7_a:'Remittance Note',
        pp_pay2_list7_tip:'Note: Please be sure to include your currently logged-in phone number as a remark when making the corporate remittance.',
        pp_pay2_step2:'Step 2: Activate Service',
        pp_pay2_tip1:'After completing the transfer, please click "Payment Completed" on the page. Your account will be automatically upgraded within 1 business day upon receiving the remittance.',
        pp_pay2_tip2_a:'For premium processing, please contact our ',
        pp_pay2_tip2_b:'online customer service',
        pp_pay2_tip2_c:'or send relevant request to',
        pp_pay2_tip2_d:'after completing the remittance.',
        // pp_pay2_tip2_d:'1@kr.run', 邮箱不能用双语
        PP_pay2_btn3:'Back',

        PP_pay2_btn1:'payment transfer completed',
        PP_pay2_btn2:'check for payment method ',

        PP_all_tip1:'Please wait patiently....',
        PP_all_tip2:'获取微信支付订单失败，请稍后重试',
        PP_all_tip3:'获取推荐价格失败',

        PP_discounts_top1:'rights and interests',
        PP_discounts_tip1:'Please enter the promotional referral code.',
        PP_discounts_btn1:'confirm',
        PP_discounts_tip2:'Use the referral code to enjoy an exclusive discount, valid only for the first-time purchasing.',
    },
    // 工作台 DIY
    workbench:{
        right_top_title:'Personal Center',
        right_top_list1:'Personal Information',
        right_top_list2:'Massage Notice',
        right_top_list3:'My Plan',
        right_top_list4:'Purchasing History',

        W_diy_step1_tip1:'Before filling out the',
        W_diy_step1_tip2:'form, please carefully read the official application instructions (',
        W_diy_step1_tip3:'Application Instructions)',
        W_diy_step1_tip4:'It includes, but is not limited to, applicant requirements, form guidance, and filling requirements. You can download it and review it thoroughly.',

        W_diy_step1_tip5:'If you haven’t purchase ',
        W_diy_step1_tip6:'Translation Service',
        W_diy_step1_tip7:', please fill out in English.',

        //证据
        W_diy_step2_tip1:'All materials submitted for the application must be in English.',
        W_diy_step2_tip2:'If your original documents are in Chinese, in addition to the original Chinese files, corresponding translated English files must be included in the PDF to be uploaded. ',
        W_diy_step2_tip3:'If your original documents are already in English, there’s no need for further translation. ',
        W_diy_step2_tip4:'If you’ve purchased ',
        W_diy_step2_tip5:'translation services',
        W_diy_step2_tip6:', and we will provide the English translations for you. ',

        //证据类型
        W_diy_step2_evi1_tip1:'1.*Copies of certificates of awards, honors or rankings, and photos of trophies and medals. If the certificate is lost, you can also provide a letter of certification from the awarding organization or the organizing committee.',
        W_diy_step2_evi1_tip2:'2.A copy of the award notification.',
        W_diy_step2_evi1_tip3:'3.A list of the public announcement of the award by the organizer.',
        W_diy_step2_evi1_tip4:'4.Media reports on the awards or the winners.',
        W_diy_step2_evi1_tip5:'5.Photographs of the award presentation site.',
        W_diy_step2_evi1_tip6:'6.*The introduction of the organizer and the evaluation of the award organization.',
        W_diy_step2_evi1_tip7:'7.*Introduction to the awards’ domestic and international influence and status.',
        W_diy_step2_evi1_tip8:'8.*Introduction to the selection criteria, award conditions, entry qualifications, and campaign procedures of the awards.',
        W_diy_step2_evi1_tip9:'9.List of judges and introduction to the judges.',
        W_diy_step2_evi1_tip10:'10.*Introduction to the awards setting, including the awards type and winner numbers.',
        W_diy_step2_evi1_tip11:'11.*Introduction to list of other winners and candidates.',
        W_diy_step2_evi1_tip12:'12.Introduction to list of previous awards and some of the celebrity winners.',

        W_diy_step2_evi2_tip1:'1.*Copy of membership card or letter of appointment.',
        W_diy_step2_evi2_tip2:'2.Letter of invitation, appointment or certification from the association.',
        W_diy_step2_evi2_tip3:'3.*Official presentation of the association.',
        W_diy_step2_evi2_tip4:"4.*Introduction to the association's social influence and reputation.",
        W_diy_step2_evi2_tip5:"5.Media coverage of the association's prestige, influence, and significant activities.",
        W_diy_step2_evi2_tip6:'6.*Membership conditions, eligibility requirements, and evaluation procedures.',
        W_diy_step2_evi2_tip7:'7.*Evidence shows that membership is only possible due to candidate’s outstanding achievements.',
        W_diy_step2_evi2_tip8:'8.Introduction to the duties of the member, director, or other position in the association, if any.',
        W_diy_step2_evi2_tip9:'9.*Introduction to the qualifications of the person reviewing for membership.',
        W_diy_step2_evi2_tip10:'10.A letter of review from the association, or a letter of recommendation from the organization',

        W_diy_step2_evi3_tip1:"1.*Copies or scans of the original magazine/newspaper media coverage, which should include the magazine front page/newspaper name with report title, date, and reporter's name.",
        W_diy_step2_evi3_tip2:'2.*Screenshot of the online report’s original page.',
        W_diy_step2_evi3_tip3:'3.*Screenshot of the TV screen containing the station logo, the name of the program and the name of the beneficiary.',
        W_diy_step2_evi3_tip4:'4.*Recordings of radio and audio reports and translations of the text version.',
        W_diy_step2_evi3_tip5:'5.*Media introduction with information on distribution area, circulation and readership.',
        W_diy_step2_evi3_tip6:'6.*Third-party statistical comparison data on circulation.',
        W_diy_step2_evi3_tip7:"7.If the report made by a well-known journalist, attach the journalist's information.",

        W_diy_step2_evi4_tip1:'1.*Invitation and completion letter, letter of appointment and certificate of the reviewer or assessor.',
        W_diy_step2_evi4_tip2:"2.*Inviting party's profile, influence and description of the review project.",
        W_diy_step2_evi4_tip3:'3.*Relevant documents or announcements of the evaluation about the results of reviewing/assessing/judging.',
        W_diy_step2_evi4_tip4:'4.*Evidence of completion of the review, evaluation, etc., such as thanks letters, and certificates of honorarium for serving as a reviewer or jury member.',
        W_diy_step2_evi4_tip5:'5.Photographs of the reviewing/judging site.',
        W_diy_step2_evi4_tip6:'6.Introductions to qualification of serving as judges and evaluators at the same time with you.',
        W_diy_step2_evi4_tip7:'7.Introductions to conditions of serving as judges and evaluators.',
        W_diy_step2_evi4_tip8:'8.Introductions to the well-known contestants or persons being evaluated.',

        W_diy_step2_evi5_title1:'Patents and software copyrights ',
        W_diy_step2_evi5_title1_tip1:'1.*Patents and software copyrights ',
        W_diy_step2_evi5_title1_tip2:'2.Specifications and pictures of patented products.',
        W_diy_step2_evi5_title1_tip3:'3.*Authorization, transfer agreement and contract of patents.',
        W_diy_step2_evi5_title1_tip4:'4.*Sales range, amount and volume of patented products. ',
        W_diy_step2_evi5_title1_tip5:'5.*Introduction to industry status, originality and sophistication, and a wide range of applications of patented products.',
        W_diy_step2_evi5_title1_tip6:'6.Subsidies, grants or other rewards given by the government or organizations.',
        W_diy_step2_evi5_title1_tip7:'7.Relevant media reports.',

        W_diy_step2_evi5_title2:'Formulation of industry standards',
        W_diy_step2_evi5_title2_tip1:'1.*Introduction to your experience on formulating standards/regulations of technology, industry, and product nationally or internationally.',
        W_diy_step2_evi5_title2_tip2:'2.*Applicable scope and region of the standards/regulations.',
        W_diy_step2_evi5_title2_tip3:'3.Introduction to other members of the standards/regulations development committee.',
        W_diy_step2_evi5_title2_tip4:'4.Relevant media reports and organization announcements.',

        W_diy_step2_evi5_title3:'Innovations on genres (study, music, art, etc.), sports and education',
        W_diy_step2_evi5_title3_tip1:'1.*Innovations in the art movement.',
        W_diy_step2_evi5_title3_tip2:'2.*Documentation of innovations in sports training or educational methods that have advanced the profession.',
        W_diy_step2_evi5_title3_tip3:'3.*Evidence on the significance of innovations.',
        W_diy_step2_evi5_title3_tip4:'4.Industry data, reports, and media coverage of original contributions.',

        W_diy_step2_evi5_title4:'Scientific and academic achievements',
        W_diy_step2_evi5_title4_tip1:'1.*Evidence of citation searches and citation numbers of published academic papers.',
        W_diy_step2_evi5_title4_tip2:'2.Influence factors, and positive comments within the field.',
        W_diy_step2_evi5_title4_tip3:'3.*Recognition by other experts in the field.',
        W_diy_step2_evi5_title4_tip4:'4.Reprint requests of your publications.',
        W_diy_step2_evi5_title4_tip5:'5.Official recognition and media reports of scientific and technological achievements.',
        W_diy_step2_evi5_title4_tip6:'6.Grants and evaluation summary reports from national/international research funds.',
        W_diy_step2_evi5_title4_tip7:'7.*Evidence of application and transformation of scientific and technological achievements.',
        W_diy_step2_evi5_title4_tip8:'8.Awards of invention, discovery, or patent.',

        W_diy_step2_evi5_title5:'Business',
        W_diy_step2_evi5_title5_tip1:'1.*Originality of business theory and implementation.',
        W_diy_step2_evi5_title5_tip2:'2.*Innovations of management methods and implementation.',
        W_diy_step2_evi5_title5_tip3:'3.*Innovations of business project engineering.',
        W_diy_step2_evi5_title5_tip4:'4.*Innovations of production processes, products, technologies, applications, services, etc.',
        W_diy_step2_evi5_title5_tip5:'5.Innovations in public relations and communication, marketing activities, brand management, etc.',

        W_diy_step2_evi5_title6:'Letter of recommendation',
        W_diy_step2_evi5_title6_tip1:'1.*Specific description from influential figures in the industry to affirm your original major contribution.',
        W_diy_step2_evi5_title6_tip2:'2.Recognition from experts to prove the originality and significance.',
        W_diy_step2_evi5_title6_tip3:'3.Certificate letter from government, organization, or agency.',
            // 
        W_diy_step2_evi6_title1:'Papers/Academic articles',
        W_diy_step2_evi6_title1_tip1:'1.*Photocopy/scan of the cover of the media in a published article and the first page of the thesis section, where you can see the name of the article, its content, authors and date.',
        W_diy_step2_evi6_title1_tip2:"2.*Introductions to the publication media's circulation area, circulation volume, readership, and industry status.",
        W_diy_step2_evi6_title1_tip3:'3.*Statistical comparison of third-party media circulation.',
        W_diy_step2_evi6_title1_tip4:'4.Media, experts and other reports or comments on the article.',
        W_diy_step2_evi6_title1_tip5:'5.Invitation letters to publish academic reports at top or important academic conferences and forums in the industry, collected proceedings, photos, videos or media reports of speeches.',

        W_diy_step2_evi6_title2:'Professional books and publications',
        W_diy_step2_evi6_title2_tip1:`1.*Photocopies or scans of the cover of the book (including the author's name page) and the page with the issue number and bar code.`,
        W_diy_step2_evi6_title2_tip2:'2.*In case of co-authorship, the beneficiary should provide evidence that he/she is responsible for part of the book writing in addition to the above.',
        W_diy_step2_evi6_title2_tip3:'3.*Brief introduction to the publishing house and its reputation, or other documents that can prove influence.',
        W_diy_step2_evi6_title2_tip4:'4.Introductions to other well-known publications by the press.',
        W_diy_step2_evi6_title2_tip5:'5.*Circulation, sales, printing lots, number of reprints, and range of distribution of the book.',
        W_diy_step2_evi6_title2_tip6:'6.Reports or comments on the book by the media, experts, etc.',
        W_diy_step2_evi6_title2_tip7:'7.If the book was cited and reprinted, provide documents showing citation and reprint data, and permission to reprint documents.',
        W_diy_step2_evi6_title2_tip8:'8.*Evidence of influence in terms of drawing extensive comments and discussions in the industry.',

        W_diy_step2_evi6_title3:'Works published on website, network, subscription account, or blog',
        W_diy_step2_evi6_title3_tip1:'1.*Screenshots of the original page.',
        W_diy_step2_evi6_title3_tip2:'2.*Notarized Data on the number of views and downloads.',
        W_diy_step2_evi6_title3_tip3:'3.*Provide a brief introduction to the publishing platform and its reputation and influence.',

                // 
        W_diy_step2_evi7_tip1:'1.*Introduction to the organizer and its reputation.',
        W_diy_step2_evi7_tip2:'2.*Introduction to the influence of the exhibition.',
        W_diy_step2_evi7_tip3:'3.Introduction to the media coverage of the exhibition.',
        W_diy_step2_evi7_tip4:'4.*Evidence that you are the author of the exhibition, such as textual introduction, or photograph on signature of the exhibition work.',
        W_diy_step2_evi7_tip5:'5.*Exhibition certificate, invitation letter, and exhibition agreement.',
        W_diy_step2_evi7_tip6:'6.*Pictures, posters, and video screenshots of the exhibition that reflect its content, venue, etc.',
        W_diy_step2_evi7_tip7:'7.Evidence of your artwork was collected and published by well-known organizations.',
        W_diy_step2_evi7_tip8:'8.Opinions, comments, and recognition from experts or officials.',

        W_diy_step2_evi8_title1:'Evidence of the institution is with great reputation.',
        W_diy_step2_evi8_title1_tip1:'1.*Brochures or websites that introduce the company/institution.',
        W_diy_step2_evi8_title1_tip2:'2.*Honors, awards, titles, etc. received by the company/institution.',
        W_diy_step2_evi8_title1_tip3:'3.*Influential social activities of the company/institution.',
        W_diy_step2_evi8_title1_tip4:'4.Data on industry ranking.',
        W_diy_step2_evi8_title1_tip5:'5.Commercial performance of the company/institution (statements, annual reports, sales, agreements and contracts).',
        W_diy_step2_evi8_title1_tip6:'6.Industry certifications, official/association certification and other documents that prove outcomes of technological R&D.',
        W_diy_step2_evi8_title1_tip7:"7.Relevant media reports on the company/institution's significant contributions and outstanding achievements.",
        W_diy_step2_evi8_title1_tip8:'8.Government grants or recognition, patents or software copyrights of the company/institution.',

        W_diy_step2_evi8_title2:'Evidence of leadership',
        W_diy_step2_evi8_title2_tip1:'1.*A letter of appointment, commission, certification, or employment contract showing your position.',
        W_diy_step2_evi8_title2_tip2:'2.*Description of job duties that show leadership of this position.',
        W_diy_step2_evi8_title2_tip3:'3.Internal awards for leadership.',
        W_diy_step2_evi8_title2_tip4:'4.*A letter from a senior member of the company/institution (chairman, vice chairman, president, vice president, general manager) containing detailed and substantiated information explaining why you play as a leader.',
        W_diy_step2_evi8_title2_tip5:'5.Media reports of your leadership in the company/institution.',

        W_diy_step2_evi8_title3:'Evidence of playing a key role',
        W_diy_step2_evi8_title3_tip1:"1.*Evidence of why the role you have played is key for the company/institution's management, operations, or construction in comparison with others who have been appointed to related positions.",
        W_diy_step2_evi8_title3_tip2:'2.Description of job duties that show the key role of this position.',
        W_diy_step2_evi8_title3_tip3:'3.*Evidence of specific work performance and examples that can show you have played a key role, focusing on practical work and the vital contribution you have made to the company/institution.',
        W_diy_step2_evi8_title3_tip4:'4.*A letter from a senior member of the company/institution (chairman, vice chairman, president, vice president, general manager) containing detailed and substantiated information explaining why you have played a key role.',
        W_diy_step2_evi8_title3_tip5:'5.Media reports of the key role you played in the company/institution.',
        W_diy_step2_evi8_title3_tip6:'6.Evidence of the key role you played as an independent consultant in the company/institution.',

                // 
        W_diy_step2_evi9_tip1:'1.*Tax returns, pay slips, and bank statements of salary flow for the past three years.',
        W_diy_step2_evi9_tip2:'2.*Employment contract with salary figures, letter of employment salary certification from the company.',
        W_diy_step2_evi9_tip3:'3.*Remuneration proof, such as bonuses, commissions, allowances and dividends.',
        W_diy_step2_evi9_tip4:'4.*Other income or services remuneration received in the stated professional field.',
        W_diy_step2_evi9_tip5:'5.Evidence on high royalties and fees for manuscripts, high-priced paintings, lectures, consulting services, and so on.',
        W_diy_step2_evi9_tip6:'6.Media reports about other outstanding people in your stated area receiving significantly higher salaries for comparison.',
        W_diy_step2_evi9_tip7:'7.Report or list from a reliable professional institute that produces salary range for expertise in your professional field.',
        W_diy_step2_evi9_tip8:'8.*Provide a survey report on regional or positional salary.',
        W_diy_step2_evi9_tip9:'9.*Comparison from authoritative reports and databases of your salary with your peers.',

        W_diy_step2_evi10_tip1:'1.*Introduction and publicity materials to your stated performing arts (film, music, opera, etc.).',
        W_diy_step2_evi10_tip2:'2.*The box-office gross sales record of your stated performing arts certified by theater parties and distributors.',
        W_diy_step2_evi10_tip3:'3.*total sales amount, volume and distribution of DVD and CD. ',
        W_diy_step2_evi10_tip4:'4.Amount of broadcasting rights and copyright sales.',
        W_diy_step2_evi10_tip5:'5.Media reports on the commercial success of your stated performing arts (including sales amount).',
        W_diy_step2_evi10_tip6:'6.The amount of online paid downloads.',

        W_diy_step2_evi11_tip1:'Suggestions on Collecting Other Evidence Materials ',
        W_diy_step2_evi11_tip2:'Supporting materials for writing petition letter, like industrial report, official data or government statistics etc.',

        W_niw_step2_evi_high1_tip1:'1.Official academic certificate.',
        W_niw_step2_evi_high1_tip2:'2.Scanned copy of graduation certificate and degree certificate.',
        W_niw_step2_evi_high1_tip3:'3.For Chinese petitioners, provide Chinese and English versions of verification report by Chinese Credentials Verification.',
        W_niw_step2_evi_high1_tip4:'4.World Education Services (WES) evaluation report.',
        W_niw_step2_evi_high1_tip5:'5.Transcript, colleges/universities introduction, professional fields introduction, etc.',

        W_niw_step2_evi_high2_tip1:`1.If you have a Bachelor's Degree, you must demonstrate a minimum of 5 years of full-time work experience in your stated field to demonstrate an equivalent Master's Degree competency.`,
        W_niw_step2_evi_high2_tip2:'2.Letters of verification or certificates of separation from all former/current working units.',
        W_niw_step2_evi_high2_tip3:'Certificates should include the name, brief introduction, address, and contact number of your working unit, and provide a detailed presentation with the position you held, the projects you led, your hiredate, and your cumulative period of the job.',
        W_niw_step2_evi_high2_tip4:'3.Also, you can provide the position announcement of your working unit. (e.g. introduction page for school teacher, or announcement page for hospital physician)',

        W_niw_step2_evi_high3_tip1:'Demonstrate that the petitioner has sufficient qualifications and accomplishments in the the stated professional field, including but not limited to the following perspectives. You may provide the evidence appropriately.',
        W_niw_step2_evi_high3_tip2:'',
        W_niw_step2_evi_high3_tip3:'1. Publication in your stated professional field',//3
        W_niw_step2_evi_high3_tip4:'Papers/Academic articles',//4
        W_niw_step2_evi_high3_tip5:'1.*Photocopy/scan of the cover of the media in a published article and the first page of the thesis section, where you can see the name of the article, its content, authors and date.',
        W_niw_step2_evi_high3_tip6:`2.*Introductions to the publication media's circulation area, circulation volume, readership, and industry status.`,
        W_niw_step2_evi_high3_tip7:`3.*Statistical comparison of third-party media circulation.`,
        W_niw_step2_evi_high3_tip8:`4.The number of citations for papers/academic articles compared with those for equivalent articles.`,
        W_niw_step2_evi_high3_tip9:'5.Introduction to published journal academic factor.',
        W_niw_step2_evi_high3_tip10:'6.Media, experts and other reports or comments on the article.',
        W_niw_step2_evi_high3_tip11:'7.Invitation letters to publish academic reports at top or important academic conferences and forums in the industry, collected proceedings, photos, videos or media reports of speeches.',
        W_niw_step2_evi_high3_tip12:'Professional books and publications',//12
        W_niw_step2_evi_high3_tip13:`1.*Photocopies or scans of the cover of the book (including the author's name page) and the page with the issue number and bar code.`,
        W_niw_step2_evi_high3_tip14:'2.*In case of co-authorship, the beneficiary should provide evidence that he/she is responsible for part of the book writing in addition to the above.',
        W_niw_step2_evi_high3_tip15:'3.Official introduction to co-author(s).',
        W_niw_step2_evi_high3_tip16:'4.*Brief introduction to the publishing house and its reputation, or other documents that can prove influence.',
        W_niw_step2_evi_high3_tip17:'5.Introductions to other well-known publications by the press.',
        W_niw_step2_evi_high3_tip18:'6.*Circulation, sales, printing lots, number of reprints, and range of distribution of the book.',
        W_niw_step2_evi_high3_tip19:'7.Reports or comments on the book by the media, experts, etc.',
        W_niw_step2_evi_high3_tip20:'8.If the book was cited and reprinted, provide documents showing citation and reprint data, and permission to reprint documents.',
        W_niw_step2_evi_high3_tip21:'9.*Evidence of influence in terms of drawing extensive comments and discussions in the industry.',
        W_niw_step2_evi_high3_tip22:'Patents',//22
        W_niw_step2_evi_high3_tip23:'1.*Copy of patent and software copyright certificates.',
        W_niw_step2_evi_high3_tip24:'2.Patent value assessment report.',
        W_niw_step2_evi_high3_tip25:'3.Screenshot of patent registration website.',
        W_niw_step2_evi_high3_tip26:'4.Specifications and pictures of patented products.',
        W_niw_step2_evi_high3_tip27:'5.*Authorization, transfer agreement and contract of patents.',
        W_niw_step2_evi_high3_tip28:'6.*Sales range, amount and volume of patented products. ',
        W_niw_step2_evi_high3_tip29:'7.*Introduction to industry status, originality and sophistication, and a wide range of applications of patented products.',
        W_niw_step2_evi_high3_tip30:'8.Subsidies, grants or other rewards given by the government or organizations.',
        W_niw_step2_evi_high3_tip31:'9.Relevant media reports.',
        W_niw_step2_evi_high3_tip32:'',
        W_niw_step2_evi_high3_tip33:'2. Awards and obtained commendations',//33
        W_niw_step2_evi_high3_tip34:'1.*Copies of certificates of awards, honors or rankings, and photos of trophies and medals. If the certificate is lost, you can also provide a letter of certification from the awarding organization or the organizing committee.',
        W_niw_step2_evi_high3_tip35:'2.A copy of the award notification.',
        W_niw_step2_evi_high3_tip36:'3.A list of the public announcement of the award by the organizer.',
        W_niw_step2_evi_high3_tip37:'4.Media reports on the awards or the winners.',
        W_niw_step2_evi_high3_tip38:'5.Photographs of the award presentation site.',
        W_niw_step2_evi_high3_tip39:'6.*The introduction of the organizer and the evaluation of the award organization.',
        W_niw_step2_evi_high3_tip40:'7.*Introduction to the awards’ domestic and international influence and status.',
        W_niw_step2_evi_high3_tip41:'8.*Introduction to the selection criteria, award conditions, entry qualifications, and campaign procedures of the awards.',
        W_niw_step2_evi_high3_tip42:'9.List of judges and introduction to the judges.',
        W_niw_step2_evi_high3_tip43:'10.*Introduction to the awards setting, including the awards type and winner numbers.',
        W_niw_step2_evi_high3_tip44:'11.*Introduction to list of other winners and candidates.',
        W_niw_step2_evi_high3_tip45:'12.Introduction or relevant reports to list of previous awards and some of the celebrity winners.',
        W_niw_step2_evi_high3_tip46:'',
        W_niw_step2_evi_high3_tip47:'3. Evidence of professional practice',//47
        W_niw_step2_evi_high3_tip48:'1.A copy of the license or certificate.',
        W_niw_step2_evi_high3_tip49:'2.Introduction to the institution that issued the license or certificate. You can request an official brochure from the organization, or provide a profile of the official website.',
        W_niw_step2_evi_high3_tip50:'3.Details of the criteria for the issuance of the license or certificate.',
        W_niw_step2_evi_high3_tip51:'',
        W_niw_step2_evi_high3_tip52:'4. Salary/remuneration',//52
        W_niw_step2_evi_high3_tip53:'1.*Tax returns, pay slips, and bank statements of salary flow for the past three years.',
        W_niw_step2_evi_high3_tip54:'2.*Employment contract with salary figures, letter of employment salary certification from the company.',
        W_niw_step2_evi_high3_tip55:'3.*Evidence of remuneration, such as bonuses, commissions, allowances and dividends.',
        W_niw_step2_evi_high3_tip56:'4.Evidence on high royalties and fees for manuscripts, high-priced paintings, lectures, consulting services, and so on.',
        W_niw_step2_evi_high3_tip57:'5.Media reports about other outstanding people in your stated area receiving significantly higher salaries for comparison.',
        W_niw_step2_evi_high3_tip58:'6.Report or list from a reliable professional institute that produces salary range for expertise in your professional field.',
        W_niw_step2_evi_high3_tip59:'7.*Provide a survey report on regional or positional salary.',
        W_niw_step2_evi_high3_tip60:'8.*Comparison from authoritative reports and databases of your salary with your peers.',
        W_niw_step2_evi_high3_tip61:'',
        W_niw_step2_evi_high3_tip62:'5. Membership of an important organization of the stated professional field',//62
        W_niw_step2_evi_high3_tip63:'Evidence that you are a member of a significant association in your stated professional field, including but not limited to:',
        W_niw_step2_evi_high3_tip64:'1.Evidence of membership: membership card, letter of appointment, letter of invitation, and certificate issued by the association.',
        W_niw_step2_evi_high3_tip65:'2.Official introduction of the association, and constitution of the association.',
        W_niw_step2_evi_high3_tip66:'3.Selection procedures for becoming a member, reviewers, admission criteria, etc.',
        W_niw_step2_evi_high3_tip67:'4.Media reports about the association, introduction of the relevant person in charge of the association, and other members of the same rank as you.',
        W_niw_step2_evi_high3_tip68:'5.If you are the leader of the association, provide a self-description of your duties.',
        W_niw_step2_evi_high3_tip69:'6. Evidence of Recognition of petitioner’s major contribution in the stated professional field',//69
        W_niw_step2_evi_high3_tip70:'1. Successfully led projects',
        W_niw_step2_evi_high3_tip71:'(Provide evidence of relevant signed contracts, certificates issued by the company, project introduction, photos, internal documents, market adoption examples and influence, media reports of your related leading project, etc. You can make a point elaboration followed by project 1, Project 2, and so on. )',
        W_niw_step2_evi_high3_tip72:'2. Achievements in your stated professional field',
        W_niw_step2_evi_high3_tip73:'(Provide evidence of industry impact of your work, citation request of your work, corresponding media coverage, award recognition, etc.)',
        W_niw_step2_evi_high3_tip74:'3. Evidence of involvement in developing or influencing industry standards',
        W_niw_step2_evi_high3_tip75:'(Provide evidence of invitation letter for industry standard formulation, final document of standard, list of attendees, introduction, photos, report, etc.)',
        W_niw_step2_evi_high3_tip76:'4. Commendations, awards, expert testimonies, letters of recommendation, etc., obtained for your expertise.',
        W_niw_step2_evi_high3_tip77:'(Provide evidence of Certificates, reports, photos, commendation organizations, etc.)',
        W_niw_step2_evi_high3_tip78:'5. Other evidence of contribution to the industry.',

        W_niw_step2_evi_high4_tip1:'Evidence supporting the description of your stated area and its importance in the petition letter.',
        W_niw_step2_evi_high4_tip2:'Including but not limited to:',
        W_niw_step2_evi_high4_tip3:'1.Industry market research reports, development trends, importance and other relevant materials issued by third-party authorities.',//3
        W_niw_step2_evi_high4_tip4:'Cite the main part (listing the source from).',//4
        W_niw_step2_evi_high4_tip5:'(1)A report on the current status of work in your stated professional field.',
        W_niw_step2_evi_high4_tip6:'(2)Research on industry development trends.',
        W_niw_step2_evi_high4_tip7:'(3)U.S. national policy bills for your stated professional field.',
        W_niw_step2_evi_high4_tip8:'(4)Authoritative media reports, expert testimony, dissertation writings, and research notes on the importance of your stated professional field to the U.S.',
        W_niw_step2_evi_high4_tip9:'2.Introduction to evidence sources.',//9
        W_niw_step2_evi_high4_tip10:'(1)An official introduction (screenshots should attache links) to demonstrate the authority of the material.',
        W_niw_step2_evi_high4_tip11:'(2)Third-party evaluations and reports on the source.',
        W_niw_step2_evi_high4_tip12:'(3)The cited industry report material has created widespread discussion or media reports.',
    
        W_niw_step2_evi_ex1_tip1:'1.Official academic certificate.',
        W_niw_step2_evi_ex1_tip2:'2.Scanned copy of graduation certificate and degree certificate.',
        W_niw_step2_evi_ex1_tip3:'3.For Chinese petitioners, provide Chinese and English versions of verification report by Chinese Credentials Verification.',
        W_niw_step2_evi_ex1_tip4:'4.World Education Services (WES) evaluation report.',
        W_niw_step2_evi_ex1_tip5:'5.Transcript, colleges/universities introduction, professional fields introduction, etc.',

        W_niw_step2_evi_ex2_tip1:`1.If you have a Bachelor's Degree, you must demonstrate a minimum of 5 years of full-time work experience in your stated field to demonstrate an equivalent Master's Degree competency.`,
        W_niw_step2_evi_ex2_tip2:'2.Letters of verification or certificates of separation from all former/current working units.',
        W_niw_step2_evi_ex2_tip3:'Certificates should include the name, brief introduction, address, and contact number of your working unit, and provide a detailed presentation with the position you held, the projects you led, your hiredate, and your cumulative period of the job.',
        W_niw_step2_evi_ex2_tip4:'3.Also, you can provide the position announcement of your working unit. (e.g. introduction page for school teacher, or announcement page for hospital physician)',
        
        W_niw_step2_evi_ex3_tip1:'1.A copy of the license or certificate.',
        W_niw_step2_evi_ex3_tip2:'2.Introduction to the institution that issued the license or certificate. You can request an official brochure from the organization, or provide a profile of the official website.',
        W_niw_step2_evi_ex3_tip3:'3.Details of the criteria for the issuance of the license or certificate.',

        W_niw_step2_evi_ex4_tip1:'1. Salary certificate issued by the working unit, indicating that you obtain high salary/remuneration with outstanding ability in the professional field.',
        W_niw_step2_evi_ex4_tip2:`2. Provide the applicant's tax returns for three years.`,
        W_niw_step2_evi_ex4_tip3:'(Note: For Chinese applicants, one can download the tax form from the official app. If the salary certificate is inconsistent with your tax bill, then provide bank statement by shielding the expenditure and small income, and the account number and your name should be displayed by the statement.)',
        W_niw_step2_evi_ex4_tip4:'3.Provide evidence of average income and statistics of peer positions.',
        
        W_niw_step2_evi_ex5_tip1:'Membership of important associations, including but not limited to:', 
        W_niw_step2_evi_ex5_tip2:'1.Membership of important associations, including but not limited to:',
        W_niw_step2_evi_ex5_tip3:'2.Official introduction of the association, and constitution of the association.',
        W_niw_step2_evi_ex5_tip4:'3.Media reports about the association, introduction of the relevant person in charge of the association, and other members of the same rank as you.',
        W_niw_step2_evi_ex5_tip5:'4.If you are the leader of the association, provide a self-description of your duties.',
        W_niw_step2_evi_ex5_tip6:'5.Note: There should be a certain selection procedure for the membership. Associations with no requirements, or only require membership dues and working experience may not be accepted.',
        
        W_niw_step2_evi_ex6_tip1:'Evidence of Recognition of petitioner’s major contribution in the stated professional field, including bu not limited to:',
        W_niw_step2_evi_ex6_tip2:'1.Successfully led projects',//2
        W_niw_step2_evi_ex6_tip3:'(Provide evidence of relevant signed contracts, certificates issued by the company, project introduction, photos, internal documents, market adoption examples and influence, media reports of your related leading project, etc. You can make a point elaboration followed by project 1, Project 2, and so on. )',
        W_niw_step2_evi_ex6_tip4:'Below are relevant evidence you may refer to:',
        W_niw_step2_evi_ex6_tip5:'(1)Originality of business theory and implementation.',
        W_niw_step2_evi_ex6_tip6:'(2)Innovations of management methods and implementation.',
        W_niw_step2_evi_ex6_tip7:'(3)Implementation of important commercial projects engineering.',
        W_niw_step2_evi_ex6_tip8:'(4)Innovations of production processes, products, technologies, applications, services, etc.',
        W_niw_step2_evi_ex6_tip9:'(5)Innovations in public relations and communication, marketing activities, brand management, etc.',
        W_niw_step2_evi_ex6_tip10:'2.Achievements in your stated professional field',//10
        W_niw_step2_evi_ex6_tip11:'(Provide evidence of industry impact of your work, citation request of your work, corresponding media coverage, award recognition, etc.)',
        W_niw_step2_evi_ex6_tip12:'Evidence of industry impact and citation request of your work',
        W_niw_step2_evi_ex6_tip13:'(1)Evidence of industry impact and citation request of your work',
        W_niw_step2_evi_ex6_tip14:'(2)Citation request, citation data, illustration, thanks letter, etc.',
        W_niw_step2_evi_ex6_tip15:'(3)Achievement authorization contract, etc.',
        W_niw_step2_evi_ex6_tip16:'(4)Achievement value assessment report.',
        W_niw_step2_evi_ex6_tip17:'(5)Expert testimony, recognition certification by the government and authoritative organizations.',
        W_niw_step2_evi_ex6_tip18:'',
        W_niw_step2_evi_ex6_tip19:'Evidence of media reports',
        W_niw_step2_evi_ex6_tip20:`(1)*Copies or scans of the original magazine/newspaper media coverage, which should include the magazine front page/newspaper name with report title, date, and reporter's name.`,
        W_niw_step2_evi_ex6_tip21:'(2)*Screenshot of the online report’s original page.',
        W_niw_step2_evi_ex6_tip22:'(3)*Screenshot of the TV screen containing the station logo, the name of the program and the name of the beneficiary.',
        W_niw_step2_evi_ex6_tip23:'(4)*Recordings of radio and audio reports and translations of the text version.',
        W_niw_step2_evi_ex6_tip24:'(5)*Media introduction with information on distribution area, circulation and readership.',
        W_niw_step2_evi_ex6_tip25:'(6)*Third-party statistical comparison data on circulation.',
        W_niw_step2_evi_ex6_tip26:`(7)If the report made by a well-known journalist, attach the journalist's information.`,
        W_niw_step2_evi_ex6_tip27:'Evidence of awards and obtained commendations',
        W_niw_step2_evi_ex6_tip28:'(1)*Copies of certificates of awards, honors or rankings, and photos of trophies and medals. If the certificate is lost, you can also provide a letter of certification from the awarding organization or the organizing committee.',
        W_niw_step2_evi_ex6_tip29:'(2)A copy of the award notification.',
        W_niw_step2_evi_ex6_tip30:'(3)A list of the public announcement of the award by the organizer.',
        W_niw_step2_evi_ex6_tip31:'(4)Media reports on the awards or the winners.',
        W_niw_step2_evi_ex6_tip32:'(5)Photographs of the award presentation site.',
        W_niw_step2_evi_ex6_tip33:'(6)*The introduction of the organizer and the evaluation of the award organization.',
        W_niw_step2_evi_ex6_tip34:'(7)*Introduction to the awards’ domestic and international influence and status.',
        W_niw_step2_evi_ex6_tip35:'(8)*Introduction to the selection criteria, award conditions, entry qualifications, and campaign procedures of the awards.',
        W_niw_step2_evi_ex6_tip36:'(9)List of judges and introduction to the judges.',
        W_niw_step2_evi_ex6_tip37:'(10)*Introduction to the awards setting, including the awards type and winner numbers.',
        W_niw_step2_evi_ex6_tip38:'(11)Introduction to list of other winners and candidates.',
        W_niw_step2_evi_ex6_tip39:'(12)Introduction to list of previous awards and some of the celebrity winners.',
        W_niw_step2_evi_ex6_tip40:'',
        W_niw_step2_evi_ex6_tip41:'3.Involvement in developing or influencing industry standards',//41
        W_niw_step2_evi_ex6_tip42:'(Provide evidence of invitation letter for industry standard formulation, final document of standard, list of attendees, introduction, photos, report, etc.)',
        W_niw_step2_evi_ex6_tip43:'1.*Introduction to your experience on formulating standards/regulations of technology, industry, and product nationally or internationally.',
        W_niw_step2_evi_ex6_tip44:'2.*Applicable scope and region of the standards/regulations.',
        W_niw_step2_evi_ex6_tip45:'3.Introduction to other members of the standards/regulations development committee.',
        W_niw_step2_evi_ex6_tip46:'4.Relevant media reports and organization announcements.',
        W_niw_step2_evi_ex6_tip47:'',
        W_niw_step2_evi_ex6_tip48:'4. Recognition, awards, expert testimonials, letters of recommendation, etc., for your exceptional ability',//48
        W_niw_step2_evi_ex6_tip49:'(Certificates, reports, photographs, descriptions of recognizing organizations, etc.)',
        W_niw_step2_evi_ex6_tip50:' i)If you have been awarded or honored, please provide:',
        W_niw_step2_evi_ex6_tip51:'(1)Medals, trophies, certificates, prizes, etc. received from the award.',
        W_niw_step2_evi_ex6_tip52:'(2)Details of the criteria for the award.',
        W_niw_step2_evi_ex6_tip53:'(3)Specific regulations for eligibility of participation or selection in the award.',
        W_niw_step2_evi_ex6_tip54:'(4)Evidence of importance and recognition of the award, such as reports by mainstream media;',
        W_niw_step2_evi_ex6_tip55:'(5)Introduction to the awarding organization and the award presenter.',
        W_niw_step2_evi_ex6_tip56:'(6)Introductions to other former awardees.',
        W_niw_step2_evi_ex6_tip57:'ii) If you have been reported by the media, please provide complete media coverage,',
        W_niw_step2_evi_ex6_tip58:'which should include the following information:',
        W_niw_step2_evi_ex6_tip59:'(a) Title, date, and author.',
        W_niw_step2_evi_ex6_tip60:'(b) Circulation, readership, distribution or other similar evidence to prove the media report was circulated on a large scale.',
        W_niw_step2_evi_ex6_tip61:'(c) Target readers or viewers of the media reports.',
        W_niw_step2_evi_ex6_tip62:'iii)Expert testimony or letters of recommendation discussing your significant contributions',
        W_niw_step2_evi_ex6_tip63:'Letters and testimonies should specifically illustrate your contribution, and must focusing on explaining why the contribution is significant. Avoiding vague and general expression about.',
        W_niw_step2_evi_ex6_tip64:'',
        W_niw_step2_evi_ex6_tip65:'5.Other evidence of contribution to the industry.',//65

        W_niw_step2_evi_ex7_tip1:'Evidence supporting the description of your stated area and its importance in the petition letter.',
        W_niw_step2_evi_ex7_tip2:'Including but not limited to:',
        W_niw_step2_evi_ex7_tip3:'1.Industry market research reports, development trends, importance and other relevant materials issued by third-party authorities.',//3
        W_niw_step2_evi_ex7_tip4:'Cite the main part (listing the source from).',//4
        W_niw_step2_evi_ex7_tip5:'(1)A report on the current status of work in your stated professional field.',
        W_niw_step2_evi_ex7_tip6:'(2)Research on industry development trends.',
        W_niw_step2_evi_ex7_tip7:'(3)U.S. national policy bills for your stated professional field.',
        W_niw_step2_evi_ex7_tip8:'(4)Authoritative media reports, expert testimony, dissertation writings, and research notes on the importance of your stated professional field to the U.S.',
        W_niw_step2_evi_ex7_tip9:'2.Introduction to evidence sources.',//9
        W_niw_step2_evi_ex7_tip10:'(1)An official introduction (screenshots should attache links) to demonstrate the authority of the material.',
        W_niw_step2_evi_ex7_tip11:'(2)Third-party evaluations and reports on the source.',
        W_niw_step2_evi_ex7_tip12:'(3)The cited industry report material has created widespread discussion or media reports.',
        
        W_niw_step2_evi_ex8_tip1:'If you have other achievements that do not exactly match the description of the above categories of claims but are comparable, please provide specific evidence accordingly.',
        W_niw_step2_evi_ex8_tip2:'1.If you have reviewed other’s work in the stated professional field as an individual/a member of a jury, please provide:',//2
        W_niw_step2_evi_ex8_tip3:'(1)Evidence of words or audio-visual recordings to prove you complete the reviewing work as a judge or referee.',
        W_niw_step2_evi_ex8_tip4:'(2)Invitation/Thanks letter from the organizer, illustrating reasons for inviting you as a reviewer.',
        W_niw_step2_evi_ex8_tip5:'(3)Mainstream media coverage of your review work.',
        W_niw_step2_evi_ex8_tip6:'(4)Introductory materials of other reviewers/judges and other evidence that shows that the invited judges are elites in the industry.',
        W_niw_step2_evi_ex8_tip7:'(5)Introductory materials of the organizer and the competition, highlighting the national/international influence of the competition, and the exceptional ability and the industrial status of judges.',
        W_niw_step2_evi_ex8_tip8:'2.If you have published scholarly articles in the professional or mainstream publishing house, please provide:',//8
        W_niw_step2_evi_ex8_tip9:'(1)excerpts of all scholarly articles you published, including the following information:',
        W_niw_step2_evi_ex8_tip10:'a.Title page.',
        W_niw_step2_evi_ex8_tip11:'b.Author(s) page.',
        W_niw_step2_evi_ex8_tip12:'c.Journal’s name.',
        W_niw_step2_evi_ex8_tip13:'(2)Citation search results (after excluding your citations to self articles) from official academic websites (SciFinder, GoogleScholar, ISI Web of Knowledge, Scopus, PubMed, China Science Citation Database, CNKI, etc.), including the following information:',
        W_niw_step2_evi_ex8_tip14:'a.All your articles, the titles of those articles, and the journals in which they were published.',
        W_niw_step2_evi_ex8_tip15:'b.H-index and I-10 index (if available).',
        W_niw_step2_evi_ex8_tip16:'(3)Evidence like third-party reviews of the journal you published articles, to demonstrate target audiences of your academic articles are experts and scholars in the stated professional field.',
        W_niw_step2_evi_ex8_tip17:'(4)Evidence of the journal you published articles with national/international profession. For example:',
        W_niw_step2_evi_ex8_tip18:'a.Circulation, readership, and other comparable evidence.',
        W_niw_step2_evi_ex8_tip19:'b.Ranking of the journal. You can search it online at scimagojr.com or other similar websites. ；',
        W_niw_step2_evi_ex8_tip20:'3.If your work has been exhibited, please provide:',//20
        W_niw_step2_evi_ex8_tip21:'(1)Evidence that you are the author or principal creator of the artwork.',
        W_niw_step2_evi_ex8_tip22:'(2)Sales records of the artwork.',
        W_niw_step2_evi_ex8_tip23:'(3)Evidence like museum presentation materials that show the exhibit venue or medium (including virtual platform) was artistry.',
        W_niw_step2_evi_ex8_tip24:'(4)Promotional materials for the artwork.',
        W_niw_step2_evi_ex8_tip25:'(5)Promotional materials for art exhibitions.',
        W_niw_step2_evi_ex8_tip26:'4.If you have been played leadership/key role in a recognizing organization/institution, please provide:',//26
        W_niw_step2_evi_ex8_tip27:'(1)Documents that demonstrate your position and specific duties.',
        W_niw_step2_evi_ex8_tip28:'(2)A staffing chart or similar document of the organization/institution showing the your position within it.',
        W_niw_step2_evi_ex8_tip29:'(3)Documents that demonstrate that your contributions are extremely important to the activities and achievements of the organization/institution.',
        W_niw_step2_evi_ex8_tip30:'(4)A letter from a current/former employer who has a good understanding of the importance of the role you played, containing the following important information:',
        W_niw_step2_evi_ex8_tip31:'a.Specifically and effectively illustrate your leadership or the key role you played.',
        W_niw_step2_evi_ex8_tip32:'b.Compare your accomplishments to those of your peers in the same field.',
        W_niw_step2_evi_ex8_tip33:'(5)Evidence of the institution is with great reputation.',
        W_niw_step2_evi_ex8_tip34:'a.Media reports.',
        W_niw_step2_evi_ex8_tip35:'b.Ranking of the organization/institution.',
        W_niw_step2_evi_ex8_tip36:'5.If there is evidence such as box office receipts/records, cassettes, compact discs, or video sales records that you have achieved commercial success in the performing arts, please provide:',//36
        W_niw_step2_evi_ex8_tip37:'(1)Box office receipts or other comparable records for your performances (movies, dances, concerts, etc.)',
        W_niw_step2_evi_ex8_tip38:'(2)Evidence of distribution, like sales receipts or other comparable sales records of discs, DVDs, or other audio/video productions.',
        W_niw_step2_evi_ex8_tip39:'6.Other evidence.',//39
        

        W_diy_step3_title:'Recommendation Letter Guidelines:',
        W_diy_step3_tip1:'1.The recommendation letter should begin with the date and be addressed to:U.S. Department of Justice Immigration and Naturalization Service',
        W_diy_step3_tip2:'2.The one who recommended you must explicitly state the purpose of the letter — “I am writing this letter to support XX’s EB1A/NIW immigration application.” He/She should explicitly confirm the applicant’s major contributions.',
        W_diy_step3_tip3:'3.The letter must end with the his/her handwritten signature and contact details (phone number + email+address). The signature can be an electronic signature or a physical one. Usually, immigration officers do not call to verify the contents of the recommendation letter, but contact information is necessary.',
        W_diy_step3_tip4:'4.If he/she holds a position in a renowned organization, such as a school principal or a company executive, requesting the use of the organization’s letterhead for printing the recommendation letter will enhance its formality and authenticity.',
        W_diy_step3_tip5:'5.Typically, the number of recommendation letters is around 5, not less than 3. Among them, at least one should come from an independent recommender, someone who does not have a direct mentor-student or superior-subordinate relationship with the applicant.',
        W_diy_step3_tip6:'6.The letter should avoid excessive subjective evaluations or praise regarding the applicant’s character, qualities, or spirit. Descriptions such as “conscientiousness,” “helpfulness,” “hardworking dedication,” etc., are not helpful for EB1A and NIW immigration applications. Immigration officers are concerned with objective facts, focusing on the applicant’s contributions, achievements, remuneration, positions, and titles.',
        W_diy_step3_tip7:"7.When uploading the recommendation letter, include the recommender's resume, which should contain their name, phone number, email, date of birth, work experience, educational background, address, etc. (Alternatively, a screenshot of the recommender’s personal information from the official website can serve as their resume.)",

        W_diy_step4_title:'Suggestions for Drafting Plans for Going to the United States',
        W_diy_step4_tip1:'1.Use Objective Language: Employ objective and evaluative language without excessive emotional expression. Avoid direct and biased language that leans too much towards one country over the other.',
        W_diy_step4_tip2:'2.Point-wise Presentation: The plan to enter the U.S. is a crucial part, particularly for the National Interest Waiver (NIW). Emphasize the advantages of the plan to the United States, ensuring practical, objective, and compelling descriptions. Keep it concise, logical, and easily understandable for immigration officials.',
        W_diy_step4_tip3:'3.Apart from the professional analysis section, use simple and clear language for other parts, avoiding overly academic or scholarly expressions.',

        W_diy_step5_title:'Suggestions for Writing a Petition Letter:',
        W_diy_step5_tip1:'1. In the opening of the application letter, please specify which criteria of the U.S. EB1A immigration you already meet, and elaborate on each criterion that applies to you.',
        W_diy_step5_tip2:'2. In the statement of personal achievements, appropriately cite evidence to demonstrate its significance, authenticity, and authority.',
        W_diy_step5_tip3:'3. In the application letter, you will interconnect the supporting evidence and provide specific explanations. The language should be clear and concise, and the statements should be objective and concrete.',
        // niw
        W_niw_step5_tip1:'1.In the opening of the petition letter, please state which criteria for the NIW immigration program you meet (advanced degree or exceptional ability) and elaborate specifically based on meeting those standards.',
        W_niw_step5_tip2:'2.Discuss meeting the criteria for national interest waiver: Demonstrate the substantial merit and national importance of your work in the professional field; prove that you are well positioned to further the proposed endeavor in your field; establish how your proposed endeavor will benefit the United States enough to waive the requirement for a job offer and labor certificate.',
        W_niw_step5_tip3:'3.Incorporate appropriate citations and evidence in your statement of personal achievements to demonstrate their importance, authenticity, and authority.',
        W_niw_step5_tip4:'4.Connect the supporting evidence coherently and provide specific explanations. Use clear and objective language for the statements.',

        W_diy_step6_title:'Notice before Submission ',
        W_diy_step6_tip1:'1.Please ensure the completeness of your application materials before confirming that they are all finalized. Only after this confirmation can you proceed with downloading, printing, auxiliary review services, and auxiliary translation services.',
        W_diy_step6_tip2:'2.You can access or purchase the services you require by clicking on “My Benefits” in the top right corner of the workbench.',
        W_diy_step6_tip3:'3.The DIY workbench only supports online editable content for application materials. For sections requiring handwritten signatures, please download, print, and complete accordingly.',

        // 左侧菜单
        W_menu_l_list1:'Schedule',
        W_menu_l_list2:'Form',
        W_menu_l_list3:'Evidence',
        W_menu_l_list4:'Reference',
        W_menu_l_list5:'Endeavor',
        W_menu_l_list6:'Petition',
        W_menu_l_list7:'ReadMe',

        // 右侧菜单
        W_menu_r_box1_title:'My interest',
        W_menu_r_box1_list1:'EB-1A',
        W_menu_r_box1_niw_list1:'NIW',
        W_menu_r_box1_list2:'Consult',
        W_menu_r_box1_list3:'Translate',
        W_menu_r_box1_list4:'Audit',

        W_menu_r_box2_title:'Function',
        W_menu_r_box2_list1:'ReadMe',
        W_menu_r_box2_list2:'Preview',
        W_menu_r_box2_list3:'Guidance',

        W_menu_r_box3_title:'Question',
        W_menu_r_box3_btn:'view',

        // 功能引导 
        // 首次引导
        W_guidance_list1_title:'Stage 1',
        W_guidance_list1_tip:'Clearly understanding how to prepare evidence materials; click to quickly navigate different stages of application materials collection.',
        W_guidance_list2_title:'Stage 2',
        W_guidance_list2_tip:'This section notes some tips and techniques to assist you in using the workbench effortlessly.',
        W_guidance_list3_title:'Stage 3',
        W_guidance_list3_tip:'This is the main workbench of evidence material collection, please fill it carefully and completely.',
        W_guidance_list4_title:'Stage 4',
        W_guidance_list4_tip:'All your entitlements and benefits are shown here, you can click to purchase and use them.',
        W_guidance_list5_title:'Stage 5',
        W_guidance_list5_tip:'Click ‘Instructions’ to view the form instructions; click ‘Preview’ to clarify your thoughts on the application process and materials collection; click ‘Guidance’ to view the step-by-step instructions for each stage of the workbench.',
        W_guidance_list6_title:'Stage 6',
        W_guidance_list6_tip:'A quick search area designed specifically for you; if you have any doubts or confusion, searching for information might be a good option; if you can’t find the information you need, you can directly ask questions, and our online immigration consultants will assist or provide answers.',
        W_guidance_list7_title:'Guidance',
        W_guidance_list7_tip:'Click ‘Guidance’ to choose from the following guided contents: Form filling, Evidence collection, Recommendation letters, Travel Plans to the US, Petition letters etc.',
    
        // 表格须知
        W_FN_title:'Form Instructions',

        W_FN_title_EB1A:'A EB-1A application might involve the following forms. Please choose and fill out according to your individual requirements',
        W_FN_title_NIW:'A NIW application might involve the following forms. Please choose and fill out according to your individual requirements',
        W_FN_tip1:'Note: Before filling out the forms, carefully read the instructions for the specific form. ',
        W_FN_tip2:' If you have not purchased translation services, please fill in English.',
        W_FN_I140_title:'I-140 (Immigrant Petition for Alien Worker)',
        W_FN_I140_tip_EB1A:'Required for EB-1A immigrant application. USCIS official application fee: $700;Note: The application fee for the I-140 Form (Immigrant Petition for Alien Workers) will be raised from $700 to $715, effective April 1, 2024.',
        W_FN_I140_tip_EB1A_tip1:'Starting April 1, 2024, the new fee will take immediate effect without any grace period. Applicants must use the updated version of Form I-140 for their application and pay the updated fee. Failure to apply as required will result in the application not being accepted by USCIS.NOTE：Self-petitioners will need to pay an additional $300 asylum program fee.',
        W_FN_I140_tip_EB1A_tip2:'Form 140 Update:Update Version Date: June 7, 2024.Starting from October 21, 2024, USCIS will only accept the version dated June 7, 2024, which is the updated version.Applicants may continue to use the April 1, 2024 version, which is the previous version, until October 21, 2024.',
        W_FN_I140_tip_NIW:'Required for NIW immigrant application. USCIS official application fee: $700;Note: The application fee for the I-140 Form (Immigrant Petition for Alien Workers) will be raised from $700 to $715, effective April 1, 2024.',
        W_FN_I140_tip_NIW_tip1:'Starting April 1, 2024, the new fee will take immediate effect without any grace period. Applicants must use the updated version of Form I-140 for their application and pay the updated fee. Failure to apply as required will result in the application not being accepted by USCIS.NOTE：Self-petitioners will need to pay an additional $300 asylum program fee.',
        W_FN_I140_tip_NIW_tip2:'Form 140 Update:Update Version Date: June 7, 2024.Starting from October 21, 2024, USCIS will only accept the version dated June 7, 2024, which is the updated version.Applicants may continue to use the April 1, 2024 version, which is the previous version, until October 21, 2024.',

        W_FN_8089_title:'ETA-9089 (Labor Certification Application)',
        W_FN_8089_tip:'ETA-9089 is used for applying for a U.S. labor certification. If you are applying for NIW, it requires printing and filling out personal information, etc., and submitting it along with the I-140 form to the USCIS.',

        W_FN_I907_title:'I-907 (Request for Premium Processing Service)',
        W_FN_I907_tip:'If you wish to expedite the USCIS review within 15 days, you can apply for premium processing services. You need to submit the I-907 form with the I-140 form and additionally pay the expedited processing fee to the USCIS: $2500, totaling an application fee of $3200 (paid in two installments). Note: The I-140 premium processing fee increased from $2500 to $2805, officially effective from February 26, 2024.',
        W_FN_I907_tip1:'The new filing fee is effective for filings postmarked Feb. 26 and later. If you are filing an acceptable prior form edition, you must include the new filing fee. For filings sent by commercial courier (such as UPS, FedEx, and DHL), the postmark date is the date reflected on the courier receipt.',

        W_FN_1145_title:'G-1145 (Request for e-Notification)',
        W_FN_1145_tip:'You can fill out the G-1145 form to apply for the USCIS e-notification service, which allows you to receive electronic notifications about case updates. This service is free to apply for.',

        W_FN_1450_title:'G-1450 (Authorization for Credit Card Transactions)',
        W_FN_1450_tip1:'If you intend to pay your application fees by credit card, complete the G-1450 form. The USCIS accepts the following credit card types for applications:',
        W_FN_1450_tip2:'Visa, MasterCard, American Express, and Discover.',

        W_FN_btn1:'No Further Reminders',
        W_FN_btn2:'Know It',
        W_FRN_tip1:'No more reminders',
    },
    // 工作台 证据
    evidenceInfo: {
        // NIW 2主张方向
        EI_NIW_type_btn1:'Advanced Degree',
        EI_NIW_type_btn2:'Exceptional Ability',
        EI_NIW_type_title:'Current Criteria Claimed:',

        EI_NIW_title:'NIW - Type',
        EI_NIW_tip_name:'Basic Criteria:',
        EI_NIW_tip1:'Advanced Degree :(meet 1 of the 2 requirements) ',
        EI_NIW_tip2:'1.Possess a Master’s degree and or its foreign equivalent degree in your field of expertise;',
        EI_NIW_tip3:'2.Possess a baccalaureate or foreign equivalent degree plus 5 years of post-baccalaureate, progressive work experience in the field. ',
        EI_NIW_tip4:'Exceptional Ability:( at meet 3 of the 6 criteria):',
        EI_NIW_tip5:'1.Official academic record showing that you have a degree, diploma, certificate, or similar award from a college, university, school, or other institution of learning relating to your area of exceptional ability',
        EI_NIW_tip6:'2.Letters from current or former employers documenting at least 10 years of full-time experience in your occupation',
        EI_NIW_tip7:'3.A license to practice your profession or certification for your profession or occupation',
        EI_NIW_tip8:'4.Evidence that you have commanded a salary or other remuneration for services that demonstrates your exceptional ability',
        EI_NIW_tip9:'5.Membership in a professional association(s)',
        EI_NIW_tip10:'6.Recognition for your achievements and significant contributions to your industry or field by your peers, government entities, professional or business organizations ,Other comparable evidence of eligibility is also acceptable.',

        EI_NIW_type1:'Advanced Degree',
        EI_NIW_type2:'Exceptional Ability',

        EI_NIW_dia_title:'Switch Criteria ',
        EI_NIW_dia_tip:' Switching will result in changes to the claimed criteria. Except for work, education, and industry reports, other materials for the claimed criteria need to be re-uploaded. Please proceed with caution.',
        EI_NIW_dia_btn1:'Cancel',
        EI_NIW_dia_btn2:'Confirm Switch',

        EI_NIW_MSG_tip1:'The criteria has been confirmed',
        EI_NIW_MSG_tip2:'Claimed criteria confirmation failed',

        EI_box1_title:'Evidence Saved',
        // EB1A
        EI_box1_EB1A_list1:'Awards',
        EI_box1_EB1A_list2:'Association',
        EI_box1_EB1A_list3:'Media Reports',
        EI_box1_EB1A_list4:'Judge',
        EI_box1_EB1A_list5:'Original Contributions',
        EI_box1_EB1A_list6:'Scholarly Publication',
        EI_box1_EB1A_list7:'Exhibition',
        EI_box1_EB1A_list8:'Critical Role',
        EI_box1_EB1A_list9:'High Salary',
        EI_box1_EB1A_list10:'Commercial Successes',
        EI_box1_EB1A_list11:'Other Evidence',
        // NIW
        EI_box1_NIW_list1:'Degree',
        EI_box1_NIW_list2:'Job',
        EI_box1_NIW_list3:'Achievements',
        EI_box1_NIW_list4:'Supporting Evidence',
        EI_box1_NIW_list5:'License',
        EI_box1_NIW_list6:'Salary',
        EI_box1_NIW_list7:'Membership',
        EI_box1_NIW_list8:'Contributions',
        EI_box1_NIW_list9:'Others',

        EI_box1_btn1:'Adjust Order',
        EI_box1_tip1_a:'Provide at least 3 primary criteria evidence （',
        EI_box1_tip1_b:'additional evidence is required',
        EI_box1_tip1_c:'）',
        EI_box1_btn2:'Add evidence',
        EI_box1_btn3:'How to use?',

        EI_table1_list1:'Evidence',
        EI_table1_list2:'Order ',
        EI_table1_list3:'Saved Documents',
        EI_table1_list4:'Actions',
        EI_table1_list4_btn1:'Rearrange',
        EI_table1_list4_btn2:'Upload',
        EI_table1_list4_btn3:'Edit',
        EI_table1_list4_btn4:'Delete',

        EI_box2_title_a:'Evidence Display (',
        EI_box2_title_b:')',
        EI_box2_title_c:'no data',

        EI_box3_title_a:'Download Template ',
        EI_box3_title_b:'（For reference only, the template can only be downloaded after adding the claim criteria.）',
        EI_box3_tip1:'Reference template',
        EI_box3_btn1:'Click to download',

        EI_dia1_title1:'Adjust Order',
        EI_dia1_title2:'Click to download',
        EI_dia1_order_tip1:'The numbering of evidence will change after rearranging the order. If your evidence has been referenced, please modify it at the index, and reindex.',
        EI_dia1_order_table1_list1:'Order',

        EI_dia1_title:'Adjust the display order of claimed criteria',
        EI_dia1_name:'Claimed Criteria',
        EI_dia1_btn1:'Confirm',
        EI_dia1_btn2:'Cancel',

        // 添加证据文件
        EI_dia_add1_title:'Add New Evidence',
        EI_dia_add1_tip1:'Click to add the original file',
        EI_dia_add1_tip2:'If there is a website link, please attach it',
        EI_dia_add1_question1:'1.Does the original file require translation?',
        EI_dia_add1_tip3:'The translation requirement refers to whether the uploaded file’s content needs to be translated into English. For instance, select ‘Not Required’ if the file already has a translated version or if it contains non-text content.',
        EI_dia_add1_question1_Y:'Required',
        EI_dia_add1_question1_N:'Not Required',
        EI_dia_add1_question2:'Evidence Name',

        EI_dia_add2_tip1:'Click to add the original file',
        // EI_dia_add2_tip2:'若有网站链接，请附上',
        EI_dia_add2_question1:'2.Choose Translation Method',
        EI_dia_add2_question1_Y:'Translation Service (Recommended)',
        EI_dia_add2_question1_N:'Personal Translation',
        EI_dia_add2_question2:'Evidence Name (translated version)',

        EI_dia_add_tip1:'You have activated translation services, enabling you to use professional translation services',
        EI_dia_add_tip2_a:'You have not purchased and activated the translation service yet. You can enjoy a professional translation service when you purchased and activated the ',
        EI_dia_add_tip2_b:'Translation Service',

        EI_dia_add_btn1:'Cancel',
        EI_dia_add_btn2:'Confirm ',
        EI_dia_add_btn2_a:'Edit',
        EI_dia_add_btn2_b:'Addition',

        EI_dia_add_options1:'1.Awards',
        EI_dia_add_options2:'2.Association',
        EI_dia_add_options3:'3.Media Reports',
        EI_dia_add_options4:'4.Judge',
        EI_dia_add_options5:'5.Original Contributions',
        EI_dia_add_options6:'6.Scholarly Publication',
        EI_dia_add_options7:'7.Exhibition',
        EI_dia_add_options8:'8.Critical Role',
        EI_dia_add_options9:'9.High Salary',
        EI_dia_add_options10:'10.Commercial Successes',
        EI_dia_add_options11:'11.Other Evidence',

        EI_dia_add_NIW_options1_list1:'1. Official Academic Credentials (Degree)',
        EI_dia_add_NIW_options1_list2:'2. Proof of Full-Time Work Experience in the Professional Field (Job)',
        EI_dia_add_NIW_options1_list3:'3. Materials Related to Personal Professional Achievements (Achievements)',
        EI_dia_add_NIW_options1_list4:'4. Supporting Evidence Demonstrating the Importance of the Professional Field (Supporting Evidence)',

        EI_dia_add_NIW_options2_list1:'1.Official academic record showing that you have a degree, diploma, certificate, or similar award from a college, university, school, or other institution of learning relating to your area of exceptional ability. (Degree)',
        EI_dia_add_NIW_options2_list2:'2.Letters from current or former employers documenting at least 10 years of full-time experience in your occupation. (Job)',
        EI_dia_add_NIW_options2_list3:'3.A license to practice your profession or certification for your profession or occupation. (License)',
        EI_dia_add_NIW_options2_list4:'4.Evidence that you have commanded a salary or other remuneration for services that demonstrates your exceptional ability. (Salary)',
        EI_dia_add_NIW_options2_list5:'5.Membership in a professional association(s).(Membership)',
        EI_dia_add_NIW_options2_list6:'6.Recognition for your achievements and significant contributions to your industry or field by your peers, government entities, professional or business organizations. (Contribution)',
        EI_dia_add_NIW_options2_list7:'7.Related industry report demonstrating the importance of your proposed endeavor. (Supporting Evidence)',
        EI_dia_add_NIW_options2_list8:'8.Other comparable evidence of eligibility is also acceptable.(Others)',

        EI_dia_add_input_tip1:'Please choose whether to translate',
        EI_dia_add_input_tip2:'Please choose the translation method',
        EI_dia_add_input_tip3:'Please upload the original file',
        EI_dia_add_input_tip4:'Please upload the translated file',
        EI_dia_add_input_tip5:'Please fill in the evidence name',
        EI_dia_add_input_tip6:'Please fill in the evidence name',

        // 添加证据
        EI_dia_add_EviType_title:'Add New Evidence',
        EI_dia_add_EviType_question1:'Claimed Criteria',
        EI_dia_add_EviType_question1_tip:'Select Claimed Criteria',
        EI_dia_add_EviType_question2:'Evidence Name',
        EI_dia_add_EviType_question2_tip:'Enter Evidence Name',
        EI_dia_add_EviType_tip_a:'Note:After adding an evidence folder, you still need to click on the folder and follow the prompts to upload the corresponding evidence materials (details).',
        EI_dia_add_EviType_tip_b:'Details',
        EI_dia_add_EviType_btn1:'Cancel',
        EI_dia_add_EviType_btn2:'Confirm Addition',
        EI_dia_add_EviType_input_tip1:'Select Claimed Criteria',
        EI_dia_add_EviType_input_tip2:'Enter Evidence Name',

       // 修改证据
       EI_dia_set_title:'Edit Evidence',
       EI_dia_set_question1:'Evidence Name',
       EI_dia_set_table_name:'Uploaded Evidence',
       EI_dia_set_table_list1:'File  Name',
       EI_dia_set_table_list2:'Corresponding Translated File',
       EI_dia_set_table_list2_a:'No translation',
       EI_dia_set_table_list2_c:'Awaiting Official Translation',
       EI_dia_set_table_list3:'Evidence Name',
       EI_dia_set_table_list4:'Website Link',
       EI_dia_set_table_list5:'Actions',
       EI_dia_set_table_list5_btn1:'Edit',
       EI_dia_set_table_list5_btn2:'Delete',
       EI_dia_set_table_list5_btn2_tip:'If the evidence has been indexed, please make online adjustments for deletion at the indexed location on the webpage. Are you sure you want to delete?',
       EI_dia_set_btn1:'Cancel',
       EI_dia_set_btn2:'Confirm',
       EI_dia_set_input_tip:'Select Claimed Criteria',

       // 证据文件列表
       EI_select_text1:'Add Claim:',
       EI_select_tip1:'Select Claimed Criteria',
       EI_select_tip2:'No Claimed Criteria',
       EI_EvidenceFile_tip1:'Failed to obtain evidence folder',
       EI_EvidenceFile_tip4:'Failed to obtain evidence file',
       EI_EvidenceFile_tip2_a:'The following are suggestions for collecting evidence documents for the claim for the',
       EI_EvidenceFile_tip2_b:', and the content is provided for reference only. If the evidence document you wish to add is not covered by the types listed below, it can be uploaded to other categories.',
       EI_EvidenceFile_tip3:'Whether translation is needed refers to whether the content of the uploaded file needs to be translated into English. For example, choose not to translate if the file already has a translated version or contains no textual content, and vice versa.',

       // 证据文件组件
       EI_EvidenceFile_text1:'Official Translation',
       EI_EvidenceFile_text2:'No Translation',
       EI_EvidenceFile_text3:'expound',
       EI_EvidenceFile_text4:'Evidence Document',
       EI_EvidenceFile_textBtn1:'Close',
       EI_EvidenceFile_textBtn2:'Open',
       EI_EvidenceFile_textBtn3:'View Expound',
       


       


        // 前端提醒
        EI_tip1:'Please select the claimed criteria and the evidence name before uploading',
        EI_tip2:'Be Uploading',
        EI_tip3:'Upload Successful',
        EI_tip4:'Successfully Edited',
        EI_tip5:'Addition Failed',
        EI_tip6:'Save Successfully',
        EI_tip7:'Adjustment of order completed',
        EI_tip8:'Adjustment failure',
        EI_tip9:'Sequencing failure',
        EI_tip10:'Please select a claimed criteria',
        EI_tip11:'Download Template :',
        EI_tip12:'Change Failed',
        EI_tip13:'Added Successfully',
        EI_tip14:'Successfully Delete',
        EI_tip15:'Fail to Delete',
    },
    // 工作台 推荐信
    petition: {
        PT_title:'Recommendation Letters Saved',
        PT_table_list1:'Recommendation Letters',
        PT_table_list2:'Recommendation Letters (Translation)',
        PT_table_list3: "Referrer's Resume",
        PT_table_list4:"Referrer's Resume (Translation)",
        PT_table_list5:'Order of Evidence',
        PT_table_list6:'Actions',
        PT_table_list6_btn1:'Reorder',
        PT_table_list6_btn2:'View',
        PT_table_list6_btn3:'Edit',
        PT_table_list6_btn4:'Delete',
        PT_table_list6_btn4_tip:'As evidence has been indexed, please adjust or delete it online at the indexed location on the webpage. Are you sure you want to delete it?',
        PT_table_list6_btn5:'Upload',
    
        PT_temp_title_a:'Recommendation Letter Template',
        PT_temp_title_b:'（For reference only）',

        PT_temp_tab1_title:'Example 1',
        PT_temp_tab1_p1:'Name',
        PT_temp_tab1_p2:'Date',
        PT_temp_tab1_p3:'United States Department of Justice',
        PT_temp_tab1_p4:'Immigration and Naturalization Service',
        PT_temp_tab1_p5:'To Whom It May Concern,',
        PT_temp_tab1_p6:'I am XXX (introduction of the referrer), an expert in the field of XX. I am writing this letter to support Mr. XX’s immigration application under the EB1A/NIW immigration category.',
        PT_temp_tab1_p7:'I became acquainted with Mr. XX at... (introduction of the applicant’s contributions).',
        PT_temp_tab1_p8:'I consider Mr. XX to be one of the foremost experts in the field of XX. His/Her approval to immigrate to the United States would be tremendously advantageous to the development of the XX industry. Should you require further information about Mr. XX, please do not hesitate to contact me.',
        PT_temp_tab1_p9:'Sincerely,',
        PT_temp_tab1_p10:'XXX (Referrer’s Name)',
        PT_temp_tab1_p11:'Signature:',
        PT_temp_tab1_p12:'Email:',
        PT_temp_tab1_p13:'Phone:',
        
        PT_temp_tab2_title:'Example 2',
        PT_temp_tab2_p1:'Two-Part Writing',
        PT_temp_tab2_p2:'First Paragraph - Introduction of the Referrer (Brief Introduction), Name, Position, Achievements:',
        PT_temp_tab2_p3:'Presenting referrer information: Graduating institution, current position, showcasing the personal excellence of the referrer, such as being the president of an association, a high-ranking executive in a major company, an author of books, a published author of papers, a national-level project leader, an innovator in developing a certain material or product, or receiving recognized international or domestic awards in a specific field. ',
        PT_temp_tab2_p4:"Clearly showcase referrer’s high willingness to provide a recommendation letter for the applicant as an recognized and qualified industry expert and how the referrer got acquainted with the applicant (brief introduction).Highlighting the applicant's abilities in a particular field that the referrer deems professional and outstanding.",
        PT_temp_tab2_p5:'Second Paragraph - Emphasizing:',
        PT_temp_tab2_p6:'1.Emphasis on Innovation:',
        PT_temp_tab2_p7:'........ is groundbreaking at the provincial/national/worldwide level',
        PT_temp_tab2_p8:'........ is the first specialized work in the XX field ',
        PT_temp_tab2_p9:'2.Emphasis on Importance:',
        PT_temp_tab2_p10:'....... can be called a national-level researcher/artist in the XX industry',
        PT_temp_tab2_p11:'Has gained extensive respect and accolades within the industry, acclaimed as.......',
        PT_temp_tab2_p12:'...... has influenced the creative ideas of numerous peers',
        PT_temp_tab2_p13:'3.Emphasis on Uniqueness:',
        PT_temp_tab2_p14:'The sole individual to achieve this honor......',
        PT_temp_tab2_p15:'...... has stood out among XX peers/competitors',
        PT_temp_tab2_p16:'4.Emphasis on Continuity:',
        PT_temp_tab2_p17:'Over the span of X years, XX has consistently earned broad respect and accolades within the industry.',

        PT_temp_tab3_title:'Example 3',
        PT_temp_tab3_p1:'',
        PT_temp_tab3_p2:'November 18, 2022',
        PT_temp_tab3_p3:'U.S. Department of Justice',
        PT_temp_tab3_p4:'Immigration and Naturalization Bureau',
        PT_temp_tab3_p5:'Dear Sir/Madam,',
        PT_temp_tab3_p6:"I am XXX, currently serving as the Chief Engineer of the Electric Power Group at Shenyang Power Supply Company in Liaoning. I hold the title of Senior Engineer and am recognized as an expert committee member of the National Standards Committee for Bare Wires (SAC/TC422), a member of the China Electricity Council’s Power Line Materials Standardization Technical Committee, and the China Electrical Equipment Industry Association's Bare Wire Special Committee (CEEIA/T23). Additionally, I lead the Special Wire and Cable Application Technology Laboratory and have been the head of the New Materials for Electrical Workers Research Team at the State Grid Liaoning Power Company.",
        PT_temp_tab3_p7:"I have long been involved in research and development within the transmission field, focusing on special wire and energy-saving new material technologies. I have spearheaded over 20 national grid company scientific projects, from the composition of conductor materials to the precise control of alloy wire preparation, demonstrating practical applications. Our team has developed high-conductivity, heat-resistant aluminum alloy wires with conductivity rates of 61%, 61.5%, and 61.8%. These accomplishments were recognized by relevant academicians as “technologically at the forefront internationally.”",
        PT_temp_tab3_p8:`The results of my research have filled technological gaps in relevant fields, earning one second-class national scientific and technological progress award, twelve provincial-level awards, authoring ten national and industry standards, obtaining 27 invention patents, 43 utility model patents, publishing 21 papers in core journals, and writing two books. My research meets the needs of high-capacity, low-loss, and high-reliability power transmission, reducing the land area occupied by transmission corridors, which is vital support for major national power transmission projects.`,
        PT_temp_tab3_p9:'As an expert in the field of electrical engineering, I am writing this recommendation letter in support of Ms. XX’s immigration application under the EB1A clause.',
        PT_temp_tab3_p10:'I met Ms. XX in 2014 when we both participated in the construction of the Jiuquan Wind Power Base in Jiuquan, Gansu Province. This base is the fourth-largest wind power base in China. By 2015, the wind power installed capacity reached 12,710 MW, making it the fourth-largest wind power base in China. The transmission line of the Hexi Power Grid is about 1000KM long, making it the longest transmission distance in China and connecting the most 330KV substations. The complex terrain and unpredictable climate in the Hexi Corridor area made lightning protection and insulation for power transmission lines a challenging task.',
        PT_temp_tab3_p11:"In response to this issue, as one of the engineering contractors, the Shaanxi Shengjia Power Equipment Engineering Co., Ltd., led by Ms. XX, analyzed the relationship between the lightning tolerance level of ultra-high-voltage transmission lines and the waveform of lightning current. Using the EMTP simulation software's basic components, they established a lightning current model, transmission line tower model, insulator flashover model, and incoming line end model. By analyzing the data obtained from EMTP simulation software, they determined the lightning resistance level of ultra-high-voltage transmission lines under different lightning current waveforms. The analysis provided the basis for the economic and safe insulation level of ultra-high-voltage transmission lines under different lightning current waveforms, significantly advancing the construction of the Jiuquan Wind Power Base.",
        PT_temp_tab3_p12:"I highly recognize Ms. XX's professional achievements in the field of power engineering and firmly believe that her involvement in related work in the United States will significantly promote the development of American enterprises in the fields of electricity and environmental protection. If you need further information, please feel free to contact me.",
        PT_temp_tab3_p13:'Sincerely,',
        PT_temp_tab3_p14:'Email:',
        PT_temp_tab3_p15:'Phone:',

        PT_temp_tab4_title:'Example 4',
        PT_temp_tab4_p1:'United States Department of Justice',
        PT_temp_tab4_p2:'U.S. Citizenship and Immigration Services',
        PT_temp_tab4_p3:'Dear Sir/Madam,',
        PT_temp_tab4_p4:'I present this independent advisory opinion to persuade you that approving XXX’s application for permanent residency is prudent.',
        PT_temp_tab4_p5:'My Qualifications',
        PT_temp_tab4_p6:"My name is XXX, a practitioner of Traditional Chinese Medicine (TCM), currently serving as the President of XXX hospital and Chief TCM Physician. I have held positions as a committee member of XXX associations, director of XXX research committees, executive director of XXX branch committees, and more. In August 2014, I was conferred the title “XXXXXX,” and on September 29, 2019, I was awarded the title “XXXXXX.”",
        PT_temp_tab4_p7:"I have authored numerous specialized books and papers such as “XXXXXX,” “XXXXXX,” “XXXXXX,” “XXXXXX,” “XXXXXX,” etc. Additionally, I have nurtured a group of outstanding successors in TCM, carrying forward the essence and skills of Traditional Chinese Medicine.",
        PT_temp_tab4_p8:"Therefore, I think I am sufficiently qualified to assess XX’s professional level and contributions.",
        PT_temp_tab4_p9:'XX’s Professional Achievements',
        PT_temp_tab4_p10:"My first encounter with XX was at the 2021 Acupuncture and Tuina Technique Evaluation Event held by the Shenzhen Association of Traditional Chinese Medicine. XX, as a specially appointed expert of the association, provided profound insights into various therapeutic methods in TCM. Building upon the ancient method of thunder-fire moxibustion, XX innovatively combined modern medicine, incorporating aromatic properties within moxa to penetrate and activate blood circulation, alleviating menstrual pain in women. This approach allows for the selection of different acupuncture points based on the condition, effectively penetrating acupoints and producing a warming effect to dispel cold, invigorate blood, and unblock meridians.",
        PT_temp_tab4_p11:"The thunder-fire moxibustion method pioneered by XX integrates theories of TCM meridians and modern pharmacology. Not only does it possess superior compositions that effectively warm and open meridians, invigorate blood circulation, and regulate Qi and blood, but it also demonstrates originality in the selection of acupuncture points and technique. This method precisely targets different causes and symptoms, achieving the goal of expelling pathogenic factors and restoring balance. In hundreds of clinical practices at XX’s clinic, significant and consistent therapeutic effects were achieved with almost no adverse reactions. This green therapy is a rare gem in medical practice and deserves wide promotion and implementation in the field of TCM. This achievement led to XX being recognized as one of the intellectual experts by the Board of the China Society of Traditional Chinese Medicine Information, whose members are national-level experts in the field of Chinese medicine.",
        PT_temp_tab4_p12:'XX’s Achievements Met the Immigration Criteria',
        PT_temp_tab4_p13:"According to a 2020 study published in “BMC Women’s Health,” the incidence of dysmenorrhea in American women ranges from 50% to 90%, being a major cause of absenteeism and depression in females. Dysmenorrhea not only brings many physiological discomforts to women but also significantly affects their learning, work, and social activities, thus affecting their psychological health and even leading to severe depression. Currently, the treatment methods for dysmenorrhea in the United States mainly include drug therapy and non-drug therapy.Drug therapy primarily involves non-steroidal anti-inflammatory drugs (NSAIDs) and hormonal contraceptives. Drug therapy generally has good efficacy, effectively relieving symptoms in most women. However, drug therapy also has some drawbacks and side effects, such as the inability to fundamentally resolve the problem, requiring long-term use, potential side effects on the digestive tract, abnormal bleeding, thrombosis, interactions with other drugs or diseases, etc.",
        PT_temp_tab4_p14:'Non-drug therapy mainly includes lifestyle adjustments and alternative medical methods. Among them, alternative medical methods include acupuncture, moxibustion, Chinese herbal medicine, aromatherapy, biofeedback, etc., which can regulate endocrine and immune systems, balance Qi and blood, and relieve pain by stimulating acupuncture points or nerve endings. Compared with drug therapy, acupuncture treatment has the advantage of no drug side effects, no drug dependence or addiction, and effectively reduces the intensity and duration of pain. Therefore, the promotion of acupuncture treatment in the United States can to some extent alleviate the adverse reactions and drug dependence caused by excessive medication.',
        PT_temp_tab4_p15:'As a traditional medical method in Asia, thunder-fire moxibustion is not well known in the United States and lacks sufficient recognition and promotion. However, with the increasing interest and demand for natural therapy and alternative medicine, thunder-fire moxibustion will gain more attention and application in the United States in the future. Therefore, after XX arrives in the United States, leveraging his professional expertise and knowledge in TCM, he will promote the development and application of thunder-fire moxibustion therapy in the United States.',
        PT_temp_tab4_p16:'Conclusion',
        PT_temp_tab4_p17:'Based on my in-depth understanding of XX’s abilities and achievements, I offer my strongest support for his application for permanent residency. He is an outstanding and pioneering researcher in the field of acupuncture and Tuina massage in TCM. Combined with XX’s achievements and abilities, his continued efforts will significantly contribute to the national interests of the United States. I hope the above opinions will assist you in deciding whether XX is eligible for permanent residency in the United States. If you require more information, please feel free to contact me.',
        PT_temp_tab4_p18:'Sincerely,',
        PT_temp_tab4_p19:'XXX',
        PT_temp_tab4_p20:'Signature:',
        PT_temp_tab4_p21:'Contact:',

        PT_temp_tab5_title:'Example 5',
        PT_temp_tab5_p1:'Date:',
        PT_temp_tab5_p2:'United States Department of Justice',
        PT_temp_tab5_p3:'U.S. Citizenship and Immigration Services',
        PT_temp_tab5_p4:'Dear Sir/Madam,',
        PT_temp_tab5_p5:'I am presenting this independent recommendation letter to suggest you that approving the permanent residency application of Ms. XX is a wise decision.',
        PT_temp_tab5_p6:'My Background',
        PT_temp_tab5_p7:'I am the founder of XXX designer brand fashion company, currently serving as the legal representative and executive director of XXX Limited Company in Hangzhou. I am primarily responsible for the overall business operation planning and management of the company. With nearly 30 years in clothing design, I am a key driver of business strategies and achievements. Under my leadership, XXX has gradually grown to become one of China’s most distinctive and influential brands, gaining recognition and acceptance from consumers in China and other regions. With a presence in 1956 independent retail stores globally, apart from China, XXX has outlets in Australia, Russia, Japan, and other locations. Presently, XXX’s annual revenue is considerable, establishing it as a leading figure in the designer brand industry. I have always adhered to the philosophy of “art exploration, beautiful life,” aiming to create an artistic living atmosphere with XXX, allowing consumers to derive more pleasure from diverse artistic design products and experience a better artistic life. As a pioneering figure in the clothing design industry, I can objectively assess my distinguished peer Ms. XX.',
        PT_temp_tab5_p8:'Ms. XX’s Professional Achievements',
        PT_temp_tab5_p9:'Ms. XX and I have a similar on fashion design look. We both insist on integrating art with life, presenting an image of independent and playful femininity through vibrant colors. XX is an exceptional designer, and her designs have appeared multiple times in the world’s top four fashion weeks. Even before she established her personal brand XXX, her fashion works had participated in 3 London Fashion Weeks and 1 New York Fashion Week. In a short span, she has garnered numerous awards, including the 2017 Lane Crawford Creativity Award and the 2019 International Wool Bureau Asia-Pacific Region Champion. In her design work, she constantly seeks to balance technicality with design aesthetics, showcasing her romantic artistic side while revealing a rational aspect in her creations. Her use and coordination of colors follow her unique method, discovering patterns in her own way, and ultimately interpreting the balance of colors through knitting techniques to the extreme.',
        PT_temp_tab5_p10:'Ms. XX is a technology enthusiast among designers, and her passion for knitting technology has led her to seek collaborations with top industry factories, establishing technological barriers in knitting. She has already made breakthroughs in seamless knitting and applied them in subsequent design collections. In terms of the clothing design industry, she stands at the forefront of this field, and in the future, she will continue to lead the team in researching design technology to promote high-quality fashion design in the United States.',
        PT_temp_tab5_p11:'',
        PT_temp_tab5_p12:'Ms. XX Will Significantly Benefit the United States',
        PT_temp_tab5_p13:'Since 1970, due to the globalization of production and consumption, the manufacturing design industry in the United States has shifted to third-world countries, leading to a significant outflow of design personnel and a decline in the industry. It was not until the 1990s that there was a significant change in the industrial structure, and manufacturing design was given due importance. Today, the design industry is a rapidly growing sector, and the United States hopes to attract outstanding designers from around the world to address the talent gap. As evidenced by Ms. XX’s past achievements, her contributions are crucial to the national interests of the United States.',
        PT_temp_tab5_p14:'Conclusion',
        PT_temp_tab5_p15:'Based on Ms. XX’s outstanding abilities and significant contributions in the field of fashion design, I offer my strongest support for her application for permanent residency. Considering her achievements and capabilities, her continued efforts will significantly promote the national interests of the United States. I hope the above opinions will assist you in deciding whether Ms. XX qualifies for permanent residency in the United States. If you require further information, please feel free to contact me.',
        PT_temp_tab5_p16:'Sincerely,',
        PT_temp_tab5_p17:'XXX',
        PT_temp_tab5_p18:'Signature:',
        PT_temp_tab5_p19:'Company:',
        PT_temp_tab5_p20:'Position:',
        PT_temp_tab5_p21:'Contact:',
        PT_temp_tab5_p22:'Signing Date:',

        PT_temp_down_a:'Download Template ',
        PT_temp_down_b:'（For reference only）',
        PT_temp_down_name:'Reference Template',
        PT_temp_down_name2:'Click to Download',

        PT_tip1:'View',
        PT_tip2:'Edit Recommendation Letter',
        PT_tip3:'Adjusting the Order of Recommendation Letters',
        PT_tip4:'Order Adjustment Completed',
        PT_tip5:'Order Adjustment Failed',
        PT_tip6:'You haven’t fill out the name of the reference letter ！',
        PT_tip7:'Add Recommendation Letter',
        PT_tip8:'Add Recommendation Letter',

        DP_title:'Please select the file type to view:',
        DP_btnsBox1_btn1:'Original (Resume)',
        DP_btnsBox1_btn2:'Translated (Resume)',
        DP_btnsBox2_btn1:'Original (Letter)',
        DP_btnsBox2_btn2:'Translated (Letter)',


        UL_title1:'Upload Recommender’s Resume',
        UL_upfile1:'Click to add original file',
        UL_upfile1_ques1:'1.Does the original file need translation?',
        UL_upfile1_ques1_tip:'Does translation required” refers to whether the content of the uploaded file needs to be translated into English. For instance, if the file already has an English translation version or if it contains no textual content, you would select “No translation required.” Conversely, if the file contains textual content that needs to be translated into English, you would select “Translation required.',
        UL_upfile1_ques1_a:'Required',
        UL_upfile1_ques1_b:'No Required',
        UL_upfile1_ques2:'recommender’s name',
        UL_upfile1_ques3:'recommender’s job title',

        UL_upfile2:'Click to add original file',
        UL_upfile2_ques1:'2.Choose the translation method',
        UL_upfile2_ques1_a:'Translation Service (Recommended)',
        UL_upfile2_ques1_b:'Personal Translation',
        UL_upfile2_ques2:'recommender’s name',
        UL_upfile2_ques3:'recommender’s job title',

        UL_title2:'Upload Recommender Letter',
        UL_upfile3:'Click to add original file',
        UL_upfile3_ques1:'1.Does the original file need translation?',
        UL_upfile3_ques1_tip:'Does translation required” refers to whether the content of the uploaded file needs to be translated into English. For instance, if the file already has an English translation version or if it contains no textual content, you would select “No translation required.” Conversely, if the file contains textual content that needs to be translated into English, you would select “Translation required.',
        UL_upfile3_ques1_a:'Required',
        UL_upfile3_ques1_b:'No Required',

        UL_upfile4:'Click to add original file',
        UL_upfile4_ques1:'2.Choose the translation method',
        UL_upfile4_ques1_a:'Translation Service (Recommended)',
        UL_upfile4_ques1_b:'Personal Translation',

        UL_tip1:'You have access to translation services, enabling the use of professional translation services',
        UL_tip2_a:'You have not purchased and activated the translation service yet. You can enjoy a professional translation service when you purchased and activated the translation service.',
        UL_tip2_b:'Translate Service',

        UL_btn1:'Cancel',
        UL_btn2:'Confirm',
        UL_btn2_a:'Edit',
        UL_btn2_b:'Addition',

        UL_input_tip1:'Please choose whether to translate',
        UL_input_tip2:'Please choose the translation method',
        UL_input_tip3:'Please upload the original file',
        UL_input_tip4:'Please upload the translated file',
        UL_input_tip5:'Please entering refcommender’s name',
        UL_input_tip6:'Please enter the recommender’s job description',
        UL_input_tip7:'Please enter the name of the recommender (English)',
        UL_input_tip8:'Please enter the recommender’s job description (English)',
        UL_input_tip9:'Please choose whether to translate',
        UL_input_tip10:'Please choose the translation method',
        UL_input_tip11:'Please upload the original file',
        UL_input_tip12:'Please upload the translated file',
    },
    // 工作台 赴美计划
    USA_Plan: {
        UP_title1:'Saved Attachment', 
        UP_box1_btn1:'Upload Attachment', 
        UP_box1_tip1:'Notice: The attachments include the business proposal, the industry reports/data, job/collaboration invitations, etc. mentioned in the plan to go to the United States.',
        UP_table1_list1:'Attachment Name', 
        UP_table1_list2:'Attachment Name (Translated File)', 
        UP_table1_list3:'Order', 
        UP_table1_list4:'Actions', 
        UP_table1_list4_btn1:'Reorder ', 
        UP_table1_list4_btn2:'View ', 
        UP_table1_list4_btn3:'Delete', 
        UP_table1_list4_btn3_tip:'Are you sure you want to delete it?', 

        UP_longText_title:'Plan to Go to the United States',
        UP_longText_tip_a:'When writing your plan to go to the United States, we recommend that you first read the suggestions and instructions provided at the top of the page and in the online editing box, as they will assist you in your writing. ',
        UP_longText_tip_b_1:'If you haven’t purchased a ',
        UP_longText_tip_b_2:'translation service',
        UP_longText_tip_b_3:', please write in English.',
        UP_longText_tip_c:'The online editing box contains a content template provided for your reference. You can fill in the template with the content you wish to include.',
        
        UP_longText_EB1A_content1:'Writing Suggestions for Future Stay Plan in the U.S. (EB-1A)',
        UP_longText_EB1A_content2:'Professional and detailed market analysis to reflect your in-depth understanding of the overall development of the industry, preferably with a clear comparison of domestic/international market efficiency data, benchmarked to the United States.',
        UP_longText_EB1A_content3:'Detailed thoughts on career path.',
        UP_longText_EB1A_content4:'Strong intention to go to the United States. State what actions you have taken to settle in the United States, such as obtaining a language/professional/license certificate with U.S. recognition, receiving an offer from a U.S. company/a U.S. research studio, opening an American branch office, joining an American trade union, or developing a business partnership with a U.S. company.',
        UP_longText_EB1A_content5:'Your actions are purposed for U.S. national interests. The plan needs to be specific as to where you are settling, the job you are doing, the trade associations you are involved in, etc. You should demonstrate your knowledge of the current competitive marketplace for your work, such as existing shortcomings and industry/research dilemmas internationally and in the U.S. in particular, for which you can offer practical solutions. ',
        UP_longText_EB1A_content6:'Express your desire to go to the United States, emphasize your indispensability to the good of the United States, and earnestly ask the immigration officer to grant your immigration petition.',
        UP_longText_EB1A_content7:'（For example, if you are currently engaged in cutting-edge scientific research in the United States, but are unable to remain in the United States due to visa issues, and your departure would cause delays or failures in your work. So it makes sense to grant your application for permanent resident status. Or, if you are currently engaged in a research project/business activity that will bring great value to the U.S., and if your application fails, it will cost the U.S. economy hundreds of millions of dollars. So it makes sense to grant your application for permanent resident status.）',

        UP_longText_NIW_content1:'Writing Suggestions for Future Stay Plan in the U.S. (NIW)',
        UP_longText_NIW_content2:'Emphasize your willingness and commitment to contribute to the U.S. national interest',
        UP_longText_NIW_content3:'Field of study/work and background',
        UP_longText_NIW_content4:'Summarize your field of study/work and its importance to the U.S. national interest.',
        UP_longText_NIW_content5:'Emphasize your expertise, experience, and accomplishments.',
        UP_longText_NIW_content6:'Emphasize your current qualifications, demonstrate your ability to advance the program, and thus request a waiver of the labor certification and job offer letter.',
        UP_longText_NIW_content7:'Goal of Working in the U.S.',
        UP_longText_NIW_content8:'Describe the specific area of study/work you plan to do in the U.S.',
        UP_longText_NIW_content9:'State your long-term goals and career development plans.',
        UP_longText_NIW_content10:'Emphasize your intention to collaborate with relevant U.S. institutions, research teams, or industry.',
        UP_longText_NIW_content11:'Specific Research Plan or Project',
        UP_longText_NIW_content12:'Provide a detailed illustration of the research program or project.',
        UP_longText_NIW_content13:'State the research question/objectives and expected outcomes.',
        UP_longText_NIW_content14:'Explain the importance of the program/project to the field and the U.S.',
        UP_longText_NIW_content15:'Introduce the research methodology used and implementation plan.',
        UP_longText_NIW_content16:'Resources and Partners',
        UP_longText_NIW_content17:'Introduce the resources and equipment you plan to utilize.',
        UP_longText_NIW_content18:'Highlight collaborative relationships with relevant researchers, institutions, or partners.',
        UP_longText_NIW_content19:'Illustrate how these resources and partners will support and contribute to the program/project.',
        UP_longText_NIW_content20:'Time frame and Phased Goals',
        UP_longText_NIW_content21:'Provide a time frame for the research program/project.',
        UP_longText_NIW_content22:'List specific goals and plans for each phase.',
        UP_longText_NIW_content23:'Describe expected outcomes for each phase.',
        UP_longText_NIW_content24:'Societal Impact and Contribution',
        UP_longText_NIW_content25:'Describe how your research/work will impact and contribute to society.',
        UP_longText_NIW_content26:'Emphasize the importance of your contribution to solving problems, improving existing technologies, or driving economic growth.',
        UP_longText_NIW_content27:'Provide concrete examples and data to support your argument.',
        UP_longText_NIW_content28:'Concluding Remarks',
        UP_longText_NIW_content29:'Summarize the program and your commitment to the U.S.',
        UP_longText_NIW_content30:'Express your appreciation and desire for NIW approval.This refined outline will help you organize your language for specifically writing your future stay plan in the United States. Please provide clear and concise information in each section with examples and data to support your statements. Ensure that your plan is consistent with other parts of your application materials and highlights your expertise, research capabilities, and contributions.',

        UP_box3_title_a:'Import Document',
        UP_box3_title_b:'(if already written)',
        UP_box3_tip1:'Click or Drag to Add Original File',
        UP_box3_btn1:'Save',
        UP_box3_btn2:'Preview',

        UP_box4_title_a:'Download Template',
        UP_box4_title_b:'（For reference only）',
        UP_box4_tip1:'Reference Template',
        UP_box4_btn1:'Click to Download',

        UP_to_title:'U.S. Plan - Preview',

        UP_tip1:'Saving process is proceeding, please wait!!',
        UP_tip2:'Generation complete',
        UP_tip3:'Preview failure',
        UP_tip4:'File for preview is generating, please wait.',

        UP_dia_title1:'Add Attachment',
        UP_dia_title2:'Adjustment of Attachment Order',
        UP_dia_title3:'View',

        UP_dia1_tip1:'Click to add original file',
        UP_dia1_ques1:'1.Does the original file need translation?',
        UP_dia1_ques1_tip:'“Does translation required” refers to whether the content of the uploaded file needs to be translated into English. For instance, if the file already has an English translation version or if it contains no textual content, you would select “No translation required.” Conversely, if the file contains textual content that needs to be translated into English, you would select “Translation required.”',
        UP_dia1_ques1_a:'Required',
        UP_dia1_ques1_b:'No Required',
        UP_dia1_ques2:'File Name',

        UP_dia2_tip1:'Click to add original file',
        UP_dia2_ques1:'2.Choose the translation method',
        UP_dia2_ques1_a:'Translation Service (Recommended)',
        UP_dia2_ques1_b:'Personal Translation',
        UP_dia2_ques2:'File Name',

        UP_dia_tran_tip1:'You have access to translation services, enabling the use of professional translation services',
        UP_dia_tran_tip2_a:'You have not purchased and activated the translation service yet. You can enjoy a professional translation service when you purchased and activated the ',
        UP_dia_tran_tip2_b:'translation service',
        UP_dia_btn1:'Cancel',
        UP_dia_btn2_a:'Confirm ',
        UP_dia_btn2_b:'Edit',
        UP_dia_btn2_c:'Addition',

        UP_dia_input_tip1:'Please choose whether to translate',
        UP_dia_input_tip2:'Please choose the translation method',
        UP_dia_input_tip3:'Please upload the original file',
        UP_dia_input_tip4:'Please upload the translated file',
        UP_dia_input_tip5:'Please enter the evidence name',
        UP_dia_input_tip6:'Please enter the evidence name (English)',

        UP_dia3_View_tip:'Please select the file type to view:',
        UP_dia3_View_btn1:'Original file ',
        UP_dia3_View_btn2:'Translated file',
    },
    // wangEider
    wangEider:{
        wangEditer_inputBtn1:'Evidence Index',
        wangEditer_inputBtn2:'Reference Letter Index',

        wangEditer_tip_a:'The evidence will change after rearranging the order/adding new evidence/deleting evidence/changing',
        wangEditer_tip_b:'name. If your ',
        wangEditer_tip_c:' has been referenced, please modify it at the index, and reindex.',

        wangEditer_key1:'evidence ',
        wangEditer_key2:'reference letter',

        wangEditer_title1:'Saved ',
        wangEditer_list1_a:'Evidence Name',
        wangEditer_list1_b:'Name of the Reference Letter',
        wangEditer_list2:'Action',
        wangEditer_list_btn:'Choose',
    },
    //  工作台 申请信
    ApplyLetter:{
        AL_title:'Petition Letter',
        AL_box1_tip1:'a.When writing your petition letter, we recommend that you first read the suggestions and instructions provided at the top of the page and in the online editing box, as they will assist you in your writing. ',
        AL_box1_tip2_a:'b.If you haven’t purchased a ',
        AL_box1_tip2_b:'translation service',
        AL_box1_tip2_c:', please write in English.',
        AL_box1_tip3:'c.The online editing box contains a content template provided for your reference. You can fill in the template with the content you wish to include.',

        AL_box2_title:'Import Document',
        AL_box2_title_p:'(if already written)',
        AL_box2_tip1:'Click or Drag to Add Original File',
        AL_box2_btn1:'Save',
        AL_box2_btn2:'Preview',

        AL_box3_title_a:'Download Template ',
        AL_box3_title_b:'（For reference only）',
        AL_box3_tip1:'Reference Template',
        AL_box3_btn1:'Click to Download',

        AL_dia1_title:'Please choose service used',
        AL_dia1_tip1:'If you purchase two services at the same time, we will provide you with the Review Service first, and then the Translation Service.',
        AL_dia1_btn1:'comfirm',
        AL_dia1_btn2:'cancel',

        AL_tip1:'The petition letter is empty and cannot be saved!!!',
        AL_tip2:'Saving process is proceeding, please wait!!',
        AL_tip3:'Preview file is generating, please wait!',
        AL_tip4:'Generated successfully',

        AL_to_title:'Petition Letter —— preview',

        // 默认文本
        AL_EB1A_article1:'Framework for Drafting EB-1A Petition Letter',
        AL_EB1A_article2:'Before writing, you shall conduct the following steps first:',
        AL_EB1A_article3:'Collect relevant industrial/official reports, analyze existing materials, and define the specific field of expertise that you belong for application.',
        AL_EB1A_article4:'Review the current development status of the chosen field of expertise, including cutting-edge research, peer performance, government attention, and industry association focus on this specific field.',
        AL_EB1A_article5:'Once the field and profession are identified, gather supporting materials in accordance with the advocated criteria.',
        AL_EB1A_article6:'Contact outstanding professionals in the chosen or related field domestically and internationally for recommendation letters.',
        AL_EB1A_article7:'Understand the standards for petition letter writing and material organization, adhere to the official instructions to prepare and translate application materials and to draft the petition letter.',
        AL_EB1A_article9:'Cover Page：',
        AL_EB1A_article10:'Main Body of the Petition Letter:',
        AL_EB1A_article11:'1.Introduction of Personal Achievements and Qualifications in the Professional Field: Present an overview of individual accomplishments and qualifications within the professional domain.',
        AL_EB1A_article12:'2.In order to demonstrate you have qualification for applying EB1A immigration program, you shall meet 3 of the 10 listed criteria below:',
        AL_EB1A_article13:'(1).Evidence of receipt of lesser nationally or internationally recognized prizes or awards for excellence',
        AL_EB1A_article14:'(2).Evidence of your membership in associations in the field which demand outstanding achievement of their members',
        AL_EB1A_article15:'(3).Evidence of published material about you in professional or major trade publications or other major media',
        AL_EB1A_article16:'(4).Evidence that you have been asked to judge the work of others, either individually or on a panel',
        AL_EB1A_article17:'(5).Evidence of your original scientific, scholarly, artistic, athletic, or business-related contributions of major significance to the field',
        AL_EB1A_article18:'(6).Evidence of your authorship of scholarly articles in professional or major trade publications or other major media',
        AL_EB1A_article19:'(7).Evidence that your work has been displayed at artistic exhibitions or showcases',
        AL_EB1A_article20:'(8).Evidence of your performance of a leading or critical role in distinguished organizations',
        AL_EB1A_article21:'(9).Evidence that you command a high salary or other significantly high remuneration in relation to others in the field',
        AL_EB1A_article22:'(10). Evidence of your commercial successes in the performing arts',
        AL_EB1A_article23:'3. Demonstration of Your Outstanding Abilities: Substantiate the outstanding abilities of the applicant by providing detailed evidence and constructing a cohesive chain of proof based on the criteria asserted.',
        AL_EB1A_article24:'4.Explanation of How the Your Outstanding Abilities Place You Among the Top Tier in the Field: Clarify how the your exceptional abilities position you as one of the few that ascended to the very top of the field, garnering sustained recognition nationally and internationally.',
        AL_EB1A_article25:'5.Description of Your Intention to Work and Stay in the United States: Outline your intention to continue working in your field of expertise that require extraordinary ability in the United States, including an overview of past and present employment, post-immigration work plans, goals, and their significance.',
        AL_EB1A_article26:'6.Emphasis on the Importance of Your Research/Work in the U.S.: Highlight the significance of your research in your professional field to the United States and underscore their continuous substantial contributions to field development and society advancement.',
        AL_EB1A_article27:'7.Conclusion: Summarize the evidence and expert testimony, stating that your outstanding abilities have received consistent national and international acclaim. Assert that the achievements have been acknowledged in the field through implementation by others and widespread citation of your research. Affirm that you fully meets the eligibility criteria for the EB-1A application and request careful consideration and approval by immigration officers.',


        AL_NIW_article1:'Framework for Drafting NIW Petition Letter',
        AL_NIW_article2:'Before writing, you shall conduct the following steps first:',
        AL_NIW_article3:'1.Collect relevant industrial/official reports, analyze existing materials, and define the specific field of expertise that you belong for application.',
        AL_NIW_article4:'2.Review the current development status of the chosen field of expertise, including cutting-edge research, peer performance, government attention, and industry association focus on this specific field.',
        AL_NIW_article5:'3.Once the field and profession are identified, gather supporting materials in accordance with the advocated criteria.',
        AL_NIW_article6:'4.Outline the writing approach, and emphasize key points in drafting.',
        AL_NIW_article7:'5.Contact outstanding professionals in the chosen or related field domestically and internationally for recommendation letters.',
        AL_NIW_article8:'6.Understand the standards for petition letter writing and material organization, adhere to the official instructions to prepare and translate application materials and to draft the petition letter.',
        AL_NIW_article9:'Cover Page：',
        AL_NIW_article10:'Main Body of the Petition Letter:',
        AL_NIW_article11:'1.Introduction to Your Professional Field: Provide a concise overview of your professional field, offering immigration officers a brief understanding of the industry.(What you are doing is important.)',
        AL_NIW_article12:'2.Outline achievements in the professional field based on 6 criteria:',
        AL_NIW_article13:'(1) High academic qualifications',
        AL_NIW_article14:'(2) Work experience',
        AL_NIW_article15:'(3) Professional qualifications',
        AL_NIW_article16:'(4) Membership credentials',
        AL_NIW_article17:'(5) High salary ',
        AL_NIW_article18:'(6) Personal Important contributions to the industry',
        AL_NIW_article19:'(7) Other accomplishments',
        AL_NIW_article20:'3.Comparison with Peers: Compare the outlined achievements with those of peers, emphasizing the significance of your contributions. Utilize relevant industry data to highlight distinctions and contributions, placing particular emphasis on your unique qualities.',
        AL_NIW_article21:'4.Collection of U.S. Official Documents/Reports: Gather official U.S. documents/reports, advocating the importance of your field of expertise to the livelihood, the economy, environment, etc. for the United States. For the specific sub-field, delve into details, presenting intuitive data for clarity.',
        AL_NIW_article22:'5.Collection of U.S. Official Documents/Reports: Assert the scarcity and urgency of talents like yourself, emphasizing the contributions that professionals with your level of expertise can make to relevant U.S. industries. Provide a narrative on how individuals with your specialization can contribute to the United States.',
        AL_NIW_article23:'6.Discussion of Current Bottlenecks/Status in the Your Field of Expertise: Discuss the current state of your field of expertise, emphasizing how your current qualifications can drive and support the development of the industry.',
        AL_NIW_article24:'7.Conclusion: Summarize personal achievements, the current state of the industry, and U.S. policies related to the field. Propose that you have the capability to steer and contribute to your proposed endeavor, requesting immigration officers to exempt the labor certification and job offer requirements for the employment-based second preference category.',

    },
    // 工作台 提交必看
    ReadMe:{
        RM_title:'Notice before Submission ',
        RM_step1_title:'I.Verify the completeness of application materials',
        RM_step1_tip1:'I have confirmed the completion of all the uploaded and written materials.',
        RM_step1_tip2:'Please review carefully, tick the confirmation box before proceeding to the next step.',
        RM_step1_btn1:'I confirm.',

        RM_step1_list1_title:'Application forms',
        RM_step1_list1_type:'Check',
        RM_step1_list1_search:'Application forms',
        RM_step1_list1_tip1_a:'Please ensure that all the necessary forms are completely filled out. If you have not purchased ',
        RM_step1_list1_tip1_b:'translation services',
        RM_step1_list1_tip1_c:', please ensure that you complete the forms in English.',
        RM_step1_list1_tip2:'I-140 Form (mandatory)',
        RM_step1_list1_tip3:'I-907 Form (if you are Request for Premium Processing)',
        RM_step1_list1_tip4:'G-1145 Form (if you are Request for Electronic Notification)',
        RM_step1_list1_tip5:'G-1450 Form (if you are applying for Credit Card Payment)',
        RM_step1_list1_tip6:'ETA-9089 Form (must be downloaded, printed, and filled out for NIW petitioner)',
        

        RM_step1_list2_title:'Supporting documents',
        RM_step1_list2_type:'Check',
        RM_step1_list2_search:'Supporting documents',
        RM_step1_list2_tip1_a:'Please ensure that all your evidence material files have been uploaded as required. If you have not purchased ',
        RM_step1_list2_tip1_b:'translation services',
        RM_step1_list2_tip1_c:', please ensure that all uploaded original files are either in English or accompanied by certified English translations of the original documents.',
        RM_step1_list2_tip2:'Provide at lease 3 of the 10 claimed criteria',

        RM_step1_list3_title:'Recommendation letters',
        RM_step1_list3_type:'Check',
        RM_step1_list3_search:'Recommendation letters',
        RM_step1_list3_tip1_a:'Please ensure that all your reference letters have been uploaded as required. If you have not purchased ',
        RM_step1_list3_tip1_b:'translation services',
        RM_step1_list3_tip1_c:', please ensure that all uploaded original files are either in English or accompanied by certified English translations of the original documents.',
        RM_step1_list3_tip2:'1. The reference letter must conclude with the referencer’s handwritten signature (electronic signatures or stamps are acceptable) and contact information (phone number + email + address).',
        RM_step1_list3_tip3:'2. The typical quantity of reference letters is around 5, and should not be fewer than 3.',

        RM_step1_list4_title:'Plan to go to the United States',
        RM_step1_list4_type:'Check',
        RM_step1_list4_search:'Plan to go to the United States',
        RM_step1_list4_tip1_a:'Please ensure that your plan to go to the United States has been written or uploaded as required. If you have not purchased ',
        RM_step1_list4_tip1_b:'translation services',
        RM_step1_list4_tip1_c:',please ensure that all written context or uploaded original files are either in English or accompanied by certified English translations.',
        RM_step1_list4_tip2:'1.Writing language should be objective, point-wise, concise, and logical.',
        RM_step1_list4_tip3:'2.Ensure accurate indexing of evidence/reference letters (indexed evidence should correspond to their related names, sequence, and content in the “Evidence Collection”/ “Reference Letter” sections).',


        RM_step1_list5_title:'Petition letter',
        RM_step1_list5_type:'Check',
        RM_step1_list5_search:'Petition letter',
        RM_step1_list5_tip1_a:'Please ensure that your petition letter has been written or uploaded as required. If you have not purchased ',
        RM_step1_list5_tip1_b:'translation services',
        RM_step1_list5_tip1_c:', please ensure that all written context or uploaded original files are either in English or accompanied by certified English translations.',
        RM_step1_list5_tip2:'Ensure accurate indexing of evidence/reference letters (indexed evidence should correspond to their related names, sequence, and content in the “Evidence Collection”/ “Reference Letter” sections).',



        RM_step2_title:'II.Platform Services Guidance',
        RM_step2_tip1:'1.Review Service (Auxiliary Service)',
        RM_step2_tip2_a:'If you’ve purchased document review service, please click “One-Click Review” at the top right of the webpage to submit your materials for review. You can check the review status or cancel the review request in your ',
        RM_step2_tip2_b:'Personal Center',
        RM_step2_tip2_c:'My Plan',
        RM_step2_tip2_d:'My Benefits',
        RM_step2_tip2_e:'.',
        RM_step2_tip3:'2.Translation Service (Auxiliary Service)',
        RM_step2_tip4_a:'If you’ve purchased translation services, please click “Translation Service” at the top right of the webpage to submit your materials for translation. You can check the translation status or cancel the translation request in your ',
        RM_step2_tip4_b:'Personal Center',
        RM_step2_tip4_c:'My Plan',
        RM_step2_tip4_d:'My Benefits',
        RM_step2_tip4_e:'.',
        RM_step2_tip5:'3.Download and Print',
        RM_step2_tip6:'Please preview and download/print materials in your personal center under “My Plan.”',
        
        RM_step3_title:'III.Subsequent Procedures',

        RM_step3_list1_title:'Download and print materials, completing handwritten sections requiring signature.',
        RM_step3_list1_type:'',
        RM_step3_list1_search:'Download, Print and Sign the Application Materials',

        RM_step3_list2_title:'Application materials',
        RM_step3_list2_type:'Organize and compile',
        RM_step3_list2_search:'Organizing and Packing Application Materials',

        RM_step3_list3_title:'Submit application materials',
        RM_step3_list3_type:'to the Immigration Office.',
        RM_step3_list3_search:'Submission of Application Materials to USCIS',

        RM_step3_list4_title:'Check the progress of',
        RM_step3_list4_type:'your case review.',
        RM_step3_list4_search:'Checking Case Processing Status',

        RM_step3_list5_title:'( if necessary)',
        RM_step3_list5_type:'Response to Request for Evidence (RFE)',
        RM_step3_list5_search:'RFE (Request for Evidence)',

        RM_step3_list6_title:'Interview',
        RM_step3_list6_type:'',
        RM_step3_list6_search:'Immigrant Visa Processing (IVP) / Adjustment of Status (I-485) ',

        RM_step3_list7_title:'7.Obtain the Green Card.',
        RM_step3_list7_type:'',
        RM_step3_list7_search:'Immigrant Visa Processing (IVP) / Adjustment of Status (I-485) ',

        RM_tip1:'already identified',
        RM_tip2:'There are parts left unfilled, submission can’t be confirmed.',
        RM_tip3:'You need to log in to go to your personal center',
    },
    // 商品页面
    PayService:{
        PS_title:'Immigration Service Package Introduction',
        PS_box1_tip1:'The DIY Immigration Service (Standard Edition) serves as the basic service. ',
        PS_box1_tip2:'The Review Service and the Translation Service are supplementary and cannot be purchased independently. ',
        PS_box1_tip3:'The Review Service and the Translation Service are supplementary and cannot be purchased independently. ',
        PS_box1_tip4_a:'All DIY services are linked to the immigration program. In the case of purchasing errors or service replacements, ',
        PS_box1_tip4_b:'please contact us.',

        PS_box2_title:'Purchase Immigration  Service ',
        PS_box2_tip1:'Choose freely choose and pay for any packages with no hidden fees.',
        PS_box2_tip2_a:'Before making a purchase, please ensure that you have completed the ',
        PS_box2_tip2_b:'immigration assessment',
        PS_box2_tip2_c:' to avoid any issues in using the purchased services.',
        PS_box2_tip3:'select a state',
        PS_box2_tip4:'project selection',

        PS_box3_title:'Comparison of Immigration  Service Packages',
        PS_box3_tip1:'Review service and translation service are optional  services. ',
        PS_box3_tip2:'The following is a comparison of the service benefits included in each service package.',
        PS_box3_btn1:'Purchase Now',
        PS_box3_btn2:'Purchased',
        PS_box3_btn3:'Not Available',

        PS_dia3_title:'Complete Payment',

        PS_dia4_title:'Not Log In',
        PS_dia4_tip:'Please log in for service purchasing.',
        PS_dia4_btn1:'To Log In',
        PS_dia4_btn2:'I konw',


        PS_type_NIW:'USA_NIW',
        PS_type_EB1A:'USA_EB1A',

        PS_tip1:',Please check the purchased order in the personal center.',
        PS_tip2:'You have confirmed the payment, please wait for manual confirmation.',
        PS_tip3:'Confirmation failed, please try again.',
        PS_tip4:'Please log in to get free evaluation.',
        PS_tip5:'Payment completed, please wait.',
        PS_tip6:'Update failed.',

        // payCard 组件
        PC_type:'Extra',
        PC_title1:'Service',
        PC_right_top:'Recommend',
        PC_tip1:'cost',
        PC_btn1:'Purchased',
        PC_btn2:'Purchase Now',

        // 底部购买
        CMT_title1:'Tools',
        CMT_title2:'Supplementary Services',

        // 购买须知
        BK_title:'DIY Immigration Services Purchasing Instruction',

        BK_tip1:'Important Notice: This is a purchase notice! By purchasing the DIY services, you acknowledge that the immigration program must meet certain immigration criteria. ',
        BK_tip2:'The service is for single-person use only, and any violation will result in legal consequences.',
        BK_NIW_title:'To Apply for NIW Immigration Program, One Shall Meet one of the Two Criteria Below',
        BK_NIW_tip1:'1.Advanced Degree',
        BK_NIW_tip2:'1)Possess a Master’s degree and or its foreign equivalent degree in your field of expertise;',
        BK_NIW_tip3:'2)Possess a baccalaureate or foreign equivalent degree plus 5 years of post-baccalaureate, progressive work experience in the field. ',
        BK_NIW_tip4:'2.Exceptional Ability:( at meet 3 of the 6 criteria)',
        BK_NIW_tip5:'1)Official academic record showing that you have a degree, diploma, certificate, or similar award from a college, university, school, or other institution of learning relating to your area of exceptional ability',
        BK_NIW_tip6:'2)Letters from current or former employers documenting at least 10 years of full-time experience in your occupation',
        BK_NIW_tip7:'3)A license to practice your profession or certification for your profession or occupation',
        BK_NIW_tip8:'4)Evidence that you have commanded a salary or other remuneration for services that demonstrates your exceptional ability',
        BK_NIW_tip9:'5)Membership in a professional association(s)',
        BK_NIW_tip10:'6)Recognition for your achievements and significant contributions to your industry or field by your peers, government entities, professional or business organizations',

        BK_EB1A_title:'In order to demonstrate you have qualification for applying EB1A immigration program, you shall meet 3 of the 10 listed criteria below:',
        BK_EB1A_tip1:'1.Evidence of receipt of lesser nationally or internationally recognized prizes or awards for excellence',
        BK_EB1A_tip2:'2.Evidence of your membership in associations in the field which demand outstanding achievement of their members',
        BK_EB1A_tip3:'3.Evidence of published material about you in professional or major trade publications or other major media',
        BK_EB1A_tip4:'4.Evidence that you have been asked to judge the work of others, either individually or on a panel',
        BK_EB1A_tip5:'5.Evidence of your original scientific, scholarly, artistic, athletic, or business-related contributions of major significance to the field',
        BK_EB1A_tip6:'6.Evidence of your authorship of scholarly articles in professional or major trade publications or other major media',
        BK_EB1A_tip7:'7.Evidence that your work has been displayed at artistic exhibitions or showcases',
        BK_EB1A_tip8:'8.Evidence of your performance of a leading or critical role in distinguished organizations',
        BK_EB1A_tip9:'9.Evidence that you command a high salary or other significantly high remuneration in relation to others in the field',
        BK_EB1A_tip10:'10.Evidence of your commercial successes in the performing arts',

        BK_tip3_a:'I have read and agreed to the ',
        BK_tip3_to:'Purchase Agreement',
        BK_tip3_b:'“CasePass Immigration DIY Service Purchase Agreement.”',
        BK_tip4:'Purchase Agreement',
        BK_tip5:'Acknowledged and Continue to Purchasing.',
    },
    // 登录
    Login:{
        L_title:'Log in with mobile verification code',
        L_input_tip1:'type in',
        L_input_tip2:'type in',
        L_tip1:'get auth code',
        L_tip2:'Send again',
        L_tip3:'I have read and agree to the',
        L_tip4:'Terms of Service',
        L_tip5:'and',
        L_tip6:'Privacy Agreement.',
        L_dia_title:'Graphic verification code',

        L_btn1:'Log in',
        L_btn2:'Cancel',
        L_btn3:'Confirm',
    },
    // 弹窗翻译
    Dia:{
        D_title1:'Consultation Appointment',

        D_tip1:'We’ll contact at you as soon as possible.',

        D_dia1_title:'Submission Confirmed',
        D_dia1_tip:'You only got one free evaluation opportunity',
        D_dia1_btn1:'Check Again',
        D_dia1_btn2:'Confirm ',

        D_dia2_title:'U.S. Immigration Program Evaluation',
        D_dia2_tip1:'Submitted successfully, please wait patiently for the analysis results.',
        D_dia2_tip2:'The immigration consultant will contact you at the appointment time, please answer the phone/email/message promptly',
        D_dia2_tip3:'If you need to get the evaluation results as soon as possible, you can proactively contact customer service for premium response.',
        D_dia2_btn:'got it  ',

        // 工作台右侧审核1
        D_dia3_tip1:'You haven’t purchased this service, so you cannot use this feature.',
        D_dia3_tip2:'If you need the service, please click for ',
        // 工作台右侧审核2
        D_dia3_tip3:'Submit application materials for review and audit.',

        D_dia3_btn1:'details',
        D_dia3_btn2:'Understood ',
        D_dia3_btn3:'Activate Service',
        D_dia3_btn4:'Cancel ',
        D_dia3_btn5:'Confirm',

        // 审核提交成功
        D_dia4_title:'Successfully submitted',
        D_dia4_tip1:'1. If the review service is not performed, you can edit it at any time',
        D_dia4_tip2:' (The content cannot be edited again while the review service is in progress).',
        D_dia4_tip3:'2. You can check the progress and results in',
        D_dia4_tip4:'Personal Center> My Plan> My Benefits .',

        D_dia4_btn1:'Got',
        D_dia4_btn2:'Go to Personal Center',

        // 工作台右侧咨询1
        D_dia5_tip1:`You haven't purchased  consultation service, therefore, you cannot utilize this function. For service, please click for `,
        // 工作台右侧咨询2
        D_dia5_tip2:'Apply for consulting services, and a dedicated customer service representative will assist you immediately.',

        D_dia5_btn1:'details',
        D_dia5_btn2:'Understood ',
        D_dia5_btn3:'Activate Service',
        D_dia5_btn4:'Cancel',
        D_dia5_btn5:'I Want Consultation',

        // 咨询提交成功
        D_dia6_title:'Successfully submitted',
        D_dia6_tip1:'1.Please note that the exclusive customer service call later.',
        D_dia6_tip2:'(If you fail to answer the call the first time, we will raise the next call every five minutes. The consultant service provides three times calls, you will be considered to drop the service if all of them are not connected.)',
        D_dia6_tip3:'2.If you make a wrong click, please click below to cancel this consulting service.',
        D_dia6_tip4:'(Thanks for your understanding for a better experience.)',

        D_dia6_btn1:'Cancel',
        D_dia6_btn2:'Got',

        // 工作台右侧翻译1
        D_dia7_tip1:'You haven’t purchased this service, so you cannot use this feature. If you need the service, please click for ',
        // 工作台右侧翻译2
        D_dia7_tip2:'submit all the application materials for translation.',

        D_dia7_btn1:'details',
        D_dia7_btn2:'Understood ',
        D_dia7_btn3:'Activate Service',
        D_dia7_btn4:'Cancel ',
        D_dia7_btn5:'Confirm',

        // 翻译提交成功
        D_dia8_title:'Successfully submitted',
        D_dia8_tip1:'1. If the translation service is not performed, you can edit it at any time',
        D_dia8_tip2:'(The content cannot be edited again while the translation is in progress)',
        D_dia8_tip3:'2. You can check the progress and results in ',
        D_dia8_tip4:'Personal  Center> My Plan> My Benefits.',

        D_dia8_btn1:'Got',
        D_dia8_btn2:'Go to Personal Center',
    },
    // 9089表
    ETA_9089:{
        ETA_title:'ETA-9089 Application Form',
        ETA_tip1:'1.Download and complete the personal information part (Part J-L) in English.',
        ETA_tip2:'2.Follow the form instructions to fill out the form and sign and date on page L.',
        ETA_tip3:'3.For any uncertain areas, fill in N/A.',
        ETA_tip4:'The NIW immigration program application requires filling out the ETA-9089 form. Failure to submit it will result in the application being rejected.',
        ETA_tip5:'ETA-9089 is a form required by the U.S. Department of Labor (DOL) for labor certification applications under the EB-2 and EB-3 immigration categories.Although the labor certification is exempted in the NIW application, applicants still need to submit the ETA-9089 form together with the I-140 form to the USCIS to prove that he/she meets the requirements of the U.S. labor market.',
        ETA_tip6_a:'You can click to download the ETA-9089 form and official instructions. After printing, follow the "',
        ETA_tip6_b:'ETA-9089 Form Instructions',
        ETA_tip6_c:'" to manually fill in the form.',
        ETA_tip7:'Original ETA-9089 Form',
        ETA_tip8:'Form ETA-9089 Instructions',
        ETA_tip9:'Instructions for Filling (Instructions)',

        ETA_file1:'ETA-9089 Application Form.PDF',
        ETA_file2:'ETA-9089 Official Form Instructions.PDF',
        ETA_file3:'ETA-9089 Form Filling Guide.PDF',
    },
    Web_title:{
        title1:'Login',
        title2:'CasePass-Home Page',
        title3:'USA-EB-1A-immigrant(DIY)',
        title4:'USA-NIW-immigrant(DIY)',
        title5:'Audit Consultation',
        title6:'Translation Service',
        title7:'About Us',
        title8:'Immigration Agent',
        title9:'Q&A Zone',
        title10:'Free Assessment',
        title11:'HK Assessment',
        title12:'Shop',
        title13:'Personal Center',
        title14:'Personal Details',
        title15:'Message Reminding',
        title16:'My plan',
        title17:'Purchase History',
        title18:'EB-1A',
        title19:'NIW',
        title20:'Functional Guidance',
        title21:'PDF Show',
        title22:'Comparison Preview',
        title23:'Preview',
    }
}