import axios from "../utils/request";

export function toLogin(params) {
    return axios.post('/auth/customer/login_register/',params)
}

export function getAuthCode(params) {
    return axios.post('/auth/customer/get_captcha/',params)
}

export function getNoteCode(params) {
    return axios.post('/auth/customer/send_sms_code/',params)
}

export function refreshToken(params){
    return axios.post('/auth/token/refresh/',params)
}

export function getAreaCode(){
    return axios.get('/customer/phone_area_code/',{
        params:{
            size:200
        }
    })
}

export function getTimeZones() {
    return axios.get('/customer/messy/timezones/')
}