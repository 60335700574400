import { getNotRead } from '@/api/customer.api'

export const personalCenterModule = {
    state: {
        notRead: false,

    },
    mutations: {
        setNotReadNum(state, newval) {
            state.notRead = newval;
            console.log("notRead:" + state.notRead)
        }
    },
    actions: {
        asyncSetNotReadNum({ commit }){
            getNotRead().then(res=>{
                if(res.data.count > 0) {
                    commit('setNotReadNum',true)
                }else{
                    commit('setNotReadNum',false)
                }
            })

        }
    },
}
