import axios from "../utils/request";

export function getCustomer() {
    return axios.get('/customer/customer/myself/')
}

//注销账号
export function delCustomer(){
    return axios.delete('/customer/customer/delete_account/')
}

//获取消息
export function getMessageRemind(params){
    return axios.get('/customer/notification/',{params})
}

//删除消息
export function delMessage(params){
    return axios.delete(`/customer/notification/${params.id}/`)
}

//删除信息  多项
export function delMessageAll(params){
    return axios.post('/customer/notification/delete_by_ids/',params)
}

//消息已读
export function MessageRead(id){
    return axios.patch(`/customer/notification/${id}/`,{is_read:true})
}

//商品列表 
export function getProduct(params){
    return axios.get('/customer/product/',{params})
}

//移民项目级联数据
export function getAreaProject(){
    return axios.get('/customer/project/area_projects/')
}


//登录用户获取短信验证码
export function getAuthCode(params) {
    return axios.post('/customer/customer/send_sms_code/',params)
}

//修改手机号
export function setPhoneNum(params) {
    return axios.post('/customer/customer/change_phone_number/',params)
}

//获取计划信息
export function getPlanData(params) {
    return axios.get('/customer/plan/',{params})
}

//获取我的权益
export function getMyrights() {
    return axios.get('/customer/customer/my_rights/')
}

//我购买的服务
export function getBuyServe(params) {
    return axios.get('/customer/customer/my_bought_services/',{params})
}
//我购买的服务(所有)
export function getBuyServeAll() {
    return axios.get('/customer/customer/my_bought_project_services/')
}

//未读消息数量
export function getNotRead() {
    return axios.get('/customer/notification/not_read_count/')
}

//取消提交任务
export function cancalTask(id) {
    return axios.patch(`/customer/task/${id}/cancel_submit/`)
}

//提交任务
export function submitTask(id) {
    return axios.patch(`/customer/task/${id}/submit/`)
}



//修改用户信息
export function setCustomer(params) {
    return axios.patch(`/customer/customer/edit/`,params)
}

//新建联系人
export function addCustomer(params) {
    return axios.post(`/customer/contact/`,params)
}

//获得评估信息
export function getAssessment(params) {
    return axios.get('/customer/assess/my_assessment/',{params})
}

//保存评估信息
export function setAssessment(params) {
    return axios.patch('/customer/assess/update_my_assessment/',params)
}

//提交评估
export function submitAssessment(id,params) {
    return axios.patch(`/customer/assess/${id}/submit/`,params)
}

//答疑目录
export function getCategory() {
    return axios.get('/customer/qa_category/')
}

//根据ID获取问答详情
export function getQaDetails(id) {
    return axios.get(`/customer/qa/${id}/`)
}

//问答列表
export function getQuestionList(params) {
    return axios.get('/customer/qa/',{params})
}

//热门关键词
export function getHotKeyWordList() {
    return axios.get('/customer/qa/hot_keywords/')
}

//新建问答
export function newQuestion(params) {
    return axios.post('/customer/qa/',params)
}

//新建反馈建议
export function newSuggest(params) {
    return axios.post('/customer/advice/',params)
}

// 申请生成总PDF
export function requestGenerationDownload(id){
    return axios.patch(`/customer/plan/${id}/apply_print_material/`)
}

//获得静态文件URL
export function getStaticFile() {
    return axios.get(`/customer/messy/static_files/`)
}

// 设置ETA-9089表格已经下载
export function setETA9089FormDown(id){
    return axios.patch(`/customer/immigration_data/${id}/set_form_eta_9089_downloaded/`)
}

// 模板文件接口（需登录购买后）
export function getGuidanceZipFiles(params) {
    return axios.get(`/customer/project/guidance_zip_files/`,{params})
}

// 获取私有指导文件 (需要登录)
export function getPrivateInstructionFile(params) {
    return axios.get(`/customer/project/instruction_pdf_files/`,{params})
}
/**
 * 
 * 获取审核翻译下载文件地址  params 
 */
export function getFileDownUrl(id) {
    return axios.get(`/customer/task/${id}/get_zip_file_url/`)
}






