import { createStore } from 'vuex'
import createPersistedstate from 'vuex-persistedstate'

import { personalCenterModule } from '@/store/module/personalCenter'
/**
 * 创建仓库和导出
 */
export default createStore({
    state: {
        customer:localStorage.getItem('Customer')?JSON.parse(localStorage.getItem('Customer')):null,
        buyingLeads:localStorage.getItem('buyingLeads')?JSON.parse(localStorage.getItem('buyingLeads')):null,
        assessData:{
            US:{},
            HK:{}
        },
        staticFiles:{},
        // 跳转到支付页面的参数
        paymentPageData:false,
    },
    mutations: {
        setCustomerPhone(state, newval) {
          state.customer.fuzzy_phone = newval
        },
        setCustomer(state,newval){
            state.customer = newval
            localStorage.setItem('Customer',JSON.stringify(newval))
        },
        setBuyingLeads(state,newval){
            state.buyingLeads = newval
            localStorage.setItem('buyingLeads',JSON.stringify(newval))
        },
        // US评估
        setAssessDataUS(state,newval){
            state.assessData.US = {...state.assessData.US,...newval}
        },
        // HK评估
        setAssessDataHK(state,newval){
            state.assessData.HK = {...state.assessData.HK,...newval}
        },
        setStaticFiles(state,newval){
            state.staticFiles = newval
        },
        setPaymentPageData(state,newval){
            state.paymentPageData = newval
        },
        // 修改登录的私有文件(EB1A)
        setEB1APrivateInstructionFileData(state,newval){
            state.privateInstructionFileData.EB1A = newval
        },
        // 清空store仓库
        RESET_STATE: (state) => {
            Object.assign(state, getDefaultState())
            console.log('清理了')
        },
    },
    modules:{ personalCenterModule },
    plugins: [
        createPersistedstate({
            storage:window.localStorage,
            key:'store',
            render(state){
                return {...state}
            }
        })
    ]
})

const getDefaultState = () => {
    return {
        customer: null,
        buyingLeads:null,
        assessData:{
            US:{},
            HK:{}
        },
        staticFiles:{},
        // 跳转到支付页面的参数
        paymentPageData:false,
    }
}